//https://codesandbox.io/s/f0mzp?file=/src/WaveSurferComponent.js:0-3407
import React from "react";
import {
  Button,
  Input,
} from "reactstrap";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.js';

class WaveSurferModuleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [
        {
          id: "sample1",
          start: 10, // time in seconds
          end: 50, // time in seconds
          color: "hsla(100, 100%, 30%, 0.1)"
        }
      ],
      context: null,
      processor: null,
      showVolSlider:false,
      isAudioPlaying:false,
      elapsedSeconds:this.formatTime(0),
      audioSeconds:0,
      audioDuration:this.formatTime(0),
      playing:false,
      attemptCount:0,
      startButtonClicked:false,
      hidePlayPause:true,
      startRecording:false,
      recording:false,
      recordedBlob:null,
      startRecordingButtonClicked:false,
      recordPlaying:true,
      startModal:false,
      attemptFinishModal:false,
      startCount:0,
      playerAudio:this.props.audio,
      parentAutoPlay:true,
      showTimetimer:true,
      segmentIndex:-1,
      showPlayingLabel:true
    };
    this.timelineTimer = React.createRef();
    this.timelineTimer.current = null;

    this.recordingStartTimerRef = React.createRef();
    this.recordingStartTimerRef.current = null;

    this.childComponent = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({playerAudio:nextProps.audio})
    return true
  }

  /*componentWillUnmount(){
    console.log("CWU")
    if (this.wavesurfer.isPlaying())
      this.wavesurfer.stop();
    //this.wavesurfer = null;
    //this.record = null;
  }
*/
  componentDidMount() {
    //console.log("AP:",this.props.autoPlayAllowed, this.state.parentAutoPlay, this.props.audio)
    this.wavesurfer = WaveSurfer.create({
      height: 80,
      interact:true,
      barWidth: 2,
      //cursorWidth: 15,
      autoplay: this.props.autoPlayAllowed,
      container: this.props.containerEle,
      backend: "MediaElement",
      progressColor: "#4a74a5",
      responsive: true,
      waveColor: "#ccc",
      cursorColor: "#4a74a5",
      url: this.props.audio,
    });
    this.record= null;
    this.recordedWavesurfer = null;
    
    
    this.record = this.wavesurfer.registerPlugin(RecordPlugin.create())
    /*
    //this.wavesurfer.load(this.state.playerAudio);
    */

    // get duration on ready callback
    this.wavesurfer.on("ready", (e) => {
      //console.log("ready",this.wavesurfer.getDuration(), )
      this.setState({audioDuration:this.formatTime(this.wavesurfer.getDuration())})
      if (this.wavesurfer.isPlaying())
        this.startAudioTimelineTimer() // start timer
    });

    this.wavesurfer.on("finish", (e) => {
      //console.log("FINISH AUDIO PLAYING", this.props.forSegment)
      this.stopAudioTimelineTimer();
      
      if (this.props.handlerAudioFinish)
        this.props.handlerAudioFinish()

      if (this.props.forSegment){
        //console.log("5. FINISH SEGMENT AUDIO", this.props.forSegment)
        this.reinitializeRecordingWaveform()
        //console.log("5.1 RE-INITIALIZE WS RECORD", this.wavesurferRecord)
        this.segmentRecordingCommonHandler(false)
      }
    });
  }

  reinitializeRecordingWaveform = (waveContainerEle="#waveform", autoplayProp=false, audioBlob=null) => {
    let playerAudioUrl = audioBlob!=null?audioBlob:this.state.playerAudio;
    //console.log("PAU:", playerAudioUrl)
    this.wavesurfer = WaveSurfer.create({
      height: 80,
      interact:true,
      autoplay:autoplayProp,
      barWidth: 2,
      container: waveContainerEle,
      backend: "MediaElement",
      progressColor: "red",
      responsive: true,
      waveColor: "purple",
      cursorColor: "green",
      audioRate:10,
      url:playerAudioUrl,
    });
    this.record = this.wavesurfer.registerPlugin(RecordPlugin.create())
    setTimeout(()=>{
      //console.log("AFETR TIMEOUT", this.wavesurfer.getDuration())
      this.setState({
        audioDuration:this.formatTime(this.wavesurfer.getDuration()),
      });
    },1200)
    this.wavesurfer.on("finish", (e) => {

      this.stopAudioTimelineTimer();
      //console.log("ON END", this.state.startCount, this.props.forSegment)
      if (this.props.handlerAudioFinish)
        this.props.handlerAudioFinish()
      // start recording for other modules questions once audio finishes
      //if (this.state.startCount > 0){
        this.record.startRecording().then(() => {
          //console.log("1.d Recording started")
          this.setState({
            startRecordingButtonClicked:true,
            recording:true,
            showTimetimer:false,
            hidePlayPause:true,
            startButtonClicked:true,
          });
        });        
      //}
    })
  }

  clearContainer = (elem="recorded_waveform", removeChild=false) => {
    var myNode = document.getElementById(elem);
    //console.log("CC", myNode, removeChild, elem)
    if (myNode !== null){
      var fc = myNode.firstChild;
      if (removeChild){
        while (fc.nextSibling) {
          myNode.removeChild(fc.nextSibling);
        }      
      }else{
        // remove first child only
        myNode.removeChild(fc);
      }
    }
  }
  showRecordedWaveform = (audioBlob, loadAudioOnly=false, waveContainerEle="#recorded_waveform", autoplayProp=false) => {
    
      //console.log("ELSE",this.recordedWavesurfer);
      if (this.recordedWavesurfer !== null){
        //console.log("IN IF", this.recordedWavesurfer)
        //this.recordedWavesurfer.destroy();
        //this.recordedWavesurfer = null;
       /* this.recordedWavesurfer = WaveSurfer.create({
          height: 80,
          interact:false,
          autoplay:autoplayProp,
          barWidth: 2,
          container: waveContainerEle,
          backend: "MediaElement",
          progressColor: "green",
          responsive: true,
          waveColor: "yellow",
          cursorColor: "black",
          audioRate:10,
          url:audioBlob,
        });*/
        this.recordedWavesurfer.load(audioBlob);
        //this.clearContainer("recorded_waveform", false)

      }else{
        //console.log("IN ELSE")
        this.recordedWavesurfer = WaveSurfer.create({
          height: 80,
          interact:false,
          autoplay:autoplayProp,
          barWidth: 2,
          container: waveContainerEle,
          backend: "MediaElement",
          progressColor: "green",
          responsive: true,
          waveColor: "yellow",
          cursorColor: "black",
          audioRate:10,
          url:audioBlob,
        });

        //this.clearContainer("recorded_waveform", true)
      }
      setTimeout(()=>{
        //console.log("AFETR TIMEOUT SRWF", this.recordedWavesurfer.getDuration())
        this.setState({
          audioDuration:this.formatTime(this.recordedWavesurfer.getDuration()),
        });
      },1200)

      //this.clearContainer();
      //console.log("DOC", document.getElementById("recorded_waveform"))
      this.recordedWavesurfer.on("finish", (e) => {
        this.stopAudioTimelineTimer();
      })
  }

  showSampleWaveform = (audioBlob, waveContainerEle="#sample_waveform", autoplayProp=false) => {
    this.recordedWavesurfer = WaveSurfer.create({
      height: 80,
      interact:false,
      autoplay:autoplayProp,
      barWidth: 2,
      container: waveContainerEle,
      backend: "MediaElement",
      progressColor: "yellow",
      responsive: true,
      waveColor: "red",
      cursorColor: "cyan",
      audioRate:10,
      url:audioBlob,
    });
    setTimeout(()=>{
      //console.log("AFETR TIMEOUT SRWF", this.recordedWavesurfer.getDuration())
      this.setState({
        audioDuration:this.formatTime(this.recordedWavesurfer.getDuration()),
      });
    },1200)
    this.recordedWavesurfer.on("finish", (e) => {
      this.stopAudioTimelineTimer();
    })
  }

  cleanUpRecordingWaveform = (destroyRecording=false) =>{
    this.wavesurfer.destroy( )
    this.wavesurfer = null;
    
    if (destroyRecording){
      this.record.destroy();
      this.record = null;      
    }
  }
  // to format the audio timer
  formatTime = (e) =>{
    return String(Math.floor(e/60))+":"+("0"+String(Math.floor(e%60))).slice(-2)
  }
  
  // to handle the play & pause
  handlePlayPause = () => {
    if (! this.state.playing){
      this.startAudioTimelineTimer() // start timer
    }
    else
      this.stopAudioTimelineTimer(false) // pause timer
    
    this.setState({ playing: !this.state.playing, showPlayingLabel:true });
    //console.log("SEG IN PLAY",this.props.segmentIndex, this.state.segmentIndex)
    if(this.props.segmentIndex < 0)
      this.setState({ showPlayingLabel: false });
    
    this.wavesurfer.playPause();
  };

  // to stop playing audio
  handleStop = () => {
    this.wavesurfer.stop();
    this.setState({isAudioPlaying:false,playing:false, startRecordingButtonClicked:false})
    this.stopAudioTimelineTimer();
  };

  // to start audio timer
  startAudioTimelineTimer = () => {
    var time = 0;
    this.timelineTimer.current = setInterval(() => {
      if (this.state.audioSeconds > 0)
        time = this.state.audioSeconds;
      
      time = time + 1;
      let elapSec = this.formatTime(time);
      this.setState({audioSeconds:time, elapsedSeconds:elapSec});
    }, 1000);
  }

  // to stop audio timer
  stopAudioTimelineTimer = (resetSeconds=true) =>{
    if (this.timelineTimer.current){
      this.setState({ playing:false });
      if (resetSeconds)
        this.setState({audioSeconds:0, elapsedSeconds:this.formatTime(0)})
      clearInterval(this.timelineTimer.current)
      this.timelineTimer.current = null;
    }
  }

  // to stop recording timer
  stopRecordTimer = () =>{
    if (this.recordingStartTimerRef.current){
      clearTimeout(this.recordingStartTimerRef.current)
      this.recordingStartTimerRef.current = null;
    }
  }

  // On volume button click
  onVolumeClick = (e) => {
    this.wavesurfer.setVolume(e.target.value)
  }

  playRegion = () => {
    this.wavesurfer.play(
      this.state.regions.start,
      this.state.regions.end
      // this.wavesurfer.regions.params.regions[0].start,
      // this.wavesurfer.regions.params.regions[0].end
    );
  };

  removeAllRegions = () => {
    //this.wavesurfer.clearRegions();
    if (this.props.handlerAttemptStarted)
      this.props.handlerAttemptStarted(true)
  };

  onAudioControlsHandler = (hidePlayPauseState=false) => {
    //console.log("ON START BUTTON HANDLER")
    this.setState({
      hidePlayPause:hidePlayPauseState,
      //startButtonClicked:!this.state.startClicked
    })
  }
  
  attemptCountHandler = () => {
    this.setState({attemptCount: this.state.attemptCount + 1})
  }

  onClick = () => {
    this.childComponent.current.onStartButtonHandler();
  };

  commonPlayRecordStop = (stopRecording=false) => {
    if (this.wavesurfer.isPlaying()){
      //console.log("IS PLAYING 358")
      this.wavesurfer.stop();
    }

    if (stopRecording){
      //console.log("355:", this.record.isRecording())
      if (this.record.isRecording())
        this.record.stopRecording();
      //this.record.stopRecording();
    }

    setTimeout(function(){
    },1200)
  }

  segmentRecordingCommonHandler = (stopExisting=true) =>{
    //console.log("6. SEG INITIALISING RECORDING NOW")
    if (stopExisting){
      //console.log("STOPING")
      this.commonPlayRecordStop();
    }
    
    this.record.startRecording().then(() => {
    //console.log("7. SEG RECORDING ALREADY STARTED AFTER AUDIO FINISH", this.record.getRecordedUrl(), this.record, this.wavesurferRecord)
    this.setState({
      recording:true,
      startRecordingButtonClicked:true,
      recordedBlob:this.record.getRecordedUrl(),
      playing:false
    })
      
    // to hide play/pause
    this.onAudioControlsHandler(true)
      
    return;
    });
  }

  recordingCommonHandler = (stopExisting=true) =>{
    //console.log("6. INITIALISING RECORDING NOW")
    if (stopExisting){
      //console.log("STOPING")
      this.commonPlayRecordStop(true);
    }
    
    // stop audio timeline while recording in progress
    this.stopAudioTimelineTimer();
    
    this.record.startRecording().then((e) => {
      //console.log("7. RECORDING RESTARTED NOW",e, this.record.getRecordedUrl())
      this.setState({
        recording:true,
        startRecordingButtonClicked:true,
        recordedBlob:this.record.getRecordedUrl(),
        playing:false,
        showTimetimer:false
        //startCount:1
      })
      
      // to hide play/pause
      this.onAudioControlsHandler(true)

      return;
    });
  }

  onSegmentModalStartButtonHandler = () => {
    //console.log("2.1 ON SEGMENT MODAL START BUTTON HANDLER")
    // destroy existing recording object
    this.cleanUpRecordingWaveform()

    if(this.wavesurfer.isPlaying() === false){
      // start timer
      //console.log("2.2 STARTING TIMER FOR SEGMENT")
      this.startAudioTimelineTimer();
      
      // start playing segment audio
      this.wavesurfer.play();

      this.setState({
        startCount: this.state.startCount+1, 
        startRecordingButtonClicked:true,
        playing:true,
        startModal:false,
      })

      //console.log("2.3 ON SEGMENT MODAL INCREASING ATTEMPT COUNT FOR SEGMENT")

      // to show attempt counter
      this.attemptCountHandler()

      // to hide next button
      if (this.props.handlerAttemptStarted)
        this.props.handlerAttemptStarted(true)

      //console.log("2.4. DESTROYING RECORDING WAVEFORM FROM SEGMENT MODEL BUTTON")
      
      
      /*this.recordingStartTimerRef.current = setTimeout(() => {
        console.log("ALLOWING REC ON MODAL", this.wavesurfer.getDuration())
        if (this.record.isRecording()){
          console.log("GOING TO START SO STOPPING NOW")
          this.record.stopRecording()
        }
        this.recordingCommonHandler(false);
      },this.wavesurfer.getDuration()*1000)*/
    }
  }

  onSegmentModalAttemptFinishHandler = () =>{
    //console.log("2.8 On Segment MODAL ATTEMPT FINISH HANDLER");
    // stop ongoing segment audio play
    if (this.wavesurfer.isPlaying())
      this.wavesurfer.stop()

    //this.stopAudioTimelineTimer() // stop timer
    //console.log("2.9. AFTER AUDIO STOP TIMER")

    // stop ongoing recording
    if(this.record){
      if (this.record.isRecording()){
        //console.log("2.10 YES RECORDING:", this.record.getRecordedUrl())
        this.record.stopRecording()
      }
      
      this.setState({
        recordedBlob:this.record.getRecordedUrl(),
      })
      //console.log("2.11. AFTER STOP RECORDING", this.record.getRecordedUrl())
    }

    // update other required states
    this.setState({
      recording:false,
      startRecordingButtonClicked:false,
      attemptFinishModal:false,
      playing:false,
      hidePlayPause:false,
      showTimetimer:true,
      startButtonClicked:false,
      startCount:0,
    });
    
    setTimeout(() => {

      // to store recorded audio
      if (this.record){
        //console.log("576")
        if (this.props.handlerRecordedAudio || this.record.getRecordedUrl()){
          //console.log("2.12. PASSING RECORDING URL TO PARENT COMP",  this.record.getRecordedUrl())
          this.props.handlerRecordedAudio(this.record.getRecordedUrl())
          //this.cleanUpRecordingWaveform();
          //this.reinitializeRecordingWaveform();
          this.wavesurfer.load(this.state.playerAudio);
          //this.showRecordedWaveform(this.record.getRecordedUrl());

        }
      }
    },1200)
    // clean up existing recording waveform

    // reintialize recording waveform

    // to show next button
    if (this.props.handlerAttemptStarted)
      this.props.handlerAttemptStarted(false)

    //console.log("LATER SEG")
  }
  /* SEGMENT ENDS */

  onStartButtonHandler = () =>{
    //console.log("START RECORDING ")
    
    this.clearContainer("recorded_waveform")

    if (this.props.handlerAttemptStarted)
      this.props.handlerAttemptStarted(true)

    this.recordingCommonHandler(true);
    this.setState({
      startCount:this.state.startCount +1,
      startButtonClicked:true
    })
  }

  onStartButtonNewHandler = () =>{
    console.log("1. START BUTTON CLICKED ")
    
    this.clearContainer("recorded_waveform")

    console.log("1.a RECORDED WAVE CONTAINER CLEARED ")
    // to hide next/prev button
    if (this.props.handlerAttemptStarted)
      this.props.handlerAttemptStarted(true)

    if(this.wavesurfer.isPlaying())
      this.wavesurfer.stop()

    // start timer
    console.log("1.b STARTING TIMER FOR SEGMENT")
    this.startAudioTimelineTimer() // start timer
    
    // start playing segment audio
    this.wavesurfer.play()

    this.setState({
      startCount:this.state.startCount +1,
      startRecordingButtonClicked:true,
      playing:true, 
      hidePlayPause:true,
      startButtonClicked:true,
      showPlayingLabel:true,
    })

    //console.log("1.c DESTROYING RECORDING WAVEFORM")
    // destroy existing recording object
    //this.cleanUpRecordingWaveform();
  }

  onModalStartButtonHandler = () => {
    //console.log("ON MODAL START BuTTON HAN")
    // to hide next button
    if (this.props.handlerAttemptStarted)
      this.props.handlerAttemptStarted(true)
    // to show attempt counter
    this.attemptCountHandler()

    this.recordingCommonHandler();
    this.setState({
      startCount: this.state.startCount+1, 
      startModal:false
    })
  }

  onModalAttemptFinishHandler = () =>{
    //console.log("On onFinishButtonHandler RECORDING");

    // stop ongoing recording
    if (this.record.isRecording())
      this.record.stopRecording()

    this.setState({
      attemptFinishModal:false,
      startRecordingButtonClicked:false,
      startButtonClicked:false,
      recording:false,
      hidePlayPause:false,
      showTimetimer:true,
      playing:false,
    });

    // stop ongoing play
    if (this.wavesurfer.isPlaying())
      this.wavesurfer.stop()

    
    if (this.props.handlerAttemptStarted)
      this.props.handlerAttemptStarted(false)


    if (this.props.handlerRecordedAudio)
      this.props.handlerRecordedAudio(false)
    
    //console.log("LATER")
  }

  closeStartModal = () =>{
    this.setState({startModal:!this.state.startModal});
  }

  closeAttemptFinishModal = () =>{
    this.setState({attemptFinishModal:!this.state.attemptFinishModal});
  }

  updateAutoPlayViaParent = (autoplay=false) => {
    this.setState({parentAutoPlay:autoplay})

  }
  resetComponent = (newAudioBlob=null, containerEle="#waveform", autoplay=false, segmentIndex=-1) => {
    //console.log(" RESET STATES IN CHILD",this.props.hidePlayPause, this.state.hidePlayPause, segmentIndex)
    this.setState({
      attemptCount:0,
      attemptFinishModal:false,
      startRecordingButtonClicked:false,
      startButtonClicked:false,
      recording:false,
      playing:false,
      recordedBlob:null,
      startCount:0,
      parentAutoPlay:false,
      //hidePlayPause:hideControls,
      audioDuration:this.formatTime(0),
      playerAudio: newAudioBlob,
      hidePlayPause:false,
      segmentIndex: segmentIndex

    })
    
    this.commonPlayRecordStop();
    this.stopAudioTimelineTimer();

    if (newAudioBlob != null){
      let autoPlayAllowed = (segmentIndex === -1)?false:autoplay;

      //console.log("BEFORE DESTROYING", segmentIndex)
      this.cleanUpRecordingWaveform();
      this.reinitializeRecordingWaveform(containerEle, autoPlayAllowed, newAudioBlob)
    }
  }

  updateRecordedWaveformAudio = (newBlob) => {
    //console.log("URWFA", newBlob)
  }
  render() {
    return (
      <>
      <div id="audioWaveformContainer">
        <div className="waveVolumeContainer">
          <Button 
            color="primary"
            className="bottom-btn volume-btn" 
            onClick={
              ()=>this.setState({showVolSlider:!this.state.showVolSlider})
            } 
            size="sm">
            <i className={'fa fa-volume-up'}></i>
          </Button>
          {
            (this.state.showVolSlider === true)?(
              <div className="volumeControl" style={{display:this.state.showVolSlider===true?"show":"hide"}}>
                <Input orient="vertical" type="range" min="0" defaultValue={1} step="0.1" onChange={this.onVolumeClick} max="1"  />
              </div>
            ):null
          }
        </div>

        {
          this.props.onlyAudioPlayer?(
            <div
              className="waveContainer"
              id="waveform"
            />
          ):null
        }
        {
          this.props.onlySamplePlayer?(
            <div
              className="waveContainer"
              id="sample_waveform"
            />
          ):null
        }
        {
          (this.props.onlyRecordedPlayer || this.state.recordedBlob)?(
            <div
              className="waveContainer"
              id="recorded_waveform"
            />
          ):null
        }
        {
          this.state.showTimetimer?(
            <div id="timelineContainer">
              <span>{this.state.elapsedSeconds}</span>
              <span>{this.state.audioDuration}</span>
            </div>
          ):null
        }

        <div className="waveButtons">
          {
            (this.props.hidePlayPause === false && this.props.forInstructions) ||
            (this.props.hidePlayPause === false && this.state.hidePlayPause === false) ||
            (this.props.hidePlayPause === false && this.props.onlySamplePlayer) || 
            (this.props.hidePlayPause === false && this.props.onlyRecordedPlayer)
            
            ?(
                <>
                <Button color="primary" className="bottom-btn" id="play-button" onClick={this.handlePlayPause} size="sm" disabled={this.state.startButtonClicked?true:false}>
                  <i className={!this.state.playing ? 'fa fa-play' : 'fa fa-pause'}></i>
                </Button>
                <Button color="primary" className="bottom-btn" id="play-button" onClick={this.handleStop} size="sm" disabled={!this.state.playing?true:false}>
                  <i className={'fa fa-stop'}></i>
                </Button>
                </>
            ):null
          }
          {/*<>
          <span>REC ALLOWRD:{this.props.allowRecording != undefined ?this.props.allowRecording.toString():"n/a"}</span>
          <br/>
          <span>FOR SEG:{this.props.forSegment!= undefined?this.props.forSegment.toString():"n/a"}</span>
          <br/>
          <span>PROPS:{this.props.segmentIndex}</span>
          <br/>
          </>*/}
          {
            this.props.allowRecording ?(
              !this.props.forSegment?(

                !this.state.startRecordingButtonClicked?(
                  (this.props.segmentIndex >=0)?(
                    /*<Button color="primary" className="start-record-btn bottom-btn" onClick={()=>this.onStartButtonNewHandler()} disabled={(this.state.playing || this.state.startButtonClicked)?true:false}>Start</Button>*/
                    null
                  ):null
                ):(
                  <Button color="primary" className="start-record-btn bottom-btn" size="sm" onClick={()=>this.onSegmentModalAttemptFinishHandler()} disabled={(!this.state.recording)?true:false}>
                    Stop Recording
                  </Button>
                )
              ):null
            ):null
          }
        </div>
        {
          ((this.state.recording === true) && (this.props.showRecordBlinker)) ? (
          <div className="recordingContainer">
            <label id="recording_btn_container">
              <i className="fa fa-circle"></i>
              <span>REC</span>
            </label>
          </div>
          ):null
        }
        {
          ((this.props.showPlayingSourceBlinker) && (this.state.playing === true) && (this.state.showPlayingLabel))? (
          <div className="playingSourceContainer">
            <label id="source_listening_msg">
              <i className="t-icon t-icon-hc-spin t-icon-spinner"></i>
              <span>PLAYING SOURCE FILE</span>
              </label>
          </div>
          ):null
        }
      </div>
      </>
    );
  }
}

export default WaveSurferModuleComponent;