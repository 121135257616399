import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Branches extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submit:false,
            flag:false,
            lockSubmit:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
 render(){
    return (
        <React.Fragment>
        <Helmet htmlAttributes>
            <title>Language Academy</title>
            <meta name="twitter:title" content="Language Academy" />
            <meta property="og:title" content="Language Academy" />
        </Helmet>
        <Header />
        <div class="innerPage">
            <section class="bannerOuter">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <h1>Our Branches</h1>
                            <span>Visit us and fulfil your dreams</span><br />
                            <a href="javascript:;" class="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section class="branchesOuter">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-center branchesHeading">
                        <h2 className="pb-3">Hello. What can we help you with?</h2>
                        <h3>Our Offices</h3>
                    </div>
                    <div class="col-sm-6 adresssBlock">
                        <div class="adresssBlockInr">
                            <div class="mapOuter">
                                <img src="images/parramatta.jpg" alt="parramatta" /><h3>Parramatta</h3>
                            </div>
                            <div class="adressCntnt">
                                <div class="addressInfo"><p>46 George Street, Parramatta, 2150, NSW, <br></br>Australia</p></div>
                                <div class="contactNo">
                                    <a style={{color:'grey'}} href="tel:61426230061" >+61 426 230 061</a>
                                </div><div class="locationBtn"><a href="https://goo.gl/maps/tMnL36J54bEjBSVt7" target="_blank" class="themeBtn" rel="noopener noreferrer" >Get Directions</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 adresssBlock">
                        <div class="adresssBlockInr">
                            <div class="mapOuter">
                                <img src="images/sydney.jpg" alt="sydney"/>
                                <h3>Sydney CBD</h3>
                            </div>
                            <div class="adressCntnt">
                                <div class="addressInfo"><p>Suite 502 Level 5/630 George St, Sydney NSW 2000, <br></br>Australia</p></div>
                                <div class="contactNo">
                                    <a style={{color:'grey'}} href="tel:61455566654" >+61 455 566 654</a>
                                </div><div class="locationBtn"><a href="https://goo.gl/maps/zPWWG4kAKSY1VADu7" target="_blank" class="themeBtn" rel="noopener noreferrer" >Get Directions</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 adresssBlock">
                        <div class="adresssBlockInr">
                            <div class="mapOuter">
                                <img src="images/jalandhar.jpg" alt="Jalandhar" /><h3>Jalandhar</h3>
                            </div>
                            <div class="adressCntnt">
                                <div class="addressInfo"><p>172, Green Park, Bedi Paradise Building, Garha Road, Near Bus Stand, <br></br>Jalandhar City</p></div>
                                <div class="contactNo">
                                    <a style={{color:'grey'}} href="tel:917652811911" >+91 765 281 1911</a>
                                </div><div class="locationBtn"><a href="https://goo.gl/maps/Exkyq5Uv8iDLFKeC8" target="_blank" class="themeBtn" rel="noopener noreferrer" >Get Directions</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 adresssBlock">
                        <div class="adresssBlockInr">
                            <div class="mapOuter">
                                <img src="images/gurdaspur.jpg" alt="Gurdaspur"/><h3>Gurdaspur</h3>
                            </div>
                            <div class="adressCntnt">
                                <div class="addressInfo"><p>State Bank of India, GT Road, Mandi Branch, <br></br>Gurdaspur</p></div>
                                <div class="contactNo">
                                    <a style={{color:'grey'}} href="tel:919056511913" >+91 905 651 1913</a>
                                </div><div class="locationBtn"><a href="https://goo.gl/maps/NmVe3JC891hJ4XQn8" target="_blank" class="themeBtn" rel="noopener noreferrer" >Get Directions</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 adresssBlock">
                        <div class="adresssBlockInr">
                            <div class="mapOuter">
                                <img src="images/amritsar.jpg" alt="Amritsar" /><h3>Amritsar</h3>
                            </div>
                            <div class="adressCntnt">
                                <div class="addressInfo"><p>D-Block 52-53 SCO, Ranjit Avenue, Opp. Starbucks Building, <br></br>Amritsar</p></div>
                                <div class="contactNo">
                                    <a style={{color:'grey'}} href="tel:919915011914" >+91 991 501 1914</a>
                                </div>
                                {/*<div class="locationBtn">
                                  <a href="https://goo.gl/maps/NmVe3JC891hJ4XQn8" target="_blank" class="themeBtn">Get Directions</a>
                                </div>*/}
                            </div>
                        </div>
                    </div>
					<div class="col-sm-6 adresssBlock">
                        <div class="adresssBlockInr">
                            <div class="mapOuter">
                                <img src="images/sirsa.jpg" alt="sirsa" /><h3>Sirsa</h3>
                            </div>
                            <div class="adressCntnt">
                                <div class="addressInfo"><p>Opp. Telephone Exchange, Post Office road near Lal Batti Chowk Dabwali Road, Sirsa.</p></div>
                                <div class="contactNo">
                                    <a style={{color:'grey'}} href="tel:919878611915" >+91 987 861 1915</a>
                                </div>
                                {/*<div class="locationBtn">
                                  <a href="https://goo.gl/maps/NmVe3JC891hJ4XQn8" target="_blank" class="themeBtn">Get Directions</a>
                                </div>*/}
                            </div>
                        </div>
                    </div>

					<div class="col-sm-6 adresssBlock">
                        <div class="adresssBlockInr">
                            <div class="mapOuter">
                                <img src="images/sriganganagar.jpg" alt="srinagar" /><h3>Sri Ganganagar</h3>
                            </div>
                            <div class="adressCntnt">
                                <div class="addressInfo"><p>8 Mukharjee Nagar, Behind Main Bus Stand, Sri Ganganagar.</p></div>
                                <div class="contactNo">
                                    <a style={{color:'grey'}} href="tel:919915411918" >+91 991 541 1918</a>
                                </div>
                                {/*<div class="locationBtn">
                                  <a href="https://goo.gl/maps/NmVe3JC891hJ4XQn8" target="_blank" class="themeBtn">Get Directions</a>
                                </div>*/}
                            </div>
                        </div>
                    </div>

					<div class="col-sm-6 adresssBlock">
                        <div class="adresssBlockInr">
                            <div class="mapOuter">
                                <img src="images/kurukshetra.jpg" alt="Kurukshetra"/><h3>Kurukshetra</h3>
                            </div>
                            <div class="adressCntnt">
                                <div class="addressInfo"><p>A-Block, Ground Floor, Divine City Centre, Pipli Road, Near Bus Stand, Kurukshetra.</p></div>
                                <div class="contactNo">
                                    <a style={{color:'grey'}} href="tel:919878111916" >+91 987 811 1916</a>
                                </div>
                                {/*<div class="locationBtn">
                                  <a href="https://goo.gl/maps/NmVe3JC891hJ4XQn8" target="_blank" class="themeBtn">Get Directions</a>
                                </div>*/}
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 div20task text-center">
                        <h4>For online classes, please contact us on <a href="tel:61455566654">+61 455 566 654</a>
                        </h4>
                        <h4>For online classes in India, please contact us on <a href="tel:917652811912">+91 765 281 1912</a></h4>
                    </div>
                </div>
            </div>
        </section>
    <Footer />
    </React.Fragment>);
    }
}
export default Branches;
