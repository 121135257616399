import React from "react";
import { NavLink, Link, Redirect } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
import "components/custom.css";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
//import {store} from 'store/index';
import cookie from 'react-cookies';
//import { setToken } from 'action/index.js';
import API from "api.js";
var ps;

/*const mapStateToProps = (state) => {
   return {
      token: state.token
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok))
   };
};*/

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
    this.state = {
      image_src: "",
      userData:[],
      logout:false
    }
  }
  logout = () => {
    cookie.remove('access-token', { path: '/' });
    this.setState({logout:true});
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.type === 2) return null;
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li id="collap-menu"
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
            onClick={()=>document.body.classList.remove("sidebar-mini")}
          >
            <a
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
              href="#"
            >
              {prop.className !== undefined ? (
                <>
                  <i className={prop.className} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                    {prop.crownImage !== undefined ? (
                      <img src={"/images/icon/"+prop.crownImage} alt="crownImage" />
                      ):null
                    }
                  </p>
                </>
              ) : (
                <>
                  <i className={prop.className} />
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key} onClick={()=>document.body.classList.remove("sidebar-mini")}>
           <NavLink to={prop.layout + prop.path} activeClassName=""  onClick={prop.name==="Logout"?this.logout:null}>
            {prop.className !== undefined ? (
              <>
                <i className={prop.className} />
                <p>{prop.name}</p>
                {prop.crownImage !== undefined ? (
                  <img src={"/images/icon/"+prop.crownImage} alt="crownImage" />
                  ):null
                }
              </>
            ) : (
              <>
                <i className={prop.className} />
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  async componentDidMount() {
    // fetch profile data from api
    let profileData = await this.getProfileData();
    if (profileData)
      this.setState({ image_src:profileData.profile_image })
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  getProfileData = async() => {
    try{
      const response = await API({
        method: 'GET',
        url: '/profile'
      })
      let userData = {};
      if (response.status === 200)
        userData = response.data.data.profile_details;
      //console.log("UD", userData)
      //console.log("SARTW", store.getState())
      //console.log("STORE STATE", this.state)
      return userData;
    }catch(error) {
      this.setState({error:{error} });
    };
  }
  render() {
    if (this.state.logout) {
      return (<Redirect to="/auth/login" />);
    }
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo" style={{textAlign: "center"}}>
          <div 
            className="collapseNavIcon"
            id="minimizeSidebar"
          >
            <a onClick={this.props.handleMiniClick} href="#">
              {<img src={`/images/icon/nav-collapse-arrow.png`} alt="nav-collapse-arrow" />}
              {/*<i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
              <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />*/}
            </a>
          </div>
            <div className="logo-img add">
              <Link to="/admin/dashboard">
                {/*<img src={`https://backend22.languageacademy.com.au/images/logol.png`} alt="react-logo" />*/}
                <img src="/images/icon/language-academy-01.svg" alt="logo" />
              </Link>
            </div>
          
        </div>

        <div className="sidebar-wrapper " ref="sidebar">
          <Nav>
          {this.createLinks(this.props.routes)}
            {/* <li className={this.activeRoute('/admin' +'/videos')} >
            <NavLink to="/admin/videos" activeClassName="">              
              <>
                <i className="fa fa-youtube-play" />
                <p>{(store.getState().is_main)?"PTE Secret Strategy":"Videos"} </p>
                {store.getState().is_main && <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Videos </p>}

              </>
            
          </NavLink>
        </li> */}
        {/*
          store.getState().is_main && <li className={this.activeRoute('/admin/transaction')} >
                    <NavLink to="/admin/transaction" activeClassName="">
                      
                        <>
                          <i className="fa fa-retweet" />
                          <p>Transaction</p>
                        </>
                    
                  </NavLink>
                </li>
          */
        }
        {
          /*

        <li className={this.activeRoute('/admin' +'/help')} >
            <NavLink to="/admin/help" activeClassName="">
              
                <>
                  <i className="fa fa-question-circle" />
                  <p>Help</p>
                </>
            
          </NavLink>
        </li>
          */
        }
          
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
