import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import { NavLink } from "react-router-dom";
import { setUser} from 'action/index.js';
// core components
import { connect } from 'react-redux';
const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};

class DialoguePractice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false,
      dialogueId:this.props.match.params.id,
      languageId:this.props.match.params.language_id,
      currentIndex:0,
      isMock:false,
      all_ok:false,
      segmentAudioBlob:null
    };
  }
  async componentDidMount() {
    // close the sidebar
    document.body.classList.add("sidebar-mini");

    let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
    let all_ok = (isChrome)?true:false;
    navigator.getUserMedia({ audio: true },
    () => {
      //console.log('Permission Granted');
      this.setState({all_ok:all_ok});
    },
    () => {
      this.setState({all_ok:false});
      //console.log('Permission Denied');
      },
    );
    // get dialogue detail
    await this.getDialogueDetail(this.state.dialogueId, this.state.languageId)
  }

  getDialogueDetail = async(dialogueId,languageId,pageFrom="dialogue") => {
    try{
      const url = (pageFrom.toLowerCase() === 'exammemories') ? 'exam_memories/' : 'dialogue/';
      const response = await API({
        method: 'GET',
        url: url + dialogueId,
      });

      if (response.data.success && response.data.data.dialogue_details) {
        const dialogueDetails = response.data.data.dialogue_details;
        this.setState({data:dialogueDetails});
        
        await this.getSegmentAudioBlob(dialogueDetails.start_audio)

      } else {
        console.log("ERROR in fetchDialgoue : ", response.data);
      }
    }catch(error){
      console.log("ERROR IN CATCH", error)
    };
  }

  getSegmentAudioBlob = async(segmentAudioPath,
    forSegmentResponse=false,
    forUserResponse=false,
    resetChild=false
  ) => {
    const url = 'convert_mock_audio';
    var bodyFormData = new FormData();
    bodyFormData.set('path', segmentAudioPath);
    const response = await API({
        method: 'POST',
        url: url,
        data: {
          'is_response':false,
          'is_dialogue':true,
          'file_path':segmentAudioPath
        },
      });

      //console.log("CMO MOCK RESPONSE", response,)
      if (response.data){
        if (response.data.success) {
          // for segment
          if (!forUserResponse && !forSegmentResponse){
            //console.log("I AM AUDIO",response.data.data.base64)
            this.setState({segmentAudioBlob:response.data.data.base64});
          }
        } else {
          //showMessage({ message: response.data.message, type: "danger" });
          console.log("ERROR in convert_mock_audio : ", response.data);
        }          
      }
    
  }

  render() {

    return (
      <>
        {!this.state.all_ok && <ChromeCheck />}
        {/*true && <div className="content">*/}
        {this.state.all_ok && <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4"><span>{this.state.data.title}</span></CardTitle>
                </CardHeader>

                <CardBody>
                  {this.state.data.description}
                  <CardTitle className="instructionsTitle"><h5>Instructions</h5></CardTitle>
                  <Card className="instructionsCard">
                    {this.state.isMock ? (
                      <span className="text">
                        Once you have started the MOCK TEST you can't re-attempt it again.
                      </span>
                    ) : null}
                    <span className="text">
                      You will hear two dialogues between an English speaker and another language speaker. You are required to interpret
                      into the other language what each speaker says as soon as each speaker finishes a segment. You may take notes if you wish.
                    </span>
                  </Card>
                  <div className="nextPrevOuter">
                    <NavLink
                      to={`/admin/dialogue/attempt/${this.state.dialogueId}/${this.state.languageId}`}
                      className="btn-round btn btn-info dialogue-start"
                      onClick={()=>this.setState({segmentAudioBlob:this.state.segmentAudioBlob})}
                      
                    >Start
                    </NavLink>
                  </div>              
                </CardBody>
              </Card>
            </Col>

          </Row>
        </div>}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialoguePractice);