//import prediction from "views/Prediction.jsx";
//import material from "views/Material.jsx";
/*import DailyFeedback from "views/DailyFeedback.jsx";
//import LockScreen from "views/pages/LockScreen.jsx";
import DailyTask from "views/DailyTask.jsx";
import session from "views/Session.jsx";
import notification from "views/Notification.jsx";
import DailyTaskAdmin from "views/DailyTaskAdmin.jsx";
import QuestionList from "views/QuestionList.jsx";*/
//import QuestionListWeekly from "views/QuestionListWeekly.jsx";

//import FullTest from "views/FullTest.jsx";
//import FullTest1 from "views/FullTest1.jsx";

//import ViewPdf from "views/ViewPdf.jsx";
//import Result from "views/Result.jsx";
//import Score from "views/Score.jsx";
//import PracticedDetail from "views/PracticedDetail.jsx";
//import Template from "views/Template.jsx";
//import MockPending from "views/MockPending.jsx";
//import MockResultLists from "views/MockResultLists.jsx";
//import MockResponse from "views/MockResponse.jsx";
//import MockAnalysis from "views/MockAnalysis.jsx";
//import MockAnalysis1 from "views/MockAnalysis1.jsx";
//import Transaction from "views/Transaction.jsx";
//import LoginPortal from "views/pages/LoginPortal.jsx";
import Dashboard from "views/Dashboard.jsx";
import Video from "views/Video.jsx";
import Zoom from "views/Zoom.jsx";
import Subscription from "views/Subscription.jsx";
import PaymentForm from "views/PaymentForm.jsx";
import SubSuccess from "views/SubSuccess.jsx";
import Practice from "views/Practice.jsx";
import Test from "views/Test.jsx";
import PlanPackages from "views/PlanPackages.jsx";
import ShowVideo from "views/ShowVideo.jsx";
import Mock from "views/Mock.jsx";
import Help from "views/Help.jsx";
import MockFeedback from "views/MockFeedback.jsx";
import MockResponseNew from "views/MockResponseNew.jsx";
import Profile from "views/Profile.jsx";
import Login from "views/pages/Login.jsx";
import ResetPassword from "views/pages/ResetPassword.jsx";
import Forgot from "views/pages/Forgot.jsx";
import Register from "views/pages/Register.jsx";
import Chrome from "views/Chrome.jsx";
import ChromeCheckGlobal from "views/ChromeCheckGlobal.jsx";
import Password from "views/Password.jsx";
import ProgressTracker from "views/ProgressTracker.jsx";
import NotFound from './page/NotFound';
import NotFoundAdmin from './page/NotFoundAdmin';
import Vocabulary from 'views/Vocabulary.jsx';
import DialoguePractice from 'views/DialoguePractice.jsx';
import DialogueAttempt from 'views/DialogueAttempt.jsx';
import DialogueLists from 'views/DialogueLists.jsx';
import OnlineCoaching from 'views/OnlineCoaching.jsx';
import LastMinutePracticeLists from 'views/LastMinutePracticeLists.jsx';
import LastMinutePractice from 'views/LastMinutePractice.jsx';
import LastMinutePracticeAttempt from 'views/LastMinutePracticeAttempt.jsx';
import ComprehensiveMaterialLists from 'views/ComprehensiveMaterialLists.jsx';
import ComprehensiveMaterial from 'views/ComprehensiveMaterial.jsx';
import ComprehensiveMaterialAttempt from 'views/ComprehensiveMaterialAttempt.jsx';
import AccessCode from 'views/AccessCode.jsx';
import MockAttempt from 'views/MockAttempt.jsx';
import MockInProgress from 'views/MockInProgress.jsx';
import VocabularyTest from 'views/VocabularyTest.jsx';

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-tachometer",
    component: Dashboard,
    layout: "/admin",
    className: "dashIcon",
    type:1,
  },{
    path: "/chrome",
    name: "Chrome Speech",
    icon: "fa fa-user",
    component: Chrome,
    layout: "/admin",
    type:2,
  },{
    path: "/progress/:id",
    name: "Chrome Speech",
    icon: "fa fa-user",
    component: ProgressTracker,
    layout: "/admin",
    type:2,
  },{
    path: "/allCheck",
    name: "Chrome Speech",
    icon: "fa fa-user",
    component: ChromeCheckGlobal,
    layout: "/admin",
    type:2,
  },/*{
    path: "/practice-detail",
    name: "Practiced Detail",
    icon: "fa fa-user",
    component: PracticedDetail,
    layout: "/admin",
    type:2,
  },*/{
    path: "/profile",
    name: "My Profile",
    icon: "fa fa-user",
    component: Profile,
    layout: "/admin",
    type:2,
  },
  {
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    type:2,
    views: [
      
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth"
      },
      
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/forgot",
        name: "Forgot",
        mini: "R",
        component: Forgot,
        layout: "/auth"
      },
      {
        path: "/reset-password/:hash",
        name: "ResetPassword",
        mini: "R",
        component: ResetPassword,
        layout: "/auth"
      }
    ]
  },
  /*{
    path: "",
    name: "Practice Materials",
    icon: "fa fa-search",
    component: material,
    layout: "/admin",
    type:1,
    state: "pagesCollapse",
    collapse: true,
    views: [
      {
        path: "/test-material",
        name: "Practice Questions",
        icon: "fa fa-file-text",
        component: material,
        layout: "/admin",
        type:1,
      },{
        path: "/weekly-prediction",
        name: "Weekly Prediction",
        icon: "fa fa-hourglass-half",
        component: prediction,
        layout: "/admin",
        type:1
      },
    ]
  },
  {
    path: "/template",
    name: "Template",
    icon: "fa fa-clipboard",
    component: Template,
    layout: "/admin",
    type:1
  },
  */
  {
    path: "/coaching",
    name: "Online Coaching",
    icon: "fa fa-search",
    component: OnlineCoaching,
    layout: "/admin",
    type:1,
    className: "searchIcon"
  },
  {
    path: "/vocabularies/:catId",
    name: "Vocabulary",
    icon: "fa fa-clipboard",
    component: Vocabulary,
    layout: "/admin",
    type:1,
    className: "vocabIcon"
  },
  /*{
    path: "/vocabulary/test/",
    name: "Vocabulary Test",
    icon: "fa fa-clipboard",
    component: VocabularyTest,
    layout: "/admin",
    type:1,
    className: "vocabTestIcon",
    crownImage: "crown.png"
  },*/
  {
    path: "/dialogues",
    name: "Practice Dialogues",
    icon: "fa fa-file-text",
    component: DialogueLists,
    layout: "/admin",
    type:1,
    className: "searchGraphIcon"
  },
  {
    path: "/comp_mats",
    name: "Comprehensive Material",
    icon: "fa fa-file-text",
    component: ComprehensiveMaterialLists,
    layout: "/admin",
    type:1,
    className: "compreIcon",
    crownImage: "crown.png"
  },
  {
    path: "/lmps",
    name: "Last Minute Practice",
    icon: "fa fa-file-text",
    component: LastMinutePracticeLists,
    layout: "/admin",
    type:1,
    className: "lmpIcon"
  },
  
  /*{
    path: "/mock/listing",
    name: "Mock Test",
    icon: "fa fa-clipboard",
    component: Mock,
    layout: "/admin",
    type:1
  },*/
  {
    path: "/mock/attempt/:id/:language_id/:duration/:title",
    name: "Mock Test Attempt",
    icon: "fa fa-clipboard",
    component: MockAttempt,
    layout: "/admin",
    type:2
  },
  {
    path: "/mock/in_process/:id/:language_id/:duration/:title",
    name: "Mock In Process",
    icon: "fa fa-clipboard",
    component: MockInProgress,
    layout: "/admin",
    type:2
  },
  {
    path: "/test/:id/:pending",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: Test,
    layout: "/admin",
    type:2
  },
  /*
  {
    path: "/fulltest/:id/:pending",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: FullTest1,
    layout: "/admin",
    type:2
  },
  {
    path: "/viewpdf/:id",
    name: "ViewPdf",
    icon: "nc-icon nc-bank",
    component: ViewPdf,
    layout: "/admin",
    type:2
  },
  {
    path: "/result/:id",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: Result,
    layout: "/admin",
    type:2
  },
  {
    path: "/scorecard/:id",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: Score,
    layout: "/admin",
    type:2
  },{
    path: "/analysis/:id",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: MockAnalysis,
    layout: "/admin",
    type:2
  },{
    path: "/mock-analysis/:id",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: MockAnalysis1,
    layout: "/admin",
    type:2
  },*/
  {
    path: "/video/:id",
    name: "video",
    icon: "nc-icon nc-bank",
    component: ShowVideo,
    layout: "/admin",
    type:2
  },
  {
    path: "/practice/:type/:id/:index/:aType/:mark/:attempted/:complexity",
    name: "practice",
    icon: "nc-icon nc-bank",
    component: Practice,
    layout: "/admin",
    type:2
  },
  {
    path: "/dialogue/practice/:id/:language_id",
    name: "DialoguePractice",
    icon: "nc-icon nc-bank",
    component: DialoguePractice,
    layout: "/admin",
    type:2
  },
  {
    path: "/dialogue/attempt/:id/:language_id",
    name: "DialogueAttempt",
    icon: "nc-icon nc-bank",
    component: DialogueAttempt,
    layout: "/admin",
    type:2
  },
  {
    path: "/lmp/practice/:id/:language_id",
    name: "LastMinutePractice",
    icon: "nc-icon nc-bank",
    component: LastMinutePractice,
    layout: "/admin",
    type:2
  },
  {
    path: "/lmp/attempt/:id/:language_id",
    name: "LastMinutePracticeAttempt",
    icon: "nc-icon nc-bank",
    component: LastMinutePracticeAttempt,
    layout: "/admin",
    type:2
  },
  {
    path: "/comp_mat/practice/:id/:language_id",
    name: "ComprehensiveMaterial",
    icon: "nc-icon nc-bank",
    component: ComprehensiveMaterial,
    layout: "/admin",
    type:2
  },
  {
    path: "/comp_mat/attempt/:id/:language_id",
    name: "ComprehensiveMaterialAttempt",
    icon: "nc-icon nc-bank",
    component: ComprehensiveMaterialAttempt,
    layout: "/admin",
    type:2
  },
  {
    path: "/mock/listing/",
    name: "Mock Test",
    icon: "fa fa-pencil-square-o",
    component: Mock,
    layout: "/admin",
    type:1,
    className: "testIcon",
    crownImage: "crown.png",
  },
  /*{
    path: "",
    name: "Mock Test",
    icon: "fa fa-pencil-square-o",
    component: Mock,
    layout: "/admin",
    type:1,
    className: "testIcon",
    state: "mockCollapse",
    crownImage: "crown.png",
    collapse: true,
    views: [
      {
        path: "/mock/listing",
        name: "Take test",
        icon: "fa fa-check",
        component: Mock,
        layout: "/admin",
        type:1,
        className: "takeTestIcon"
      },
      {
        path: "/mock/results",
        name: "Test result",
        icon: "fa fa-line-chart",
        component: MockResultLists,
        layout: "/admin",
        type:1,
        className: "testResultIcon"
      },
      ,{
        path: "/pending",
        name: "Pending Test",
        icon: "fa fa-star-half",
        component: MockPending,
        layout: "/admin",
        type:1
      },
      
    ]
  }*/,
  {
    path: "/mock/feedback/:id/:language_id",
    name: "Test result",
    icon: "fa fa-line-chart",
    component: MockFeedback,
    layout: "/admin",
    type:2
  },
  {
    path: "/mock/response/:mock_attempt_id/:mock_id",
    name: "Mock Response",
    icon: "fa fa-line-chart",
    component: MockResponseNew,
    layout: "/admin",
    type:2
  },
  {
    path: "/videos",
    name: "Videos",
    icon: "fa fa-youtube-play",
    component: Video,
    layout: "/admin",
    type:1,
    className: "youtubeIcon"
  },
 /* {
    path: "/transactions",
    name: "Transactions",
    icon: "fa fa-retweet",
    component: Transaction,
    layout: "/admin",
    type:1,
    className: "paymentIcon"
  },*/
  {
    path: "/access_code",
    name: "Access Code",
    icon: "fa fa-credit-card",
    component: AccessCode,
    layout: "/admin",
    type:1,
    className: "helpIcon"
  },
  {
    path: "/profile",
    name: "My Profile",
    icon: "fa fa-credit-card",
    component: Profile,
    layout: "/admin",
    type:1,
    className: "settingsIcon"
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "fa fa-logout",
    component: AccessCode,
    layout: "/admin",
    type:1,
    className: "logoutIcon"
  },
  /*{
    path: "/transactions",
    name: "Transactions",
    icon: "fa fa-retweet",
    component: Transaction,
    layout: "/admin",
    type:1,
    className: "paymentIcon"
  },*/
  /*
  {
    path: "/videos",
    name: "PTE Secret Strategy Videos",
    icon: "fa fa-youtube-play",
    component: Video,
    layout: "/admin",
    type:1
  },
  {
    path: "",
    name: "Daily Goals",
    icon: "fa fa-line-chart",
    component: DailyTask,
    layout: "/admin",
    type:1,
    state: "dailyCollapse",
    collapse: true,
    views: [
      {
        path: "/daily-task",
        name: "Daily Task",
        icon: "fa fa-list-ul",
        component: DailyTask,
        layout: "/admin",
        type:1,
      },{
        path: "/success-plan",
        name: "Success Plan",
        icon: "fa fa-sticky-note-o",
        component: DailyTaskAdmin,
        layout: "/admin",
        type:1
      },{
        path: "/daily-feedback",
        name: "Daily Feedback",
        icon: "fa fa-comments",
        component: DailyFeedback,
        layout: "/admin",
        type:1
      },
    ]
  },
  {
    path: "/transaction",
    name: "Transactions",
    icon: "fa fa-retweet",
    component: Transaction,
    layout: "/admin",
    type:2
  },
  {
    path: "/session",
    name: "Live session",
    icon: "fa fa-video-camera",
    component: session,
    layout: "/admin",
    type:1
  },
  {
    path: "/notification",
    name: "Notification",
    icon: "fa fa-bell",
    component: notification,
    layout: "/admin",
    type:1
  },*/
  {
    path: "/packages",
    name: "Transactions",
    icon: "fa fa-retweet",
    component: PlanPackages,
    layout: "/admin",
    type:2
  },
  {
    path: "/videos",
    name: "PTE Secret Strategy Videos",
    icon: "fa fa-youtube-play",
    component: Video,
    layout: "/admin",
    type:2
  },{
    path: "/help",
    name: "Help",
    icon: "fa fa-youtube-play",
    component: Help,
    layout: "/admin",
    type:2
  },{
    path: "/zoom",
    name: "Zoom Meeting",
    icon: "fa fa-youtube-play",
    component: Zoom,
    layout: "/admin",
    type:2  
	},
  {
    path: "/subscription",
    name: "Videos",
    icon: "fa fa-youtube-play",
    component: Subscription,
    layout: "/sub",
    type:2
  },
  {
    path: "/payment/form/:id",
    name: "PaymentForm",
    icon: "fa fa-credit-card",
    component: PaymentForm,
    layout: "/sub",
    type:2
  },
  {
    path: "/success",
    name: "SubSuccess",
    icon: "fa fa-signal",
    component: SubSuccess,
    layout: "/sub",
    type:2
  },
  {
    path: "/password",
    name: "Change Password",
    icon: "fa fa-signal",
    component: Password,
    layout: "/admin",
    type:2
  },{
    path: "",
    name: "",
    icon: "fa fa-user",
    component: NotFoundAdmin,
    layout: "/admin",
    type:2,
  },{
    path: "",
    name: "Login",
    mini: "L",
    type:2,
    component: NotFound,
    layout: "/auth"
  },
  {
    path: "/vocabulary/:catId",
    name: "Vocabularies",
    component: Vocabulary,
    layout: "/admin",
    type:2
  },
  
];

export default routes;
