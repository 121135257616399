import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class About extends React.Component {
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
 render(){   
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Language Academy</title>
        <meta name="twitter:title" content="Language Academy" />
        <meta property="og:title" content="Language Academy" />
     </Helmet>
    <Header />
        <div class="innerPage">
        <section class="bannerOuter">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>About Us</h1>
                    <span>Complete Preparation Package To Achieve The Score You Desire</span><br />
                    <a href="javascript:;" class="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                </div>
            </div>
        </div>
        </section>
        </div>
        <section class="whylanAcdmy">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h2 class="title">Why Language <span>Academy?</span></h2>
                    <p>At Language Academy, you can be assured that you will be learning from the best in the industry. We give a touch of care and personal attention to every student, which is what differentiates us from the rest.</p>

                    <p>We are so confident in our strategies that we offer a FREE Trial Face-2-Face Class to everyone – so you can judge for yourself! Please contact us to book your FREE Face-2-Face Trial Class now!</p>
                </div>
            </div>
        </div>
        </section>
        <section style={{padding: '30px 90px 50px'}}>
        <div class="row">
                  <div class="col-sm-6 col-md-5">
                     <img src="images/about-us.png" class="img-responsive center-block visible-lg visible-xs" style={{width:'90%'}} alt="about" />
                     
                  </div>
                  <div class="col-xs-12 visible-xs">
                     <br/><br/>
                  </div>
                  <div class="col-sm-6 col-md-7">
                     <h3 class="right-head" style={{marginTop:'0px'}}> Our Success is Defined by Our Student’s Success. The recipe of their success: </h3>
                     <br/>
                     <ul class="checklist">
                        <li> Regular Personal Feedback</li>
                        <li> Learn effective techniques and strategies to crack your test</li>
                        <li> Mock tests under exam conditions</li>
                        <li> Access to our resources and practice materials</li>
                     </ul>
                  </div>
               </div>
            </section>
        <section>
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <div class="row whyloveCntnt">
                        <div class="col-sm-4">
                            <img src="images/mission.png" alt="mission" />
                            <h3>Our Mission</h3>
                            <p>We understand your struggles. We stand to be able to help students’ progress</p>
                        </div>
                        <div class="col-sm-4">
                            <img src="images/vission.png" alt="vission" />
                            <h3>Our Vision</h3>
                            <p>We aim to create a better life for people struggling to obtain their permanent residency</p>
                        </div>
                        <div class="col-sm-4">
                            <img src="images/values.png" alt="values" />
                            <h3>Our Values</h3>
                            <p>We endeavor to behave professionally, communicate with our team and students </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
    <Footer />
    </React.Fragment>);
    }
}

export default About;