import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Alert,
  Col,Button,
  CardFooter,
} from "reactstrap";

import API from "api.js";
import SimpleReactValidator from 'simple-react-validator';
import { setAlert} from 'action/index.js';
import { setToken, setUser, setSub } from 'action/index.js';
// core components
import {store} from 'store/index';
import { connect } from 'react-redux';
const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok)),
      setUser: (user) => dispatch(setUser(user)),
      setSub: (sub) => dispatch(setSub(sub))
   };
};
class AccessCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      access_code: '',
      error_bag:null,
      paidUser:0
    };
    this.validator = new SimpleReactValidator();

  }

  async componentDidMount() {
    let stateData = store.getState();
    // check & state paid user or not
    this.setState({paidUser:stateData.user.user_paid_plan_id !== null?1:0});
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

  getProfileData = async() => {
    try{
      const response = await API({
        method: 'GET',
        url: '/profile'
      })
      let userData = {};
      if (response.status === 200)
        userData = response.data.data.profile_details;
      return userData;
    }catch(error) {
      this.setState({error:{error} });
    };
  }

  submitAccessCode = async(e) => {
    try{

      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        return false;
      }

      this.setState({error_bag:null});
      if ((this.state.access_code === "") || (this.state.access_code === null)){
        store.dispatch(setAlert({'flag':true,'type':2,'message':"Access code cannot be empty."}));
        return false;
      }

      const response = await API({
        method: 'GET',
        url: '/use_access_code/'+this.state.access_code,
      })

      if (response.data.success){
        let profileData = await this.getProfileData();
        this.props.setUser(profileData);
        this.props.setSub(true);
        store.dispatch(setAlert({'flag':true,'type':1,'message':"Access code has been added successfully. Please relogin into application/web portal to access the course."}));
      }
      else
        store.dispatch(setAlert({'flag':true,'type':2,'message':"Something went wrong, either the access code is invalid or plan is invalid. Please contact administrator."}));

    }catch(error) {
      this.setState({error:{error} });
    };
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                  <CardTitle tag="h4"><span>Access Code</span></CardTitle>
                </CardHeader>

                <CardBody>
                  { this.state.paidUser === 1 && <Alert color="info">Your plan is already active, so, you cannot use access code feature.</Alert> }
                  <div className="profileOuter">
                <Form>

                    <Row>
                      <Col className="" md="5" sm="12">
                        <FormGroup>
                          <label>Access Code</label>
                          <Input
                            defaultValue={this.state.access_code}
                            name="access_code"
                            placeholder="Have access code?"
                            type="text"
                            readOnly={this.state.paidUser}
                            required={true}
                            onChange={(e) => { this.changeInput(e)} }
                          />
                          {this.validator.message('access code', this.state.access_code, 'required')}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  </div>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-round mx-auto"
                    color="info"
                    disabled={this.state.paidUser === 1?true:false}
                    onClick={this.submitAccessCode}
                  >
                    Submit
                  </Button>
                  
                </CardFooter>
              </Card>
            </Col>

          </Row>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessCode);
