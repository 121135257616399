import React from "react";
import "components/custom.css";
import API from "api.js";
import NotificationAlert from "react-notification-alert";
//import { Redirect, NavLink } from "react-router-dom";
import Footer from "components/Footer/Footer.jsx";
class Subscription extends React.Component {
  state =  {
    cvc: '',
    message: '',
    expYear: '',
    expMonth: '',
    cardNumber: '',
    formProcess: false,
    paymentStatus:false,
    plan:false
  };
 
  componentDidMount() {
    this.loadPlan();
    this.loadStripe();    
  }

  loadPlan = () =>{
      API({
        method: 'GET',
        url: '/plans/'+this.props.match.params.id,
      }).then(data => {
        //console.log("PLAMS",data.data.data.plan_details)
        this.setState({plan:data.data.data.plan_details});
    }).catch(()=>{
        
    });
  }
 
  handleChange = (evt) => {
    this.setState({
      [evt.target.name] : evt.target.value
    });
  }
  
  notify = (type,message) => {
    
    switch (type) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: 'tr',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  pay = async (e) => {
 
    e.preventDefault();
 
    this.setState({
      formProcess: true
    });
 
    window.Stripe.card.createToken({
      number: this.state.cardNumber,
      exp_month: this.state.expMonth,
      exp_year: this.state.expYear,
      cvc: this.state.cvc
    },async (status, response) => {  
      if (status === 200) {
        this.setState({
          message: ``,
          formProcess: false
        });
        var bodyFormData = new FormData();
        bodyFormData.set('planId', this.props.match.params.id);
        bodyFormData.set('tokenId', response.id);
        let res = null;
        
        try{
          res = await API({
            method: 'POST',
            url: 'plans/',
            data: {
              "tokenId":response.id,
              "planId":this.props.match.params.id
            },
          })
        }catch(error) {
            console.log(error);
        };
        if(res.data){
          if (res.data.success === true) {
            this.notify(1,res.data.message);
            this.setState({paymentStatus:true});        
          }else{
            this.notify(2,res.data.message);
            this.setState({paymentStatus:false});
          }
        }
 
      } else {
        this.setState({
          message: response.error.message,
          formProcess: false
        });
      }
    });
  }
 
 
  loadStripe = () => {
     
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey('pk_live_JIwxa9FoBUiNl8YZcQClauSQ00aVGdi4OQ');
        //window['Stripe'].setPublishableKey('pk_test_f3HFKuah7T4BNhb9cReEXjEY00d143JEoD');
      }
      window.document.body.appendChild(s);
    }
  }
  render() {

    if (this.state.paymentStatus) {
      this.props.history.push(`/sub/success?p=${this.state.plan.price}`);
    }
    return (
      <div className="main-panel" ref="mainPanel">
        <div className="content" style={{height:'100vh'}}>
        <NotificationAlert ref="notificationAlert" />    
          <div className="row">
              <div className="col-xs-12 col-md-6 mr-auto subPaymentPage">            
                  <div className="panel panel-default">
                      <h2 className="formTitle">Payments</h2>
                      <form onSubmit={this.pay}>
                        <div className="panel-body mt-5">
                            
                            <p><b>{this.state.message}</b></p>
                            <div className="row">

                                <div className="col-xs-12 col-md-12">
                                    <div className="form-group">
                                        <label>Card Number</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Valid Card Number" name="cardNumber" maxLength="18" onChange={this.handleChange} />
                                            <span className="input-group-addon"><span className="fa fa-credit-card"></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-7 col-md-7">
                                    <div className="form-group">
                                        <label><span className="hidden-xs">Expiration</span> Date</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <select name="expMonth" className="form-control"  onChange={this.handleChange}>
                                                        <option value="">Select Month</option>
                                                        <option value="1">01</option>
                                                        <option value="2">02</option>
                                                        <option value="3">03</option>
                                                        <option value="4">04</option>
                                                        <option value="5">05</option>
                                                        <option value="6">06</option>
                                                        <option value="7">07</option>
                                                        <option value="8">08</option>
                                                        <option value="9">09</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 pull-right">
                                                <div className="form-group">
                                                    <select name="expYear" className="form-control" onChange={this.handleChange}>
                                                        <option value="">Select Year</option>
                                                        <option value={new Date().getYear() - 100}>{1900 + new Date().getYear()}</option>
                                                        <option value={new Date().getYear() - 99}>{1901 + new Date().getYear()}</option>
                                                        <option value={new Date().getYear() - 98}>{1902 + new Date().getYear()}</option>
                                                        <option value={new Date().getYear() - 97}>{1903 + new Date().getYear()}</option>
                                                        <option value={new Date().getYear() - 96}>{1904 + new Date().getYear()}</option>
                                                        <option value={new Date().getYear() - 95}>{1905 + new Date().getYear()}</option>
                                                        <option value={new Date().getYear() - 94}>{1906 + new Date().getYear()}</option>
                                                        <option value={new Date().getYear() - 93}>{1907 + new Date().getYear()}</option>
                                                        <option value={new Date().getYear() - 92}>{1908 + new Date().getYear()}</option>
                                                        <option value={new Date().getYear() - 91}>{1909 + new Date().getYear()}</option>
                                                        <option value={new Date().getYear() - 90}>{1910 + new Date().getYear()}</option>          
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                            
                                </div>
                                <div className="col-xs-5 col-md-5 pull-right">
                                    <div className="form-group">
                                        <label>CVV CODE</label>
                                        <input type="text" name="cvv" className="form-control" placeholder="CVC" maxLength="4" onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            <div className="row">
                                <div className="col-xs-12 col-md-12">
                                    { (this.state.formProcess)? (
                                        <span className="btn btn-warning btn-lg btn-block">Please wait...</span>
                                      ) : (
                                        <button className="btn btn-warning btn-lg btn-block"><span className="lockImg"></span> Process payment</button>
                                      )}
                                </div>
                            </div>
                        </div>
                    </form>
                    <p className="mt-2"></p>
                    <div className="formBottomImg">
                      <img src="/images/payment-form-img.png" alt="payment-form-img" />
                    </div>
                  </div>
                </div>
            
              <div className="col-xs-12 col-md-6 mr-auto pt-5 subPaymentPage planDetails">
                <div className="panel panel-default">
                  <div className="panel-body mt-5" style={{borderRight:"1px black ridge"}}>
                    <div className={(this.state.plan.name === "Beginner")?"priceBlockBigener":"priceBlockBigener priceBlockPro"}>
                      <h2><span>Beginner</span>Price - $ {this.state.plan && this.state.plan.price}</h2>
                      <h5>Duration - {this.state.plan && this.state.plan.validity/30} Month(s)</h5>
                      {/*<h6>Video Course Duration - 2 Month(s)</h6>*/}
                      {/*<div className="plan-detail">{this.state.plan && (this.state.plan.is_video)?<i className="fa fa-check grn-color" aria-hidden="true"></i>:<i className="fa fa-times text-danger" aria-hidden="true"></i>} <span> Video Course Access</span></div>*/}
                      {/*<div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span>{this.state.plan && this.state.plan.mock_count} AI Scored Full Mock Test</span></div>
                      <div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span> {this.state.plan && this.state.plan.mock_count*4} AI Scored Sectional Mock Test</span></div>*/}
                    </div>
                    <div className="plan-detail"><i className="fa fa-check grn-color" aria-hidden="true"></i> <span>Real Exam Practice Material</span></div>
                    <div className="plan-detail"><i className="fa fa-check grn-color" aria-hidden="true"></i> <span>Full Vocabulary Access</span></div>
                    <div className="plan-detail"><i className="fa fa-check grn-color" aria-hidden="true"></i> <span>Full Length Mock Tests</span></div>
                    <div className="plan-detail"><i className="fa fa-check grn-color" aria-hidden="true"></i> <span>Last Minute Practice</span></div>
                    <div className="plan-detail"><i className={(this.state.plan.name === "Beginner")?"fa fa-times red-color":"fa fa-check grn-color"} aria-hidden="true"></i> <span>1 Mock test with Scorecard and Feedback on Improvement</span></div>
                  </div>
                  
                </div>
            </div>
          </div>
        </div>
        <Footer fluid />
      </div>
    );
  }
}

export default Subscription;
