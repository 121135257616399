import React,{Component} from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
//var recognition = new SpeechRecognition();
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Alert,
  Col,
  CardFooter
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import { setAlert} from 'action/index.js';
import API from "api.js";
import { setUser} from 'action/index.js';
// core components
import {store} from 'store/index';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};

const Dictaphone = (props) => {
  const { transcript } = useSpeechRecognition()

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null
  }
  props.setTrans(transcript);
  return "";
}

class Mock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
       listening: false,
      activeTab:1,
      userName: '',
      first_name: store.getState().user.first_name,
      last_name: store.getState().user.last_name,
      country_citizenship:store.getState().user.country_citizenship,
      country_residence:store.getState().user.country_residence,
      dob: store.getState().user.dob,
      email: store.getState().user.email,
      phone: store.getState().user.phone,
      address: store.getState().user.address,
      lang: store.getState().user.lang,
      error_bag:null,
      trans:""
    };
    this.validator = new SimpleReactValidator();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

  componentDidMount() {
    //document.querySelector('select[name="lang"]').value= store.getState().user.lang;
  }

  postSignUp = async(e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
    this.setState({error_bag:null});
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.set('phone', this.state.phone);
    bodyFormData.set('first_name', this.state.first_name);
    bodyFormData.set('last_name', this.state.last_name);
    bodyFormData.set('dob', this.state.dob);
    bodyFormData.set('lang', this.state.lang);
    bodyFormData.set('address', this.state.address);
    bodyFormData.set('email', this.state.email);
    bodyFormData.set('country_citizenship', this.state.country_citizenship);
    bodyFormData.set('country_residence', this.state.country_residence);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/saveUser',
        data: bodyFormData
      })
    }catch(error) {
        this.setState({error:{error} });
    };
    console.log(res);
    if (res.status === 200) {
      this.props.setUser({...res.data.response.user});
      store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.response.message}));       
    }else if (res.response.status === 422) {
      this.setState({error:res.response });
      let str = '';
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str});
    }
  }

  startListen(){
    SpeechRecognition.startListening({ continuous: true });
  }

  toggle = tab => {
    if(this.state.activeTab !== tab){
      this.setState({activeTab:tab});
    }
  }
  setTrans = (a)=>{
    if (this.state.trans !== a) {
      this.setState({trans:a})  
    }    
  }
  
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                  <CardTitle tag="h4"><span>Chrome speech to text</span></CardTitle>
                </CardHeader>
                <CardBody>
                    
                    <Row>
                      <Col md="12">
                        <div>
                        <h5>Once  you've  picked  a  general  topic  for  your  paper,  you  need  to  come  up  with  a  thesis.  Your  thesis  is  the  main  and  focal  point  of  your  paper  and  it's  the  position  you'll  take  on  your  particular  topic.  Formulating  a  strong  thesis  is  one  of  the  most  important  things  you  need  to  do  to  ace  your  paper. </h5>
                          <button onClick={this.startListen}>Start</button>
                          <button onClick={SpeechRecognition.stopListening}>Stop</button>
                          
                          <p>{this.state.trans}</p>
                        </div>
                        <Dictaphone setTrans={this.setTrans} />      
                      </Col>
                    </Row>
                </CardBody>
                <CardFooter>                  
                </CardFooter>
              </Card>
            </Col>            
          </Row>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mock);