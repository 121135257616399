import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import API from "../api.js";
import { setAlert} from 'action/index.js';
import {store} from 'store/index';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Redirect} from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
class Franchise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:"",
            phone:"",
            email:"",
            location:"",
            reason:"",
            experience:"",
            error:[],
            submit:false,
            flag:false,
            lockSubmit:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({[name]:value});
    }
    submitForm = async(e) =>{
        console.log(this.state);
        if (this.validator.allValid() && !this.state.lockSubmit) {
            this.setState({lockSubmit:true});
            e.preventDefault();
            var bodyFormData = new FormData();
            bodyFormData.append('name', this.state.name);
            bodyFormData.append('phone', this.state.phone);
            bodyFormData.append('email', this.state.email);
            bodyFormData.append('location', this.state.location);
            bodyFormData.append('reason', this.state.reason);
            bodyFormData.append('experience', this.state.experience);
            API({
              method: 'POST',
              url: "post/franchise",
              data:bodyFormData,
            }).then(data => {
                console.log(data);
                this.setState({submit:true,lockSubmit:false,name:'',phone:'',email:'',location:'',reason:'',experience:'',flag:true});
                store.dispatch(setAlert({'flag':true,'type':1,'message':data.data.response.message}));
            }).catch(()=>{
                this.setState({lockSubmit:false});
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render(){ 
        if (this.state.flag) {
            return (<Redirect to="/thank-you" />);
        } 
        return (
            <React.Fragment>
            <Helmet htmlAttributes>
                <title>Language Academy</title>
                <meta name="twitter:title" content="Language Academy" />
                <meta property="og:title" content="Language Academy" />
            </Helmet>
            <Header />
            <div class="innerPage franchiseBanner">
                <section class="bannerOuter">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 text-center">
                                <h1>Own A Franchise</h1>
                                <span>We turned our passion into our business. Now it’s your turn.</span><br />
                                <a href="javascript:;" class="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section class="franchiseCntnt">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 franchCntntInr">
                            <h2 className="pb-3">Become part of the LA family and turn<br /> your passion into a business.</h2>
                        </div>
                        <div class="col-sm-12">
                            <p>Our goal is clear, to help as many students achieve their dreams.</p>
                            <p>If you are looking forward to helping students achieve their goals and run a business at the same time, you are at the right place.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="whyLa">
                <div class="container">
                    <div class="row whyLaInr">
                        <div class="col-sm-12">
                            <div class="services-presentation">
                                <div class="inner-container">
                                    <h2>Why LA?</h2>
                                    <div id="service-1" class="service">
                                        <img src="images/trusted.png" alt="trusted" />
                                        <p class="service-text">Most trusted and recognized PTE training brand all across the globe.</p>
                                    </div>
                                    <div id="service-2" class="service">
                                        <img src="images/successful-students.png" alt="successful-students" />
                                        <p class="service-text">More than 20,000<br />successful students in<br />the last 3 years.</p>
                                    </div>
                                    <div id="service-3" class="service"><img src="images/multi-user.png" alt="multi-user" />
                                        <p class="service-text">We are a team. All the training<br />and support provided<br />throughout the operations.</p>
                                    </div>
                                    <div id="service-4" class="service"><img src="images/speaker.png" alt="speaker" />
                                        <p class="service-text">Proven operations and marketing<br />system provided.</p>
                                    </div>
                                    <div id="service-5" class="service"><img src="images/training.png" alt="training" />
                                        <p class="service-text">Regular training and<br />quality standard checks.</p>
                                    </div>
                                    <div id="service-6" class="service"><img src="images/star.png" alt="star" />
                                        <p class="service-text">Commitment to quality and<br />student support.</p>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </section>
            <section class="franchForm">
                <div class="container">
                    <div class="row franchFormInr">
                        <div class="col-sm-12">
                            <h2 class="text-center">Apply Now</h2>
                            <p class="text-center">If you want to become a part of ours, please fill out the inquiry<br />form below and our team will be in touch with you.</p>
                            <div class="fieldOuter">
                                <label>Name*</label>
                                <input type="text" name="name" placeholder="Your Answer" value={this.state.name} onChange={this.handleChange}/>
                                {this.validator.message('Name', this.state.name, 'required')}
                            </div>
                            <div class="fieldOuter">
                                <label>Mobile*</label>
                                <input type="text" name="phone" placeholder="Your Answer" value={this.state.phone} onChange={this.handleChange}/>
                                {this.validator.message('Mobile', this.state.phone, 'required')}
                            </div>
                            <div class="fieldOuter">
                                <label>Email*</label>
                                <input type="email" name="email" placeholder="Your Answer" value={this.state.email} onChange={this.handleChange}/>
                                {this.validator.message('Email', this.state.email, 'required')}
                            </div>
                            <div class="fieldOuter">
                                <label>The location you are interested in?*</label>
                                <input type="text" name="location" placeholder="Your Answer" value={this.state.location} onChange={this.handleChange}/>
                                {this.validator.message('Location', this.state.location, 'required')}
                            </div>
                            <div class="fieldOuter">
                                <label>Why do you want to be a part of LA?*</label>
                                <input type="text" name="reason" placeholder="Your Answer" value={this.state.reason} onChange={this.handleChange}/>
                                {this.validator.message('Reason', this.state.reason, 'required')}
                            </div>
                            <div class="fieldOuter">
                                <label>Do you have any experience in the education industry?*</label>
                                <input type="text" name="experience" placeholder="Your Answer" value={this.state.experience} onChange={this.handleChange}/>
                                {this.validator.message('Experience', this.state.experience, 'required')}
                            </div>
                            <div class="fieldOuter">
                                {!this.state.lockSubmit && <button type="submit" name="" onClick={this.submitForm} class="themeBtn" >Submit</button>}
                                {this.state.lockSubmit && <button type="submit" name="" class="themeBtn" >Processing...</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="smallTxt">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 mt-5">
                            <p>Based on the answers above, our franchising team will be in touch with you.</p>
                            <p>Only apply if you want to genuinely help students achieve their dreams along with making a profit.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            </React.Fragment>
        );
    }
}
export default Franchise;