import React from 'react';
import { Link,Redirect} from 'react-router-dom';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
/*import FameWallPte from '../components/Frontend/FameWallPte';
import VideoTuts from '../components/Frontend/VideoTuts';
import WhyLove from '../components/Frontend/WhyLove';*/
import Testimonial from '../components/Frontend/Testimonial';
import SimpleReactValidator from 'simple-react-validator';
import {store} from 'store/index';
import { setAlert} from 'action/index.js';
import API from "api.js";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Pte extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        email: '',
        phone:'',
        course:'IELTS Online',
        lang:'Hindi',
        query:'',
        flag:false,
        loginLock:false,
        readMoreFlag:false
      };
      this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
    submitForm = (e) => {
      e.preventDefault();
      this.hitApi();
    }

    changeReadMore = () =>{
      this.setState({readMoreFlag:!this.state.readMoreFlag})
    }

    hitApi = async() => {
      if (this.state.loginLock) {
        return ;
      }
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        return ;
      }
      this.setState({loginLock:true});
      
      var bodyFormData = new FormData();
      bodyFormData.set('email', this.state.email);
      bodyFormData.set('name',this.state.name);
      bodyFormData.set('phone',this.state.phone);
      bodyFormData.set('message','<b>course:</b> '+this.state.course+'<br/><b>Query:</b> '+this.state.query);
      bodyFormData.set('data', 'email: '+ this.state.email+',name: '+this.state.name+',phone: '+this.state.phone+',course: '+this.state.course+',query: '+this.state.query);
      bodyFormData.set('page', "IELTS coaching Sydney");
      let res = null;
      try{
        res = await API({
          method: 'POST',
          url: 'sendOnlinePte',
          data: bodyFormData
        })
      }catch(error) {

          
      };
      console.log(res)
      if (res?.status === 200) {
        store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
        this.setState({name:'',email:'',phone:'',course:'PTE On-Campus',query:'',loginLock:false,flag:true})
      }else{
        this.setState({loginLock:false});
      }
      
    
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  }
 render(){  
  if (this.state.flag) {
    return (<Redirect to="/thank-you" />);
  } 
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>IELTS Classes in Sydney - Language Academy</title>
        <meta name="twitter:title" content="IELTS Classes in Sydney - Language Academy" />
        <meta property="og:title" content="IELTS Classes in Sydney - Language Academy" />
        <meta name="description" content="Are you searching for the best PTE coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top PTE training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching centre, contact at +61 426230061." />      
        
        <meta name="twitter:description" content="Are you searching for the best PTE coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top PTE training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching centre, contact at +61 426230061." />
        <meta property="og:description" content="Are you searching for the best PTE coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top PTE training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching centre, contact at +61 426230061." />
    </Helmet>
    <div className="innerPage pteOnline ieltsClasses">
    <Header />
      <section class="bannerOuter">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1>IELTS Training Centre in Sydney</h1>
        <Link to="/contact" className="themeBtn">Contact us</Link>
      </div>
    </div>
  </div>
</section>
<section class="ieltsTraining">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 ">
        <div className="text-center">
          <h2 class="title">IELTS training in <span>Sydney with Language Academy</span></h2>
        </div>
        <p>There are many English Training Institutes in Sydney with the top facilities. Language Academy is one of the many such renowned IELTS classes in Sydney. Spacious classes with all the facilities to help ease the learning make it easier for students to get habitual of real test-like environment and improve quickly. Students easily start overcoming the nervousness and improving, getting more confident with one-on-one feedback from the experts.</p>
        <p>We give attention to the performance of every student in each section, and so, we create personalized learning modules for each student and modify it with the course of their progress. We are really proud of the fact that we are counted among the leading IELTS classes in Sydney.</p>
        {!this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text" >Read More</p>}
        {this.state.readMoreFlag && <React.Fragment>
        <h5>Is Sydney The Best Location for IELTS Coaching?</h5>
        <p>Sydney is one of the largest cities in Australia. The capital of New South Wales, this city is famous for its Sydney Opera House located at the harbour front. Several instances are proving the existence of people as early as 30,000 years ago, making this place significant even because of historical engravings.</p>

        <h5>The IELTS Training in Sydney CBD, Australia</h5>
        <p>Non-English Speakers willing to move to any English-speaking nation like Australia can take an English language test, and obtain a certificate proving their expertise in the English Linguistics Skills. Many tests assess the English language capabilities of the non-native English speakers, but IELTS (International English Language Testing System), is one of the most preferred and widely recognized tests among all.</p>
        <p>Aspirants are assessed in two ways – through General and Academic modules. Students are supposed to take IELTS Academic and score a minimum of 6.5. Whereas all other non-student migrators can take IELTS General. Scoring just a score of Grade 5.5 is, however, qualifies an applicant.</p>

        <h5>Advantages of IELTS Course and Future Opportunities</h5>
        <p>IELTS training in Sydney from Language Academy will be helpful for you in successfully achieving the extra points toward your Australian permanent residency. You can apply for any professional or educational engagement with these scores. The certificate will stand valid for 2 years (one additional year if it is General module), which allows you to have ample time to prepare for permanent moving to Sydney, Australia or anywhere else in Australia.</p></React.Fragment>}
        {this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text">Read Less</p>}
      </div>
    </div>
  </div>
</section>
<section class="practicePortal">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 class="title">Why choose <span>Language Academy IELTS Training</span></h2>
        <div class="row whyloveCntnt">
          <div class="col-sm-4">
            <img src="images/experienced-trainer.png" alt="experienced-trainer" />
            <h3>Experienced Trainer</h3>
            <p>They are adept at both Linguistics skills and imparting the educational lectures online.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/star-ribben.png" alt="star-ribben" />
            <h3>Highest Success Rate</h3>
            <p>With detailed and well-planned online learning modules, we are able to give the best training to our online students, and they are constantly achieving 8+ band scores.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/book.png" alt="book" />
            <h3>Free Intensive Material</h3>
            <p>Our templates make it easier for any online student to self-learn quickly from the framework and follow them to achieve the best scores.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/settings.png" alt="settings" />
            <h3>Latest Resources</h3>
            <p>Our students get access to our online learning resources.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Online Support</h3>
            <p>Our experts are always for any online student. You can reach out to any trainer and ask questions or seek help in solving any doubts that you have.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Past Exam questions to Practice</h3>
            <p>We have a repository of past exam questions with answers to practice from that can help any student to quickly practice the previous year’s questions.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Discussion Sessions</h3>
            <p>Our faculties carefully assess the level of the students during the online discussion sessions and suggest the personalized study plan.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Affordable Fees</h3>
            <p>We have kept the online course fees so less so that every student can easily afford to pay the costs on their own.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Real Time Test Environment</h3>
            <p>We assess all our online students from time to time and give them a real-time test environment, so they acclimate to the actual test environment and feel less stressed during the actual test.</p>
          </div>
        </div>
        <button class="themeBtn">Register for a FREE Trial Session for IELTS</button>
      </div>

    </div>
  </div>
</section>
<section class="WhyLove">
  <div class="container">
    <div class="row whyloveCntnt text-center">
      <div class="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>500+</h3>
        <p>Coaching Preview</p>
      </div>
      <div class="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>500+</h3>
        <p>Registered students</p>
      </div>
      <div class="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>200+</h3>
        <p>Successful Students</p>
      </div>
    </div>
  </div>
</section>
<section class="getWithUs">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h2 class="title text-center">What You Will <span>Get With Us</span></h2>
        <div class="row">
          <div class="col-sm-8">
            <div class="ourFeatures">
              <ul class="checklist">
                <li>Unlimited Classes</li>
                <li>Unlimited Writing & Speaking Assessments</li>
                <li>1 to 1 Tutorial with Tutor</li>
                <li>Practice Questions</li>
                <li>Method Lessons</li>
                <li>Overview Classes</li>
                <li>Pre-Test Strategy Sessions</li>
                <li>Post-Test Consultations</li>
              </ul>
            </div>
          </div>
          <div class="col-sm-4 text-center">
            <div class="naatiCoaching">
              <h3>Take a FREE Demo Class of Online IELTS Coaching</h3>
              <form onSubmit={this.submitForm}>
                <div class="fieldOuter">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name*"
                    value={this.state.name}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Name', this.state.name, 'required')}
                </div>
                <div class="fieldOuter">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email*"
                    value={this.state.email}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Email', this.state.email, 'required')}
                </div>
                <div class="fieldOuter">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone*"
                    value={this.state.phone}
                    onChange={(e) => { this.changeInput(e)} } 
                  />
                  {this.validator.message('Phone', this.state.phone, 'required')}
                </div>
                <div class="fieldOuter">
                  <select 
                  name="course"
                  value={this.state.course}
                  onChange={(e) => { this.changeInput(e)} }>
                    <option value="IELTS Online">IELTS Online</option>
                    <option value="PTE On-Campus">PTE On-Campus</option>
                    <option value="NAATI CCL Online">PTE Online</option>
                    <option value="NAATI CCL On-Campus">NAATI CCL On-Campus</option>
                    <option value="IELTS Online">NAATI CCL Online</option>
                    <option value="IELTS On-Campus">IELTS On-Campus</option>
                  </select>
                </div>
                <div class="fieldOuter">
                  <input type="text"
                    name="query"
                    placeholder="Comments/Query"
                    value={this.state.query}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Query', this.state.query, 'required')}
                </div>
                <div class="fieldOuter">
                  {!this.state.loginLock && <button class="themeBtn" onClick={(e)=>{this.hitApi()}}>Send Message</button>}
                  {this.state.loginLock && <button class="themeBtn" >Wait...</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Testimonial />
<section class="mapOuter">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-sm-12">
          <iframe style={{width: '100%', height: '500px'}} src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Suite%20502%20Level%205/630%20George%20St,%20Sydney%20NSW%202000,%20Australi+(Language%20Academy%20Sydney%20-%20PTE,%20IELTS%20&amp;%20NAATI%20CCL%20Coaching%20Classes)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" title="Iframe4"></iframe>
      </div>
    </div>
  </div>      
</section>
<section class="tutorialVd">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 class="title">IELTS <span>Tutorial Videos</span></h2>
        <div class="videosCntnt">
          <div class="videoBlk">
            <div class="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/_ZXBmscplck?list=PLfM0GWoT8-NLQ_nsckqcwQtwbV6V4rg_6" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="Iframe1"></iframe>
            </div>
          </div>
          <div class="videoBlk">
            <div class="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/Hhu17DFCQtM?list=PLfM0GWoT8-NLQ_nsckqcwQtwbV6V4rg_6" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="Iframe2"></iframe>
            </div>
          </div>
          <div class="videoBlk">
            <div class="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/W2QX4_73sGs?list=PLfM0GWoT8-NLQ_nsckqcwQtwbV6V4rg_6" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="Iframe3"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    <Footer />
      </div>
    </React.Fragment>);
    }
}

export default Pte;