import React from "react";
import "components/custom.css";
import API from "api.js";
import {store} from 'store/index';
import {setSub  } from 'action/index.js';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class SubSuccess extends React.Component {
  state =  {
    interval: null
  };
  
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const p = urlParams.get('p');

    ReactGA.initialize('UA-157984080-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init('197333187452630');
    ReactPixel.track('Purchase', {value: p, currency: 'AUD'});
        
    //var inter = setInterval(this.checkSub, 3000);
    //this.setState({interval:inter}); 
    var element = document.getElementsByClassName("wrapper-full-page");
    if(element.length > 0){
      element[0].classList.add("full-height");   
      element[0].classList.remove('wrapper-full-page');   
    }
  }
 
  checkSub = async () => {
    let res = null;
      try{
        res = await API({
          method: 'GET',
          url: 'check/payment'
        })
      }catch(error) {
          
      };
      if(res.status === 200){
        clearInterval(this.state.interval);
        store.dispatch(setSub(true));
      }
  }
  render() {
    return (
    <div class="col-xs-12 successContent">
      <i class="fa fa-check"></i>
      <p>You are successfully subscribed to our plan, your service activated after payment recieved</p>
      <p className="mt-2"></p>
    </div>
    );
  }
}

export default SubSuccess;
