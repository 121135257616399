import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

class WhatWeOffer extends React.Component {
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
        if (window.location.href.indexOf('#packages') !== -1) {
            window.location.hash='';
            window.location.hash='packages';
        }
    }
 render(){   
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Language Academy</title>
     </Helmet>
    <Header />
        <div class="innerPage weOfrBnr">
        <section className="bannerOuter">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h1>AI in Education</h1>
                        <span>Artificial Intelligence: Transforming Education and the Learning Experience </span><br />
                        <a href="javascript:;" className="downArrow"><img src="/images/banner-arrow.png" alt="banner-arrow" /></a>
                    </div>
                </div>
            </div>
        </section>
        </div>
        <section className="whyOnline whyOnlineOD">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 courseFeatures">
                        <p>AI is not the future. It is the present. And we can totally feel its existence even in the educational sector which mostly rely on human interaction. Artificial Intelligence has played a crucial role in the modern world. It is gradually transforming education and the learning experience. From the traditional classroom setup to personalized online learning, AI has proven that it is now an integral part in the global education landscape. In fact, most of the universities today have opted to rely on AI to drive enrolment and to deliver instruction. With the development of AI, a lot of educational institutions are revisiting their curricular programs to meet the demands of AI technology.</p>


                        <div className="courses-offered aiBenefits">
                            <img src="/images/artificial-intelligence-3706562_960_720.jpg" alt="aet" />
                            <h2>What are the benefits of AI in today’s education? </h2>
                            <ol>
                                <li>Personalized Instruction – AI makes learning more tailored because it has the capacity to find out what students know.  AI is designed to answer specific students’ needs taking into account the knowledge gaps and ways on how to fill the gaps.  Learning portals allow students to log in anytime, anywhere giving them so much convenience to access their lessons and requirements.</li>
                                <li>Automation of Admin Tasks – For teachers, the amount of time checking and grading requirements is reduced with the automation ability of AI.  It permits teachers to spend more time with students having to leave most of the admin work for AI technology.</li>
                                <li>Tutoring and Support – AI applications have given more attention to tutoring software and tools which can help students learn at their own pace. Personal study programs outside the classroom aid the learners to cope with the course content and keep the parents and teachers from struggling to explain complex and challenging lessons.</li>
                                <li>Universal Access to Learning – AI has granted inclusivity in learning with the adoption of AI technologies for students with special needs.  AI can grant access to students with learning disabilities such as those who are visually impaired and have difficulty in hearing.</li>
                                <li>Quick response – AI can provide answers immediately using existing knowledge data base even without the presence of the teacher. The instant feedback would make their learning more productive and rewarding as they immediately know what aspects in their learning experience they excel and need to improve.</li>
                                <li>Learning Flexibility – Artificial Intelligent I tools can facilitate unsupervised learning and can direct students to achieve the learning goals. Students can track their own progress at their chosen pace thus reducing less pressure and academic stress.</li>
                            </ol>
                            <p>AI may be intimidating to understand at first but having a closer look at it would make you appreciate AI technology more. Here are some of the favorite AI tools for education which can make learning more engaging and fun.</p>
                        </div>
                        <div className="courses-offered">
                            <p><b>AI Tools for Education</b></p>
                            <ul className="course-content">
                                <li>Voice recognition – This tool is effective for language learning and can enhance speech and phonetic awareness of students</li>
                                <li>Natural language processing – This AI tool can help in identifying student grammar and word mechanics difficulties. This can improve the writing skills of students by providing immediate and actionable feedback.</li> 
                                <li>Semantic analysis – Texts and images are quickly interpreted using this AI tool. This can aid students to research and investigate textual content and make generalizations out of it.</li>
                                <li>Pronunciation enhancement - This AI tool makes speech classes more enjoyable by teaching students different languages and correct pronunciation.  Learning a new language becomes possible because of this technology.</li> 
                            </ul>
                            <p className="course-content">There are still a lot of tools that AI can offer but what is more important is that this advanced technology has benefited education in so many ways. Learning has become more manageable, responsive to the needs of time, and accessible to anyone around the world.</p>
                            <p className="course-content">AI has a huge potential in the transformation of education. With its developing technology, there is still so much more to look forward to with AI. The contributions of Artificial Intelligence in education is endless and it is high time to look into its advantages rather than its threats.</p>
                        </div>
                        <div className="courses-offered">
                            <h3>AI, Innovations and Technology at LA.</h3>
                            <p>Varun's and his team's most prestigious work is that of LA Language Academy, the most innovative AI-powered Practice Portal (www.languageacademy.com.au). Along with his team of highly skilled teachers, Varun builds a strong network with the intent to bridge the knowledge gap between his community of learners in Australia, and all other parts of the world.</p> 

                            <p>The LA Language Academy portal boasts cutting-edge technology through AI-powered software where students can log on to anytime and anywhere. The first of its kind, the portal makes use of technology that allows the students to access their lessons and learning requirements, while at the same time tailoring these lessons for each student's needs individually.</p> 

                            <p>The platform is built to facilitate unsupervised learning, completely AI-Powered, and has been developed by <strong><em>taking machine learning, deep learning, language processing, trusted algorithms, and semantic analysis</em></strong>. Inculcating the prodigious applications of AI, the platform is capable of self-guiding students with their speaking fluency and pronunciation enhancement, as well as grammar and writing enhancement.</p>

                            <p>Some special functions include instant feedback based on user input, essay and speaking evaluation through AI-Voice recognition and pronunciation enhancement, tracking the student's progress, identifying areas where they are not good at, and allowing both the teacher and the student to focus on these certain areas in order to improve them</p>

                            <p>This platform is so that each of his students would have equal learning opportunities, regardless of their location, accessibility, or differences in language proficiencies. As unique as each student is, the portal serves as an avenue to improve each of his or her capabilities by helping them identify their weaknesses and turning them into strengths.</p>
                            <div class="offerVideoOuter">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/0Ij5cPLtfEw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/OTGnFO_iQP0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="courses-offered">
                            <h2>Language Academy’s Future Plan</h2>
                            <p>Language Academy continues to aim high and ensure quality education for its learners, with the constant goal of being a top institution in the higher education sector. Language Academy, led by its founder and CEO, Varun Dhawan, is a trailblazing institution that seeks to further revolutionize the traditional higher education curriculum through merging and intertwining the technological aspects of AI and software with that of business knowledge. Varun Dhawan and his team have explored the most significant areas presented initially through the traditional method, but when these become integrated with the aspects of technology, artificial intelligence, and even business, these areas can produce more high-yield results for the Australian education and society, as well as provide Australians with a new educational edge.</p>

                            <p>In the forthcoming future, LA Language Academy plans to implement AI into learning and offer the following higher-ed courses </p>
                        </div>

                        <div className="courses-offered aiCaurses">
                            <h4>Courses</h4>
                            <img src="/images/what-is-artificial-intelligence.jpeg" alt="courses" />
                        </div>

                        <div className="courses-offered tabCntnOutr">
                            <ul class="nav nav-tabs">
                                <li class="active"><a data-toggle="tab" href="#course01">AI in Business</a></li>
                                <li><a data-toggle="tab" href="#course02">Machine Learning and Use</a></li>
                                <li><a data-toggle="tab" href="#course03">Deep Learning</a></li>
                                <li><a data-toggle="tab" href="#course04">Natural Language Processing</a></li>
                                <li><a data-toggle="tab" href="#course05">AI and Cybersecurity</a></li>
                                <li><a data-toggle="tab" href="#course06">AI and Blockchain</a></li>
                                <li><a data-toggle="tab" href="#course07">AI in Ethics</a></li>
                                <li><a data-toggle="tab" href="#course08">Advanced IT</a></li>
                            </ul>
                          <div class="tab-content">
                            <div id="course01" class="tab-pane fade in show active">
                              <h5>1. AI in Business</h5>
                                <p><strong>Course Description:</strong> This course provides a glimpse into the world of artificial intelligence (AI) interspersed in the field of business and in everyday life. This area talks about AI as a well-valued support system that is proficient in the processing and analysis of ample amounts of data in record time. AI in business also incorporates valuable software and algorithms that make work easier for users without having to rely tediously on human hands.</p>
                                <p><strong>No. of Hours/Weeks:</strong> 48 hours/16 weeks (3 hours a week) </p>
                                <p><strong>Prerequisites:</strong> Students who are interested to enrol in this class should have a prior exposure to Basic Algebra, Statistics and Probability, IT projects, and business management</p>

                                <p><strong>Expected Learning Outcomes:</strong></p>
                                <p>At the end of the course, the learners are expected to:</p>
                                <ul>
                                    <li>Acquire basic knowledge about AI’s fundamental concepts and methods</li>
                                    <li>Identify the potentials of AI in economy, government, and society</li>
                                    <li>Analyze the ethical implications of AI in different areas of business</li>
                                    <li>Manage business data for customer service, sales, and marketing </li>
                                    <li>Apply AI support system in business strategies and practical tasks </li>
                                    <li>Build AI solutions in response to business trends and global economic issues </li>
                                </ul>
                            <h5>Course Outline</h5>
                            <div class="tabCntnOutr">
                                <ul class="nav nav-tabs">
                                    <li><a data-toggle="tab" href="#week1">Weeks 1</a></li>
                                    <li><a data-toggle="tab" href="#week23">Weeks 2 & 3</a></li>
                                    <li><a data-toggle="tab" href="#week45">Weeks 4 & 5</a></li>
                                    <li><a data-toggle="tab" href="#week67">Weeks 6 & 7</a></li>
                                    <li><a data-toggle="tab" href="#week10">Weeks 8 to 10</a></li>
                                    <li><a data-toggle="tab" href="#week14">Weeks 11 to 14</a></li>
                                    <li><a data-toggle="tab" href="#week16">Weeks 15 & 16</a></li>
                                </ul>
                                <div class="tab-content">
                                    <div id="week1" class="tab-pane fade">
                                        <h5>Week 1</h5>
                                        <div className="course-content">
                                            <p><strong>Module 1:</strong>  Foundations of Artificial Intelligence </p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Introduction to AI</li>
                                                <li>Concepts and Methods of AI</li>
                                                <li>AI Technology Tools in Business</li> 
                                            </ul>
                                            <p><em>In the first week, you will be exposed to the foundations of artificial intelligence through class discussion and lectures by AI experts and educators. Topics in the first week will deal with the overview of AI such as its brief history, uses, and significance in today’s world. The concepts, methods and tools of AI specifically those applicable in business will also be introduced and thoroughly examined.</em></p>
                                        </div>
                                    </div>
                                    <div id="week23" class="tab-pane fade">
                                        <h5>Weeks 2 & 3 </h5>
                                        <div className="course-content">
                                            <p><strong>Module 2:</strong>  Potentials of AI Technologies</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>AI to Improve Customer Experience</li>
                                                <li>AI to Increase Sales and Profit</li>
                                                <li>AI to Make Automated Marketing Decisions</li>
                                            </ul>
                                            <p><em>Through readings and discourse of several articles about the use of AI in developing the global economy, society, and business, you will be able to understand and appreciate the potentials of AI technologies in the digital era. You will be asked to contribute your insights by presenting a news feature article about a business organization which made use of AI to achieve success and commercial recognition in the aspects of customer service, sales, and marketing. </em></p>
                                        </div>
                                    </div>
                                    <div id="week45" class="tab-pane fade">
                                        <h5>Weeks 4 and 5 </h5>
                                        <div className="course-content">
                                            <p><strong>Module 3:</strong> Ethical Implications of AI in Business </p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Business Ethics and AI </li>
                                                <li>Ethics Risks of AI Systems</li>
                                                <li>Transparency, Privacy, and Accountability </li>
                                            </ul>
                                            <p><em>With a focus on business ethics and the rise of artificial intelligence, Module 3 will deal mostly on analyses of case studies on the challenges of AI in the sustainability of transparency, privacy, and accountability of business entities. You will develop mindfulness and consideration towards AI when adopting new ways of machinery without compromising human workforce.</em></p>
                                        </div>
                                    </div>
                                    <div id="week67" class="tab-pane fade">
                                        <h5>Weeks 6 and 7</h5>
                                        <div className="course-content">
                                            <p><strong>Module 4:</strong> Working with Business Data</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Search and Data Mining </li>
                                                <li>Customer Database</li>
                                                <li>Sales and Marketing Automation </li>
                                            </ul>
                                            <p><em>Having learned the foundations of AI, the impact of AI in business will be further examined by working with big data. This supervised experiential learning will allow you to have a hands-on practice on how to search and mine data from customer database so that you will have a better understanding of the challenges and opportunities of sales and marketing automation in your future business industry.</em></p>
                                        </div>
                                    </div>
                                    <div id="week10" class="tab-pane fade">
                                        <h5>Weeks 8, 9, and 10</h5>
                                        <div className="course-content">
                                            <p><strong>Module 5:</strong>  AI Applications in Business</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>AI for Customer Service</li>
                                                <li>AI for Sales</li>
                                                <li>AI for Marketing </li>
                                            </ul>
                                            <p><em>With the understanding of AI platforms, tools, technologies, and big data you will look at how AI is being applied for Customer Service, Sales, and Marketing to produce measurable ROI. You will create a business proposal incorporating AI applications in a business of your choice. Through supervised learning, you will be mentored by AI experts in business in crafting your proposal which is feasible and relevant to the current economic situation.</em></p>
                                        </div>
                                    </div>
                                    <div id="week14" class="tab-pane fade">
                                        <h5>Weeks 11, 12, 13, 14</h5>
                                        <div className="course-content">
                                            <p><strong>Module 6:</strong>  AI Solutions and the Future of Business</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Global Business Trends </li>
                                                <li>Use of AI in Business </li>
                                                <li>AI and Future Directions </li>
                                            </ul>
                                            <p><em>As the course comes to an end, you will spend time in building our AI applications in your approved business proposal. This capstone project will help you create an AI solution that has an impact to the global economic trends and business landscape. You will be guided on how to find solutions to business problems from AI standpoint. You will also experience an implementation of AI applications that has a remarkable business value.</em></p>
                                        </div>
                                    </div>
                                    <div id="week16" class="tab-pane fade">
                                        <h5>Weeks 15 and 16</h5>
                                        <div className="course-content">
                                            <p>Capstone Project Presentation </p>
                                            <p><em>As a culminating activity, the capstone project presentation will give you an opportunity to showcase your AI project in a forum.  This is one way for the academy to search and recognize highly-skilled AI students who can use the learning experience in transforming the business industry through Artificial Intelligence technologies.</em></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p><strong>Course Requirements</strong></p>
                            <div className="course-content">
                                <table>
                                    <tr>
                                        <td>Quizzes</td>
                                        <td>15%</td>
                                    </tr>
                                    <tr>
                                        <td>Class Participation</td>
                                        <td>15%</td>
                                    </tr>
                                    <tr>
                                        <td>Assignments</td>
                                        <td>15%</td>
                                    </tr>
                                    <tr>
                                        <td>Case Analysis</td>
                                        <td>15%</td>
                                    </tr>
                                    <tr>
                                        <td>Capstone Project</td>
                                        <td>40%</td>
                                    </tr>
                                    <tr>
                                        <td>TOTAL</td>
                                        <td>100%</td>
                                    </tr>
                                </table>
                            </div>
                            <p><strong>Career Paths and Opportunities</strong></p>
                            <ul className="course-content">
                                <li>AI Data Analyst</li>
                                <li>AI Engineer</li>
                                <li>AI Intelligence Specialist</li>
                                <li>Automation Service Specialist</li>
                                <li>Big Data Engineer</li>
                                <li>Business Consultant </li>
                                <li>Business Intelligence Developer</li>
                                <li>Business Software Designer</li>
                                <li>Data Scientist</li>
                                <li>Economic Analyst</li>
                                <li>Market Data Analyst</li> 
                                <li>Product Manager</li>
                                <li>Research Scientist</li> 
                            </ul>
                            <p><strong>Why Learn AI in Business? </strong></p>
                            <ul className="course-content">
                                <li>The global economy and business organizations are rapidly adapting AI industry. This gives you more opportunities to find high paying careers.</li>
                                <li>With the digital transformation and fast-paced technology developments, AI is now becoming in-demand in business and commerce.</li>
                                <li>AI technology has a significant impact on business and it makes AI a more profitable career today and in the future.</li>
                                <li>Knowledge and skills in AI will get you a better edge on other job candidates and even in the workplace.</li> 
                                <li>Having the foundations of AI can assist you how to leverage AI strategies in your business organizations and companies leading to better production and efficient business management and administration.</li> 
                                <li>Through practical studies, you will become highly confident in using AI across industries to amplify your business success.</li>
                                <li>You can be a corporate leader who had a deeper understanding of the transformation of business in this modern society.</li> 
                            </ul>
                            </div>
                            <div id="course02" class="tab-pane fade">
                              <h5>2. Machine Learning and Use</h5>
                              <p><strong>Course Description:</strong> This course gives an introduction on machine learning, which is a common form of AI that uses big chunks of data for quick processing into a more understandable context for human users, and is popularly utilized in most businesses in this day and age. This course highlights how algorithms for machine learning are fed a constant stream of data from different devices, and then analyse and identify significant information via a specific algorithm, and later improve with the information they have over time.</p>
                              <p><strong>No. of Hours/Weeks:</strong> 30 hours/10 weeks (3 hours a week)</p>
                              <p><strong>Prerequisites:</strong> Students who are interested to enrol in this class should have a prior exposure to Statistics, Calculus, Linear Algebra and Probability, Computer Programming, and Data Modelling</p>
                              <p><strong>Expected Learning Outcomes:</strong></p>
                              <p>At the end of the course, the learners are expected to:</p>
                              <ul>
                                <li>Gain formal understanding of how, why, and when machine learning works</li>
                                <li>Explore the different types and models of machine learning</li>
                                <li>Learn how statistical inferences are applied in machine learning</li>
                                <li>Analyze how algorithms in machine learning functions</li>
                                <li>Investigate the different ways that machine learning takes place</li>
                                <li>Develop hands on experience on machine learning and how to apply it in everyday lives</li>
                            </ul>
                            <h5>Course Outline</h5>
                            <div class="tabCntnOutr">
                                <ul class="nav nav-tabs">
                                    <li><a data-toggle="tab" href="#week102">Week 1</a></li>
                                    <li><a data-toggle="tab" href="#week202">Week 2</a>
                                    </li>
                                    <li><a data-toggle="tab" href="#week502">Weeks 3, 4, and 5</a></li>
                                    <li><a data-toggle="tab" href="#week702">Weeks 6 and 7</a></li>
                                    <li><a data-toggle="tab" href="#week802">Weeks 8, 9, and 10</a></li>
                                </ul>
                                <div class="tab-content">
                                    <div id="week102" class="tab-pane fade">
                                        <h5>Week 1</h5>
                                        <div class="course-content">
                                            <p><strong>Module 1:</strong>  Understanding Machine Learning </p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>The Historical Perspectives</li>
                                                <li>Concepts of Machine Learning</li>
                                                <li>Machine Learning and Ethics</li>
                                            </ul>
                                            <p><em>In the first week, through series of lectures, you will have an overview of the history of machine learning and how it evolved today.  You will also learn the basic definition of machine learning and unpack important machine learning terminologies. Moreover, you will be acquainted with the ethical consideration in working with machine learning to avoid human conflict and future problems.</em></p>
                                        </div>
                                    </div>
                                    <div id="week202" class="tab-pane fade">
                                        <h5>Weeks 2</h5>
                                        <div class="course-content">
                                            <p><strong>Module 2:</strong> Machine Learning Types and Models</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Supervised Learning</li>
                                                <li>Unsupervised Learning</li>
                                                <li>Reinforcement Learning</li>
                                            </ul>
                                            <p><em>For the second week, you will differentiate the types of machine learning, their specific purpose and action, and how they can be utilized in data and business management. Through examples and models, you will have a grasp of these types of learning and their uses so that you will be guided how to evaluate and create your own model later on. You will be given a take home assignment on machine learning types which you need to submit before the due date.</em></p>
                                        </div>
                                    </div>
                                    <div id="week502" class="tab-pane fade">
                                        <h5>Weeks 3, 4, and 5</h5>
                                        <div class="course-content">
                                            <p><strong>Module 3:</strong> Machine Learning and Algorithms</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Basic Statistics in Machine Learning</li>
                                                <li>Commonly Used Machine Learning Algorithm</li>
                                                <li>Uses of Statistical Inferences in Machine Learning</li>
                                            </ul>
                                            <p><em>Weeks 3, 4, and 5 will allow you to revisit your statistical knowledge specifically on inferential data analysis which are commonly used for data science. Learning algorithms such as linear regression, logistic regression, decision tree, Naïve Bayes algorithm, K-means will be given emphasis. This module will look into the big data and how these are streamed to different devices via algorithms and how this information is identified and interpreted.  With the given problem sets, you will be given a time to practice answering numerous drills and exercises and analyze how algorithms function in machine learning.</em></p>
                                        </div>
                                    </div>
                                    <div id="week702" class="tab-pane fade">
                                        <h5>Weeks 6 and 7</h5>
                                        <div class="course-content">
                                            <p><strong>Module 4:</strong> Main Uses of Machine Learning</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Supervised Learning uses and Examples</li>
                                                <li>Unsupervised Learning Uses and Examples</li>
                                                <li>Machine Learning Algorithms for Business</li>
                                            </ul>
                                            <p><em>Having learned the different types of machine learning and algorithms, weeks 6 and 7 will expose you to the different uses of machine learning in preparation for your hands on experience and project. You will be able to identify the uses of machine learning for each type.  The focus of this module is on the conceptualization of a project that aims to utilize machine learning in most businesses today. Whatever skills you acquired in this course will be relevant in the future landscape of the corporate and digital industry.</em></p>
                                        </div>
                                    </div>
                                    <div id="week802" class="tab-pane fade">
                                        <h5>Weeks 8, 9, and 10</h5>
                                        <div class="course-content">
                                            <p><strong>Module 5:</strong>  Working with Machine Learning</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Choosing the Right Machine Learning Model for Business</li><li>Real-world Application of Machine Learning</li>
                                                <li>The Future Direction of Machine Learning</li>
                                            </ul>
                                            <p><em>The last three weeks would delve deeper into the uses of machine learning which can be applicable to the project you have conceptualized in the past weeks. Our learning platform is designed to guide you in choosing the right machine learning model for your proposed project thus giving you the flexibility in creating your project in this hands on experience activity. This module will help you understand how machine learning works in the real world as you will be able to apply what you have acquired in this course through independent learning.</em>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <p><strong>Course Requirements</strong>
                                </p>
                                <div class="course-content">
                                   <table>
                                      <tr>
                                         <td>Assignments</td>
                                         <td>20%</td>
                                      </tr>
                                      <tr>
                                         <td>Problem Sets</td>
                                         <td>30%</td>
                                      </tr>
                                      <tr>
                                         <td>Project</td>
                                         <td>50%</td>
                                      </tr>
                                      <tr>
                                         <td>TOTAL</td>
                                         <td>100%</td>
                                      </tr>
                                   </table>
                                </div>
                                <p><strong>Career Paths and Opportunities</strong></p>
                                <ul class="course-content">
                                   <li>Algorithm Specialist</li>
                                   <li>Big Data Engineer</li>
                                   <li>Business Intelligence Developer</li>
                                   <li>Computational Linguist</li>
                                   <li>Computer Engineering Consultant</li>
                                   <li>Computer Programmer</li>
                                   <li>Data Scientist</li>
                                   <li>Human Centered Machine Learning</li>
                                   <li>Information Analyst</li>
                                   <li>Machine Learning Engineer</li>
                                   <li>Research Scientists</li>
                                   <li>Software Analyst</li>
                                   <li>Software Developer and Designer</li>
                                   <li>Statistical Analyst</li>
                                   <li>University Professor</li>
                                </ul>
                                <p><strong>Why Learn Machine Learning</strong></p>
                                <ul class="course-content">
                                   <li>Machine Learning is a growing field and a high demand with a lucrative annual salary.</li>
                                   <li>Skills on machine learning makes you a marketable resource in any sector such as business, healthcare, engineering, manufacturing which leaves you a lot of opportunities to choose from.</li>
                                   <li>All industries have a lot of applications in machine learning so there is a shortage of machine learning experts as of now.</li>
                                   <li>You will have a secure career because machine learning is a skill of the future.</li>
                                   <li>Machine learning is promising in the business industry and this will expose you to work on real-life challenges which could have a great impact to the society.</li>
                                   <li>A job on machine learning will give you great fulfilment because you are part of the solutions of the issues that the industry faces in the digital age.</li>
                                   <li>Since machine learning is a thriving skill, you will have a first-hand experience on this field that can intensify your relevance in the marketplace.</li>
                                   <li>It gives you an opportunity to expand your career avenues and work simultaneously in other fields such as data science which is a big chunk of machine learning skill.</li>
                                   <li>This is a career journey filled with knowledge challenges, and excitement which can make your life more gratifying.</li>
                                </ul>
                            </div>
                            <div id="course03" class="tab-pane fade">
                              <h5>3. Deep Learning</h5>
                                <p><strong>Course Description:</strong> This course is an introduction of deep learning, an advanced type of machine learning that delves into a more enhanced way for algorithms to imitate deep neural networks through nonlinear reasoning. It covers technology and algorithms that analyse a wide range of factors and large amounts of data at once, inspired by that of the human brain’s own neuronal network.</p>
                                <p><strong>No. of Hours/Weeks:</strong> 36 hours/12 weeks (3 hours a week)</p>
                                <p><strong>Prerequisites:</strong> Students who want to take this course should have an understanding of Basic Python, Linear Algebra and Probability</p>

                                <p><strong>Expected Learning Outcomes:</strong></p>
                                <p>At the end of the course, the learners are expected to:</p>
                                <ul>
                                    <li>Understand the capabilities and challenges of deep learning</li>
                                    <li>Examine the technological trends that drive the rise of deep learning</li>
                                    <li>Identify key parameter in a neural network</li>
                                    <li>Implement efficient neural network components, architectures, and algorithms</li>
                                    <li>Explore the security aspects of deep learning algorithms</li>
                                    <li>Recognize research problems and proposed approaches in deep learning</li>
                                </ul>
                            <h5>Course Outline</h5>
                            <div class="tabCntnOutr">
                                <ul class="nav nav-tabs">
                                    <li><a data-toggle="tab" href="#week103">Week 1</a></li>
                                    <li><a data-toggle="tab" href="#week203">Weeks 2</a></li>
                                    <li><a data-toggle="tab" href="#week403">Weeks 3 & 4</a></li>
                                    <li><a data-toggle="tab" href="#week703">Weeks 5, 6 and 7</a></li>
                                    <li><a data-toggle="tab" href="#week903">Weeks 8 and 9</a></li>
                                    <li><a data-toggle="tab" href="#week1203">Weeks 10, 11, and 12</a></li>
                                </ul>
                                <div class="tab-content">
                                    <div id="week103" class="tab-pane fade">
                                        <h5>Week 1</h5>
                                        <div className="course-content">
                                            <p><strong>Module 1:</strong>  Fundamentals of Deep Learning</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Definition of Deep Learning</li>
                                                <li>Capabilities of Deep Learning</li>
                                                <li>Challenges of Deep Learning</li> 
                                            </ul>
                                            <p><em>In the first week, you will have a full grasp of what deep learning is and to what extent it can do in the present times and in the future.  The lectures will focus more on the capabilities and challenges of deep learning so that you will be prepared on what deep learning can offer to humanity and how you would be able to solve issues involving deep learning and AI.</em></p>
                                        </div>
                                    </div>
                                    <div id="week203" class="tab-pane fade">
                                        <h5>Week 2</h5>
                                        <div className="course-content">
                                            <p><strong>Module 2:</strong> The Rise of Deep Learning</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Current State of Deep Learning</li>
                                                <li>Deep Learning and the Technology</li>
                                                <li>The Future of Deep Learning</li>
                                            </ul>
                                            <p><em>Through real life examples, you will be able to walk through the evolution of deep learning and how it has grown in the recent times.  You will have a full understanding of how the technological trend has contributed to the rise of deep learning in business, healthcare, finance, and other industries. By understanding the current state of deep learning, you will have a clearer picture of the prospects of deep learning.  At this point, your task is to create an expository essay based on your observation about the upsurge of deep learning.</em></p>
                                        </div>
                                    </div>
                                    <div id="week403" class="tab-pane fade">
                                        <h5>Weeks 3 and 4 </h5>
                                        <div className="course-content">
                                            <p><strong>Module 3:</strong> Introduction to Neural Networks</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Terminologies and argons</li>
                                                <li>Simple Neural Networks</li>
                                                <li>Non-linearities</li>
                                            </ul>
                                            <p><em>Module 3 will introduce to you the neural networks basics which are the fundamental system in deep learning.  You will have a wider understanding why neural networks are used in deep learning.  With a focus on simple neural networks and non-linearities, you will be able to solve complex problems and analytical calculations and carry on basic deep learning tasks like image recognition and natural language understanding.</em></p>
                                        </div>
                                    </div>
                                    <div id="week703" class="tab-pane fade">
                                        <h5>Weeks 5, 6 and 7</h5>
                                        <div className="course-content">
                                            <p><strong>Module 4:</strong> Exploring Neural Network</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Neural Network Components</li>
                                                <li>Neural Network Architecture</li>
                                                <li>Neural Network Top Algorithms </li>
                                            </ul>
                                            <p><em>For three weeks, the in-depth discussion will focus on neural network components, architecture, and algorithms. The principal function of neural network will be further discoursed so that you will be able to understand how the massive amount of data and complicated patters can be classified by the neural network. There will also be drills and problem sets to be answered for you to have a full exposure to neural network no matter how complex it is.</em></p>
                                        </div>
                                    </div>
                                    <div id="week903" class="tab-pane fade">
                                        <h5>Weeks 8 and 9</h5>
                                        <div className="course-content">
                                            <p><strong>Module 5:</strong> Security of Neural Network</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Vulnerability of Neural Network’s Security</li>
                                                <li>Solutions to Security Problems</li>
                                                <li>Possible Issues in the Future</li>
                                                <li>Applications of Deep Learning in Cybersecurity</li>
                                            </ul>
                                            <p><em>Having explored neural networks, you will encounter several case studies and real-time issues regarding security vulnerabilities of neural network. This is to make you aware that neural network is vulnerable to hackers, thus giving you solution show to protect your system in the future. You will also learn different applications of deep learning in cybersecurity which will encourage you to implement a network with so much consideration on security protection.</em></p>
                                        </div>
                                    </div>
                                    <div id="week1203" class="tab-pane fade">
                                        <h5>Weeks 10, 11, and 12</h5>
                                        <div className="course-content">
                                            <p><strong>Module 6:</strong> Deep Learning and the Future</p>
                                            <p class="topicOD">Topics:</p>
                                            <ul>
                                                <li>Research Trends</li>
                                                <li>Deep Learning in Industries</li>
                                                <li>The Impending Issues and Solutions</li>
                                            </ul>
                                            <p><em>As the course comes to an end on the 10th week, you will be given time to become visionaries by looking into the future of deep learning. Through reviewing the research literature of deep learning, you will be able to have a glimpse of what is in store in deep learning and AI in several industries.  As a final project, you will write a term paper focusing on the current issues of deep learning and the solutions you want to implement. These solutions would be supported with readings and literature which you have encountered in this course.</em></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p><strong>Course Requirements</strong></p>
                            <div className="course-content">
                                <table>
                                    <tr>
                                        <td>Problems Sets</td>
                                        <td>20%</td>
                                    </tr>
                                    <tr>
                                        <td>Midterm Output</td>
                                        <td>30%</td>
                                    </tr>
                                    <tr>
                                        <td>Final Project</td>
                                        <td>50%</td>
                                    </tr>
                                    <tr>
                                        <td>TOTAL</td>
                                        <td>100%</td>
                                    </tr>
                                </table>
                            </div>
                            <p><strong>Career Paths and Opportunities</strong></p>
                            <ul className="course-content">
                                <li>Applied Scientist</li>
                                <li>Computer Vision Engineer</li>
                                <li>Data Analysis</li>
                                <li>Data Engineer</li>
                                <li>Data Scientist</li>
                                <li>Image Recognition</li>
                                <li>Deep Learning Instructor</li>
                                <li>Lead Manager for Deep Learning</li>
                                <li>Natural Language Process Engineer</li>
                                <li>Research Analyst</li>
                                <li>Software Developer</li>
                                <li>Software Engineer</li> 
                            </ul>
                            <p><strong>Why Learn Deep Learning?</strong></p>
                            <ul className="course-content">
                                <li>Having knowledge with deep learning will level up your career, thus making you marketable in the IT industry.</li>
                                <li>The next decade will be an AI society and the skills in deep learning is a main component in creating AI to make it into a thriving business.</li>
                                <li>Having deep learning specialization provides you a complete space in AI which can leverage your opportunities in the future.</li>
                                <li>Deep learning is the most advanced development in machine learning and business leaders have seen the need of understanding how deep learning works. Having the skills would give you a wider career path because this is such a sought-after expertise today and in the future.</li> 
                                <li>Deep learning is changing our world and being an expert on this would give you a chance to be a big part of the advancement.</li> 
                                <li>It would give you a better understanding of how complex and big data are simplified and classified. By learning the techniques, you will also have a chance to teach the younger generation so that our world will be prepared for the future.</li> 
                            </ul>
                            </div>
                            <div id="course04" class="tab-pane fade">
                              <h5>4. Natural Language Processing</h5>
                                <p><strong>Course Description:</strong> This course introduces natural language processing as an advanced type of artificial intelligence that makes use of the manipulation of natural human language into useful information by advanced software. This course aims to let learners see the interactions between computer software and that of human language such as speech and text, and appreciate how they process the given data into an organized and understandable output.</p>
                                <p><strong>No. of Hours/Weeks:</strong> 30 hours/10 weeks (3 hours a week)</p>
                                <p><strong>Prerequisites:</strong> Students who are interested to enrol in this class should have basic programming skills and general understanding of Calculus and Statistics</p>

                                <p><strong>Expected Learning Outcomes:</strong></p>
                                <p>At the end of the course, the learners are expected to:</p>
                                <ul>
                                    <li>Acquire knowledge on the basic principle, theoretical issues, and application of natural language processing</li>
                                    <li>Discuss current and future performance of several natural language applications</li>
                                    <li>Learn computational techniques used to develop information and communication system</li>
                                    <li>Gain insight into research problems and trends in natural language</li>
                                    <li>Design simple natural learning language experiment</li>
                                    <li>Apply recent deep learning frameworks in NLP design</li>
                                </ul>
                                <h5>Course Outline</h5>
                                <div class="tabCntnOutr">
                                    <ul class="nav nav-tabs">
                                        <li><a data-toggle="tab" href="#week104">Week 1</a></li>
                                        <li><a data-toggle="tab" href="#week304">Weeks 2 & 3</a></li>
                                        <li><a data-toggle="tab" href="#week604">Weeks 4, 5, and 6</a></li>
                                        <li><a data-toggle="tab" href="#week704">Week 7</a></li>
                                        <li><a data-toggle="tab" href="#week1004">Weeks 8, 9, and 10</a></li>
                                    </ul>
                                    <div class="tab-content">
                                        <div id="week104" class="tab-pane fade">
                                            <h5>Week 1</h5>
                                            <div className="course-content">
                                                <p><strong>Module 1:</strong>  Introduction to Natural Language Processing</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Basic Principles</li>
                                                    <li>Theoretical Issues</li>
                                                    <li>Applications</li> 
                                                </ul>
                                                <p><em>You will have an overview of what natural language processing is all about with the aid of our video lectures scheduled on the first week.  In this module, you will be able to understand the basic principles of NLP and the theories underlying this new development.  Moreover, you will also have a general idea on how natural language processing can be applicable in the recent industries and even in our day to day activities.</em></p>
                                            </div>
                                        </div>
                                        <div id="week304" class="tab-pane fade">
                                            <h5>Weeks 2 & 3</h5>
                                            <div className="course-content">
                                                <p><strong>Module 2:</strong> Probing into Natural Language Processing Tools</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Exploring Common NLP Applications</li>
                                                    <li>Future Performance of NLP Tools</li>
                                                    <li>Deep Learning Framework in NLP</li>
                                                </ul>
                                                <p><em>After learning basic principles of natural language processing, Module 2 will take you to deeper to NLP tools and applications.  You will be able to discover current NLP applications, discuss future performance, and predict AI communication through recent applications.  In addition, you will also probe into deep learning and how the frameworks can be applied in NLP. To further enhance your skills in NLP applications, hands on experience through series of activities will be introduced which will focus more on the NLP applications in business and trade industries.</em></p>
                                            </div>
                                        </div>
                                        <div id="week604" class="tab-pane fade">
                                            <h5>Weeks 4, 5, and 6</h5>
                                            <div className="course-content">
                                                <p><strong>Module 3:</strong> NLP, Information, and Communication</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Extracting Information Techniques</li>
                                                    <li>How NLP is used in Communication</li>
                                                    <li>Implementing NLP Techniques</li>
                                                    <li>Ethics in NLP Implementation</li>
                                                </ul>
                                                <p><em>Module 3 is basically more on experiential learning which will expose you to different techniques of extracting information, implementation of NLP applications, and utilization of NLP with consideration to ethical issues and security. With natural language understanding, text analytics, and computational techniques, you will be able to practice named entity recognition, text summarization, aspect mining, and many others which are now commonly used in AI industry.</em></p>
                                            </div>
                                        </div>
                                        <div id="week704" class="tab-pane fade">
                                            <h5>Week 7</h5>
                                            <div className="course-content">
                                                <p><strong>Module 4:</strong> Research Trends in NLP</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Common Research Topics for Natural Language Processing</li>
                                                    <li>Analysis of NLP Issues</li>
                                                    <li>Research Problems and Issues in NLP</li>
                                                </ul>
                                                <p><em>With the importance of research in the AI industry, this module emphasizes the need to conduct research about natural language processing.  Through discussions and analysis of research literature focusing on computer software and human language, you will become more aware of the several research problems which are worth studying in the future. The purpose of this is to come up with a breakthrough research in the future so that NLP will continue to evolve, improve, and thrive.</em></p>
                                            </div>
                                        </div>
                                        <div id="week1004" class="tab-pane fade">
                                            <h5>Weeks 8, 9, and 10</h5>
                                            <div className="course-content">
                                                <p><strong>Module 5:</strong> Natural Language Experiment</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Planning Stage</li>
                                                    <li>Designing Stage</li>
                                                    <li>Presentation of Design</li>
                                                </ul>
                                                <p><em>With the understanding of NLP research trends and issues, you are now challenged to come up with your own design of simple but relevant natural learning language experiment which shows a system of processing given data into an organized output. You will be given time to plan and conceptualize an experiment showcasing the application of deep learning framework.  Guidelines will also be provided so that your design will be more focused to what the recent technology needs. You will also present your design for critiquing and evaluation.  You also need to enhance your design based on the feedbacks given by your study group.</em></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p><strong>Course Requirements</strong></p>
                                <div className="course-content">
                                    <table>
                                        <tr>
                                            <td>Quizzes</td>
                                            <td>20%</td>
                                        </tr>
                                        <tr>
                                            <td>Assignments</td>
                                            <td>30%</td>
                                        </tr>
                                        <tr>
                                            <td>Final Project</td>
                                            <td>50%</td>
                                        </tr>
                                        <tr>
                                            <td>TOTAL</td>
                                            <td>100%</td>
                                        </tr>
                                    </table>
                                </div>
                                <p><strong>Career Paths and Opportunities</strong></p>
                                <ul className="course-content">
                                    <li>Automation Architect</li>
                                    <li>Big Data Developer</li>
                                    <li>Business Intelligence</li>
                                    <li>Chat/Virtual Engineer</li>
                                    <li>Chatbot Conversation Designer</li>
                                    <li>Conversational designer</li>
                                    <li>Data Analytics Head</li>
                                    <li>Data and Insights Manager</li>
                                    <li>Data Science Manager</li>
                                    <li>Enterprise Cloud Architect</li>
                                    <li>Full Stack Java Developer</li>
                                    <li>Lead Data Scientist</li>
                                    <li>Machine Learning Application Developer</li>
                                    <li>NLP Consultant</li>
                                    <li>NLP Engineer</li>
                                    <li>NLP Researcher</li>
                                    <li>NLP Software Developer</li>
                                    <li>Research Scientist</li>
                                </ul>
                                <p><strong>Why Learn Natural Language Processing?</strong></p>
                                <ul className="course-content">
                                    <li>There is a growing need of language processing tools and having a specialization on natural language processing will give you an edge in the job market.</li>
                                    <li>NLP matters today because it creates numeric structure to ambiguous language which in turn can help in the analysis of texts to be more understandable.</li>
                                    <li>To increase customer and to protect from cybersecurity issues and frauds, most organizations such as banking, manufacturing, government, heath care are already using text analytics which is a basic knowledge taught in natural language processing.</li> 
                                    <li>Having the skill in NLP, you can now create meaning to the big data through content categorization, contextual extraction, speech-to-text and text-to-speech conversion, document summarization, and machine translation.</li>
                                    <li>Natural Language Processing allows you to have a deeper understanding of the raw language input and how to use algorithms to enrich the text which in the end can provide better interpretation to vague and complex data.</li>
                                    <li>NLP is the future and having basic and advanced knowledge will make you more prepared with what technology can offer in the next years.</li>
                                    <li>You will be part of the improvement of the society by sharing your knowledge and capability in interpreting human language and uncovering insights which are hidden in the online streams such as social media and websites.</li>
                                    <li>More business problems will be resolved using NLP algorithms which can create a thriving and progressive economy.</li>
                                </ul>
                            </div>
                            <div id="course05" class="tab-pane fade">
                              <h5>5. AI and Cybersecurity</h5>
                                <p><strong>Course Description:</strong> This course provides essential information on the conventional use of AI in terms of security, privacy, and rights. With the easy yet extensive access of users to all kinds of information in the software world, it is fundamental to learn about the basics on what one can or cannot do in the aspect of AI and technology. This area emphasizes on teaching learners how to arm themselves, their privacy, and their personal information from any potential lapses and breaches.</p>
                                <p><strong>No. of Hours/Weeks:</strong> 36 hours/12 weeks (3 hours a week)</p>
                                <p><strong>Prerequisites:</strong> Students who are interested to enrol in this class should have a prior knowledge about foundational cybersecurity and IT knowledge, machine learning, and basic risk management methodology</p>

                                <p><strong>Expected Learning Outcomes:</strong></p>
                                <p>At the end of the course, the learners are expected to:</p>
                                <ul>
                                    <li>Have an overview of what cybersecurity is and the evolution of cybersecurity standards</li>
                                    <li>Identify the security and vulnerabilities of AI systems and recognize limitations and threats of AI</li>
                                    <li>Recommend appropriate risk reduction actions of cybersecurity threats and attacks</li>
                                    <li>Apply proper solutions to mitigate cyber-attacks, vulnerabilities and privacy issues</li>
                                    <li>Analyze case studies and research and look into the future developments in cybersecurity</li>
                                </ul>
                                <h5>Course Outline</h5>
                                <div class="tabCntnOutr">
                                    <ul class="nav nav-tabs">
                                        <li><a data-toggle="tab" href="#week205">Weeks 1 and 2</a></li>
                                        <li><a data-toggle="tab" href="#week405">Weeks 3 and 4</a></li>
                                        <li><a data-toggle="tab" href="#week705">Weeks 5, 6, and 7</a></li>
                                        <li><a data-toggle="tab" href="#week1005">Weeks 8, 9, and 10</a></li>
                                        <li><a data-toggle="tab" href="#week1105">Week 11</a></li>
                                        <li><a data-toggle="tab" href="#week1205">Week 12</a></li>
                                    </ul>
                                    <div class="tab-content">
                                        <div id="week205" class="tab-pane fade">
                                            <h5>Weeks 1 and 2</h5>
                                            <div className="course-content">
                                                <p><strong>Module 1:</strong> Key Concepts of Cybersecurity</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Definition of Cybersecurity</li>
                                                    <li>History and Cybersecurity Standards</li>
                                                    <li>Ethical Considerations in AI and cybersecurity</li> 
                                                </ul>
                                                <p><em>In this introductory week, you will be able to understand better what cybersecurity means and what encompasses it.  Discussions will also focus on the cybersecurity and its evolution as a field, and what standards and regulations were made to respond to the threats. Ethical challenges will also be presented to properly address when these issues will come up in the future.</em></p>
                                            </div>
                                        </div>
                                        <div id="week405" class="tab-pane fade">
                                            <h5>Weeks 3 and 4</h5>
                                            <div className="course-content">
                                                <p><strong>Module 2:</strong> The Limitations of AI</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Vulnerabilities of AI systems</li>
                                                    <li>The Issue of Safety and Privacy</li>
                                                    <li>Common Cyber Attacks</li>
                                                </ul>
                                                <p><em>For weeks 2, 3, and 4, lectures will delve deeper to the limitations of AI in terms of privacy, security, and cyber-attacks.  You will be introduced to different cyber-attacks and find ways to avoid them to protect your system. Knowing the limitations of AI will guide and prepare you on how to make AI systems safe and reliable when you face cybersecurity issues and breaches.</em></p>
                                            </div>
                                        </div>
                                        <div id="week705" class="tab-pane fade">
                                            <h5>Weeks 5, 6, and 7</h5>
                                            <div className="course-content">
                                                <p><strong>Module 3:</strong> Mitigating Cybersecurity Threats</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Network Security Basics</li>
                                                    <li>Securing Personal Devices</li>
                                                    <li>Authentication, Encryption, Antiviruses</li>
                                                </ul>
                                                <p><em>Knowing several cybersecurity threats, you will now encounter different tools and applications in mitigating the risks of cybersecurity threats. You will learn the basic network security for your organization and for your personal use. You will also have a first-hand experience on how to work with authentication, encryption, and antiviruses so that you can use these tools and applications when you encounter threats with your system.</em></p>
                                            </div>
                                        </div>
                                        <div id="week1005" class="tab-pane fade">
                                            <h5>Weeks 8, 9, and 10</h5>
                                            <div className="course-content">
                                                <p><strong>Module 4:</strong> Detection and Response</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Addressing the Insecurity</li>
                                                    <li>Role of Human Operators</li>
                                                    <li>Privacy and Data Governance</li>
                                                    <li>Security Practices</li>
                                                </ul>
                                                <p><em>In this module, you will learn the importance of early detection and response in mitigating cyber threats.  You will also appreciate the importance of human intervention in exercising control or oversight in automation problems, data privacy hacking, cyber attacks and many more. You will also learn the importance of data governance in the reliability of information systems. Having learned all the issues, you will be given a sample cybersecurity case to respond to using the tools, software, applications, processes, and practices you have gained.</em></p>
                                            </div>
                                        </div>
                                        <div id="week1105" class="tab-pane fade">
                                            <h5>Week 11</h5>
                                            <div className="course-content">
                                                <p><strong>Module 5:</strong> Cybersecurity Issues of Today</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Case Study Analysis</li>
                                                    <li>Research Trends in Cybersecurity</li>
                                                </ul>
                                                <p><em>In the 11th week of the course, you will be looking at case studies and research results, analyze them, and make predictions about the future of cybersecurity.  In-depth discussions will be done to further deliberate what cybersecurity can offer and how this can be improved for the betterment of the society.</em></p>
                                            </div>
                                        </div>
                                        <div id="week1205" class="tab-pane fade">
                                            <h5>Week 12</h5>
                                            <div className="course-content">
                                                <p><strong>Module 5:</strong> Looking Ahead to the Future of Cybersecurity</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Future Developments</li>
                                                    <li>Cybersecurity and Government Policy</li>
                                                </ul>
                                                <p><em>To end the course, you will look ahead to the direction of cybersecurity.  You will listen to lectures about policies of different organizations and governments on counter-attacking cyber threats. Moreover, you will have a glimpse of the developments of cyber security in terms of data privacy, cloud computing issues, third party software, Crypto and blockchain attacks and other common cybersecurity issues.</em></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p><strong>Course Requirements</strong></p>
                                <div className="course-content">
                                    <table>
                                        <tr>
                                            <td>Quizzes</td>
                                            <td>25%</td>
                                        </tr>
                                        <tr>
                                            <td>Class Participation</td>
                                            <td>25%</td>
                                        </tr>
                                        <tr>
                                            <td>Assignments</td>
                                            <td>25%</td>
                                        </tr>
                                        <tr>
                                            <td>Case Analysis</td>
                                            <td>25%</td>
                                        </tr>
                                        <tr>
                                            <td>TOTAL</td>
                                            <td>100%</td>
                                        </tr>
                                    </table>
                                </div>
                                <p><strong>Career Paths and Opportunities</strong></p>
                                <ul className="course-content">
                                    <li>Application Security Engineer</li>
                                    <li>Chief Information Security Officer</li>
                                    <li>Cryptographer</li>
                                    <li>Forensics Expert</li>
                                    <li>Incident Responder</li>
                                    <li>Information Security Analyst</li>
                                    <li>IT Security Specialist</li>
                                    <li>Network Security Engineer</li>
                                    <li>Penetration Tester</li>
                                    <li>Security Administrator</li>
                                    <li>Security Analyst</li>
                                    <li>Security Architect</li>
                                    <li>Security Auditor</li>
                                    <li>Security Consultant</li>
                                    <li>Security Director</li>
                                    <li>Security Engineer</li>
                                    <li>Security Manager</li>
                                    <li>Security Software Developer</li>
                                    <li>Security Specialist</li>
                                    <li>Source Code Auditor</li>
                                    <li>Vulnerability Assessor</li>
                                </ul>
                                <p><strong>Why Learn AI and Cybersecurity?</strong></p>
                                <ul className="course-content">
                                    <li>Companies need cybersecurity to protect their organization. Since data is valuable but information online is sensitive, a cybersecurity specialist is definitely a go-to person.</li>
                                    <li>Learning even the basic concept of cybersecurity will already give you an edge to protect yourself against cyber-attacks.</li>
                                    <li>Cybersecurity is a takeaway skill and there are millions of job openings for people who have knowledge about cybersecurity. This means that the job looks for you and not the other way around.</li> 
                                    <li>With the development of AI, network connectivity is growing which creates more demands for cybersecurity experts.</li>
                                    <li>You are making an investment in your future because cybersecurity experts receive a lucrative salary considering how important their roles are.</li>
                                    <li>This is a constant discovery, a changing landscape, and an exciting career especially for people who are always eager to solve complex problems.</li>
                                    <li>You have a multiple and practical educational direction with cybersecurity course because having the prior knowledge of IT, networking and systems management plus cybersecurity knowledge, you can have an impressive resume.</li>
                                    <li>Being a cybersecurity expert will always give you opportunities to think and figure things out to find solutions to cyber threats for the benefit of yourself, the organization, the community, and the world.</li>
                                </ul>
                            </div>
                            <div id="course06" class="tab-pane fade">
                              <h5>6. AI and Blockchain</h5>
                                <p><strong>Course Description:</strong> This course introduces the topic of blockchain, which is a technological innovation that makes use of a well-distributed and decentralized ledger that stores useful chunks of data. Blockchain’s combination with AI has become a hot trend in the cyber world, and this course will illustrate how they boost each other. The use of AI and machine learning together with blockchain can enhance its software potential and processing power, while blockchain and its ledger of data can contain polished algorithms that can read and analyse the data contained swiftly.</p>
                                <p><strong>No. of Hours/Weeks:</strong> 36 hours/12 weeks (3 hours a week)</p>
                                <p><strong>Prerequisites:</strong> Students who are interested to enrol in this class should have a prior experience with linear algebra, basic calculus, and basic probability theory.</p>

                                <p><strong>Expected Learning Outcomes:</strong></p>
                                <p>At the end of the course, the learners are expected to:</p>
                                <ul>
                                    <li>Gain ample knowledge on the functionality and utility of blockchain network technology</li>
                                    <li>Understand the regulatory framework for applying blockchain in business and finance settings</li>
                                    <li>Evaluate the implications of blockchain technologies for the financial services</li>
                                    <li>Develop broad understanding on how the Big data and AI can work with blockchains and cryptocurrencies</li>
                                    <li>Explore the opportunities presented by AI and blockchain technologies in business</li>
                                    <li>Choose an application of blockchain ecosystem suitable to the current economic setting of the world</li>
                                </ul>
                                <h5>Course Outline</h5>
                                <div class="tabCntnOutr">
                                    <ul class="nav nav-tabs">
                                        <li><a data-toggle="tab" href="#week106">Week 1</a></li>
                                        <li><a data-toggle="tab" href="#week306">Weeks 2 and 3</a></li>
                                        <li><a data-toggle="tab" href="#week506">Weeks 4 and 5</a></li>
                                        <li><a data-toggle="tab" href="#week706">Weeks 6 and 7</a></li>
                                        <li><a data-toggle="tab" href="#week906">Weeks 8 and 9</a></li>
                                        <li><a data-toggle="tab" href="#week1206">Weeks 10, 11, and 12</a></li>
                                    </ul>
                                    <div class="tab-content">
                                        <div id="week106" class="tab-pane fade">
                                            <h5>Week 1</h5>
                                            <div className="course-content">
                                                <p><strong>Module 1:</strong> The Functionality and Utility of Blockchain</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Overview of Current Technology Trends in Business Environment</li>
                                                    <li>The Technical Framework of Blockchain</li>
                                                    <li>Getting Familiar with Cryptocurrency</li> 
                                                </ul>
                                                <p><em>During the first week, an introductory lecture about blockchain will be available. There will be a short glimpse of the current business trends and how blockchain has emerged and changed the landscape of the financial services. You will further understand the technical framework of blockchain and how cryptocurrencies work in these highly-developed technology.</em></p>
                                            </div>
                                        </div>
                                        <div id="week306" class="tab-pane fade">
                                            <h5>Weeks 2 and 3</h5>
                                            <div className="course-content">
                                                <p><strong>Module 2:</strong> The Blockchain Networks</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>The Active Networks</li>
                                                    <li>Smart Contracts</li>
                                                    <li>The Token Economics</li>
                                                </ul>
                                                <p><em>Module 2 will direct you to a clearer picture of the blockchain networks. You will encounter different active networks and smart contracts which are successfully run by big companies today.  You will also understand how token economics work in this kind of system giving you a direction on what blockchain network would you design later on as the course ends.</em></p>
                                            </div>
                                        </div>
                                        <div id="week506" class="tab-pane fade">
                                            <h5>Weeks 4 and 5</h5>
                                            <div className="course-content">
                                                <p><strong>Module 3:</strong> AI and Blockchain in Finance</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>The Revolution in Financial Setting</li>
                                                    <li>Blockchain in Business Industry</li>
                                                    <li>Understanding the Regulatory Framework</li>
                                                </ul>
                                                <p><em>For two weeks, discussion will further go deeper to the capabilities of blockchain in different business domains and financial services. You will develop an understanding of the blockchain technology when applied to business and finance settings. You will also learn the regulatory, legal, and technical framework underlying the blockchain technology giving you ample understanding as to how the technology can be applied in the financial settings of today.</em></p>
                                            </div>
                                        </div>
                                        <div id="week706" class="tab-pane fade">
                                            <h5>Weeks 6 and 7</h5>
                                            <div className="course-content">
                                                <p><strong>Module 4:</strong> Implications of Blockchains</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Blockchain for Financial Services</li>
                                                    <li>Blockchain for Other Business Industries</li>
                                                </ul>
                                                <p><em>For Weeks 5 and 6, you will look into the implications of blockchain for financial services and other business industries like gaming, promotion, and entertainment.  There will be a discourse on the positive and negative impact of blockchain to these industries.  You will also read research literature concerning blockchain technology to further widen your understanding of its effects to the world’s economy and to evaluate whether these effects are relevant or not.</em></p>
                                            </div>
                                        </div>
                                        <div id="week906" class="tab-pane fade">
                                            <h5>Weeks 8 and 9</h5>
                                            <div className="course-content">
                                                <p><strong>Module 5:</strong> The Big Data and Blockchain</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Complementing Blockchains</li>
                                                    <li>Digital Asset Management</li>
                                                    <li>Blockchain and the Economy</li>
                                                </ul>
                                                <p><em>In this module, you will explore the use of Big data and AI and doscover how they complement the blockchain technology. You will also be guided on how to manage digital assets which are applicable for personal use and business industries.  Moreover, you will also be properly directed to the use of blockchain in the modern economy.  As part of the final project, you will design your own blockchain network which is applicable to the industry that you are interested in.</em></p>
                                                <ul>
                                                    <li>Develop broad understanding on how the Big data and AI can work with blockchains and cryptocurrencies</li>
                                                    <li>Explore the opportunities presented by AI and blockchain technologies in business</li>
                                                    <li>Choose an application of blockchain ecosystem suitable to the current economic setting of the world.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div id="week1206" class="tab-pane fade">
                                            <h5>Weeks 10, 11, and 12</h5>
                                            <div className="course-content">
                                                <p><strong>Module 5:</strong> The Blockchain Ecosystem</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Opportunities of AI and Blockchain</li>
                                                    <li>Designing a Blokchain Network</li>
                                                </ul>
                                                <p><em>You will be given 3 weeks to design your own simple blockchain network which complements the industry of your choice. You will be given a model as sample and you can either copy it or enhance it.  Afterwhich, your design will be presented in the class for feedbacking.</em></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p><strong>Course Requirements</strong></p>
                                <div className="course-content">
                                    <table>
                                        <tr>
                                            <td>Quizzes</td>
                                            <td>25%</td>
                                        </tr>
                                        <tr>
                                            <td>Class Participation</td>
                                            <td>25%</td>
                                        </tr>
                                        <tr>
                                            <td>Final Project</td>
                                            <td>50%</td>
                                        </tr>
                                        <tr>
                                            <td>TOTAL</td>
                                            <td>100%</td>
                                        </tr>
                                    </table>
                                </div>
                                <p><strong>Career Paths and Opportunities</strong></p>
                                <ul className="course-content">
                                    <li>Accountants</li>
                                    <li>Analysts</li>
                                    <li>Blockchain Architect</li>
                                    <li>Blockchain Business Analyst</li>
                                    <li>Blockchain Developer</li>
                                    <li>Blockchain Legal Consultant</li>
                                    <li>Blockchain Project Manager</li>
                                    <li>Blockchain Quality Engineer</li>
                                    <li>Blockchain Solutions Architect</li>
                                    <li>Blockchain UX Designer</li>
                                    <li>Crypto brokers</li>
                                    <li>Crypto journalists</li>
                                    <li>Cryptocurrency developer</li>
                                    <li>Cryptocurrency Mining Engineer</li>
                                    <li>Finance Manager</li>
                                    <li>ICO advisors</li>
                                    <li>Managers</li>
                                    <li>Marketers</li>
                                </ul>
                                <p><strong>Why Learn AI and Blockchain?</strong></p>
                                <ul className="course-content">
                                    <li>The impact of technology changed our lives and the financial setting of the world. Learning about blockchain technology will give you an edge in your financial decisions and business plans.</li>
                                    <li>Career opportunities for blockchain experts are endless because it is one of the hottest in the world job market.</li>
                                    <li>The demand for experts with AI and Blockchain skills is very high which will give you a chance to land a stable and profitable job in the future.</li> 
                                    <li>All areas of business industries are into blockchain so you are assured that your investment in studying this course will be of great use.</li>
                                    <li>Only a few percentage of the world’s population are using blockchain but a lot of people were already aware of it.  Having learned this course now, you will be far from everyone and that will give you an edge from others which are just starting in blockchain technology.</li>
                                    <li>For personal use, blockchain infrastructure is safe technology. It can solve data breach and AI vulnerability so it is best to invest your time and effort to learn this now for your own security.</li>
                                </ul>
                            </div>
                            <div id="course07" class="tab-pane fade">
                              <h5>7. AI in Ethics</h5>
                                <p><strong>Course Description:</strong> This course focuses on the guidelines and implementations of the proper use of AI systems, as they are continuously reinvented and improved to become more intelligent and more adaptive to human interactions. This course will also introduce the proper and systematic way of utilizing technology and AI when dealing with the aspects of privacy, morals, rights, and legalities that we come across in everyday life.</p>
                                <p><strong>No. of Hours/Weeks:</strong> 30 hours/10 weeks (3 hours a week)</p>
                                <p><strong>Prerequisites:</strong> None</p>

                                <p><strong>Expected Learning Outcomes:</strong></p>
                                <p>At the end of the course, the learners are expected to:</p>
                                <ul>
                                    <li>Gain knowledge of philosophical issues on ethics of AI technology</li>
                                    <li>Show the ability to produce coherent philosophical views about ethics for AI systems</li>
                                    <li>Demonstrate research skills on the intersection of AI and practical ethics</li>
                                    <li>Acquire skills in analysis and argumentation regarding ethical issues in AI</li>
                                    <li>Developed informed decisions about the impacts of AI in the world</li>
                                </ul>
                                <h5>Course Outline</h5>
                                <div class="tabCntnOutr">
                                    <ul class="nav nav-tabs">
                                        <li><a data-toggle="tab" href="#week207">Weeks 1 and 2</a></li>
                                        <li><a data-toggle="tab" href="#week407">Weeks 3 and 4</a></li>
                                        <li><a data-toggle="tab" href="#week607">Weeks 5 and 6</a></li>
                                        <li><a data-toggle="tab" href="#week807">Weeks 7 and 8</a></li>
                                        <li><a data-toggle="tab" href="#week1007">Weeks 9 and 10</a></li>
                                    </ul>
                                    <div class="tab-content">
                                        <div id="week207" class="tab-pane fade">
                                            <h5>Weeks 1 and 2</h5>
                                            <div className="course-content">
                                                <p><strong>Module 1:</strong> Ethics of AI</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>The Philosophical Issues in Relation to AI</li>
                                                    <li>The Moral Relevance of AI</li>
                                                    <li>The Ethical Concerns of AI</li> 
                                                </ul>
                                                <p><em>This course will commence with the ethical concerns about AI starting from the philosophical issues that govern it to moral relevance and ethical considerations. Problems such as the threat of AI to humanity, the accountability of AI, and the relationship with AI will be tackled to give you a wider perspective about AI at the start of the course. You will also be given further readings which would guide you in making your own ethical position and philosophical stance about controversial issues in AI.</em></p>
                                            </div>
                                        </div>
                                        <div id="week407" class="tab-pane fade">
                                            <h5>Weeks 3 and 4</h5>
                                            <div className="course-content">
                                                <p><strong>Module 2:</strong> Ethics for AI Systems</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Ethical Considerations</li>
                                                    <li>Safety and Autonomy</li>
                                                    <li>Privacy and Bias</li>
                                                    <li>Economic Inequality</li>
                                                </ul>
                                                <p><em>Module 2 will be a series of forum concerning AI and ethical considerations. Topics on safety, autonomy, privacy, bias, and economic inequality will be given utmost importance in the discussion. You will be tasked to organize your philosophical views regarding these issues and present them in the class. You are required to take a standpoint to the issue assigned to you and coherently defend it in the forum. There will be exchange of ideas which would broaden your understanding about AI and its impact to the world.</em></p>
                                            </div>
                                        </div>
                                        <div id="week607" class="tab-pane fade">
                                            <h5>Weeks 5 and 7</h5>
                                            <div className="course-content">
                                                <p><strong>Module 3:</strong> AI in Ethics and Research Trends</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Research Literature on AI in Ethics</li>
                                                    <li>Ethics of Information</li>
                                                </ul>
                                                <p><em>After your exposure to several ethical issues concerning AI in ethics, weeks 5 and 6 will now give you the opportunity to work on a simple literature review about the current problems that AI is facing now.  You will further find answers to complex questions about practical ethics from the literature you have researched on. Module 3 will be an unsupervised learning but you are expected to write your own paper about your discoveries, insights, and reflections during your research activity.</em></p>
                                            </div>
                                        </div>
                                        <div id="week807" class="tab-pane fade">
                                            <h5>Weeks 7 and 8</h5>
                                            <div className="course-content">
                                                <p><strong>Module 4:</strong> The Paradox of AI Technology</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Facing the Ethical Issues</li>
                                                    <li>Case Analysis and Argumentation</li>
                                                    <li>Existential Threat or Promise?</li>
                                                </ul>
                                                <p><em>Weeks 7 and 8 will test your ability to analyze and argue on ethical issues concerning AI technology. There will be different cases to choose from. We will begin by forming a group with the same stand on the issue then each group will present a summary of the case being chosen. After that, the case will be scrutinized and debated on. We will end the module by coming up with a consensus on the cases we have analyzed and scrutinized.</em></p>
                                            </div>
                                        </div>
                                        <div id="week1007" class="tab-pane fade">
                                            <h5>Weeks  9 and 10</h5>
                                            <div className="course-content">
                                                <p><strong>Module 5:</strong> Impact of AI on Society</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Ethical Implications of AI</li>
                                                    <li>Transparency and Accountability</li>
                                                    <li>Autonomy and Control</li>
                                                </ul>
                                                <p><em>Our last session will be devoted on making a reflection paper on the impact of AI on society putting emphasis on ethical implications. You will be required to develop your own point of view about recent issues on AI that can create a great threat in the humanity. Your paper will focus on issues such as transparency, accountability, autonomy, and control and how these ethical concerns can impact the society.  We will wrap up the course by presenting your paper to the class for critiquing.</em></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p><strong>Course Requirements</strong></p>
                                <div className="course-content">
                                    <table>
                                        <tr>
                                            <td>Short Writing Tasks</td>
                                            <td>30%</td>
                                        </tr>
                                        <tr>
                                            <td>Class Participation</td>
                                            <td>30%</td>
                                        </tr>
                                        <tr>
                                            <td>Reflection Paper</td>
                                            <td>40%</td>
                                        </tr>
                                        <tr>
                                            <td>TOTAL</td>
                                            <td>100%</td>
                                        </tr>
                                    </table>
                                </div>
                                <p><strong>Career Paths and Opportunities</strong></p>
                                <ul className="course-content">
                                    <li>Administrative Assistant</li>
                                    <li>AI Ethicist</li>
                                    <li>AI Governance and Risk Manager</li>
                                    <li>Algorithmic Accountability Manager</li>
                                    <li>Artificial Intelligence Director</li>
                                    <li>College Instructor</li>
                                    <li>Communication Analyst</li>
                                    <li>Data Analytics Strategy Director</li>
                                    <li>Data Scientist</li>
                                    <li>Digital Ethicist</li>
                                    <li>Digital Manager</li>
                                    <li>Legal Counselor</li>
                                    <li>Machine Learning Engineer</li>
                                    <li>Reporting Analyst</li>
                                    <li>Research Associate</li>
                                    <li>Researcher</li>
                                    <li>Robot Ethics Analyst</li>
                                    <li>Senior Software Engineer</li>
                                    <li>Sociologist</li>
                                    <li>Transparency Control Manager</li>
                                </ul>
                                <p><strong>Why Learn AI in Ethics</strong></p>
                                <ul className="course-content">
                                    <li>AI system is the future and with that it should support human autonomy and reliable decision-making. Learning about ethics would make us responsible AI specialists, developers, and engineers.</li>
                                    <li>A specialization in AI in ethics would aid us in understanding the philosophical, technical, and legal issues that AI systems face nowadays. This would help us choose adequate mechanisms which are dependable, reliable, and accurate without threatening the humanity and society.</li>
                                    <li>Learning about AI in Ethics will make us more accountable with our actions thus directing us to create sound decisions on data governance, privacy, transparency, and autonomy which can benefit everyone today and in the future.</li> 
                                    <li>Customers increase their trust to AI systems which are governed by ethical standards and responsible management thus making the business more progressive and marketable.</li>
                                    <li>Organizations now pay more attention to ethical matters so a person looking for jobs with enough knowledge about AI in ethics would be given utmost priority by these companies.</li>
                                    <li>AI ethics is a moral responsibility and has now become a business imperative so a deeper and wider perspective regarding ethical issues can contribute to the success and sustainability of an industry.</li>
                                    <li>For personal benefit, having a specialization in AI in ethics will be a plus point to your resume and achievements. In case you will put up your own AI system in the future, you are already prepared with the intricacies and complications of AI technology and confident enough in creating solutions to these complex ethical issues.</li>
                                </ul>
                            </div>
                            <div id="course08" class="tab-pane fade">
                              <h5>8. Advanced IT</h5>
                                <p><strong>Course Description:</strong> This course provides an extensive course on the challenging and complex modern-day software, algorithms, and AI that we have today and how they will continue to shape the future of technology. The course leaves no stone unturned as it tackles how the extensive and breakthrough technology, AI, and software we have now are constantly changing, adapting, and revolutionized for the better.</p>
                                <p><strong>No. of Hours/Weeks:</strong> 36 hours/12 weeks (3 hours a week)</p>
                                <p><strong>Prerequisites:</strong> Basic IT knowledge and skills</p>

                                <p><strong>Expected Learning Outcomes:</strong></p>
                                <p>At the end of the course, the learners are expected to:</p>
                                <ul>
                                    <li>Develop computer and IT-related skills for immediate applications and digital technology use</li>
                                    <li>Demonstrate ability on computing and network systems, database systems, and project managements and other advanced IT specializations</li>
                                    <li>Acquire analytical skills necessary to work in computing and AI management environment</li>
                                    <li>Master new technological techniques and apply IT skills to real world problems</li>
                                    <li>Build an impressive resume focusing on the IT expertise and specialization</li>
                                </ul>
                                <h5>Course Outline</h5>
                                <div class="tabCntnOutr">
                                    <ul class="nav nav-tabs">
                                        <li><a data-toggle="tab" href="#week108">Week 1</a></li>
                                        <li><a data-toggle="tab" href="#week308">Weeks 2 and 3</a></li>
                                        <li><a data-toggle="tab" href="#week508">Weeks 4 and 5</a></li>
                                        <li><a data-toggle="tab" href="#week1008">Weeks 6, 7, 8, 9 , and 10</a></li>
                                        <li><a data-toggle="tab" href="#week1208">Weeks 11 and 12</a></li>
                                    </ul>
                                    <div class="tab-content">
                                        <div id="week108" class="tab-pane fade">
                                            <h5>Week 1</h5>
                                            <div className="course-content">
                                                <p><strong>Module 1:</strong> The Basic of Information Technologies</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Overview of Information Technology</li>
                                                    <li>Fundamentals of Programming</li>
                                                    <li>Computer Networking</li> 
                                                </ul>
                                                <p><em>To review your basic knowledge about IT, you will be given an opportunity to review the fundamentals of programming and networking which will serve as a preparatory lesson as you advance to higher IT skills and knowledge. A pre-assessment will be conducted to measure how much you have learned from your basic IT courses. This will help the course facilitator plan out the necessary specializations to focus on as you proceed to the middle part of the course.</em></p>
                                            </div>
                                        </div>
                                        <div id="week308" class="tab-pane fade">
                                            <h5>Weeks 2 and 3</h5>
                                            <div className="course-content">
                                                <p><strong>Module 2:</strong> Advanced IT Specializations (Part 1)</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Cloud Computing and Networking</li>
                                                    <li>Computer Programing</li>
                                                    <li>Data analytics</li>
                                                    <li>Digital Systems Technology</li>
                                                </ul>
                                                <p><em>Module 2 will have an overview of different specializations in IT so that you will be able to make a decision on what career path you would like to go into.  You will be guided by the results of your pre-assessment in making your choice or you can also select a specialization which you think is more applicable to the industry you are in.  For each specialization, a resource speaker will be invited to talk about the expertise needed in certain specialization and the challenges and opportunities in the specialized IT careers.</em></p>
                                            </div>
                                        </div>
                                        <div id="week508" class="tab-pane fade">
                                            <h5>Weeks 4 and 5</h5>
                                            <div className="course-content">
                                                <p><strong>Module 3:</strong> Advanced IT Specializations (Part 2)</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Network Administration</li>
                                                    <li>System Automation</li>
                                                    <li>Systems Security</li>
                                                    <li>Web and Application Development</li>
                                                </ul>
                                                <p><em>Module 3 will still present the different IT specializations with the help of invited experts and resource speakers. At the end of the sessions, you will be given a time to reflect on the IT skills that you want to enhance so that you can confidently choose which specialization you would like to focus on during the short training program.</em></p>
                                            </div>
                                        </div>
                                        <div id="week1008" class="tab-pane fade">
                                            <h5>Weeks 6, 7, 8, 9, and 10</h5>
                                            <div className="course-content">
                                                <p><strong>Module 4:</strong> Working with IT Industries</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>AI Management vis-a-vis IT Specializations</li>
                                                    <li>Technological Techniques in the Modern World</li>
                                                    <li>Application of IT Skills in Thriving Industries</li>
                                                </ul>
                                                <p><em>In this module, the class will already be grouped according to the specialization chosen. They will be supervised by IT experts on the field they want to pursue. For four weeks, you will be exposed to several practical activities that would develop your advanced IT skills in any of the majors listed: Cloud Computing and Networking, Computer Programing, Data Analytics, Digital Systems Technology, Network Administration, System Automation, Systems Security, and Web and Application Development. This short training program will expose you to the IT specialization of your choice and to the industries that would require your expertise.</em></p>
                                            </div>
                                        </div>
                                        <div id="week1208" class="tab-pane fade">
                                            <h5>Weeks 11 and 12</h5>
                                            <div className="course-content">
                                                <p><strong>Module 5:</strong> Career Opportunities for IT Experts</p>
                                                <p class="topicOD">Topics:</p>
                                                <ul>
                                                    <li>Writing a Resume for IT Employment</li>
                                                    <li>Future Prospects for IT Specialists</li>
                                                    <li>Do’s and Don’ts in a Job Interview</li>
                                                    <li>Job Fair Preparations</li>
                                                </ul>
                                                <p><em>Information Technology offers endless of job opportunities and with the specialization you chose, you will have your own niche in the IT industry. In this module, you will be taught how to write an impressive resume, building on to your IT expertise. You will sell your strongest skill to the IT industries so that you can pass the job interview and get the dream career you want. To commence the course, you are required to join the job fairs to showcase the proficiency you have acquired in the advanced IT classes.</em></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p><strong>Course Requirements</strong></p>
                                <div className="course-content">
                                    <table>
                                        <tr>
                                            <td>Quizzes</td>
                                            <td>20%</td>
                                        </tr>
                                        <tr>
                                            <td>Class Participation</td>
                                            <td>30%</td>
                                        </tr>
                                        <tr>
                                            <td>Performance Task</td>
                                            <td>50%</td>
                                        </tr>
                                        <tr>
                                            <td>TOTAL</td>
                                            <td>100%</td>
                                        </tr>
                                    </table>
                                </div>
                                <p><strong>Career Paths and Opportunities</strong></p>
                                <ul className="course-content">
                                    <li>AI Senior Project Manger</li>
                                    <li>Application Programmer</li>
                                    <li>Cloud Computing Consultant</li>
                                    <li>Computer Information Specialist</li>
                                    <li>Database Designer</li>
                                    <li>Enterprise Information Officer</li>
                                    <li>Graphic Designer</li>
                                    <li>Hardware & Network Expert</li>
                                    <li>Information Systems Manager</li>
                                    <li>Information Technologist</li>
                                    <li>IT Consultant</li>
                                    <li>Network Administrator</li>
                                    <li>PC Architect and Maintenance</li>
                                    <li>Software Developer</li>
                                    <li>Software Tester</li>
                                    <li>System Designer and Administrator</li>
                                    <li>University Professor</li>
                                    <li>User Interface Specialist</li>
                                </ul>
                                <p><strong>Why Learn Advanced IT</strong></p>
                                <ul className="course-content">
                                    <li>Having basic knowledge in IT is not a guarantee that you will find a lucrative job. It is still best to maximize your IT skills by learning advanced IT and in-demand specializations.</li>
                                    <li>Information technology continues to grow and develop so it is advisable to also continue your educational journey to cope with the fast-changing world of IT.</li>
                                    <li>Almost all businesses now rely on IT, software, and cloud-based services so a specialization on advanced IT would improve your job performance and would give you the promotion that you need.</li> 
                                    <li>Information Technology careers can earn you a lucrative salary. You can also choose to become a freelancer or an entrepreneur because your skills are necessary anytime, anywhere.</li>
                                    <li>Learning about advanced IT is not just for earning money but also for professional growth.  Completing a course is a fulfilment and a personal achievement which is priceless and incomparable.</li>
                                    <li>If you want to stay relevant, then it’s time to modernize your skill. One best way to do that is to learn advanced IT courses.  Learning online courses would also open more social connections and widen your networks.</li>
                                    <li>It is very rewarding to be a part of a thriving community. Your expertise in IT would open more doors for you to contribute to the advancement of technology. Having the IT specialization would allow you to be part of a bigger initiative that would create a great impact to the society.</li>
                                </ul>
                            </div>
                          </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <Footer />
    </React.Fragment>);
    }
}

export default WhatWeOffer;