import React from 'react';
import Header from '../../components/pteportal/Header';
import Footer from '../../components/pteportal/Footer';
import ClientFeedback from '../../components/pteportal/ClientFeedback';

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

import ReactPixel from 'react-facebook-pixel';
import API from "api.js";
import { setAlert} from 'action/index.js';
import {store} from 'store/index';
import SimpleReactValidator from 'simple-react-validator';
class Home extends React.Component {

    constructor(props) {
        
        super(props);
        this.state = {
            indexModal:false,
            name:"",
            phone:"",
            email:"",
            center:"",
            process:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        if((store.getState().token)?false:true){
            setTimeout(() => { this.setState({indexModal:true}) }, 10000);
        }
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }

    closeIndexModal = () =>{
        this.setState({indexModal:false});
    }

    changeInput = (e) => {
        let { name, value } = e.target;
        this.setState({ [name] : value });
    };

    postSubmit = async(e) => {

        if (!this.validator.allValid()) {
          this.validator.showMessages();
          this.forceUpdate();
          return false;
        }
        e.preventDefault();
        this.setState({process:true});
        var bodyFormData = new FormData();
        bodyFormData.append('phone', this.state.phone);
        bodyFormData.append('name', this.state.name);
        bodyFormData.append('email', this.state.email);
        bodyFormData.append('center', this.state.center);
        let res = null;
        try{
          res = await API({
            method: 'POST',
            url: '/submit-query',
            data: bodyFormData
          })
        }catch(error) {
            this.setState({process:false });
            return "l";
        };
        console.log(res);
        if (res.status === 200) {
          store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.response.message,process:false}));
          this.setState({indexModal:false});       
        }else if (res.response.status === 422) {
          this.setState({error:res.response,process:false });
          let str = '';
          Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
          this.setState({error_bag:str});
        }
    
    
  }
 render(){   
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching Centre In Sydney</title>
        <meta name="twitter:title" content="Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching Centre In Sydney" />
        <meta property="og:title" content="Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching Centre In Sydney" />
        <meta name="description" content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au" />      
        
        <meta name="twitter:description" content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au" />
        <meta property="og:description" content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au" />      
      </Helmet>
    
  <section class="header-with-banner">
    <Header active="home" />
    <section id="hero" class="d-flex align-items-center">
      <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-6">
            <h2 class="main-heading mt-5 pt-5">AI-Driven Scored PTE Mock Test & PTE Praactice Test</h2>
            <p class="mt-3 sub-content">Prepare with scored PTE mock test and section-wise practice test to achieve your desire score with instant result. Our PTE practice test helps you to target the individual section to overcome your weakness.</p>
            <button type="button" class="btn btn-demo">Get Demo</button>
          </div>

          <div class="col-xl-6 col-lg-6 text-center client-form">
            <div class="form-heading">
              <h2 class="book-session">Book Your <span class="free-demo">Free Demo Session</span></h2>
              <p>Please confirm that you would like to request the following</p>
            </div>
            <form>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Name" />
              </div>

              <div class="form-group">
                <input type="email" class="form-control" placeholder="Email Address" />
              </div>
              <div class="form-group">
                <input type="test" class="form-control"  placeholder="Phone Number" />
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Institute Name" />
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </section>


  <main id="main">
    <section id="team" class="team section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>All The Features You Need</h2>
          <p>Prspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/battery.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Instand Result</h4>
              </div>
              <div class="pehraraph">
                <p>Nunde omnier spiciatiss iste natus error sit voluptatem accusantium doloremque laudantium.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/men.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>AI Experts’ advice</h4>
              </div>
              <div class="pehraraph">
                <p>Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/sun.png" class="img-fluid" alt=""/>
              </div>
              <div class="member-heading">
                <h4>Accurate Result</h4>
              </div>
              <div class="pehraraph">
                <p>Laudantium. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/toungh.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Detail Analytics</h4>
              </div>
              <div class="pehraraph">
                <p>Doloremque ut perspiciatis unde omnis iste natus error sit accusantium laudantium voluptatem </p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/blue.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Instand Result</h4>
              </div>
              <div class="pehraraph">
                <p>Nunde omnier spiciatiss iste natus error sit voluptatem accusantium doloremque laudantium.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/yellow.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Tutor Feedback</h4>
              </div>
              <div class="pehraraph">
                <p>Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/pink-toungh.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Statistics Dashboard</h4>
              </div>
              <div class="pehraraph">
                <p>Nunde omnier spiciatiss iste natus error sit voluptatem accusantium doloremque laudantium.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/data.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Mock Tests With Scorecard</h4>
              </div>
              <div class="pehraraph">
                <p>Omnis ut perspiciatis unde  iste natus error sit  accusantium doloremque laudantium voluptatem</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about" class="hero-about">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-6 col-md-6 footer-contact">
            <p class="about-words age-fact">
              <img src="/assets/img/banner-1.png" class="img-fluid" alt="" />
            </p>
          </div>

          <div class="col-lg-6 col-md-6 footer-links">
            <h2 class="banner-heading">Online Pte Practice</h2>
            <h5 class="online-sub-heading">One of the best PTE Academic Exam preparation tools to evaluate your performance</h5>
            <div class="row">
              <div class="col-lg-6 col-md-6 footer-contact mt-3 mb-3">
                  <div class="list-of-online">
                    <ul class="left-side-list">
                      <li> <img src="/assets/img/tick.png" class="img-fluid" alt="" />Full PTE Academic practice tests</li>
                      <li> <img src="/assets/img/tick.png" class="img-fluid" alt="" />Real PTE Exam based Sample questions</li>
                      <li> <img src="/assets/img/tick.png" class="img-fluid" alt="" />Model answers</li>
                      <li> <img src="/assets/img/tick.png" class="img-fluid" alt="" />Scored AI PTE Mock Test</li>
                      <li> <img src="/assets/img/tick.png" class="img-fluid" alt="" />PTE Academic Study Plan</li>
                    </ul>
                  </div>
              </div>

              <div class="col-lg-6 col-md-6 footer-contact mt-3 mb-3">
                  <div class="list-of-online">
                    <ul class="left-side-list">
                      <li> <img src="/assets/img/tick.png" class="img-fluid" alt="" />PTE Video tutorials</li>
                      <li> <img src="/assets/img/tick.png" class="img-fluid" alt="" /> PTE Ebook</li>
                      <li> <img src="/assets/img/tick.png" class="img-fluid" alt="" />PTE Practice Sectional Tests</li>
                      <li> <img src="/assets/img/tick.png" class="img-fluid" alt="" />Developed by PTE certified trainers</li>
                    </ul>
                  </div>
              </div>
            </div>
            <div class="get-started">
                <button type="submit" class="btn btn-submit">Get Started Now</button>
              </div>
          </div>
        </div>
      </div>
    </section>
    <section id="power-section" class="team section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>The Tech That Powers Pteportal</h2>
          <p>One of the best PTE Academic Exam preparation tools to evaluate your performance</p>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/box-1.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Scored Mock Test</h4>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/box-2.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Scored Mock Test</h4>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/box-3.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Scored Mock Test</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ClientFeedback />
    <section id="accordian-section" class="team section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Frequently Asked Questions</h2>
          <p>One of the best PTE Academic Exam preparation tools to evaluate your performance</p>
        </div>

        <div class="row mt-4 mb-4">
          <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-2 mb-2" data-aos="fade-up" data-aos-delay="200">
            <div class="accord-box">
              <div class="panel-group" id="accordionSingleOpen" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingOne">
                    <h4 class="panel-title">
                      <a role="button" data-toggle="collapse" href="#collapseItemOpenOne" aria-expanded="true" aria-controls="collapseItemOpenOne">
                        How to improve PTE describe image scores?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseItemOpenOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                    <div class="panel-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                      on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                      raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-2 mb-2" data-aos="fade-up" data-aos-delay="200">
            <div class="accord-box">
              <div class="panel-group" id="accordionSingleOpen" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingOne">
                    <h4 class="panel-title">
                      <a role="button" data-toggle="collapse" href="#collapseItemOpentwo" aria-expanded="true" aria-controls="collapseItemOpentwo">
                        How to get high score in PTE summarize written text?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseItemOpentwo" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                    <div class="panel-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                      on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                      raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4 mb-4">
          <div class="col-lg-6 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="accord-box">
              <div class="panel-group" id="accordionSingleOpen" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingOne">
                    <h4 class="panel-title">
                      <a role="button" data-toggle="collapse" href="#collapseItemOpenthree" aria-expanded="true" aria-controls="collapseItemOpenthree">
                        How to improve oral fluency score?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseItemOpenthree" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingthree">
                    <div class="panel-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                      on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                      raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="accord-box">
              <div class="panel-group" id="accordionSingleOpen" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingOne">
                    <h4 class="panel-title">
                      <a role="button" data-toggle="collapse" href="#collapseItemOpenfour" aria-expanded="true" aria-controls="collapseItemOpenfour">
                        How to improve PTE retell lecture score?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseItemOpenfour" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                    <div class="panel-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                      on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                      raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4 mb-4">
          <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-2 mb-2" data-aos="fade-up" data-aos-delay="200">
            <div class="accord-box">
              <div class="panel-group" id="accordionSingleOpen" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingOne">
                    <h4 class="panel-title">
                      <a role="button" data-toggle="collapse" href="#collapseItemOpenfive" aria-expanded="true" aria-controls="collapseItemOpenfive">
                        How improve PTE Written Discourse?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseItemOpenfive" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingthree">
                    <div class="panel-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                      on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                      raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-2 mb-2" data-aos="fade-up" data-aos-delay="200">
            <div class="accord-box">
              <div class="panel-group" id="accordionSingleOpen" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingOne">
                    <h4 class="panel-title">
                      <a role="button" data-toggle="collapse" href="#collapseItemOpensix" aria-expanded="true" aria-controls="collapseItemOpensix">
                        How to improve your PTE reading score in exam?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseItemOpensix" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                    <div class="panel-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                      on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                      raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4 mb-4">
          <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-2 mb-2" data-aos="fade-up" data-aos-delay="200">
            <div class="accord-box">
              <div class="panel-group" id="accordionSingleOpen" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingOne">
                    <h4 class="panel-title">
                      <a role="button" data-toggle="collapse" href="#collapseItemOpenseven" aria-expanded="true" aria-controls="collapseItemOpenseven">
                        How to improve PTE Speaking repeat sentence?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseItemOpenseven" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingthree">
                    <div class="panel-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                      on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                      raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-2 mb-2" data-aos="fade-up" data-aos-delay="200">
            <div class="accord-box">
              <div class="panel-group" id="accordionSingleOpen" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingOne">
                    <h4 class="panel-title">
                      <a role="button" data-toggle="collapse" href="#collapseItemOpeneight" aria-expanded="true" aria-controls="collapseItemOpeneight">
                        How to improve PTE write Essay?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseItemOpeneight" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                    <div class="panel-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                      on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                      raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <section id="power-section" class="team section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Take Scored PTE Mock Test Free Now</h2>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="sign-box">
              <div class="sign-up-free">
                <button type="submit" class="btn btn-submit">Sign Up For Free</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
  <Footer />
      
   
    </React.Fragment>);
    }
}

export default Home;