import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import API from "../api.js";
import { setAlert} from 'action/index.js';
import {store} from 'store/index';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Redirect} from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
class JoinUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            exambefore:"",
            score:"",
            location:"",
            number:"",
            experience:"",
            start:"",
            file:"",
            interest_location:"",
            position:"",
            error:[],
            submit:false,
            flag:false,
            lockSubmit:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({[name]:value});
    }
    submitForm = async(e) =>{
        console.log(this.state);
        if (this.validator.allValid() && !this.state.lockSubmit) {
            this.setState({lockSubmit:true});
            e.preventDefault();
            var bodyFormData = new FormData();
            bodyFormData.append('name', this.state.name);
            bodyFormData.append('exambefore', this.state.exambefore);
            bodyFormData.append('score', this.state.score);
            bodyFormData.append('location', this.state.location);
            bodyFormData.append('number', this.state.number);
            bodyFormData.append('experience', this.state.experience);
            bodyFormData.append('start', this.state.start);
            bodyFormData.append('interest_location', this.state.interest_location);
            bodyFormData.append('position', this.state.position);
            if (this.state.file) {
                bodyFormData.append('file', this.state.file);  
            }
            API({
              method: 'POST',
              url: "post/join-us",
              data:bodyFormData,
            }).then(data => {
                console.log(data);
                this.setState({submit:true,lockSubmit:false,name:'',exambefore:'',score:'',location:'',number:'',experience:'',start:'',interest_location:'',position:'',file:'',flag:true});
                store.dispatch(setAlert({'flag':true,'type':1,'message':data.data.response.message}));
            }).catch(()=>{
                this.setState({lockSubmit:false});
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handleFileChange = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }
 render(){   
    if (this.state.flag) {
        return (<Redirect to="/thank-you" />);
    } 
    return (
        <React.Fragment>
        <Helmet htmlAttributes>
            <title>Language Academy</title>
            <meta name="twitter:title" content="Language Academy" />
            <meta property="og:title" content="Language Academy" />
        </Helmet>
        <Header />
        <div class="innerPage">
            <section class="bannerOuter">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <h1>Join Us</h1>
                            <span>Join Our Team and Work from anywhere. We offer on-campus and remote work.</span><br />
                            <span>We are hiring PTE tutors and admin staff, both on-campus and online.</span><br />
                            <a href="javascript:;" class="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section class="joinBanner">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4">
                        <h2>We are hiring!</h2>
                        <p>Join Our Team and Work from anywhere. We offer on-campus and remote work. We are hiring PTE tutors and admin staff, both on-campus and online.</p>
                        <div class="joinBtn">
                            <a href="javascript:;" class="themeBtn sendResume">Send your cv</a>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <img src="images/Join-Our-Team.png" alt="our-team" />
                    </div>
                </div>
            </div>
        </section>
        <section class="joinForm">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 formOuter pl-5 pr-5">
                        <h1 className="title text-center">Apply Now</h1>
                        <div class="fieldOuter">
                            <label>Your Full Name*</label>
                            <input type="text" name="name" placeholder="Your Answer" onChange={this.handleChange} value={this.state.name}/>
                            {this.validator.message('Full Name', this.state.name, 'required')}
                        </div>
                        <div class="fieldOuter">
                            <label>Position you are applying for*</label>
                            <select name="position" value={this.state.position} onChange={this.handleChange}>
                                <option value="PTE Traine">PTE Trainer</option>
                                <option value="Admin">Admin</option>
                                <option value="Other">Other</option>
                            </select>
                            {this.validator.message('Position', this.state.position, 'required')}
                        </div>
                        <div class="fieldOuter">
                            <label>Have you taken the PTE exam before?*</label>
                            <div class="radioFields">
                                <div>
                                    <input type="radio" id="beforeExamY" name="exambefore" onChange={this.handleChange} value="yes"/>
                                    <label for="beforeExamY">Yes</label>
                                </div>
                                <div>
                                    <input type="radio" id="beforeExamN" name="exambefore" onChange={this.handleChange} value="no"/>
                                    <label for="beforeExamN">No</label>
                                </div>
                            </div>
                            {this.validator.message('Exam Before', this.state.exambefore, 'required')}
                        </div>
                        <div class="fieldOuter">
                            <label>If yes, what was your score?*</label>
                            <input type="text" name="score" placeholder="Your Answer" onChange={this.handleChange} value={this.state.score}/>
                            {this.validator.message('Score', this.state.score, 'required')}
                        </div>
                        <div class="fieldOuter">
                            <label>Where are you based?*</label>
                            <input type="text" name="location" placeholder="Your Answer" onChange={this.handleChange} value={this.state.location}/>
                            {this.validator.message('Location', this.state.location, 'required')}
                        </div>
                        <div class="fieldOuter">
                            <label>Location you are Interested to work at*</label>
                            <select name="interest_location" value={this.state.interest_location} onChange={this.handleChange}>
                                <option value="Parramatta">Parramatta</option>
                                <option value="Sydney CBD">Sydney CBD</option>
                                <option value="Jalandhar">Jalandhar</option>
                                <option value="Gurdaspur">Gurdaspur</option>
                                <option value="Work from home">Work from home</option>
                            </select>
                            {this.validator.message('Interest Location', this.state.interest_location, 'required')}
                        </div>
                        <div class="fieldOuter">
                            <label>What is your best contact number?*</label>
                            <input type="text" name="number" placeholder="Your Answer" onChange={this.handleChange} value={this.state.number}/>
                            {this.validator.message('Number', this.state.number, 'required')}
                        </div>
                        <div class="fieldOuter">
                            <label>Do you have any experience in teaching?*</label>
                            <input type="text" name="experience" placeholder="Your Answer" onChange={this.handleChange} value={this.state.experience}/>
                            {this.validator.message('Experience', this.state.experience, 'required')}
                        </div>
                        <div class="fieldOuter">
                            <label>When can you start?*</label>
                            <input type="text" name="start" placeholder="Your Answer" onChange={this.handleChange} value={this.state.start}/>
                            {this.validator.message('Start', this.state.start, 'required')}
                        </div>
                        <div class="fieldOuter">
                            <label>Upload Resume*</label>
                            <input type="file" name="file" onChange={this.handleFileChange} />
                            {this.validator.message('File', this.state.file, 'required')}
                        </div>
                        <div class="fieldOuter">
                            {!this.state.lockSubmit && <button type="submit" name="" onClick={this.submitForm} class="themeBtn" >Submit</button>}
                            {this.state.lockSubmit && <button type="submit" name="" class="themeBtn" >Processing...</button>}
                        </div>
                    </div>
                </div>
            </div>
        </section>        
    <Footer />
    </React.Fragment>);
    }
}
export default JoinUs;