import React,{Component} from "react";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal, ModalBody, ModalFooter
} from "reactstrap";
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import PaidUserCheck from "views/PaidUserCheck.jsx";
import {store} from 'store/index';

class MockAttempt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total: 0,
      page: 1,
      count: 15,
      type: this.props.match.params.id,
      all_ok_mock_listing: true,
      paidUser:0,
      mockId:this.props.match.params.id,
      languageId:this.props.match.params.language_id,
      duration:this.props.match.params.duration,
      mockTitle:this.props.match.params.title,
      resourceTested:false,
      mockTime:20,
      mockCat:1,
      secondCount:0,
      modal:false,
      segmentModal:false,
      mockDescription:false
    };
  }

  componentDidMount() {
    let stateData = store.getState();
    // check & state paid user or not
    this.setState({paidUser:stateData.user.user_paid_plan_id !== null?1:0});

    this.chromeFunc();
    // check & state language id
    this.setState({languageId:stateData.user.language_id});
    // get dialogues
    this.getMockTest(stateData.user.language_id);
  }

  chromeFunc = () => {
    if (![1,2,3,4,5].includes(parseInt(this.state.type))) {
      return true;
    }

    let isChrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
    if (navigator.getUserMedia === undefined) {
      this.setState({all_ok_mock_listing:false});
    } else {
      navigator.getUserMedia({ audio: true }, () => {
        //console.log('Permission Granted');
        this.setState({all_ok_mock_listing:isChrome});
      }, () => {
        this.setState({all_ok_mock_listing:false});
        //console.log('Permission Denied');
      });
    }
  }

  getMockTest = async(languageId=this.state.languageId) => {
    try{
      const response = await API({
        method: 'GET',
        url: '/mock_tests/all/'+this.state.mockId
      });
      //console.log("GET MOCK", response.data)
      if (response.data.success && response.data.data.mock_dialogues) {
        this.setState({
          data:  response.data.data.mock_dialogues,
          total: response.data.data.mock_dialogues.length
        });
      } 
      else if (response.data.success && response.data.data.have_access !== undefined && response.data.data.have_access === false) {
        console.log("PURCHASE PLAN ERROR in have_access : ", response.data);
      }
      else {
        console.log("ERROR in fetch mock test : ", response.data);
      }
    }catch(error) {
      this.setState({error:{error} });
    };
  }

  showTest = () => {
    this.setState({resourceTested:true,secondCount:this.state.mockTime});
    this.forceUpdate();
  }

  nextButton = (e,p) => {
    
      let pending = 0;
      let msg ="";
      let complete = 0;
      if (p ===0) {
        pending = 0;
        msg = "Do you want to go to next Question?";
      }else if(p === 1){
        pending = 0;
        msg = "Do you want to submit test?";
        complete = 1;
        // clearInterval(this.state.intervalId)
      }else if(p === 2){
        msg = "Do you want to save this question and exit mock test?";
        pending = 1;
        // clearInterval(this.state.intervalId)
      }
      this.setState({'modal':true,'pending':pending,msg:msg,nextType:p,complete:complete}); 
  }

  closeSegmentModal = () =>{
    this.setState({segmentModal:!this.state.segmentModal});
  }

  manipulateStorage = () =>{
    localStorage.setItem('attemptCount', parseInt(0) )
  }



  render() {
    let { page, count } = this.state;
      let i = page * count-count;
    return (
      <>
        {!this.state.all_ok_mock_listing && <ChromeCheck />}
        {this.state.paidUser === 0?(<PaidUserCheck/>):null}
        {/*!this.state.resourceTested && <ResourceCheck showTest={this.showTest} pending={0} data={{}} cat={this.state.mockCat} />*/}
        {/*this.state.all_ok_mock_listing && this.state.paidUser &&*/}
        {/*this.state.all_ok_mock_listing && this.state.resourceTested &&*/}
        {
          this.state.modal && <Modal isOpen={this.state.modal} size="sm"  >
            <ModalBody>
              <div>{this.state.msg}</div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" className="modalCloseBtn" onClick={this.toggleYes}>Yes</Button>
              <Button color="secondary" className="modalCloseBtn" onClick={this.toggle}>No</Button>
            </ModalFooter>
          </Modal>
        }

        {this.state.segmentModal && <Modal isOpen={this.state.segmentModal} id="segmentPopup" size="lg">
          <div className="modal-body">
            <button onClick={(e)=>this.closeSegmentModal()} type="button" className="close" data-dismiss="modal">&times;</button>
            <div className="mobileIcon">
              <Card>
                <CardTitle><h3>Select Segment</h3></CardTitle>
                <CardBody>
                  <Row>
                    <Col>
                    <Button className="btn-icon btn-round btn btn-default" onClick={()=>this.navigateSegment(-1)}>Start</Button>
                    {
                      this.state.data && this.state.data.map((prop, key) => {
                        return(
                          <>
                            <div className="upperTabs">
                              <Button>{prop.title}</Button>
                            </div>
                            <div className="segmentButtons">
                              {
                                /*this.state.data[0] && this.state.data[0].mock_dialogue_segments.map((prop, innerKey) => {
                                return(
                                  <Button key={innerKey} className="btn-icon btn-round btn btn-default" onClick={()=>this.navigateSegment(innerKey)}>{innerKey+1}</Button>
                                )
                                }*/
                              }
                            </div>
                          </>
                        )
                      })
                    }
                    <Button className="btn-icon btn-round btn btn-default" onClick={()=>this.navigateSegment(-2)}>End</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="contentInr">    
            </div>
          </div>            
        </Modal>
        }

        {/*{true && }*/}
        {true &&
          <div className="content questionsArea">
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs={6}>
                        <CardTitle tag="h2">
                          <span>INFORMATION FOR CANDIDATES</span>
                        </CardTitle>
                      </Col>
                      <Col xs={6}>
                        <div className="text-right questionTime">
                          <div className="timeRemaining">
                            <p><i className="fa fa-clock-o"></i> <span>Total Duration: {this.state.duration} minutes</span></p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <p>The Practice Test includes 1 dialogue only (there will be 2 dialogues in your actual CCL Test)</p>
                    <p>There are a number of segments in the dialogue which should be rendered from the source language to the target language.</p>
                    <p>If you would like to repeat a segment, click on <b>Finish attempt</b>, and the <b>Start</b> button again.</p>
                    <p>You are permitted <b>one segment repeat per dialogue without penalty.</b></p>
                    <p>Please click <b>Next</b>  to start with the example segment.</p>
                  </CardBody>                    
                </Card>
              </Col>
              <Col xs={12}><div className="bottomBtns">
                <Row>
                  <Col></Col>
                  <Col xs={3}>
                      <NavLink
                        to={`/admin/mock/in_process/${this.state.mockId}/${this.state.languageId}/${this.state.duration}/${this.state.mockTitle}`}
                        className="nav-link buttonLink"
                        activeClassName="active"
                        onClick={() => this.manipulateStorage()}
                      >
                        <i className="" />
                        <p>Next</p>
                      </NavLink>
                  </Col>               
                </Row>
                </div>
              </Col>
            </Row>
          </div>
        }
      </>
    );
  }
}

export default MockAttempt;