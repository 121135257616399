import React from "react";
import {
  Button,
  Alert,
  Input,
} from "reactstrap";
import { connect } from 'react-redux';
import "components/custom.css";
import Header from '../../../components/pteportal/Header';
import Footer from '../../../components/pteportal/Footer';
import API from "api.js";
import {  NavLink,Link } from "react-router-dom";
import { setToken, setUser, setLogo, setSub} from 'action/index.js';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
const mapStateToProps = (state) => {
   return {
      token: state.token
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok)),
      setUser: (user) => dispatch(setUser(user)),
      setSub: (sub) => dispatch(setSub(sub)),
      setLogo: (sub) => dispatch(setLogo(sub))
   };
};
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      login:false,
      error:null,
      error_bag:null,
      loginLock:false,
      logoIcon:"/images/logol.png",
      isMainSite: (window.location.hostname.split('.').shift() === "www")?true:false
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    ReactGA.initialize('UA-157984080-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init('197333187452630');
    ReactPixel.pageView();
    document.body.classList.toggle("login-page");
    this.props.setToken(null);
    
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  postLogIn = async() => {
    if (this.state.loginLock) {
      return ;
    }
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
    }
    this.setState({error_bag:null,loginLock:true});
    
    var bodyFormData = new FormData();
    bodyFormData.set('email', this.state.email);
    bodyFormData.set('password', this.state.password);
    bodyFormData.set('subdomain', window.location.hostname.split('.').shift());
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/login',
        data: bodyFormData
      })
    }catch(error) {

        
    };
    
    if (res.status === 200) {
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60);
      this.props.setToken(res.data.access_token);
      this.props.setUser(res.data.user);
      this.props.setLogo(res.data.image);
      let sub  = (res.data.user.active_subscription.length === 0)?false:true;
      this.props.setSub(sub);
      if (res.status === 200) {
        window.location.href  = "/admin/dashboard";
        //this.setState({login:true,loginLock:false});     
      }
      
    }else if(res.response.status === 422){
      this.setState({error:res.response });
      let str = '';
      
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str,loginLock:false});

    }
    else if (res.response.status === 402) {
      this.setState({error_bag:"Wrong credentials.",loginLock:false});
    }
    
  }

  submitForm = (e) => {
    e.preventDefault();
    this.postLogIn();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };
  render() {
    if (this.state.login) {
        //return (<Redirect to="/admin/dashboard" />);
    }
    return (
      <div>
      <div>
      <Helmet htmlAttributes>
        <title>Login - Language Academy</title>
        <meta name="twitter:title" content="Login - Language Academy" />
      <meta property="og:title" content="Login - Language Academy" />
      <meta name="description" content="Login into your Language Academy account and start practicing for NAATI, PTE and IELTS." />      
        
        <meta name="twitter:description" content="Login into your Language Academy account and start practicing for NAATI, PTE and IELTS." />
        <meta property="og:description" content="Login into your Language Academy account and start practicing for NAATI, PTE and IELTS." />
    </Helmet>
      <section class="header-with-banner">
        <Header />
        <section id="hero about-us-page" class="d-flex align-items-center">
        <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 mb-5">
              <h2 class="main-heading pt-5 text-center">Login </h2>
              <div class="breadcum">
                <ol class="breadcrumb primary-color text-center">
                    <li class="breadcrumb-item"><a class="white-text" href="#">Home</a></li>
                    <li class="breadcrumb-item active">Login</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
    </section>
        </section>
        <main id="main" class="avout-page">

<section class="contentOuter">
  <form onSubmit={this.submitForm}>
  <div class="container">
    <div class="row">
      <div class="col-sm-7 loginForm">
        {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
          <div class="fieldOuter">
            <Input 
                placeholder="Email"
                type="text"
                name="email"
                value={this.state.email}
                onChange={(e) => { this.changeInput(e)} }
              />
              {this.validator.message('Email', this.state.email, 'required|email')}
          </div>
          <div class="fieldOuter">
            <Input
                placeholder="Password"
                type="password"
                name="password"
                value={this.state.password}
                onChange={(e) => { this.changeInput(e)} }
                autoComplete="off"
              />
              {this.validator.message('Password', this.state.password, 'required')}
          </div>
          <div class="rememberForgot">
            <div class="rememberMe">
              <input type="checkbox" name="" />
              <span>Remember me</span>
            </div>
            <div class="forgotPwd">
              <NavLink className="mt-5" to='/auth/forgot'><span>Forgot your Password?</span></NavLink>
            </div>
          </div>
          <div class="fieldOuter text-right">
            <Button
              className="themeBtn"
              onClick={(e) => {this.postLogIn()}}
            >
              Login
            </Button>
          </div>
      </div>
      <div class="col-sm-5 registerLink text-center">
       {this.state.isMainSite && <React.Fragment> <h3>New User?</h3>
               <p>Register now to obtain your<br /> Free Mock Test Material</p>
               <Link to="/auth/register" class="themeBtn blueBtn">Register</Link> </React.Fragment>}
      </div>
    </div>
  </div>
  <input  type="submit" style={{display:"none"}} />
  </form>
</section>

      </main> 
        <Footer />
      </div>
      
      </div>
      
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
