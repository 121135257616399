import React,{Component} from "react";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Table,
	Row,
	Col,
	Button,

} from "reactstrap";
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import PaidUserCheck from "views/PaidUserCheck.jsx";
import { thead, tbody } from "variables/general";
import {store} from 'store/index';

class ComprehensiveMaterialLists extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			page: 0,
			search: '',
			count: 15,
			mark: 'all',
			attempted: 'all',
			activeTab: 1,
			type: this.props.match.params.id,
			prediction: 1,
			all_ok: true,
			orderby: 'desc',
			complexity: 'all',
			paidUser:0,
  		languageId:1,
  		offset:0,
  		pageSize:15,
  		srNo:1,
  		paginatedTotalData:0,
  		prevRecordCount:0
		};
	}

	componentDidMount() {
		let stateData = store.getState();
  	// check & state paid user or not
    this.setState({paidUser:((stateData.user.user_paid_plan_id === null) || (stateData.user.user_paid_plan_id === undefined))?0:1});
		this.chromeFunc();
    // check & state language id
  	this.setState({languageId:stateData.user.language_id});
  	// get dialogues
  	this.getDialogues(0, stateData.user.language_id);
	}

	chromeFunc = () => {
		if (![1,2,3,4,5].includes(parseInt(this.state.type))) {
			return true;
		}

		let isChrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
		if (navigator.getUserMedia === undefined) {
			this.setState({all_ok:false});
		} else {
			navigator.getUserMedia({ audio: true }, () => {
      	//console.log('Permission Granted');
      	this.setState({all_ok:isChrome});
			}, () => {
				this.setState({all_ok:false});
				//console.log('Permission Denied');
    	});
		}
	}

	changePagination = (p, pageIs="next") => {
    let page = this.state.page + p;
    if (pageIs === "next")
			this.setState({srNo:this.state.srNo+1});
		else
			this.setState({
				srNo:this.state.srNo-1,
				paginatedTotalData:this.state.paginatedTotalData -this.state.prevRecordCount
			});

		if (page > 0) {
			this.setState({offset:page, page:page});
      this.getDialogues(page, this.state.languageId,  pageIs);
		}else{
			this.setState({offset:0, page:0});
      this.getDialogues(0, this.state.languageId,  pageIs);
		}
	}

	getDialogues = async(offset=0, languageId=this.state.languageId, pageIs="next") => {
    try{
			let routeName="comprehensivematerial";
			const postData = {
				language_id: languageId,
				pageOffset: (offset > 0)?(offset*this.state.pageSize):offset,
				pageSize: this.state.pageSize,
				type: routeName,
			};
			
			const response = await API({
        method: 'POST',
        url: '/dialogues',
        data: postData
      });
			if (response.data.success && response.data.data.rows) {
				this.setState({
					data: response.data.data.rows,
					total: response.data.data.count,
					prevRecordCount: response.data.data.rows.length
				});

				if (pageIs === "next")
					this.setState({
						paginatedTotalData:this.state.paginatedTotalData + response.data.data.rows.length
					})

			} else if (response.data.success && response.data.data.paid_plan_required) {
				console.log("PURCHASE PLAN ERROR in fetchDialogues : ", response.data);
			} else {
				console.log("ERROR in fetchDialogues : ", response.data);
			}
    }catch(error) {
      this.setState({error:{error} });
    };
  }

	render() {
		let { count, srNo } = this.state;
    let i = srNo * count-count;
		return (
  		<>
  			{!this.state.all_ok && <ChromeCheck />}
  			{this.state.paidUser === 0?(<PaidUserCheck/>):null}
    		{this.state.all_ok && this.state.paidUser &&
					<div className="content">
	    			<Row>
	        		<Col xs={12}>
	        			<Card>
	          			<CardHeader>
	            			<CardTitle tag="h4"><span>Comprehensive Material</span></CardTitle>
	          			</CardHeader>
	          			<CardBody>
										<div className="table-responsive">
	            				<Table>
	              				<thead className="text-primary">
	                				<tr>
	                        	<th className="">S. No</th>
	                					<th className="">Title</th>
	                					<th className="">Dialogue Detail</th>
													</tr>
	              				</thead>
	              				<tbody>
	                				{this.state.data.map((prop, key) => {
	                  				return (
	                    				<tr key={key}>
	                            	<td>{++i}</td>
																<td className="">{prop.title.split(")")[1]}</td>
	                            	<td className="">
	                    						<NavLink
				                            to={`/admin/comp_mat/practice/${prop.id}/${this.state.languageId}`}
				                            className="nav-link"
				                            activeClassName="active"
																	>
	    															<i className="" />
	    															<p>Practice Now</p>
	  															</NavLink>
	                            	</td>
															</tr>
														);
													})}
	              				</tbody>
	            				</Table>
	            			</div>
										<div className="paginationOuter" dd={this.state.paginatedTotalData}>
	            				<Button
	            					color="primary"
	            					className="bottom-btn"
	            					onClick={this.changePagination.bind(this,-1, "previous")}
	            					size="sm"
	            					disabled={this.state.paginatedTotalData === 15?true:false}
	            					>
	            					<i className="fa fa-arrow-left"></i>
	            				</Button>
	           					<Button
	           					 color="primary"
	           					 className="bottom-btn"
	           					 onClick={this.changePagination.bind(this,1, "next")}
	           					 size="sm"
	           					 disabled={this.state.paginatedTotalData === this.state.total?true:false}
	           					>
	           						<i className="fa fa-arrow-right"></i>
	           					</Button>
	            			</div>
	          			</CardBody>
	        			</Card>
	        		</Col>
						</Row>
					</div>
				}
    	</>
		);
  }
}

export default ComprehensiveMaterialLists;
