import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,Button,
} from "reactstrap";
import { Helmet } from "react-helmet";
import {store} from 'store/index';
import moment from 'moment';
import API, { ASSETS_SERVER_PATH, MOCK_FEEDBACK_PATH } from "api.js";
import PaidUserCheck from "views/PaidUserCheck.jsx";
import { NavLink } from "react-router-dom";

const imageAssetPath = ASSETS_SERVER_PATH + MOCK_FEEDBACK_PATH;

class MockFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //data: [],
      data: {},
      activeTab:1,
      currentSection:"Speaking Section",
      userId:0,
      paidUser:0,
      mockId:this.props.match.params.id,
      languageId:this.props.match.params.language_id,
      mockAttemptId:0
    };
  }
  async componentDidMount() {
    let stateData = store.getState();
    //console.log("COM ", stateData.user.user_id, this.state.mockId, this.state.languageId)
    // check & state paid user or not
    this.setState({paidUser:stateData.user.user_paid_plan_id !== null?1:0});
    // check & set language id & user
    this.setState({languageId:stateData.user.language_id, userId:stateData.user.user_id});
    // get mock test attempt details
    await this.getMockFeedbackDetails(stateData.user.language_id, stateData.user.user_id);
    //this.getMockTests(stateData.user.language_id, stateData.user.user_id);
  }

  getMockFeedbackDetails = async(languageId=this.state.languageId, userId=this.state.userId) => {
    try{
        // check whether user has attempted the mock or not
        let attemptResponse = await this.checkAttemptAndNavigate(this.state.mockId);
        if (attemptResponse["error"] === false){
          if (attemptResponse["isAttempt"] === true){
            if (attemptResponse["data"]){
              let mockResult = await this.fetchMockTestResult(attemptResponse["data"].id, this.state.mockId)
              if (mockResult["error"] === false){
                if (mockResult["is_available"] === true){
                  attemptResponse["user_response"] =  mockResult["data"];
                  //attemptResponse["data"]["user_mock_feedback_images"] = ["c5b25e88f7409948d538d3004.png","c5b25e88f7409948d538d3003.png", "c5b25e88f7409948d538d3002.png", "c5b25e88f7409948d538d3001.png", "c5b25e88f7409948d538d3000.png"];
                  /*attemptResponse["data"]["user_mock_feedback_images"] = [
                    {path:"upload_7bca3129ad9c93d419d20b44b05c1133.png"},
                    {path:"upload_d0880349a6b51d579889866974a5252a.png"},
                    {path:"upload_ac47364811dc3fa7750f852b2a4d4e16.png"},
                    {path:"upload_b4e33a5e7f8d3fac480bd710f327f502.png"},
                    {path:"upload_bfc11d41e1257a5e6412be4da797bd60.png"}
                  ];*/
                }
              }
            }
          }
          //console.log("AR", attemptResponse)
          this.setState({
            data:attemptResponse,
            mockAttemptId: attemptResponse.data.id
          });
        }
        else{
          console.log("ERROR in fetch mock test : ", attemptResponse.data);
        }
    }catch(error) {
      console.log("IN ERROR", error)
      this.setState({error:{error} });
    };
  }

  getMockTests = async(languageId=this.state.languageId, userId=this.state.userId) => {
    try{
      const response = await API({
        method: 'GET',
        url: '/mock_tests/'+languageId
      });
      if (response.data.success && response.data.data.mock_tests) {
        let mockInfos = response.data.data.mock_tests;
        let attemptedMocks = [];
        for (const eachMock of mockInfos) {
          eachMock["user_response"] =  {};

          // check whether user has attempted the mock or not
          let attemptResponse = await this.checkAttemptAndNavigate(eachMock.id);
          if (attemptResponse["error"] !== true){
            if (attemptResponse["isAttempt"] === true){
              if (attemptResponse["data"]){
                let mockResult = await this.fetchMockTestResult(attemptResponse["data"].id, eachMock.id)
                if (mockResult["error"] === false){
                  if (mockResult["is_available"] === true){
                    eachMock["user_response"] =  mockResult["data"];
                    //attemptResponse["data"]["user_mock_feedback_images"] = ["c5b25e88f7409948d538d3004.png","c5b25e88f7409948d538d3003.png", "c5b25e88f7409948d538d3002.png", "c5b25e88f7409948d538d3001.png", "c5b25e88f7409948d538d3000.png"];
                    attemptResponse["data"]["user_mock_feedback_images"] = [
                      {path:"upload_7bca3129ad9c93d419d20b44b05c1133.png"},
                      {path:"upload_d0880349a6b51d579889866974a5252a.png"},
                      {path:"upload_ac47364811dc3fa7750f852b2a4d4e16.png"},
                      {path:"upload_b4e33a5e7f8d3fac480bd710f327f502.png"},
                      {path:"upload_bfc11d41e1257a5e6412be4da797bd60.png"}
                    ];
                    eachMock["user_attempt"] =  attemptResponse["data"];
                  }
                }
              }
              attemptedMocks.push(eachMock)
            }
          }
        };
        //console.log("ATEEMP",attemptedMocks)
        this.setState({
          data:  attemptedMocks,
          total: attemptedMocks.length
        });
      }
      else if (response.data.success && response.data.data.have_access !== undefined && response.data.data.have_access === false) {
        console.log("PURCHASE PLAN ERROR in have_access : ", response.data);
      }
      else {
        console.log("ERROR in fetch mock test : ", response.data);
      }
    }catch(error) {
      this.setState({error:{error} });
    };
  }

  /**
   * Check if user have already attempted mock test
   * 
   * @param {*} mock 
   */
  checkAttemptAndNavigate = async(mockId) => {
    try {
      const response = await API.get('check_user_attempt/' + this.state.languageId + '/' + mockId);
      if (response.data.success && response.data.data.user_attempt === null) {
        return {"error":false, "isAttempt":false, "data":{}};
        // redirect to test screen
      } else if (response.data.success && response.data.data.user_attempt) {
        // redirect to feedback screen cz user attempted the test
        return {"error":false, "isAttempt":true, "data":response.data.data.user_attempt};
      }
    } catch (error) {
      //console.log("ERROR in fetchMockTests : ", error);
      return {"error":true, "isAttempt":""};
    }
  };

  fetchMockTestResult = async (mockAttemptId, mockId) => {
    try {
      const response = await API.get('mock_tests/result/' + mockAttemptId + '/' + mockId);
      if (response.data.success && response.data.data.reponse_details)
        return {"data":response.data.data.reponse_details, "error":false, "is_available": true};
      else
        return {"data":response.data, "error":false, "is_available": false};
    } catch (error) {
      //console.log("ERROR in fetchMockTestResult : ", error);
      return {"data":{}, "error":true, "is_available": false};
    }
  };

  /**
   * Convert time in words format
   * 
   * @param {*} time 
   */
  convertTimeInWords = (time) => {
    if (!time) { return '0 minutes' }
    let returnTime = '';
    if (time.toString().includes(":")){
      const split = time.split(":");
      if (split[0] && parseInt(split[0]) > 0) { returnTime = parseInt(split[0])  + ' hours ' }
      if (split[1]) { returnTime += parseInt(split[1])  + ' minutes ' }
      if (split[2] && parseInt(split[2]) > 0) { returnTime += parseInt(split[2])  + ' seconds' }
    }
    return returnTime;    
  };

  /**
   * Calculate number of attempted segments
   * 
   * @param {Array} segments 
   * @returns {Number} attemped
   */
  calculateAttempedSegments = (segments) => {
    if (!segments || !segments.length) { return 0; }
    let attemped = 0
    segments.forEach(segment => {
      if (segment.user_mock_test_response && segment.user_mock_test_response.response) { attemped++; }
    })
    return attemped;
  };

  navigateResponse = async() =>{
    //console.log("NVR")
    alert("This feature is coming soon.")
  }

  formatDate = (date, format) => {
    format = format || 'YYYY-MM-DD hh:mm:ss A';
    return moment(date).format(format);
  };
 
  render() {
    return (
      <>
      {this.state.paidUser === 0?(<PaidUserCheck/>):null}

      <Helmet>
        <title>Top LACCL Training Institute</title>
        </Helmet>
        <div className="content mockResult">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader className="devideTitle">
                  <CardTitle tag="h4"><span>Mock Result Analysis</span></CardTitle>
                  <div>
                    <NavLink
                      to={`/admin/mock/response/${this.state.mockAttemptId}/${this.state.mockId}`}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className="" />
                      <Button color="primary" className="bottom-btn mb-3 d-inline-block">View Responses</Button>
                    </NavLink>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="mockTestResultOuter">
                    {
                      this.state.data.user_response !==undefined?(
                        <>
                        <Row>
                          <Col>
                            <div className="smallContainer">
                              <h5 className="mb-0">Mock Test</h5>
                              <p>{this.state.data.user_response.title}</p>
                            </div>
                            <div className="smallContainer">
                              <h5 className="mb-0">Test Duration</h5>
                              <p>{this.state.data.user_response.test_duration} minutes</p>
                            </div>
                            <div className="smallContainer">
                              <h5 className="mb-0">Time Taken</h5>
                              <p>{this.convertTimeInWords(this.state.data.data.time_taken)}</p>
                            </div>
                            <div className="smallContainer">
                              <h5 className="mb-0">Submitted At</h5>
                              <p>{this.formatDate(this.state.data.data.createdAt)}</p>
                            </div>
                            {
                              this.state.data.user_response.mock_test_dialogues.map((dialogue, dialKey) =>{
                                return(
                                    <div className="segmentContainer p-3 mb-3" key={dialKey} style={{border:"2px black"}}>
                                      <div className="smallContainer">
                                        <h5 className="mb-0">Dialogue Title</h5>
                                        <p>{dialogue.title}</p>
                                      </div>
                                      <div className="smallContainer">
                                        <h5 className="mb-0">No. of Segments</h5>
                                        <p>{dialogue.mock_dialogue_segments.length}</p>
                                      </div>
                                      <div className="smallContainer">
                                        <h5 className="mb-0">Attempted</h5>
                                        <p>{this.calculateAttempedSegments(dialogue.mock_dialogue_segments)}</p>
                                      </div>
                                      <div className="smallContainer">
                                        <h5 className="mb-0"> Description &nbsp;
                                          <i className="fa fa-arrow-down" data-toggle="collapse" data-target={"#collapseExample_"+dialKey} aria-expanded="false" aria-controls="collapseExample">
                                          </i>
                                        </h5>
                                        <hr/>
                                        <div className="collapse" id={"collapseExample_"+dialKey}>
                                            {dialogue.description}
                                        </div>
                                      </div>
                                    </div>
                                )
                              })  
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="smallContainer">
                              <h5 className="mb-0">Feedback</h5>
                              <p>{ this.state.data.data.feedback ? this.state.data.data.feedback : 'Pending' }</p>
                            </div>
                            {
                              this.state.data.data.user_mock_feedback_images && this.state.data.data.user_mock_feedback_images.length ? (
                              <div className="row" style={{ height: '300px', overflowY: 'scroll' }}>
                                {this.state.data.data.user_mock_feedback_images.map((image, index) => (
                                  <Col className="mb-3" xs="6" key={index}>
                                    <img alt={"image_"+index} src={imageAssetPath + image.path} style={{ height: '400px', width: '500px' }} />
                                  </Col>
                                ))}
                            </div>
                          ) : null}
                          </Col>
                        </Row>                        
                        </>
                      ):null
                    }
                  </div>
                </CardBody>
                
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default MockFeedback;
