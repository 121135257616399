import React,{Component} from "react";
import SpeechRecognition from 'react-speech-recognition';
import Dictaphone from "./Dictaphone.jsx";
// reactstrap components
import {
  Col,
  Progress,
  Button
} from "reactstrap";
import NoMicrophone from "views/NoMicrophone.jsx";
import MicRecorder from 'mic-recorder-to-mp3';
import "components/custom.css";
import Sound from 'react-sound';
import {s3base} from "api.js";
import Speech from 'speak-tts';
import getBlobDuration from 'get-blob-duration';
let Mp3Recorder = new MicRecorder({ bitRate: 128 });
class ReadAloud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      duration:0,
      secondCount:35,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      restart:false,
      correctFlag:true,
      soundStatus:false,
      file:'',
      trans:''
    };
  }
  componentDidMount() {
    if (!this.props.answer) {
      var intervalId = setInterval(this.timer, 1000);
      this.setState({intervalId: intervalId});
    }
  	
    console.log("RAP", this.props)
    navigator.getUserMedia({ audio: true },
      () => {
        this.setState({ isBlocked: false });
      }, () => {
        this.setState({ isBlocked: true })
      },
    );
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			//var intervalId = setInterval(ReadAloud.timer1, 1000);
  			
  			return {correctFlag:true,recordCount:0,isRecording:false,secondCount:35,questionId: props.data.id,blobURL:'',trans:'',reset:true,start_record:true};
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}
  		
  		}
  	    
    
  }


  start = () => {
    this.setState({ soundStatus:true});
  };

  handleSongFinishedPlaying = () =>{
    console.log("JHSFP")
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      console.log("ELSE")
      SpeechRecognition.startListening({ continuous: true, language: 'en-GB' });
      Mp3Recorder = new MicRecorder({ bitRate: 128 });
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',soundStatus:false});
        }).catch((e) => console.error(e));
    }
  }

   stopTest = async () =>{
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {

          const blobURL = URL.createObjectURL(blob);
          //let duration = await getBlobDuration(blobURL);
          //this.setState({isRecording: false,recordCount:100 });
          //blob = blob.slice(0, blob.size, "audio/wave");
          SpeechRecognition.stopListening();
          let file = new File([blob], 'noname.mp3', { type: blob.type });
          //this.props.submit({'data': file },true);
          console.log("TEST STOP FILE",file);
          //this.props.showResult();
          this.props.nextQuestion(this.props.index+1);
          
        }).catch((e) => console.log(e));
    }else{
      //this.props.submit({'data': '' },true);
      this.props.nextQuestion({'data': this.state.file,'audio_text':this.state.trans, 'duration':this.state.duration });
    }
  }

  stop = () => {
    if (!this.state.isBlocked && this.state.isRecording) {

      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {

          const blobURL = URL.createObjectURL(blob);
          let file = new File([blob], 'noname.mp3', { type: blob.type });
          let duration = await getBlobDuration(blobURL);
          this.setState({ blobURL, isRecording: false,recordCount:100,file:file, duration: duration });
          //blob = blob.slice(0, blob.size, "audio/wave");
          SpeechRecognition.stopListening();
          this.props.submit({'data': file,'audio_text':this.state.trans,'duration':duration },true);
          console.log("STOP FILE",file);
          this.props.showResult();
        }).catch((e) => console.log(e));
    }
  };

  updateCorrect = () =>{
     this.props.submit({'data':'',duration:this.state.duration});
     this.setState({correctFlag:false})
  }

  skip = () => {
    clearInterval(this.state.intervalId);
    this.start();
    var recordId = setInterval(this.timerRecord, 1000);
    this.setState({recordId: recordId,secondCount:0});
  };
	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
			this.start();
			var recordId = setInterval(this.timerRecord, 1000);
			this.setState({recordId: recordId});
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}

  selectSpan = (e,text) => {
    if (!this.props.testMode) {
        const speech = new Speech();
      speech.speak({
      text: text,
      }).then(() => {
          console.log("Success !")
      }).catch(e => {
          console.error("An error occurred :", e)
      });  
    }
    

  }

	timerRecord = () => {
		if (this.state.recordCount === 35) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  setTrans = (a)=>{
    if (this.state.trans !== a) {
      this.setState({trans:a})  
    }
    
  }
  
  render() {
    
  	let question ="";
  	if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.stopRecording) {
      this.stopTest();      
    }
    if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}

    if (this.state.isBlocked) {
      return (<NoMicrophone />);
    }
    return (
      <>
      <Dictaphone setTrans={this.setTrans} status={this.state.reset || this.state.isRecording} />
      <Col xs={12}>
      	<h5><b></b></h5>
      </Col>
      <Col xs={12}>
      	<div className="col-lg-12 text-center">
          
  		  { !this.props.resultMode && <div className="audio-box">
          <h4>Recorded Answer</h4>
          <p><strong>Current status:</strong></p>
  		    <p> { this.state.blobURL === '' && this.state.isRecording?"Recording started": 'Recording starts in '+this.state.secondCount+ ' seconds' } </p>
           {!this.props.testMode && this.state.blobURL === '' && !this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.skip} size="sm">Skip</Button></div> }
  		    <hr></hr>
          {/*this.state.isRecording && "Recording Time : "+this.state.recordCount*/}
          {!this.props.testMode && this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.stop} size="sm">Stop</Button></div>}
          <hr></hr>
  		    <Progress color="info" value={(this.state.recordCount*3)} />
  		    <div className="audo-line"><span className="progress-bar-fill-Read intro"></span></div>
  		  </div> }
		  <Col xs={12}>
		  	{ this.state.blobURL && !this.props.testMode && <audio src={this.state.blobURL} controls="controls" />}
		  </Col>
		</div>
      </Col>
      
      {
        ((question !== "") && (question !== undefined))?(
          <Col xs={12}><h5>{question.replace(/<span[^>]*>?/g, ' __').replace(/<[^>]*span>?/g, '__').split(' ').map(function(item){ return <React.Fragment><span className={(this.props.testMode)?"":"speach-text"} onClick={(e) => this.selectSpan(e,item)} >{item}</span>&nbsp; </React.Fragment>; },this)}</h5></Col>
        ):null
      }
      {!this.props.resultMode && this.state.soundStatus && <Sound
                  url={s3base + "/ptedata/ptemedia/beep.mp3"}
                  playStatus={Sound.status.PLAYING}
                  onFinishedPlaying={this.handleSongFinishedPlaying}
                />}
      </>
    );
  }
}

export default ReadAloud;
