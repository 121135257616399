//https://codesandbox.io/s/f0mzp?file=/src/WaveSurferComponent.js:0-3407
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Input,
  Modal,
} from "reactstrap";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.js';

// .ebee
class WaveSurferComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [
        {
          id: "sample1",
          start: 10, // time in seconds
          end: 50, // time in seconds
          color: "hsla(100, 100%, 30%, 0.1)"
        }
      ],
      // words: [],
      // isLoading: true,
      // errors: null
      context: null,
      processor: null,
      showVolSlider:false,
      isAudioPlaying:false,
      elapsedSeconds:this.formatTime(0),
      audioSeconds:0,
      audioDuration:"0",
      playing:false,
      attemptCount:0,
      startButtonClicked:false,
      hidePlayPause:false,
      startRecording:false,
      recording:false,
      recordedBlob:null,
      startRecordingButtonClicked:false,
      recordPlaying:true,
      startModal:false,
      attemptFinishModal:false,
      startCount:0,
      playerAudio:this.props.audio,
    };
    this.timelineTimer = React.createRef();
    this.timelineTimer.current = null;

    this.recordingStartTimerRef = React.createRef();
    this.recordingStartTimerRef.current = null;

    this.childComponent = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({playerAudio:nextProps.audio})
    return true
  }

  componentDidMount() {
    this.wavesurfer = WaveSurfer.create({
      height: 80,
      interact:true,
      barWidth: 2,
      //cursorWidth: 15,
      container: "#waveform",
      backend: "MediaElement",
      progressColor: "#4a74a5",
      responsive: true,
      waveColor: "#ccc",
      cursorColor: "#4a74a5",
      /*plugins: [
        RegionsPlugin.create({
          regions: this.state.regions,
          color: "hsla(100, 100%, 30%, 0.1)",

          dragSelection: {
            slop: 1
          }
        }),
        
      ]*/
    });

    this.wavesurferRecord = WaveSurfer.create({
      height: 80,
      interact:true,
      autoplay:this.props.autoPlayAllowed,
      barWidth: 2,
      //cursorWidth: 15,
      container: "#recorded_waveform",
      backend: "MediaElement",
      progressColor: "purple",
      responsive: true,
      waveColor: "red",
      cursorColor: "yellow",
      audioRate:10,
      /*plugins: [
        RegionsPlugin.create({
          regions: this.state.regions,
          color: "hsla(100, 100%, 30%, 0.1)",

          dragSelection: {
            slop: 1
          }
        }),
        
      ]*/
    });
    this.record = this.wavesurferRecord.registerPlugin(RecordPlugin.create())
    //console.log("THIS",this.state.playerAudio)
    // this.wavesurfer.load(aud, peaks);
    this.wavesurfer.load(this.state.playerAudio);
    //this.wavesurfer.load(resourceCheckAudio);
    //this.wavesurfer.load("https://reelcrafter-east.s3.amazonaws.com/aux/test.m4a");
    /* Using an arrow function for the callback below allows
    this.wavesurfer to be used by correctly scoping "this";
    https://stackoverflow.com/questions/48967390/wavesurfer-2-0-events-implementation-in-react
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions#No_separate_this */

    this.record.startRecording().then(() => {
      //console.log("HERE BY DEFAULT")
    });

   /* this.wavesurferRecord.on("ready", (e) => {
      console.log("WRECEORD READY")
    });

    this.wavesurferRecord.on("redraw", (e) => {
      console.log("WRECEORD REDRAW")
    });*/

    // get duration on ready callback
    this.wavesurfer.on("ready", (e) => {
      this.setState({audioDuration:this.formatTime(this.wavesurfer.getDuration())})
    });

    this.wavesurfer.on("finish", (e) => {
      this.stopAudioTimelineTimer()
      if (this.props.forSegment){
        //console.log("5. FINISH SEGMENT AUDIO", this.props.forSegment)
        this.reinitializeRecordingWaveform()
        //console.log("5.1 RE-INITIALIZE WS RECORD", this.wavesurferRecord)
        this.segmentRecordingCommonHandler(false)
      }
    });

    this.wavesurferRecord.on("finish", (e) => {
      // to show next button
      //console.log("THIS ENABLINGH START BUTTOn")
      if (this.props.handlerAttemptStarted)
        this.props.handlerAttemptStarted(false)
      // to enable play/pause button
      this.setState({startButtonClicked:false})
    });
    
    /*this.wavesurfer.on("audioprocess", (e) => {
      console.log("3:",this.wavesurfer.getDuration());
      this.setState({audioDuration:this.formatTime(this.wavesurfer.getDuration())})
    });*/
    

    /*this.wavesurfer.on("region-click", (region, e) => {
      console.log(region.start);
      console.log(region.end);
      e.stopPropagation();
      this.wavesurfer.play(region.start, region.end);
      console.log("regions;", this.wavesurfer.regions);
    });

    this.wavesurfer.on("region-created", (region, e) => {
      this.wavesurfer.clearRegions();
      this.setState({ regions: region });
      console.log(
        "region-created",
        this.state.regions.start,
        this.state.regions.end
      );
    });*/
  }

  reinitializeRecordingWaveform = () => {
    this.wavesurferRecord = WaveSurfer.create({
      height: 80,
      interact:true,
      //autoplay:this.props.autoPlayAllowed,
      autoplay:false,
      barWidth: 2,
      //cursorWidth: 15,
      container: "#recorded_waveform",
      backend: "MediaElement",
      progressColor: "red",
      responsive: true,
      waveColor: "purple",
      cursorColor: "green",
      audioRate:10,
      /*plugins: [
        RegionsPlugin.create({
          regions: this.state.regions,
          color: "hsla(100, 100%, 30%, 0.1)",

          dragSelection: {
            slop: 1
          }
        }),
        
      ]*/
    });
    this.record = this.wavesurferRecord.registerPlugin(RecordPlugin.create())
  }

  cleanUpRecordingWaveform = () =>{
    this.record.destroy()
    this.wavesurferRecord.destroy()
    this.record = null;
    this.wavesurferRecord = null;
  }
  // to format the audio timer
  formatTime = (e) =>{
    return String(Math.floor(e/60))+":"+("0"+String(Math.floor(e%60))).slice(-2)
  }
  
  // to handle the play & pause
  handlePlayPause = () => {
    if (! this.state.playing)
      this.startAudioTimelineTimer() // start timer
    else
      this.stopAudioTimelineTimer(false) // pause timer
    
    this.setState({ playing: !this.state.playing });
    this.wavesurfer.playPause();
  };

  // to stop playing audio
  handleStop = () => {
    this.wavesurfer.stop();
    this.setState({isAudioPlaying:false,playing:false})
    this.stopAudioTimelineTimer();
  };

  // to start audio timer
  startAudioTimelineTimer = () => {
    var time = 0;
    this.timelineTimer.current = setInterval(() => {
      if (this.state.audioSeconds > 0)
        time = this.state.audioSeconds;
      
      time = time + 1;
      let elapSec = this.formatTime(time);
      this.setState({audioSeconds:time, elapsedSeconds:elapSec})      
    }, 1000);
  }

  // to stop audio timer
  stopAudioTimelineTimer = (resetSeconds=true) =>{
    if (this.timelineTimer.current){
      this.setState({ playing:false });
      if (resetSeconds)
        this.setState({audioSeconds:0, elapsedSeconds:this.formatTime(0)})
      clearInterval(this.timelineTimer.current)
      this.timelineTimer.current = null;
    }
  }

  // to stop recording timer
  stopRecordTimer = () =>{
    if (this.recordingStartTimerRef.current){
      clearTimeout(this.recordingStartTimerRef.current)
      this.recordingStartTimerRef.current = null;
    }
  }

  // On volume button click
  onVolumeClick = (e) => {
    this.wavesurfer.setVolume(e.target.value)
  }

  playRegion = () => {
    this.wavesurfer.play(
      this.state.regions.start,
      this.state.regions.end
      // this.wavesurfer.regions.params.regions[0].start,
      // this.wavesurfer.regions.params.regions[0].end
    );
  };

  removeAllRegions = () => {
    //this.wavesurfer.clearRegions();
    if (this.props.handlerAttemptStarted)
      this.props.handlerAttemptStarted(true)
  };

  onAudioControlsHandler = (hidePlayPauseState=false) => {
    //console.log("ON START BUTTON HANDLER")
    this.setState({
      hidePlayPause:hidePlayPauseState,
      //startButtonClicked:!this.state.startClicked
    })
  }
  
  attemptCountHandler = () => {
    this.setState({attemptCount: this.state.attemptCount + 1})
  }

  onClick = () => {
    this.childComponent.current.onStartButtonHandler();
  };

  commonPlayRecordStop = () => {
    if (this.wavesurfer.isPlaying())
      this.wavesurfer.stop();

    if (this.record.isRecording())
      this.record.stopRecording();

    setTimeout(function(){
    },1200)
  }

  segmentRecordingCommonHandler = (stopExisting=true) =>{
    //console.log("6. SEG INITIALISING RECORDING NOW")
    if (stopExisting){
      //console.log("STOPING")
      this.commonPlayRecordStop();
    }
    
    this.record.startRecording().then(() => {
    //console.log("7. SEG RECORDING ALREADY STARTED AFTER AUDIO FINISH", this.record.getRecordedUrl(), this.record, this.wavesurferRecord)
    this.setState({
      recording:true,
      startRecordingButtonClicked:true,
      recordedBlob:this.record.getRecordedUrl(),
      playing:false
    })
      
    // to hide play/pause
    this.onAudioControlsHandler(true)
      
    return;
    });
  }

  recordingCommonHandler = (stopExisting=true) =>{
    //console.log("6. INITIALISING RECORDING NOW")
    if (stopExisting){
      //console.log("STOPING")
      this.commonPlayRecordStop();
    }
    
    this.record.startRecording().then(() => {
      //console.log("7. RECORDING RESTARTED NOW", this.record.getRecordedUrl())
      this.setState({
        recording:true,
        startRecordingButtonClicked:true,
        recordedBlob:this.record.getRecordedUrl(),
        playing:false
        //startCount:1
      })
      
      // to hide play/pause
      this.onAudioControlsHandler(true)

      return;
    });
  }

  /* SEGMENT START */
  onSegmentStartButtonHandler= () =>{
    //console.log("1. START PLAYING AUDIO FOR SEGMENT")
    //this.commonPlayRecordStop()

    if (this.state.startCount === 0){
      if(this.wavesurfer.isPlaying() === false){
        // start timer
        //console.log("2. STARTING TIMER FOR SEGMENT")
        this.startAudioTimelineTimer() // start timer
        
        // start playing segment audio
        this.wavesurfer.play()

        this.setState({
          startCount:1,
          startRecordingButtonClicked:true,
          playing:true, 
        })

        //console.log("3. INCREASING ATTEMPT COUNT FOR SEGMENT")
        // to show attempt counter
        this.attemptCountHandler()
        // to hide next button
        if (this.props.handlerAttemptStarted)
          this.props.handlerAttemptStarted(true)

        //console.log("4. DESTROYING RECORDING WAVEFORM")
        // destroy existing recording object
        this.record.destroy()
        this.wavesurferRecord.destroy()
        this.record = null;
        this.wavesurferRecord = null;
      }
    }else{
      //console.log("2.0 STARTING MODEL NOW")

      this.setState({startModal:true})
    } 
  }

  onSegmentFinishButtonHandler = () =>{
    //console.log("8. ON SEG FINISH BUTTON")
    if (this.wavesurfer.isPlaying())
      this.wavesurfer.stop()

    this.stopAudioTimelineTimer() // stop timer
    //console.log("9. AFTER AUDIO TIMER")

    // stop ongoing recording
    
    if (this.record.isRecording()){
      //console.log("10. YES RECORDING", this.state.recordedBlob, this.record.getRecordedUrl())

      this.record.stopRecording();

    }
    //console.log("11. AFTER STOP RECORDING", this.record.getRecordedUrl())  

    this.setState({
      recording:false,
      startRecordingButtonClicked:false,
      recordedBlob:this.record.getRecordedUrl(),
      playing:false
    })
    
    setTimeout(() => {
      // to store recorded audio
      if (this.props.handlerRecordedAudio && this.record.getRecordedUrl()){
        //console.log("12. PASSING RECORDING URL TO PARENT COMP",  this.record.getRecordedUrl())
        this.props.handlerRecordedAudio(this.record.getRecordedUrl())
      }

      // to show next button
      if (this.props.handlerAttemptStarted)
        this.props.handlerAttemptStarted(false)
    },1200)

    //this.stopRecordTimer()

    /* this.record.startRecording().then(() => {
      console.log("HERE BY SG BUTTON AFH")
    });*/
    return
    //}
  }

  onSegmentModalStartButtonHandler = () => {
    //console.log("2.1 ON SEGMENT MODAL START BUTTON HANDLER")
    // destroy existing recording object
    this.cleanUpRecordingWaveform()

    if(this.wavesurfer.isPlaying() === false){
      // start timer
      //console.log("2.2 STARTING TIMER FOR SEGMENT")
      this.startAudioTimelineTimer();
      
      // start playing segment audio
      this.wavesurfer.play();

      this.setState({
        startCount: this.state.startCount+1, 
        startRecordingButtonClicked:true,
        playing:true,
        startModal:false,
      })

      //console.log("2.3 ON SEGMENT MODAL INCREASING ATTEMPT COUNT FOR SEGMENT")

      // to show attempt counter
      this.attemptCountHandler()

      // to hide next button
      if (this.props.handlerAttemptStarted)
        this.props.handlerAttemptStarted(true)

     // console.log("2.4. DESTROYING RECORDING WAVEFORM FROM SEGMENT MODEL BUTTON")
      
      
      /*this.recordingStartTimerRef.current = setTimeout(() => {
        console.log("ALLOWING REC ON MODAL", this.wavesurfer.getDuration())
        if (this.record.isRecording()){
          console.log("GOING TO START SO STOPPING NOW")
          this.record.stopRecording()
        }
        this.recordingCommonHandler(false);
      },this.wavesurfer.getDuration()*1000)*/
    }
  }

  onSegmentModalAttemptFinishHandler = () =>{
    //console.log("2.8 On Segment MODAL ATTEMPT FINISH HANDLER");
    // stop ongoing segment audio play
    if (this.wavesurfer.isPlaying())
      this.wavesurfer.stop()

    this.stopAudioTimelineTimer() // stop timer
    //console.log("2.9. AFTER AUDIO STOP TIMER")

    // stop ongoing recording
    if(this.record){
      if (this.record.isRecording()){
        //console.log("2.10. YES RECORDING", this.state.recordedBlob, this.record.getRecordedUrl())
        this.record.stopRecording()
      }
      this.setState({
        recordedBlob:this.record.getRecordedUrl(),
      })
      //console.log("2.11. AFTER STOP RECORDING", this.record.getRecordedUrl())
    }

    // update other required states
    this.setState({
      recording:false,
      startRecordingButtonClicked:false,
      attemptFinishModal:false,
      playing:false
    });
    
    setTimeout(() => {
      // to store recorded audio
      if (this.record){
        if (this.props.handlerRecordedAudio && this.record.getRecordedUrl()){
          //console.log("2.12. PASSING RECORDING URL TO PARENT COMP",  this.record.getRecordedUrl())
          this.props.handlerRecordedAudio(this.record.getRecordedUrl())
        }        
      }else{
        // reintialize recording waveform
        //console.log("ELSE 566")
        this.reinitializeRecordingWaveform();
        this.record.startRecording().then(() => {
          //console.log("HERE BY MODAL AFH")
        });
      }

      // to show next button
      if (this.props.handlerAttemptStarted)
        this.props.handlerAttemptStarted(false)
    },1200)

    //console.log("LATER SEG")
  }
  /* SEGMENT ENDS */

  onStartButtonHandler = () =>{
    //console.log("START RECORDING ")
    if (this.state.startCount === 0){
      if (this.props.handlerAttemptStarted)
        this.props.handlerAttemptStarted(true)
      
      // to show attempt counter
      this.attemptCountHandler()

      this.recordingCommonHandler(true);
      this.setState({startCount:1,startButtonClicked:true})
    }else{
      this.setState({startModal:true})
    }
  }

  onModalStartButtonHandler = () => {
    //console.log("ON MODAL START BuTTON HAN")
    // to hide next button
    if (this.props.handlerAttemptStarted)
      this.props.handlerAttemptStarted(true)
    // to show attempt counter
    this.attemptCountHandler()

    this.recordingCommonHandler();
    this.setState({
      startCount: this.state.startCount+1, 
      startModal:false
    })
  }

  onModalAttemptFinishHandler = () =>{
    //console.log("On onFinishButtonHandler RECORDING");

    // stop ongoing recording
    if (this.record.isRecording())
      this.record.stopRecording()

    this.setState({
      attemptFinishModal:false,
      startRecordingButtonClicked:false,
      recording:false,
      hidePlayPause:false
    });

    // stop ongoing play
    if (this.wavesurferRecord.isPlaying())
      this.wavesurferRecord.stop()

    
    /*if (this.props.handlerAttemptStarted)
      this.props.handlerAttemptStarted(false)*/
    /*this.record.startRecording().then(() => {
      console.log("HERE BY MODAL AFH")
    });*/

    //this.stopRecordTimer()
    //console.log("LATER")
  }

  closeStartModal = () =>{
    //resetStates();
    this.setState({startModal:!this.state.startModal});
  }

  closeAttemptFinishModal = () =>{
    this.setState({attemptFinishModal:!this.state.attemptFinishModal});
  }

  resetStates = (newAudioBlob=null) => {
    //console.log(" RESET STATES IN CHILD",newAudioBlob)
    this.setState({
      attemptCount:0,
      attemptFinishModal:false,
      startRecordingButtonClicked:false,
      recording:false,
      playing:false,
      recordedBlob:null,
      startCount:0
    })
    
    if (newAudioBlob !== null){
      this.setState({
        playerAudio:newAudioBlob
      })
      this.wavesurfer.load(newAudioBlob)
    }

    this.commonPlayRecordStop();
    if (!this.record.isRecording()){
      this.record.startRecording().then(() => {
        //console.log("INTIALIZING AGAIN EMPTY RECORDING")
      });      
    }
  }

  render() {
    return (
      <>
      {this.state.startModal && this.state.startCount>=1 && 
        <Modal isOpen={this.state.startModal} id="startPopup" size="md">
        <div className="modal-body">
          <button onClick={()=>this.closeStartModal()} type="button" className="close" data-dismiss="modal">&times;</button>
          <div className="mobileIcon">
            <Card>
              <CardTitle><h3>Start Attempt</h3></CardTitle>
              <CardBody>
              <h4>Are you sure you want to repeat the segment?</h4>
              <button className="btn" onClick={()=>(this.props.forSegment)?this.onSegmentModalStartButtonHandler():this.onModalStartButtonHandler()}>Ok</button>
              <button className="btn cancelBtn" onClick={()=>this.closeStartModal()}>Cancel</button>
              </CardBody>
            </Card>
          </div>
        </div>
      </Modal>}
      {this.state.attemptFinishModal && 
        <Modal isOpen={this.state.attemptFinishModal} id="attemptFinishPopup" size="md">
        <div className="modal-body">
          <button onClick={()=>this.closeAttemptFinishModal()} type="button" className="close" data-dismiss="modal">&times;</button>
          <div className="mobileIcon">
            <Card>
              <CardTitle><h3>Finish Attempt</h3></CardTitle>
              <CardBody>
              <h4>Are you sure you want to finish this attempt?</h4>
              <span>You have not interpreted all segments yet.</span>
              <div className="popupFooterBtns mt-3">
                <button className="btn" onClick={()=>{(this.props.forSegment)?this.onSegmentModalAttemptFinishHandler():this.onModalAttemptFinishHandler();}}>Ok</button>
                <button className="btn cancelBtn" onClick={()=>this.closeAttemptFinishModal()}>Cancel</button>
              </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Modal>}
      <div id="audioWaveformContainer">
        <div className="waveVolumeContainer">
          <Button 
            color="primary"
            className="bottom-btn volume-btn" 
            onClick={
              ()=>this.setState({showVolSlider:!this.state.showVolSlider})
            } 
            size="sm">
            <i className={'fa fa-volume-up'}></i>
          </Button>
          {
            (this.state.showVolSlider === true)?(
              <div className="volumeControl" style={{display:this.state.showVolSlider===true?"show":"hide"}}>
                <Input orient="vertical" type="range" min="0" defaultValue={1} step="0.1" onChange={this.onVolumeClick} max="1"  />
              </div>
            ):null
          }
        </div>

        <div
          className="waveContainer"
          id="waveform"
        />
        
        <div
          className="waveRecContainer"
          id="recorded_waveform"
        />

        <div id="timelineContainer">
          <span>{this.state.elapsedSeconds}</span>
          <span>{this.state.audioDuration}</span>
        </div>

        <div className="waveButtons">
          {
            this.props.showAttemptText?
            (
              <span className="attempsCounter">Attempts: &nbsp;{this.state.attemptCount}</span>
            ):null
          }
          {
            this.props.forInstructions && this.state.hidePlayPause === false?(
              <>
              <Button color="primary" className="bottom-btn" id="play-button" onClick={this.handlePlayPause} size="sm" disabled={this.state.startButtonClicked?true:false}>
                <i className={!this.state.playing ? 'fa fa-play' : 'fa fa-pause'}></i>
              </Button>
              <Button color="primary" className="bottom-btn" id="play-button" onClick={this.handleStop} size="sm" disabled={!this.state.playing?true:false}>
                <i className={'fa fa-stop'}></i>
              </Button>
              </>
            ):null
          }
          {
            !this.props.forSegment?(
              !this.state.startRecordingButtonClicked?(
                <Button color="primary" className="start-record-btn bottom-btn" onClick={this.onStartButtonHandler} disabled={(this.state.playing || this.state.startButtonClicked)?true:false}>Start</Button>
              ):(
                <Button color="primary" className="start-record-btn bottom-btn" onClick={()=>this.closeAttemptFinishModal()}>Finish</Button>
              )
            ):(
              !this.state.startRecordingButtonClicked?(
                <Button color="primary" className="start-record-btn bottom-btn segmentBtn" onClick={this.onSegmentStartButtonHandler}>Start</Button>
              ):(
                <Button color="primary" className="start-record-btn bottom-btn segmentBtn" onClick={()=>(!this.state.recording)?this.closeAttemptFinishModal():this.onSegmentFinishButtonHandler()}>Finish</Button>
              )
            )
          }
        </div>
       
        {
          ((this.state.recording === true) && (this.props.showRecordBlinker)) ? (
          <div className="recordingContainer">
            <label id="recording_btn_container">
              <i className="fa fa-circle"></i>
              <span>REC</span>
            </label>
          </div>
          ):null
        }
        {
          ((this.props.showPlayingSourceBlinker) && (this.state.playing === true)) ? (
          <div className="playingSourceContainer">
            <label id="source_listening_msg">
              <i className="t-icon t-icon-hc-spin t-icon-spinner"></i>
              <span>PLAYING SOURCE FILE</span>
              </label>
          </div>
          ):null
        }
      </div>
      </>
    );
  }
}

export default WaveSurferComponent;
