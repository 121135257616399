import React from 'react';
import { Link} from 'react-router-dom';

class coursesWeOffer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false
        }
    }
    componentDidMount() {        
    }
 render(){

	return (
		<section className="coursesOffer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2 className="title">Courses <span>we offer</span></h2>
            <div className="row">
              <div className="col-sm-4 courseOuter">
                <div className="courseCntnt">
                  <p>PTE</p>
                  <img src="images/book.png" alt="book" />
                  <Link to="/pte-coaching-classes-parramatta" className="themeBtn">Read More</Link>
                </div>
              </div>
              <div className="col-sm-4 courseOuter">
                <div className="courseCntnt">
                  <p>NAATI</p>
                  <img src="images/book.png" alt="book" />
                  <Link to="/naati-coaching-classes-parramatta" className="themeBtn">Read More</Link>
                </div>
              </div>
              <div className="col-sm-4 courseOuter">
                <div className="courseCntnt">
                  <p>IELTS</p>
                  <img src="images/book.png" alt="book" />
                  <Link to="/ielts-coaching-parramatta" className="themeBtn">Read More</Link>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
	);
}
}

export default coursesWeOffer;