import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import API, { baseHost, baseAssetsPath, profileImagePath} from "api.js";
// reactstrap components
import { setUser} from 'action/index.js';
import {store} from 'store/index';
import avatar from "assets/img/faces/ayo-ogunseinde-2.jpg";
import { NavLink } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Modal,
  FormGroup,
  Input,
  Row,
  Form,
  Col,
} from "reactstrap";
import moment from 'moment';	
import { setAlert} from 'action/index.js';

const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};

class Dashboard extends React.Component {

	constructor(props) {
	super(props);
		//console.log("Das", store.getState())
		this.state = {
			isMainSite:store.getState().is_main,
			vocabCategories:[],
			mockStats:{},
			user:null,
			isSubscribed: store.getState().subscribed,
			savedCatId:0,
			totalVideos:0,
      videos:[],
      daysLeft:0,
      examDateModal:false,
      exam_date:null,
      examDate:this.formatDate(store.getState().user.exam_date, "DD-MM-YYYY")
		}
	}

	async componentDidMount() {
		// fetch profile
		await this.getProfileData();
		// fetch all categories
    await this.getVocabularyCategory();
		// get all videos
    await this.getDashboardVideos();
    //console.log(store.getState().user.profile_image)
    
	}
  
   // to get all available vocabulary categories
   getVocabularyCategory = async() => {
    	try{
	      const response = await API({
	        method: 'GET',
	        url: '/vocabulary_categories'
	      });
	      if (response.data.success && response.data.data.vocabulary_categories) {
	      	var savedCatId = response.data.data.vocabulary_categories.filter(iter => iter.name === "Saved").map(iter => iter.id);
	         this.setState({ 
	         	vocabCategories: response.data.data.vocabulary_categories,
	         	savedCatId:savedCatId[0]
	         });
    			this.getExamDaysLeft(this.state.user.exam_date);
	      } else {
	        console.log("Error fetchVocabCategories ==> ");
	      }
    	}catch(error) {
      	this.setState({error:{error} });
    	};
   }

   // to get user mock stats
   getMockStats = async() => {
	   try{
	      const response = await API({
	        method: 'GET',
	        url: '/mock_stats'
	      });
	      //console.log("MOCK STATS", response.data)
	      if (response.data.success) {
	        this.setState({ mockStats: response.data.data });
	      } else {
	        console.log("Error fetchgetMockStats ==> ");
	      }
	   }catch(error) {
	      this.setState({error:{error} });
	   };
   }

   getDashboardVideos = async() => {
    if (this.state.videos.length < this.state.totalVideos || this.state.totalVideos === 0) {
      try {
        let postData = {
          pageOffset: 0,
          pageSize: 5,
          type: 1
        };
        const response = await API.post('videos', postData);
        if (response.data.success) {
          this.setState({totalVideos: response.data.data.count})
          this.fetchAllVimeoDetails(response.data.data.rows);
        }
      } catch (error) {
        console.log("ERROR in fetchVideos : ", error);
      }
    }
  }

  fetchAllVimeoDetails = async (videos) => {
    for (const [index, singleVideo] of videos.entries()) {
      videos[index].thumbnailUrl = "http://i.ytimg.com/vi/"+singleVideo.link+"/maxresdefault.jpg";//result.data.video.thumbs['640'];
      videos[index].videoUrl = singleVideo.link;//result.data.request.files.hls.cdns[result.data.request.files.hls.default_cdn].url;
    }
    //console.log("VIDEOS", videos)
    this.setState({videos: videos});
  }

  formatDate = (date, format) => {
   format = format || 'YYYY-MM-DD hh:mm:ss A';
   return moment(date).format(format);
	};

	getExamDaysLeft = (examDate=this.state.user.exam_date) =>{
		var start = moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD");
		var end = moment(examDate, "YYYY-MM-DD");
		//Difference in number of days
		var daysDiff = moment.duration(end.diff(start)).asDays();

		this.setState({daysLeft:daysDiff})
		return daysDiff;
	}

	updateExamDate = async() => {
		//console.log("UP EXAM DATE", this.state.exam_date, this.state.user, this.state.user.first_name)
  	let res = null;
		try{
      res = await API({
        method: 'POST',
        url: '/profile',
        data: {
        	'first_name':this.state.user.first_name,
        	'email':this.state.user.email,
          'exam_date':this.state.exam_date
        }
      })
    }catch(error) {
      this.setState({error:{error} });
    };

    if (res.status === 200) {
      this.setState({
      	examDateModal:false,
      	examDate:this.formatDate(this.state.exam_date, "DD-MM-YYYY")
      });
      //console.log("AA",this.formatDate(this.state.exam_date, "DD-MM-YYYY"))
  		this.getExamDaysLeft(this.state.exam_date);

      store.dispatch(setAlert({'flag':true,'type':1,'message':"Exam date updated successfully!"}));
    }else if (res.status === 422) {
      this.setState({error:res.response });
    }
	}

	getProfileData = async() => {
    try{
      const response = await API({
        method: 'GET',
        url: '/profile'
      })
      if (response.status === 200){
      	//console.log("RS", response.data.data.profile_details)
        this.setState({
        	user:response.data.data.profile_details,
      		examDate:this.formatDate(response.data.data.profile_details.exam_date, "DD-MM-YYYY")

        });
      }
    }catch(error) {
      this.setState({error:{error} });
    };
  }

	changeInput = (e) => {
  	let { name, value } = e.target;
  	this.setState({ [name] : value });
	};

  render() {
		let {
			isMainSite
		} = this.state;

		return (
		<>
			{this.state.examDateModal && <Modal isOpen={this.state.examDateModal} id="examDatePopup" size="md">
        <div className="modal-body">
          <button onClick={(e)=>this.setState({examDateModal:!this.state.examDateModal})} type="button" className="close" data-dismiss="modal">&times;</button>
          <div className="mobileIcon">
            <Card>
              <CardTitle><h3>SET EXAM DATE</h3></CardTitle>
              <CardBody>
              	<Form>
	              	<Row>
	                	<Col className="exam_date_container" sm="12">
	                    <FormGroup>
	                      <label>Exam Date</label>
	                      <Input
	                        defaultValue={this.state.dob}
	                        placeholder="Set Exam Date"
	                        name="exam_date"
	                        type="date"
	                        min={moment().format("YYYY-MM-DD")}
	                        onChange={(e) => { this.changeInput(e)} }
	                      />
	                      
	                    </FormGroup>
	                  </Col>
	              	</Row>
              	</Form>
              </CardBody>
              <CardFooter>
              	<button 
              		className="btn"
              		onClick={()=>this.updateExamDate()}
              		disabled={
              			this.state.exam_date === null?true:false
              		}
              	>
              		Update
              	</button>
              	
              	<button className="btn cancelBtn" onClick={()=>this.setState({examDateModal:!this.state.examDateModal})}>Cancel</button>
              </CardFooter>
            </Card>
          </div>
          <div className="contentInr">    
          </div>
        </div>
        </Modal>}
			<div className="content">
				{/*<Col md={12} className="text-right">
					
					<Link to="/admin/help" className="btn btn-primary disabled">Help</Link>
				</Col>*/}
				<Row className="dashboardCntnt">
					<Col md="8" sm="8" className="dashLeftCntnt">
						<div className="userCatOuter radiusBlk">
							<div className="userInfo">
								<div className="dashUserImgBlk">
									<div className="dashUserImgOtr">
										<img src={(store.getState().user.profile_image)?baseHost+baseAssetsPath+profileImagePath+store.getState().user.profile_image:avatar} alt="userImage"/>
									</div>
									{
										this.state.isSubscribed?(
											<span className="premiumLogo">
												<img src="/images/icon/user-crown.png"  alt="crown"/>
											</span>
										):null
									}
								</div>
								<h4 className="userName">
									<NavLink
                    to={`/admin/profile/`}
                    className="nav-link"
                   >
                   {store.getState().user.full_name}
									</NavLink>
								</h4>
								{
									this.state.isSubscribed?(
										/*<span className="premiumUser"><img src="/images/premium-btn.png" alt="premium-btn" /></span>*/
										<span className="premiumUserbtn align-self-center ">Premium User</span>
									):null

								}
								{isMainSite &&
									<Link to="/admin/packages" className="btn btn-success upgradeBtn">{this.state.isSubscribed?"Upgrade":"Subcribe"}</Link>
								}
							</div>
							<div className="catRight">
								<h4 className="dashTitle">
									<span>Categories</span>
								</h4>
								<div className="catItems">
									{
										this.state.vocabCategories.map((prop, key) => {
											if (prop.name !== "Saved"){
												return (
													<Col className="userRecord" key={key}>
														<NavLink
	                            to={`/admin/vocabularies/${prop.id}`}
	                            className="nav-link"
	                           >
	                           	<p>{prop.name}</p>
     												</NavLink>
													</Col>
												)													
											}
										})
									}					
								</div>
								<div className="greenBtn">
									<NavLink
                    to={`/admin/vocabularies/`+this.state.savedCatId}
                    className="nav-link"
                   >
                   Saved Words
									</NavLink>
								</div>
							</div>
						</div>
						<div className="testAtemptBlk">
							<Row>
								<Col md="6" sm="6" className="testAttempts col-sm-12">
									<Row>
										<Col md="12" sm="12">
											<div className="testRecord speaking">
												<h4><Link to="/admin/dialogues">Practice Dialogues</Link></h4>													
											</div>
										</Col>
										<Col md="12" sm="12">
											<div className="testRecord writing">
												<h4><Link to="/admin/comp_mats">Comprehensive Dialogues</Link></h4>
											</div>
										</Col>
										<Col md="12" sm="12">
											<div className="testRecord reading">
												<h4><Link to="/admin/lmps">Last Minute Practice</Link></h4>
											</div>
										</Col>
										<Col md="12" sm="12">
											<div className="testRecord listening">
												<h4><Link to="/admin/mock/listing">Mock Test</Link></h4>
											</div>
										</Col>
									</Row>
								</Col>
								<Col md="6" sm="6" className="examCalender">
									<div className="examCalenderInr radiusBlk">
										<div className="examDate">
											<h2><span>Exam Date</span>{this.state.examDate}</h2>
										</div>
										<div className="examDaysLeft">
											{
												this.state.daysLeft > 0?(
													<h2><span>Days Left</span>{this.state.daysLeft}</h2>
												):(
													<Button className="btn-round btn btn-default" onClick={()=>this.setState({examDateModal:!this.state.examDateModal})}>Set Exam Date</Button>
												)
											}
										</div>
									</div>
								</Col>
							</Row>
						</div>
						{/*<div className="upcmngClasBlk">
							<Col md="12" sm="12" className="upcmngClasBlkInr radiusBlk">
								<table>
									<thead>
										<tr>
											<th className="upcomingHead"><span>Upcoming Classes</span></th>
											<th><span>Date</span></th>
											<th><span>Time</span></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												1. PTE Speaking Complete Guidance Course
											</td>
											<td>24-08-2023</td>
											<td>7:00AM</td>
										</tr>
										<tr>
											<td>
												2. PTE Read Aloud Tips & Tricks
											</td>
											<td>24-08-2023</td>
											<td>7:00AM</td>
										</tr>
										<tr>
											<td>
												3. Repeat Sentences Tips & Tricks
											</td>
											<td>24-08-2023</td>
											<td>7:00AM</td>
										</tr>
										<tr>
											<td>
												4. Secrets to score 90 in PTE
											</td>
											<td>24-08-2023</td>
											<td>7:00AM</td>
										</tr>
									</tbody>
								</table>
							</Col>
						</div>*/}
					</Col>
					<Col md="4" sm="4" className="dashRightSidebar">
						<div className="dashRightSidebarInr radiusBlk">
							<h4 className="dashTitle"><span>Videos</span></h4>
							<div className="videosList">
								{
              		this.state.videos.map((prop, key) => {
                		return(
											<div className="sideVideoOuter" key={key}>
												<iframe title="Iframe_{key}" src={'https://www.youtube.com/embed/'+prop.videoUrl+'?rel=0&autoplay=0&showinfo=0&controls=1&disablekb=1&modestbranding=1&playsinline=1'} frameBorder="0" allowFullScreen/>
											</div>
                		)
                	})
								}
								{/*<div className="sideVideoOuter">
									<img src="/images/side-video-1.jpg" alt="" />
								</div>
								<div className="sideVideoOuter">
									<img src="/images/side-video-2.jpg" alt="" />
								</div>
								<div className="sideVideoOuter">
									<img src="/images/side-video-3.jpg" alt="" />
								</div>
								<div className="sideVideoOuter">
									<img src="/images/side-video-1.jpg" alt="" />
								</div>
								<div className="sideVideoOuter">
									<img src="/images/side-video-2.jpg" alt="" />
								</div>
								<div className="sideVideoOuter">
									<img src="/images/side-video-3.jpg" alt="" />
								</div>*/}
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
			<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
		</>
	);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
