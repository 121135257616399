import React,{Component} from "react";

import {
  Col
} from "reactstrap";
import "components/custom.css";
import {arrayMove} from 'react-sortable-hoc';
import reactStringReplace from 'react-string-replace';
import ReactHtmlParser from 'react-html-parser';

class ReadingBlank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question:"",
      secondCount:1200,
      questionId:"",
      reset:false,
      correctFlag:true,
      resultObj:{},
      answerData:{'answer':[],'correct':[]}
    }
    this.questionPara = "";
  }
  componentDidmount = () => {
    
  }
	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}
  onSortEnd = ({oldIndex, newIndex}) => {

    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };
  
  allowDrop(event) {
    event.preventDefault();
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  drop1(event) {
    this.props.showResult();
    event.preventDefault();
    let data = event.dataTransfer.getData("Text");
    //let no = event.dataTransfer.getData("no");
    let temp = event.target.innerHTML;
    //let tempData = this.state.answerData.answer.slice();
    if(temp === ""){
      
     // this.setState({answerData:{'answer':[document.getElementById(event.target.id).getAttribute("no")],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })}});
    
      event.target.innerHTML = document.getElementById(data).innerHTML;
      document.getElementById(data).innerHTML = temp;
      let key = document.getElementById(event.target.id).getAttribute("no");
      let tempData = this.state.answerData.answer.slice();
      tempData[key] = event.target.innerHTML;

      //this.setState({answerData:{'answer':tempData,'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })} });
      this.props.submit({'answer':document.querySelectorAll('.response')[0].innerHTML.replace(/<[^>]*>?/gm, 'x_x').split('x_x').map(function(i,x){ if(x%2 !==0) return i; }),'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; })},true);
      if(document.querySelectorAll('#'+data).length > 0){
        //document.querySelectorAll('#'+data)[0].style.display='none';
        
      }
    }else{
      //this.setState({answerData:{'answer':[document.getElementById(event.target.id).getAttribute("no")],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })}});
    let tempData = this.state.answerData.answer.slice();
      event.target.innerHTML = document.getElementById(data).innerHTML;
      document.getElementById(data).innerHTML = temp;
      let key = document.getElementById(event.target.id).getAttribute("no");
      
      tempData[key] = event.target.innerHTML;
      console.log(tempData);
      this.props.submit({'answer':document.querySelectorAll('.response')[0].innerHTML.replace(/<[^>]*>?/gm, 'x_x').split('x_x').map(function(i,x){ if(x%2 !==0) return i; }),'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; })},true);  
    }
    this.setState({resultObj:{'answer':document.querySelectorAll('.response')[0].innerHTML.replace(/<[^>]*>?/gm, 'x_x').split('x_x').map(function(i,x){ if(x%2 !==0) return i; }),'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })},answerData:{'answer':document.querySelectorAll('.response')[0].innerHTML.replace(/<[^>]*>?/gm, 'x_x').split('x_x').map(function(i,x){ if(x%2 !==0) return i; }),'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; })}});
    //document.querySelectorAll('.span2').forEach((t) => { if(t.innerHTML == '') { t.style.display="none"; } } );
    
  }

  updateCorrect = () =>{
    this.props.submit({'answer':[],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; })});  
    this.setState({correctFlag:false,resultObj:{'answer':[],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; })}})
  } 

  drop(event) {
    event.preventDefault();
    var data = event.dataTransfer.getData("Text");
    //var no = event.dataTransfer.getData("no");
    var temp = event.target.innerHTML;
    event.target.innerHTML = document.getElementById(data).innerHTML;
    document.getElementById(data).innerHTML = temp;


  }

  showAnswer = () => {

    
      if (!this.props.resultMode) {
        let elem = document.querySelectorAll('.checkedSign');
        for (var i = 0; i <= elem.length-1; i++) {
          let id = elem[i].dataset.id;
          
          if (elem[i].dataset.text.trim() === document.getElementById('item-'+id).textContent.trim()) {
            elem[i].innerHTML= '<i class="fa fa-check" style="font-size:32px;color:green"></i>';
          }else{
            elem[i].innerHTML = '<i class="fa fa-times" style="font-size:32px;color:red"></i>';

          }    
        }  
      }
      
  }

  dragStart(event) {
    event.dataTransfer.setData("Text", event.target.id);
      
  }
  onDrop(data) {
    console.log(data);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data) {
      if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
        document.querySelectorAll('.span1').forEach((t) => { t.innerHTML="" } );
        document.querySelectorAll('.span2').forEach((t) => { t.style.display="inline-block" } );
        document.querySelectorAll('.span2').forEach((t) => { t.innerHTML=t.getAttribute('txt') } );
        return {correctFlag:true,questionId: props.data.id,answerData:{'answer':[],'correct':[]},optionList:props.data.option};
      }else{
        if (!state.questionId) {
          return {correctFlag:true,questionId: props.data.id,optionList:props.data.option};
        }
      }  
    }
  }

  render() {
    console.log(this.state.answerData);
    let question ="";
    //let optionList =[];
  	let correct =[];
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.answer) { 
      this.showAnswer();
    }
    if (this.props.stopRecording) {
      this.stopTest();      
    }
  	if (this.props.data) {
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  		question = this.props.data.question;
      correct = question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; });
      this.questionPara = reactStringReplace(question.replace(/<[^>]*>?/gm, 'x_x').replace(/x_x(.*?)x_x/g,''), /(__add_blank__)/g, (match, i, o) => (<>
          <span className="span1" onDrop={this.drop1.bind(this)} draggable="true" onDragStart={this.dragStart} no={i} id={'item-'+i} onDragOver={this.allowDrop}></span>{this.props.answer && <span><span className="checkedSign" style={{display: (this.props.answer)?"inline-block":"none"}} data-id={i} data-text={correct[(i-1)/2]} ></span><span className="correct" >{correct[(i-1)/2]}</span></span>}
      </>
        )); 
     // optionList = this.props.data.option;
     // this.setState({questionPara:questionPara});
  	}
    
    if (this.state.reset) {
      document.querySelectorAll('.span1').forEach((t) => { t.innerHTML="" } );
      console.error("reset true");
    }
    return (
      <>
        <Col xs={12}>
        	<h4 className="infoText"></h4>
        </Col>
        <Col xs={12}><h5 className="response">{this.props.data && !this.props.resultMode && this.questionPara}</h5></Col>
        <Col xs={12}>{this.props.data && this.props.resultMode && <h4>Correct answer</h4>}<h5>{this.props.data && this.props.resultMode && ReactHtmlParser(question.replace(/<span[^>]*>?/g, '<b class="correct">').replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5></Col>
        <Col xs={12}>{this.props.data && this.props.resultMode && <h4>Your response</h4>}<h5>{this.props.data && this.props.resultMode && ReactHtmlParser(question.replace(/<span[^>]*>?/g, function(v,k){ return '<b class="selected-option">';}).replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5></Col>
        <Col xs={12}>
          <h3 className="question">{this.props.data && this.props.data.question_mcq}</h3>
        </Col>
        <Col lg={12} className="anwrsOptions">
          {this.props.data && !this.props.resultMode && this.state.optionList.map((item) => { return  <span draggable="true" no="0" onDrop={this.drop.bind(this)} onDragOver={this.allowDrop} onDragStart={this.dragStart} className="span2" txt={item.options.trim()} id={'item-'+item.options.trim()}>
            {item.options.trim()}
        </span> })}
        
        </Col>
        <Col lg={12}>&nbsp;</Col>
      </>
    );
  }
}

export default ReadingBlank;
