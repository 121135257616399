import React,{Component} from "react";
import {
  Col,
  Progress,
  FormGroup,
  Input,
  Button
} from "reactstrap";
import {s3base} from "api.js";
import Sound from 'react-sound';
import "components/custom.css";

class Dictation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:10,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      content:'',
      soundPer:0,
      volume:100,
      correctFlag:true,
      resultObj:{}
    };
  }
  componentDidMount() {
  	var intervalId = setInterval(this.timer, 1000);
	this.setState({intervalId: intervalId});
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			return {correctFlag:true,recordCount:0,soundPer:0,isRecording:false,secondCount:10,questionId: props.data.id,blobURL:'',reset:true,soundStatus:true,content:''};
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}
  		
  		}
  	    
    
  }
  

	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
			
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }
  changeRange = (e) => {
      this.setState({volume: e.target.value});
  }
  handleSongFinishedPlaying = () => {
    this.setState({'soundStatus':false});
    this.props.soundChecked();
    this.props.showResult();
  } 

  copyToClipboard = () => {
    document.execCommand("copy");
  };

  cutToClipboard = () => {
    document.execCommand("cut");
  };

  pasteToClipboard = async () => {
    alert("Press Ctrl+v for paste text");
 
  };

  chaneText = (e) =>{
    let { name, value } = e.target;
    this.props.submit({'answer':value,'correct':''},true);
    this.setState({ [name] : value, resultObj: {'answer':value,'correct':''} });
  };

  updateCorrect = () =>{
    this.props.submit({'answer':'','correct':''});
    this.setState({correctFlag:false,resultObj: {'answer':'','correct':''}});
  } 

	timerRecord = () => {
		if (this.state.recordCount === 40) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}

  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}
  
  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();      
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		this.setState({intervalId: intervalId,reset:false});
  	}
    return (
      <>
      <Col xs={12}>
      	<h4 className="infoText"></h4>
        {this.props.data && !this.props.resultMode && this.state.soundStatus && this.state.secondCount === 0 && <Sound
              url={s3base + this.props.data.media_link}
              playStatus={Sound.status.PLAYING}
              onFinishedPlaying={this.handleSongFinishedPlaying}
              onPlaying={this.handleSongPlaying}
              volume={this.state.volume}
            />}
      </Col>
     { !this.props.resultMode && <Col xs={12}>
      	<div className="col-lg-12 text-center">
    		  <div className="currentStatus">
            <p>Current Status:{this.state.soundStatus === true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
            {this.state.secondCount === 0 && this.state.soundStatus && ' Playing' }
            
            <br></br>
            Volume
             <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
             <br></br>
            <Progress color="info" value={(this.state.soundPer)} />
            {!this.props.testMode && this.state.secondCount!==0 && this.state.blobURL === '' && !this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.skip} size="sm">Skip</Button></div> }
            </p>
            
          </div>
          <Col xs={12}>
            
          </Col>
    		  <Col xs={12}>
    		  	{ this.state.blobURL && <audio src={this.state.blobURL} controls="controls" />}
    		  </Col>
    		</div>
      </Col> }
      <Col xs={12}><h5>{question}</h5></Col>
      {!this.props.resultMode && <Col xs={12}>
              <FormGroup>
                <Input type="textarea" name="content" autocorrect="false" spellcheck="false" id="textBox" onChange={(e) => { this.chaneText(e)} } value={this.state.content} />
              </FormGroup>
              <div className="bottomContent">
                <p className="wordCount">Total word count: {this.state.content.split(' ').filter(function(n) { return n !== '' }).length}</p>
                <div className="copyPaste">
                  <a href="javascript:void(0)" onClick={this.cutToClipboard} className="btn">Cut</a>
                  <a href="javascript:void(0)" onClick={this.copyToClipboard} className="btn">Copy</a>
                  <a href="javascript:void(0)" onClick={this.pasteToClipboard} className="btn">Paste</a>
                </div>
              </div>
            </Col>}
      <Col xs={12}><h5>{this.props.data && this.props.answer &&  this.props.data.audio_script }</h5></Col>
      </>
    );
  }
}
export default Dictation;