import React,{Component} from "react";
// reactstrap components
import {
  CardBody,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import API from "api.js";
import { setAlert} from 'action/index.js';
import {store} from 'store/index';
class UserNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      commentData:[],
      comment:'',
      id:0,
      loaderFlag:false,
      loaderCommentFlag:false,
      scoreData:[],
      loadMoreFlag:false,
      questionId:this.props.question
    };
  }
  componentDidMount() {
    this.loadComment();
  }

  loadComment = () => {
    this.setState({loaderCommentFlag:true});
    var bodyFormData = new FormData();
    bodyFormData.set('skip', this.state.commentData.length);
    bodyFormData.set('question_id', this.state.questionId);
    API({
      method: 'POST',
      url: 'show/notes',
      data:bodyFormData
    }).then(data => {
      if(data.status === 200){
        let loadMoreFlag = true;
        if(data.data.data.length < 10){
          loadMoreFlag = false;
        }
        this.setState({loadMoreFlag:loadMoreFlag,commentData:[...this.state.commentData,...data.data.data],loaderCommentFlag:false});  
      }
    });
  }


  addComent = async () => {
    this.setState({loaderFlag:true});
    var bodyFormData = new FormData();
    if(this.state.comment === ""){
      store.dispatch(setAlert({'flag':true,'type':2,'message':"Comment cant't be empty"}));
      return ""
    }
    bodyFormData.set('question_id', this.state.questionId);
    bodyFormData.set('notes', this.state.comment);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: 'add/note',
        data: bodyFormData,
      })
    }catch(error) {
        console.log(error);
    };
    
    if(res){
      if (res.status === 200) {
        this.setState({commentData:[...res.data.data,...this.state.commentData],comment:'',loaderFlag:false});
        store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
      }
    }

  }


  changeComment = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  }

  deleteComment = (e,id) =>{
    confirmAlert({
      title: '',
      message: 'Are you sure you want to delete this record?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteCommentApi(e,id)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  deleteCommentApi = async (e,id) => {
    this.setState({loaderFlag:true});
    var bodyFormData = {'id':id};
    let res = null;
    try{
      res = await API({
        method: 'Delete',
        url: 'delete/note',
        data: bodyFormData
      })
    }catch(error) {
        //this.setState({error:{error} });
    };
    
    if(res){
      
      if (res.status === 200) {
        let commentData = this.state.commentData.filter((val)=> {if(parseInt(val.id) !== parseInt(id) ){ return val;}});
        this.setState({commentData:commentData,loaderFlag:false});
        store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
      }
    }
  }

  render() {
    
    return (
      <>
          <Row>
          
            <Col xs={12}>
              <div>
                <CardBody>
                  <div class="videoComnts">
                    <div className="commentArea">
                        <Input type="textarea" className="commentText" name="comment" value={this.state.comment} onChange={(e) => (this.changeComment(e))}  />
                        {!this.state.loaderFlag && <Button color="primary" onClick={this.addComent}>Add Note</Button>}
                        {this.state.loaderFlag && <Button color="primary" >Wait...</Button>}
                    </div>
                      <div className="postedComments">
                        {this.state.commentData.map((val, key) => {
                      
                          return (
                            <React.Fragment>
                            <div class="pComment">
                              <p style={{background:"white"}}>{val.notes}</p>  
                              <Button onClick={(e) => (this.deleteComment(e,val.id))} outline color="danger">Delete</Button>                              
                              <small>{val.aus_date +" (AEDT)"}</small>
                              <br/>
                              <hr/>
                            </div>
                            </React.Fragment>        
                          );
                        })}
                      </div>
                  </div>
                   {!this.state.loaderCommentFlag && this.state.commentData.length === 0 && <h4 className="text-center">No data found.</h4>}
                   {this.state.loadMoreFlag && !this.state.load_flag && <Button onClick={(e)=>{this.loadComment()}}>Load more</Button>}
                   {this.state.loaderCommentFlag && <h3 className="text-center">Loading...</h3>}
                </CardBody>
              </div>
            </Col>
            
          </Row>
      
      </>
    );
  }
}

export default UserNote;
