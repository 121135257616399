import React,{Component} from "react";
//import { NavLink, Prompt } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import API, { DEV_ASSETS_SERVER_PATH, ASSETS_SERVER_PATH, MOCK_AUDIO_PATH, MOCK_RESPONSE_PATH,  TEST_ASSETS_SERVER_PATH, LOCAL_ASSETS_SERVER_PATH  } from "api.js";
import PaidUserCheck from "views/PaidUserCheck.jsx";
import {store} from 'store/index';
import { Redirect } from "react-router-dom";
import WaveSurferModuleComponent from "components/Wavesurfer/WaveSurferModuleComponent.jsx";

class MockResponseNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total: 0,
      all_ok_mock_listing: true,
      paidUser:0,
      languageId:this.props.match.params.language_id,
      dialogueKey:0,
      currentQuestion:0,
      currentIndex:0,
      segmentName:"",
      isSegment:false,
      segmentIndex:-1,
      totalSegments:0,
      msg:"Are you sure you want to Submit & then exit this mock test? You cannot re-attempt after this.",
      segmentCounter:0,
      mockAttemptId:this.props.match.params.mock_attempt_id,
      mockId:this.props.match.params.mock_id,
      audioEnded:false,
      segmentHeading:"",
      dialogueIndexChange:false,
      nextAvailable:true,
      nextBtnClickCount:1,
      redirect:false,
      segmentAudioBlob:null,
      segmentResponseAudioBlob:null,
      segmentUserResponseAudioBlob:null,
      loadedAudio:false,
      staticMockAudioData:[
        {
          "4": "upload-e716dc235652f9c99ce465795d12b55a_mock_2_4_combine.mp3"
        },
        {
          "24": "upload-c000cb6d4dfc057c7c67e394030ffe7f_mock_4_24_combine.mp3"
        },
        {
          "25": "upload-6766703d3577f85470cb2f8048fa711f_combine_mock_25.mp3"
        },
        {
          "45": "upload-05977da2b8155a1f9027c6998c46e4da_mock_7_45_combine.mp3"
        },
        {
          "64": "upload-1fd38d4618ffe33ed99043ca8ee9a276_mock_9_64_combine.mp3"
        },
        {
          "84": "upload-ad9595908ec0e22e678209749dc5bcc1_mock_11_84_combine.wav"
        },
        {
          "103": "upload-71ef35ee16bfaa2e221d88d714c6b957_mock_13_103_combine.mp3"
        },
        {
          "139": "upload-109e29744db88d90a7a25cc6a8a2f4f8_mock_16_139_combine.mp3"
        },
        {
          "159": "upload-fb8d584c9352fa4591da80875afce9b0_mock_18_159_combine.mp3"
        },
        {
          "178": "upload-a6f559e1ffaf2d707952c867125e6faa_mock_20_178_combine.mp3"
        },
        {
          "196": "upload-235a9a7b6bff889accd1fa1b0449dbd7_mock_combine22_1.mp3"
        },
        {
          "216": "upload-75dfad323133927ec434ec5f6d8bd0d7_mock2_combine.mp3"
        },
        {
          "235": "upload-56f09b6acc85e3a2874be63473432ab3_mock3_combine.mp3"
        },
        {
          "255": "upload-138ec816b66f96f50580de2907684f3f_mock_combine_28_1.mp3"
        },
        {
          "274": "upload-1afeedd4a976c5ad2a332642d31c41a2_mock_combine_30_1.mp3"
        },
        {
          "292": "upload-cada4c7a124681bfa10765424d812617_mock_32_292_combine.mp3"
        },
        {
          "312": "upload-f2f786fd5e2517881c1a7d5907175597_mock_34_312_combine.mp3"
        },
        {
          "331": "upload-be2a11c954336e19b26379417aceece1_mock_36_331_combine.mp3"
        },
        {
          "351": "upload-f799be5db0428975ad8210fda5fb4fd2_mock_38_351_combine.mp3"
        },
        {
          "360": "upload-035ff5be55a1bb00d3650c45eb83326c_mock_39_360_combine.mp3"
        },
        {
          "381": "upload-e28e399d6e656dd11ba14ef7cefbd218_mock_41_381_combine.mp3"
        },
        {
          "408": "upload-a89446ee14517dc4112e0cc7ee45522f_mock_44_408_combine.mp3"
        },
        {
          "427": "upload-39ff490dd800b876ab15e59edada74f1_mock_46_427_combine.mp3"
        },
        {
          "447": "upload-0d932a6158b234490d3755c2829d927f_mock_48_447_combine.mp3"
        },
        {
          "466": "upload-054b5564e3e4598416b1bd9f7a87afdd_mock_50_466_combine.mp3"
        },
        {
          "484": "upload-aeb2dd52a7985cdd78b999656fd50e08_mock_52_484_combine.mp3"
        },
        {
          "504": "upload-bfd56311717c45726c65ab11f3668ced_mock_54_504_combine.mp3"
        },
        {
          "523": "upload-5997bd8f90971d02d8edb9264c0efb29_mock_56_523_combine.mp3"
        },
        {
          "553": "upload-daeafe7827a1794249797d32eabaca31_mock_59_553_combine.mp3"
        },
        {
          "571": "upload-57f019bd2f990525c561cf502cf9b285_mock_61_571_combine.mp3"
        },
        {
          "580": "upload-3c1195b516c4029652f3513a08e2baaa_mock_62_580_combine.mp3"
        },
        {
          "600": "upload-f0aa27bd5f24c53a03610b5fb322a6f2_mock_64_600_combine.mp3"
        },
        {
          "619": "upload-27fdadcf1c79de11a5bdc0622f979507_mock_66_619_combine.mp3"
        },
        {
          "639": "upload-d6649aa822516e5d9680bc222d6d7d41_mock_68_639_combine.mp3"
        },
        {
          "658": "upload-c4937fa8dad0d363764b1be14315ec9c_mock_70_658_combine.mp3"
        },
        {
          "788": "upload-99cfceb544a295979acd831cbf7bd81a_mock_82_788_combine.mp3"
        },
        {
          "799": "upload-4a94b3f42850cb6edf0e9045134d6455_mock_83_799_combine.mp3"
        },
        {
          "808": "upload-6d8d0ac49e62a2c79b6a08db33f72ff8_mock_84_808_combine.mp3"
        },
        {
          "817": "upload-9a27cbae512971a923c761fa7dd4f61c_mock_85_817_combine.mp3"
        },
        {
          "827": "upload-99341659592005e1e534dddca5a4b15d_mock_86_827_combine.mp3"
        },
        {
          "838": "upload-5880cff23842d66098cea6b5c7e7a08f_mock_87_838_combine.mp3"
        },
        {
          "847": "upload-cbe7675b808546bb0c13725282ad930b_mock_88_847_combine.mp3"
        },
        {
          "856": "upload-a75efc4c859ae1a4b8d5cd08c68c8f89_mock_89_856_combine.mp3"
        },
        {
          "866": "upload-1b57cdc1556400befbc9c42b11b443a5_mock_90_866_combine.mp3"
        },
        {
          "875": "upload-520e05e21325e4587733e3c64b630d7c_mock_91_875_combine.mp3"
        },
        {
          "884": "upload-31a75f3252f5bc271959bb515e28b727_mock_combine22_2.mp3"
        },
        {
          "893": "upload-68a53354b78dc1db8a295088a3c0bdbc_mock2_2_combine.mp3"
        },
        {
          "903": "upload-f9f95fd59efcc89ddb69c56f6f4fad54_mock_94_903_combine.mp3"
        },
        {
          "912": "upload-43c0b8e6c2ee57f1ed21bfeccd543131_mock_combine_28_2.mp3"
        },
        {
          "922": "upload-3d5806ca324589a4adda825725815e65_mock_combine_30_2.mp3"
        },
        {
          "931": "upload-c64ba733bf70dec5c6648f6cc052b95f_mock_97_931_combine.mp3"
        },
        {
          "940": "upload-efb2d5be3fa6cbd83258aeaf12d63f39_mock_98_940_combine.mp3"
        },
        {
          "950": "upload-4267e283bf4ac118edbcd00c61aa16b9_mock_99_950_combine.mp3"
        },
        {
          "959": "upload-5e27bbc072910c572686e2521a864480_mock_100_959_combine.mp3"
        },
        {
          "969": "upload-fcfdcfaba495407314404d4b3d4938bb_mock_101_969_combine.mp3"
        },
        {
          "978": "upload-efd2c90ff975ecfb205b3867822ae00b_mock_102_978_combine.mp3"
        },
        {
          "987": "upload-272ff78fe26964d3816a3ad81f94d829_mock_103_987_combine.mp3"
        },
        {
          "997": "upload-51e2973b59e59eef9373ed4353a9dce8_mock_104_997_combine.mp3"
        },
        {
          "1006": "upload-2ec70dab933a61d24322c0e60da8c141_mock_105_1006_combine.mp3"
        },
        {
          "1016": "upload-d0945b447756d0478a1043d7311848b5_mock_106_1016_combine.mp3"
        },
        {
          "1025": "upload-68658dd08d9b603a80dd7ebcfc08aa1f_mock_107_1025_combine.mp3"
        },
        {
          "1034": "upload-4519fde2f295b629af0fec8cc1c0e424_mock_108_1034_combine.mp3"
        },
        {
          "1044": "upload-871e14e9fef004963f0ad03ad58ff10c_mock_109_1044_combine.mp3"
        },
        {
          "1053": "upload-76b70c0004e297234d9f4202309e9805_mock_110_1053_combine.mp3"
        },
        {
          "1063": "upload-bf4750ef9cc5a92b631c97c3cec989d1_mock_111_1063_combine.mp3"
        },
        {
          "1072": "upload-4998d09c8db7405899a232e120773a02_mock_112_1072_combine.mp3"
        },
        {
          "1081": "upload-44192c464329bbf093053eb2ad3cbbe3_mock_113_1081_combine.mp3"
        },
        {
          "1091": "upload-0ebec33fd77ac425463849aec81f346e_mock_114_1091_combine.mp3"
        },
        {
          "1100": "upload-53a9d4f8f0b50661980d615c6aa4c3ef_mock_115_1100_combine.mp3"
        },
        {
          "1110": "upload-78c90e01c64dfe61b7005304f8279c63_mock_116_1110_combine.mp3"
        },
        {
          "1119": "upload-92f993737efc91c342d41ab8d33e822d_mock_117_1119_combine.mp3"
        },
        {
          "1128": "upload-2e67de5b72a7b12781369365d29d0993_mock_118_1128_combine.mp3"
        },
        {
          "1138": "upload-835fcfd698e29882b745493b2531e739_mock_119_1138_combine.mp3"
        },
        {
          "1147": "upload-089e350482ef1a3a73bada4493e05f63_mock_120_1147_combine.mp3"
        },
        {
          "1157": "upload-3bbe6a24054325c5ffe9b9ddc06f3678_mock_121_1157_combine.mp3"
        },
        {
          "1175": "upload-f6b69f17b8f7e0027ead90f5f69a5d5d_mock_12_1175_combine.mp3"
        },
        {
          "1184": "upload-1715adccbe65c9f5225e137496e05c0d_mock_124_1184_combine.mp3"
        },
        {
          "1194": "upload-eb568c02d5f211956596db6be961e8b5_mock_125_1194_combine.mp3"
        },
        {
          "1203": "upload-efc636220d2667de878702fec181295b_mock_126_1203_combine.mp3"
        },
        {
          "1213": "upload-c0adfa7f5b863b342e11c94998f3c11e_mock_127_1213_combine.mp3"
        },
        {
          "1222": "upload-5597bc64df29f3ac903476bfd38341c4_mock_128_1222_combine.mp3"
        },
        {
          "1233": "upload-5725b571dc1b9e603d1eb6ff3f9c73e2_mock_129_1233_combine.mp3"
        },
        {
          "1242": "upload-6140a349d7b4da0c3f269d251b38b391_mock_130_1242_combine.mp3"
        },
        {
          "1251": "upload-441c816089bdd62d51d6af0ea60a135d_mock_131_1251_combine.mp3"
        },
        {
          "1261": "upload-f86ef68969a3156aee9010463e3b8098_mock_132_1261_combine.mp3"
        },
        {
          "1272": "upload-839cee66d93b37ad336076dcecb8eb50_mock_133_1272_combine.mp3"
        },
        {
          "1282": "upload-25b30c76aec7c12b83648ddcc7d9dbb4_mock_134_1282_combine.mp3"
        },
        {
          "1290": "upload-1455411f1990f2deaf2a58309a15b1b9_mock_135_1290_combine.mp3"
        },
        {
          "1300": "upload-cacd015cc2ef9beff155638899c573c3_mock_136_1300_combine.mp3"
        },
        {
          "1309": "upload-f6d0a9248f934a344a1bf7e54c3322ff_mock_137_1309_combine.mp3"
        },
        {
          "1318": "upload-89ee9d0a58620bcc2750ab09068e5890_mock_138_1318_combine.mp3"
        },
        {
          "1329": "upload-fb8557bb3841ca8237776d2109632563_mock_139_1329_combine.mp3"
        },
        {
          "1338": "upload-ccd1700728085cb8bb5bbee1ad7e5b5b_mock_140_1338_combine.mp3"
        },
        {
          "1347": "upload-86095f795da6f6458ba6f49246e26f02_mock_141_1347_combine.mp3"
        },
        {
          "1357": "upload-2814fe05f9f2084437624283820b9a1a_mock_142_1357_combine.mp3"
        },
        {
          "1368": "upload-a669d986d288def0016f7733991d2de0_mock_143_1368_combine.mp3"
        },
        {
          "1377": "upload-1cfed0bebde56d69a655464a22f232c4_mock_144_1377_combine.mp3"
        },
        {
          "1386": "upload-d8dbf2c4c6802a7c0332b4e589820e24_mock_145_1386_combine.mp3"
        },
        {
          "1396": "upload-c8eb2ae11461a0aad861d775f32d8e33_mock_146_1396_combine.mp3"
        },
        {
          "1405": "upload-7dab6d2176ac53b6bd3ce54e8cdbe170_mock_147_1405_combine.mp3"
        },
        {
          "1414": "upload-a68f9a39d172e10ddb702290d5f318a1_mock_148_1414_combine.mp3"
        },
        {
          "1425": "upload-9e7c07ac37fc8167f7dbe5d5905fc14d_mock_149_1425_combine.mp3"
        },
        {
          "1434": "upload-f1891a11a5e3db56873521a4cfa06993_mock_150_1434_combine.mp3"
        },
        {
          "1443": "upload-e26f0256185573274dead85dbf6a5e72_mock_151_1443_combine.mp3"
        },
        {
          "1453": "upload-aa0fa3fa10bff3aaa98ce2a8274c3188_mock_152_1453_combine.mp3"
        },
        {
          "1464": "upload-80ac3226f3ef6ed35691c7d1a2fb059a_mock_153_1464_combine.mp3"
        },
        {
          "1473": "upload-a6f21064c571963a8954875061b42f8d_mock_154_1473_combine.mp3"
        },
        {
          "1482": "upload-64567d71f220cb86d59b57f1d5342b42_mock_155_1482_combine.mp3"
        },
        {
          "1492": "upload-e1248c13cea28353d39f5a13c014faa2_mock_156_1492_combine.mp3"
        },
        {
          "1501": "upload-591da17dea840765d0cd5503d0770295_mock_157_1501_combine.mp3"
        },
        {
          "1510": "upload-8fb61fcad51fd705cb846175ff3a55d5_mock_158_1510_combine.mp3"
        },
        {
          "1521": "upload-aff85beb71ef6c8325cfdcb731ef9fff_mock_159_1521_combine.mp3"
        },
        {
          "1530": "upload-aaefa2e80b987e6763b5bb654c12d153_mock_160_1530_combine.mp3"
        },
        {
          "1539": "upload-34ee2f154a8c8cb655acba27c2b40b73_mock_161_1539_combine.mp3"
        },
        {
          "1549": "upload-ce8c7acca82ee2138e3dab58653e85df_mock_162_1549_combine.mp3"
        },
        {
          "1560": "upload-4d2173ba2581f099e828655dd240dd1a_mock_163_1560_combine.mp3"
        },
        {
          "1569": "upload-59bbc84fb47dbb48193a494fef83dfcd_mock_164_1569_combine.mp3"
        },
        {
          "1578": "upload-6155f64bca2260eae684b3463846519f_mock_165_1578_combine.mp3"
        },
        {
          "1588": "upload-360d2bc261f1481b3d99ce067a8099be_mock_166_1588_combine.mp3"
        },
        {
          "1597": "upload-e78c2161f5f39e0748ede53198fab88b_mock_167_1597_combine.mp3"
        }
      ]
    };
  }

  async componentDidMount() {
    let stateData = store.getState();
    // check & state paid user or not
    this.setState({paidUser:stateData.user.user_paid_plan_id !== null?1:0});
    // check & state language id
    this.setState({languageId:stateData.user.language_id});
    // get mock test responses information
    await this.fetchMockTestResponse();
  }
  
  findStaticMockAudio = (id) => {
    //console.log("FSMA", id)
    let audioPath = this.state.staticMockAudioData.filter(obj=>{
      if (obj[id] !== undefined){
        return obj[id];
      }
    }).map(item =>{
      return item[id];
    });
    //console.log("FSMA OUT", audioPath[0] )
    return audioPath[0];
  }

  handleSegmentNavigation = (index, action="next") => {
    let segmentIndex = 1;
    this.setState({
      nextBtnClickCount:this.state.nextBtnClickCount+1,
      audioEnded:false,
      loadedAudio:false,
    })
    if (action === "next"){
      if (this.state.dialogueIndexChange === false)
        segmentIndex += index;
      else{
        if (this.state.nextAvailable === false)
          segmentIndex = 0;
        else
          segmentIndex += index;     
      }
    }
    this.setState({
      segmentIndex:segmentIndex
    })
    if (segmentIndex < this.state.segmentCounter){
      this.navigateSegment(segmentIndex);
    }
    if (segmentIndex === this.state.segmentCounter)
      this.navigateSegment(-2);
  }

  navigateSegment = async(index) => {
    //console.log("NRRT SIF", index, this.state.nextBtnClickCount, this.state.audioEnded)
    let dialogueSegmentAudio = "";
    this.setState({audioEnded: false})
    let segmentId = "";
    //console.log("NS", index)
    if (index === -1){
      this.setState({segmentName: ""})
      this.setState({segmentAudio: ASSETS_SERVER_PATH + MOCK_AUDIO_PATH + this.state.data[this.state.dialogueKey].description_audios.start.audio_path})
      this.setState({segmentTranscript: this.state.data[this.state.dialogueKey].description_audios.start.transcript})
      this.setState({isSegment: false})
      dialogueSegmentAudio = this.state.data[this.state.dialogueKey].description_audios.start.audio_path;

    }
    else if (index === -2){
      this.setState({segmentName: ""})
      this.setState({segmentAudio: ASSETS_SERVER_PATH + MOCK_AUDIO_PATH + this.state.data[this.state.dialogueKey].description_audios.end.audio_path})      
      this.setState({segmentTranscript: this.state.data[this.state.dialogueKey].description_audios.end.transcript})
      this.setState({isSegment: false})
      dialogueSegmentAudio = this.state.data[this.state.dialogueKey].description_audios.end.audio_path;
    }
    else{
      let mockDialSegs = this.state.data[this.state.dialogueKey].mock_dialogue_segments;
      let currentQuestionIndex = index+1;
      if (currentQuestionIndex <= mockDialSegs.length){
        this.setState({segmentHeading:this.state.data[this.state.dialogueKey].title});

        this.setState({isSegment: true, nextAvailable:true})
        segmentId = mockDialSegs[index].id;
        this.setState({segmentId: segmentId})

        this.setState({segmentName: "Question " + currentQuestionIndex + " of " + mockDialSegs.length})
        this.setState({segmentAudio: ASSETS_SERVER_PATH + MOCK_AUDIO_PATH + mockDialSegs[index].audio_path})
        this.setState({segmentTranscript: mockDialSegs[index].transcript})
        dialogueSegmentAudio = mockDialSegs[index].audio_path;
        //this.setState({segmentYourResponseTranscript: mockDialSegs[index].user_mock_test_response.response})
        let userRecordedAudio = mockDialSegs[index].user_mock_test_response.response;
        let userRecordedAudioExt = (userRecordedAudio !==null)?userRecordedAudio.split(".")[1]:"";
        //console.log("BEFORE", userRecordedAudio, userRecordedAudioExt)
        if ((["3gp","m4a", "caf"].includes(userRecordedAudioExt)) && (userRecordedAudio !==null)){
          //console.log("URAE", userRecordedAudio, userRecordedAudioExt);
          let conversionResponse = await this.getConvertedAudio(userRecordedAudio)
          //console.log("CONRES", conversionResponse);
          if (conversionResponse.data.success === true){
            //console.log("DEV CON RES 12", DEV_ASSETS_SERVER_PATH + MOCK_RESPONSE_PATH, conversionResponse.data.data);
            this.setState({segmentYourResponseAudio: ASSETS_SERVER_PATH + MOCK_RESPONSE_PATH + conversionResponse.data.data});
            // get blob for your response
            await this.getSegmentAudioBlob(conversionResponse.data.data, false, true, true)
          }
        }
        else{
          //console.log("ELSE", mockDialSegs[index].user_mock_test_response.response);
          if (mockDialSegs[index].user_mock_test_response.response !== null)
            this.setState({segmentYourResponseAudio: ASSETS_SERVER_PATH + MOCK_RESPONSE_PATH + mockDialSegs[index].user_mock_test_response.response});
          else
            this.setState({segmentYourResponseAudio: null});
        }
        
        this.setState({segmentSampleResponseAudio: ASSETS_SERVER_PATH + MOCK_AUDIO_PATH + mockDialSegs[index].sample_response})
        // get blob for segment response
        await this.getSegmentAudioBlob(mockDialSegs[index].sample_response, true)

        this.setState({segmentSampleResponseTranscript: mockDialSegs[index].sample_transcript})
        /*this.setState({segmentSampleAudio: ASSETS_SERVER_PATH + MOCK_AUDIO_PATH + this.state.data.exam_segments[index].sample_response})
        this.setState({segmentSampleTranscript: this.state.data.exam_segments[index].sample_transcript})*/        
      }
    }
    this.setDialogueKey(index);
    // get blob for segment audio
    if (dialogueSegmentAudio){
      var dialAudio = dialogueSegmentAudio;
      if (segmentId !== ""){
        dialAudio = this.findStaticMockAudio(segmentId);
        dialAudio = (dialAudio !== undefined)?dialAudio:dialogueSegmentAudio;
      }
      await this.getSegmentAudioBlob(dialAudio, false, false, false, true)

    }
  }

  setDialogueKey(index){
    //console.log("SDK",this.state.dialogueKey, index, this.state.total, this.state.data[this.state.dialogueKey].mock_dialogue_segments.length)
    if (index === this.state.data[this.state.dialogueKey].mock_dialogue_segments.length-1){
      this.setState({dialogueKey:this.state.dialogueKey+1, segmentIndex:0,dialogueIndexChange:true, nextAvailable:false})
    }
  }

  fetchMockTestResponse = async () => {
    try {
      const response = await API.get('mock_tests/result/' + this.state.mockAttemptId + '/' + this.state.mockId);
      //console.log("FFTR NEW", response.data.data.reponse_details)
      if (response.data.success && response.data.data.reponse_details){
        // set data state 
        const responseDetails = response.data.data.reponse_details;
        let segmentCounter = 0;
        responseDetails.mock_test_dialogues.forEach(dialogue => {
          segmentCounter = segmentCounter + dialogue.mock_dialogue_segments.length;
        })
        this.setState({data:responseDetails.mock_test_dialogues});
        this.setState({segmentCounter:segmentCounter});
        this.setState({total:responseDetails.mock_test_dialogues.length});
        this.setState({totalSegments:responseDetails.mock_test_dialogues.length});
        this.setState({segmentTranscript: responseDetails.mock_test_dialogues[this.state.dialogueKey].description})
        this.setState({segmentHeading:responseDetails.mock_test_dialogues[this.state.dialogueKey].title});
      } else {
          console.log("ERROR in fetch Mock Test Response : ", response.data);
      }
    } catch (error) {
      console.log("ERROR in fetchMockTestResult : ", error);
      return {"data":{}, "error":true, "is_available": false};
    }
  };

  getConvertedAudio = async (audioName) => {
    try {
      const response = await API.get('convert_audio/' + audioName);
      return response;      
    } catch (error) {
      console.log("ERROR in convert audio : ", error);
    }
  };

  segmentAudioEnded = async() =>{
    //console.log("ENDED", this.state.audioEnded);
    this.setState({audioEnded:true});
  };

  getSegmentAudioBlob = async(segmentAudioPath,
    forSegmentResponse=false,
    forUserResponse=false,
    blobForResponse=false,
    resetChild=false
    ) => {
    const url = 'convert_mock_audio';
    var bodyFormData = new FormData();
    bodyFormData.set('path', segmentAudioPath);
    await API({
        method: 'POST',
        url: url,
        data: {
          'is_response':blobForResponse,
          'file_path':segmentAudioPath
        },
      }).then(response => {
        //console.log("ES MOCK RESPONSE", response)
        if (response.data.success) {
          // for segment
          if (!forUserResponse && !forSegmentResponse){
            //console.log("HERE IN FOR SEG")
            this.setState({segmentAudioBlob:response.data.data.base64, loadedAudio:true})
          }

          // for segment response 
          if (forSegmentResponse)
            this.setState({segmentResponseAudioBlob:response.data.data.base64})
          
          // for user recorded response
          if (forUserResponse)
            this.setState({segmentUserResponseAudioBlob:response.data.data.base64})

          if (resetChild){
            if (this.child){
              this.child.resetComponent(response.data.data.base64, "#waveform", false, this.state.segmentIndex);
            }
            this.forceUpdate();
          }
        } else {
          //showMessage({ message: response.data.message, type: "danger" });
          console.log("ERROR in convert_mock_audio : ", response.data);
        }
    }).catch((error)=>{
      console.log("ERROR IN CATCH", error)
    });
  }

  handlerAttemptStarted = (isStarted=false) =>{
    this.setState({attemptStarted:isStarted});
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/admin/mock/results'} />;
    }
    return (
      <>
        {this.state.paidUser === 0?(<PaidUserCheck/>):null}

        { this.state.all_ok_mock_listing && this.state.paidUser && 
          <div className="content questionsArea ADI">
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs={6}>
                        <CardTitle tag="h4">
                          <span>{this.state.segmentHeading}</span>
                        </CardTitle>
                      </Col>
                      <Col xs={6}>
                        <div className="text-right questionTime">
                          <div className="timeRemaining">
                            
                          </div>
                          <div className="item">
                            <p>{this.state.segmentName}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  
                    <CardBody>
                      <p>{(this.state.isSegment === false)?this.state.segmentTranscript:null}</p>
                      {
                        this.state.isSegment === true&&
                      
                        <Row className='unselectable'>
                          <Card className="descriptionCard px-4 pb-4 w-100" dd={this.state.segmentIndex} >
                            {
                              (this.state.segmentIndex >= 0)?
                              (
                                <div className="currentStatus" id="waveformContainer">
                                  <div className="waveformVolume">
                                    <WaveSurferModuleComponent 
                                      key={1}
                                      audio={this.state.segmentAudioBlob}
                                      containerEle="#waveform"
                                      showAttemptText={false}
                                      hidePlayPause={false}
                                      forInstructions={true}
                                      showRecordBlinker={false}
                                      showPlayingSourceBlinker={false}
                                      autoPlayAllowed={false}
                                      allowRecording={false}
                                      onlyAudioPlayer={true}
                                      onlyRecordedPlayer={false}
                                      onlySamplePlayer={false}
                                      handlerAudioFinish={this.segmentAudioEnded.bind(this)}
                                      ref={instance => { this.child = instance; }}
                                      segmentIndex={this.state.segmentIndex}
                                      //recordForSeconds={this.state.nextSegmentClicked?30000:0} // 30 sec

                                      />
                                  </div>
                                </div>
                                 
                              ):null
                            }
                            {
                              this.state.audioEnded?(
                                <>
                                  <div className="smallContainer descTransTab">
                                    <h3 className="mt-4 mb-0">Description Transcript</h3>
                                    <div className="descTransCntnt"><h4>{this.state.segmentTranscript}</h4></div>
                                  </div>
                                  <div className="smallContainer responseBlock">
                                    <h3 className="mt-4 pageTitle">Your response</h3>
                                   
                                    {
                                      this.state.segmentYourResponseAudio!==null &&   
                                        
                                      <div className="currentStatus" id="waveformContainer">
                                        <div className="waveformVolume">
                                          <WaveSurferModuleComponent 
                                            key={this.state.segmentUserResponseAudioBlob}
                                            audio={this.state.segmentUserResponseAudioBlob}
                                            showAttemptText={false}
                                            hidePlayPause={false}
                                            forInstructions={false}
                                            showRecordBlinker={false}
                                            showPlayingSourceBlinker={false}
                                            autoPlayAllowed={false}
                                            allowRecording={false}
                                            containerEle="#recorded_waveform"
                                            onlyAudioPlayer={false}
                                            onlyRecordedPlayer={true}
                                          />
                                        </div>
                                      </div>
                                    }
                                    {
                                      this.state.segmentYourResponseAudio ===null &&
                                      <p> You did not attempt this question</p>
                                    }
                                  </div>
                                  <div className="smallContainer responseBlock">
                                    <h3 className="mt-4 pageTitle">Your response</h3>
                                    <div className="currentStatus" id="waveformContainer">
                                      <div className="waveformVolume">
                                        <WaveSurferModuleComponent 
                                          audio={this.state.segmentResponseAudioBlob}
                                          showAttemptText={false}
                                          hidePlayPause={false}
                                          forInstructions={false}
                                          showRecordBlinker={false}
                                          showPlayingSourceBlinker={false}
                                          autoPlayAllowed={false}
                                          allowRecording={false}
                                          containerEle = "#sample_waveform"
                                          onlyAudioPlayer={false}
                                          onlySamplePlayer={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ):null                      
                            }

                          </Card>
                        </Row>
                      }
                    </CardBody>
                </Card>
              </Col>
              <Col xs={12}><div className="bottomBtns">
                <Row>
                  <Col>
                  </Col>
                  <Col xs={3}>
                    {
                      (this.state.nextBtnClickCount <= this.state.segmentCounter)?(
                        <Button 
                          dd = {this.state.segmentIndex}
                          color="primary"
                          onClick={(e) => this.handleSegmentNavigation(this.state.segmentIndex, "next")}
                          size="xs"
                          disabled={this.state.loadedAudio === false?((this.state.segmentIndex < 0)?false:true):false}
                          >NEXT</Button>
                        ):(

                        <Button color="primary" onClick={()=>{ this.setState({redirect:true}) }} size="xs">BACK</Button>
                      )
                    }
                  </Col>              
                </Row>
                </div>
              </Col>
            </Row>
          </div>
        }
      </>
    );
  }
}

export default MockResponseNew;