import React,{Component} from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Table,
  Row,
  Col,
  Button,
  Modal,
} from "reactstrap";
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import PaidUserCheck from "views/PaidUserCheck.jsx";
import { thead, tbody } from "variables/general";
import {store} from 'store/index';
import { setAlert} from 'action/index.js';
import { Redirect } from "react-router-dom";

class MockLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total: 0,
      page: 1,
      search: '',
      count: 15,
      mark: 'all',
      attempted: 'all',
      activeTab: 1,
      type: this.props.match.params.id,
      prediction: 1,
      all_ok_mock_listing: true,
      paidUser:0,
      languageId:1,
      resetModal:false,
      selectedMockId:0,
      redirectToMock:false,
      showResult:false,
      showTest:false,
      selectedMockTitle:"",
      selectedMockDuration:20

    };
  }

  componentDidMount() {
    let stateData = store.getState();
    // check & state paid user or not
    //this.setState({paidUser:stateData.user.user_paid_plan_id !== null?1:0});
    this.setState({paidUser:((stateData.user.user_paid_plan_id === null) || (stateData.user.user_paid_plan_id === undefined))?0:1});

    this.chromeFunc();
    // check & state language id
    this.setState({languageId:stateData.user.language_id});
    // get dialogues
    this.getMockTests(stateData.user.language_id);
  }

  chromeFunc = () => {
    if (![1,2,3,4,5].includes(parseInt(this.state.type))) {
      return true;
    }

    let isChrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
    if (navigator.getUserMedia === undefined) {
      this.setState({all_ok_mock_listing:false});
    } else {
      navigator.getUserMedia({ audio: true }, () => {
        this.setState({all_ok_mock_listing:isChrome});
      }, () => {
        this.setState({all_ok_mock_listing:false});
      });
    }
  }

  getMockTests = async(languageId=this.state.languageId) => {
    try{
      const response = await API({
        method: 'GET',
        url: '/mock_tests/'+languageId
      });
      if (response.data.success && response.data.data.mock_tests) {
        let mockInfos = response.data.data.mock_tests;
        for (const eachMock of mockInfos) {
          // check whether user has attempted the mock or not
          let attemptResponse = await this.checkAttemptAndNavigate(eachMock);
          if (attemptResponse["error"] !== true)
            eachMock["attempt"] = attemptResponse["isAttempt"]
          else
            eachMock["attempt"] = attemptResponse["isAttempt"]
        };

        this.setState({
          data:  response.data.data.mock_tests,
          total: response.data.data.mock_tests.length
        });
      }
      /*else if (response.data.success && response.data.data.have_access !== undefined && response.data.data.have_access === false) {
        console.log("PURCHASE PLAN ERROR in have_access : ", response.data);
      }
      else {
        console.log("ERROR in fetch mock test : ", response.data);
      }*/
    }catch(error) {
      this.setState({error:{error} });
    };
  }

  /**
   * Check if user have already attempted mock test
   * 
   * @param {*} mock 
   */
  checkAttemptAndNavigate = async(mock) => {
    try {
      const response = await API.get('check_user_attempt/' + this.state.languageId + '/' + mock.id);
      if (response.data.success && response.data.data.user_attempt === null) {
        return {"error":false, "isAttempt":false};
        // redirect to test screen
      } else if (response.data.success && response.data.data.user_attempt) {
        // redirect to feedback screen cz user attempted the test
        return {"error":false, "isAttempt":true};
      }
    } catch (error) {
      console.log("ERROR in fetchMockTests : ", error);
      return {"error":true, "isAttempt":""};
    }
  };

  redirectToResult = async(mockId) => {
    console.log("RTR", mockId)
  }

  // reset selected mock test progress
  resetMock = async() =>{
    try{
      const response = await API({
        method: 'DELETE',
        url: '/mock/reset/'+this.state.selectedMockId+'/'+store.getState().user.user_id
      });
      if (response.data.success) {
        this.setState({resetModal:false, redirectToMock:true})
        store.dispatch(setAlert({'flag':true,'type':1,'message':"Mock reset successfully!"}));

        //this.forceUpdate();
      }
    }catch(error) {
      this.setState({error:{error} });
    };
  }

  render() {
    let { page, count } = this.state;
    
    if (this.state.redirectToMock === true){
      window.location.reload(false);
    }

    if (this.state.showResult === true){
      return <Redirect to={`/admin/mock/feedback/${this.state.selectedMockId}/${this.state.languageId}`} />;
    }
    
    if (this.state.showTest === true){
      return <Redirect to={`/admin/mock/attempt/${this.state.selectedMockId}/${this.state.languageId}/${this.state.selectedMockDuration}/${this.state.selectedMockTitle.split("#")[1]}`} />;
    }

    return (
      <>
        {this.state.resetModal && <Modal isOpen={this.state.resetModal} id="examDatePopup" size="md">
        <div className="modal-body">
          <button onClick={(e)=>this.setState({resetModal:!this.state.resetModal})} type="button" className="close" data-dismiss="modal">&times;</button>
          <div className="mobileIcon">
            <Card>
              <CardTitle><h3>RESET MOCK</h3></CardTitle>
              <CardBody>
                Are you sure you want to "RESET" this {this.state.selectedMockTitle} ?
              </CardBody>
              <CardFooter>
                <button 
                  className="btn"
                  onClick={()=>this.resetMock()}
                >
                  Reset Mock
                </button>                
                <button className="btn cancelBtn" onClick={(e)=>this.setState({resetModal:false})}>Cancel</button>
              </CardFooter>
            </Card>
          </div>
          <div className="contentInr">    
          </div>
        </div>
        </Modal>}
        {!this.state.all_ok_mock_listing && <ChromeCheck />}
        {this.state.paidUser === 0?(<PaidUserCheck/>):null}        
        {this.state.all_ok_mock_listing && this.state.paidUser &&
          <div className="content">
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4"><span>Mock Tests</span></CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <Table>
                        <thead className="text-primary">
                          <tr>
                            <th className="">Title</th>
                            <th className="">Duration</th>
                            <th className="">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map((prop, key) => {
                            return (
                              <tr key={key}>
                                <td className="">{prop.title}</td>
                                <td className="">{prop.test_duration}</td>
                                <td className="" >
                                {
                                  (prop.attempt === true)?(
                                    <>
                                      {/*<NavLink
                                        to={`/admin/mock/feedback/${prop.id}/${this.state.languageId}`}
                                        className="btn btn btn-secondary btn-md"
                                        activeClassName="active"
                                      >
                                        <i className="" />
                                        <p>See Result</p>
                                      </NavLink>*/}
                                      <Button className="btn" size="md" onClick={()=>this.setState({showResult:true, selectedMockId:prop.id})}>See Result</Button>
                                      <Button className="btn" size="md" onClick={()=>this.setState({resetModal:true, selectedMockId:prop.id, selectedMockTitle:prop.title })}>Reset</Button>
                                    </>
                                  ):(
                                    /*<NavLink
                                      to={`/admin/mock/attempt/${prop.id}/${this.state.languageId}/${prop.test_duration}/${prop.title.split("#")[1]}`}
                                      className="btn btn btn-secondary btn-md"
                                      activeClassName="active"
                                      onClick={()=>document.body.classList.add("sidebar-mini")}
                                    >
                                      <i className="" />
                                      <p>Take Test</p>
                                    </NavLink>*/
                                     <Button className="btn" size="md" onClick={()=>this.setState({showTest:true, selectedMockId:prop.id, selectedMockDuration:prop.test_duration, selectedMockTitle:prop.title })}>Take Test</Button>
                                  )
                                }
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        }
      </>
    );
  }
}
export default MockLists;
