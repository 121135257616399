import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Alert,
  Col,Button,
  CardFooter,
} from "reactstrap";

import API from "api.js";
import SimpleReactValidator from 'simple-react-validator';
import { setAlert} from 'action/index.js';
import { setUser} from 'action/index.js';
// core components
import {store} from 'store/index';
import { connect } from 'react-redux';
const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};
class Mock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      error_bag:null,
      message: null
    };
    this.validator = new SimpleReactValidator();
  }

  async componentDidMount() {
    // fetch profile data from api
    await this.getProfileData();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

  getProfileData = async() => {
    try{
      const response = await API({
        method: 'GET',
        url: '/profile'
      })
      let userData = {};
      if (response.status === 200){
        userData = response.data.data.profile_details;
        // manipulate profile data
        var splittedName = userData.full_name.split(" ");
        var firstName    = (splittedName.length) > 2?splittedName[0]+" "+splittedName[1]:splittedName[0];
        var lastName     = (splittedName.length) > 2 ? splittedName[2]: ((splittedName.length) === 2?splittedName[1]:"");
        this.setState({
          first_name:firstName,
          last_name:(lastName !== "null")?lastName:"",
          phone:userData.mobile,
          email:userData.email,
        });
      }
      return userData;
    }catch(error) {
      this.setState({error:{error} });
    };
  }

  bookTrialClass = async(e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
    this.setState({error_bag:null});
    if ((this.state.message === "") || (this.state.message === null)){
      store.dispatch(setAlert({'flag':true,'type':2,'message':"Enquiry cannot be empty."}));
      return true;
    }

    e.preventDefault();

    let res = null;
    const postData = { enquiry: this.state.message };
    try{
      res = await API({
        method: 'POST',
        url: '/enquiry',
        data: postData
      })
    }catch(error) {
        this.setState({error:{error} });
    };
    
    if (res.status === 200){
      store.dispatch(setAlert({'flag':true,'type':1,'message':"Thank You ! One of our consultants will be with you soon!"}));
      this.setState({message:""});
    }
    else{
      store.dispatch(setAlert({'flag':true,'type':2,'message':"Some error occurred while creating enquiry. Please retry again."}));
      this.setState({error:res.message });
    }
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                  <CardTitle tag="h4"><span>Online Coaching</span></CardTitle>
                </CardHeader>

                <CardBody>
                  <div className="profileOuter">
                    <div className="cancel_sub_div">
                    {store.getState().is_main && this.state.subscriptionId && this.state.subscriptionId !== "sub_demo" && <button className="btn1 btn-danger" onClick={(e)=>{this.cancelSub()}}>Cancel Subscription</button>}
                    {store.getState().is_main && this.state.subscriptionId && this.state.subscriptionId !== "sub_demo" && <span className="cancelsubscriptionspan">If cancelled now, you won’t have the subscription access from that moment!</span>}
                    </div>
                <Form>

                    <Row>
                      <Col className="" md="5" sm="12">
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            defaultValue={this.state.first_name}
                            name="first_name"
                            placeholder="First Name"
                            type="text"
                            readOnly={true}
                          />
                          {this.validator.message('first name', this.state.first_name, 'required')}
                        </FormGroup>
                      </Col>
                      <Col className="" md="5" sm="12">
                        <FormGroup>
                          <label>Last Name</label>
                          <Input
                            defaultValue={this.state.last_name}
                            name="last_name"
                            placeholder="Last Name"
                            type="text"
                            //readOnly={true}
                          />
                          {this.validator.message('last name', this.state.last_name, 'required')}
                        </FormGroup>
                      </Col>

                      <Col className="" md="5" sm="12">
                        <FormGroup>
                          <label>Phone</label>
                          <Input
                            defaultValue={this.state.phone}
                            name="phone"
                            placeholder="Phone"
                            type="text"
                            readOnly={true}
                          />
                          {this.validator.message('phone', this.state.phone, 'required')}
                        </FormGroup>
                      </Col>
                      <Col className="" md="5" sm="12">
                        <FormGroup>
                          <label>Email</label>
                          <Input
                            defaultValue={this.state.email}
                            name="email"
                            placeholder="Email"
                            type="text"
                            readOnly={true}
                          />
                          
                        </FormGroup>
                      </Col>
                      <Col className="" md="5" sm="12">
                        <FormGroup>
                          <label>Your message</label>
                          <Input
                            defaultValue={this.state.message}
                            name="message"
                            placeholder="Your message"
                            type="textarea"
                            required={true}
                            onChange={(e) => { this.changeInput(e)} }
                          />                          
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  </div>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-round"
                    color="info"
                    onClick={this.bookTrialClass}
                  >
                    Book my trial class
                  </Button>
                </CardFooter>
              </Card>
            </Col>

          </Row>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mock);
