import React,{Component} from "react";
import {
  Col,
  Progress,
  Button,
  Input
} from "reactstrap";
import SpeechRecognition from 'react-speech-recognition';
import Dictaphone from "./Dictaphone.jsx";
import {s3base} from "api.js";
import NoMicrophone from "views/NoMicrophone.jsx";
import Sound from 'react-sound';
import getBlobDuration from 'get-blob-duration';
import MicRecorder from 'mic-recorder-to-mp3';
import "components/custom.css";
let Mp3Recorder = new MicRecorder({ bitRate: 128 });
class RepeatSentece extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:3,
      secondCount2:0,
      recordId:1,
      intervalId:0,
      intervalId2:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      soundPer:0,
      volume:100,
      trans:'',
      correctFlag:true,
      beepStatus:false,
      file:'',
      duration:0,
      recordingDuration:0,
      currentIndex:"",
      currentDialogueKey:"",
      nowRecording:false,
      nowRecordingId:0,
      nowRecordingTimer:0,
      recordCount:0,
      nowRecordingStartIn:0,
      intervalRecId:0,
      recordIntervalId:1,
      soundEnded:false



    };
  }
  componentDidMount() {

    //  console.log("PROPS RS", this.props)
    if (!this.props.answer) {
  	 var intervalId = setInterval(this.timer, 1000);
	   this.setState({intervalId: intervalId});
  	}
    this.setState({soundEnded: false});
    
    navigator.getUserMedia({ audio: true },
      () => {
        console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
        console.log('Permission Denied');
        this.setState({ isBlocked: true })
      },
    );
  }

  static getDerivedStateFromProps(props, state) {
    //console.log("GDSFP", state.currentIndex, props.index)
  	if (props) {
      if (state.currentIndex !== ""){
    		if ((state.currentIndex >= 0 && state.currentIndex !== props.index)) {
          clearInterval(state.recordIntervalId);
          clearInterval(state.intervalRecId);
          //console.log("HERE IN IF")

          /*clearInterval(state.intervalId);
    			clearInterval(state.intervalId2);
    			clearInterval(state.recordId);
          */
    			return {correctFlag:true,recordCount:0,soundPer:0,secondCount:3,secondCount2:0,index: props.data.index,blobURL:'',trans:'',duration:0,reset:true,soundStatus:true,intervalId2:0, recordIntervalId:0, intervalRecId:0, isRecording:false, soundEnded:true };
    		}else{
    			if (!state.currentIndex) {
            console.log("INNER ELSE OF CURREMT INDEX")
    				/*return {correctFlag:true,currentIndex: props.data.index, currentDialogueKey:props.data.dialogueKey};*/
    			}
    		}
      }
  	}
  }


  start = () => {
    //console.log("STARTED")
    //this.setState({ beepStatus:true});
    SpeechRecognition.startListening({ continuous: true, language: 'en-GB' });
    Mp3Recorder = new MicRecorder({ bitRate: 128 });
    Mp3Recorder.start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',beepStatus:false});
        }).catch((e) => console.error(e));
  };

  handleBeepFinishedPlaying = () =>{
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      console.log("IN ELSE")

      //this.setState({ isRecording: true,blobURL:'',beepStatus:false});
      /*Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',beepStatus:false});
        }).catch((e) => console.error(e));*/
    }
  }

  stop = () => {
    //console.log("IN STOP")
    if (!this.state.isBlocked && this.state.isRecording) {
      //console.log("IN STOP 160")
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          let duration = await getBlobDuration(blobURL);
          console.log("STOP REPEAR", blobURL, duration)
          let file = new File([blob], 'noname3.mp3', { type: blob.type });
          this.setState({ blobURL, isRecording: false,recordCount:0,file:file,duration:duration });
          SpeechRecognition.stopListening();
          console.log("AFTER RECORDING STOP", blobURL)
          //this.props.submit({'data': file,'audio_text':this.state.trans, 'duration':duration },true);
          //this.props.showResult();
        }).catch((e) => console.log(e));
    }
  };

  stopTest = async () => {
    //console.log("IN REC STOP TEST")

    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob)
          let duration = await getBlobDuration(blobURL);
          console.log("STOP TEST", blobURL, duration)

          //this.setState({ blobURL, isRecording: false,recordCount:100 });
          SpeechRecognition.stopListening();
          let file = new File([blob], 'noname3.mp3', { type: blob.type });
          console.log("AFTER RECORDING STOP TEST", file)
          //this.props.nextQuestion({'data': file,'audio_text':this.state.trans,duration:duration });
          //this.props.submit({'data':file },true)
          //this.props.showResult();
        }).catch((e) => console.log(e));
    }else{
      console.log("ELSE OF STOP TEST")
      this.props.nextQuestion({'data': this.state.file,'audio_text':this.state.trans, duration: this.state.duration });
    }
  };

  updateCorrect = () =>{
     this.props.submit({'data':'',duration:this.state.duration});
     this.setState({correctFlag:false})
  }

  setTrans = (a)=>{
    if (this.state.trans !== a) {
      this.setState({trans:a})
    }

  }

	timer = () => {
		if (this.state.secondCount === 0) {
			clearInterval(this.state.intervalId);

		}else{
      this.setState({ secondCount: this.state.secondCount -1 });
    }

	}


 /* timer1 = () => {
    console.log("IN TIMER", this.state.secondCount2)
    if (this.state.secondCount2 === 0) {
      clearInterval(this.state.intervalId2);
      this.start();
      var recordId = setInterval(this.timerRecord, 1000);
      this.setState({'soundStatus':false,recordId: recordId});
    }else{
      this.setState({ secondCount2: this.state.secondCount2 -1 });
    }

  }*/

  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

  handleSongFinishedPlaying = () => {
    //var intervalId = setInterval(this.timer1, 1000);
    console.log("SOUNF FINISH")
    this.setState({soundEnded:true})
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      //this.setState({soundStatus:false});
      this.props.soundChecked();
     /* Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',soundStatus:false});
        }).catch((e) => console.error(e));*/
    }
    //this.start();
    //var recordId = setInterval(this.timerRecord, 1000);
    //this.setState({'soundStatus':false,recordId: recordId});
  }

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

  onLoadSound = (obj) =>{
    var intervalRecId = "";
    if (this.state.intervalRecId === 0){
      intervalRecId = setInterval(this.recordingStartTimer,1000);
      this.setState({intervalRecId: intervalRecId});
    }else{
      clearInterval(this.state.intervalRecId)
      intervalRecId = setInterval(this.recordingStartTimer,1000);
      this.setState({intervalRecId: intervalRecId});
    }

  }
  loadingSound = (obj) =>{
    let d = obj.duration/1000-(0.3);
    this.setState({
      currentIndex:this.props.index,
      currentDialogueKey:this.props.dialogueKey,
    });
    if (obj.duration !== 0){
      //
      this.setState({
        nowRecordingStartIn:Math.ceil(d),
        recordingDuration: Math.ceil(d),
        soundStatus:true        
      })
      
    }


    /*if (this.state.intervalId2 !== 0)
      clearInterval(this.state.intervalId2);

    console.log("LOADINF SOUND", obj.duration, this.state.intervalId2, this.timer1)
    if (obj.duration !== 0 && this.state.intervalId2 === 0){
      console.log("YES INNER")
      var intervalId = setInterval(this.timer1, 1000);
      this.setState({
  		  soundStatus: true,
  		  intervalId2: intervalId,
  		  secondCount2: Math.ceil(d),
        recordingDuration: Math.ceil(d),
  	  });
    }*/
  }
  recordingStartTimer = () =>{
    console.log("RST",this.state.nowRecordingStartIn)
    if (this.state.nowRecordingStartIn === 0) {
      clearInterval(this.state.intervalRecId);
      // start the recording time & recording
      console.log("TR",this.state.recordIntervalId)
      if (this.state.recordIntervalId === 1)
        this.timerRecord(true)
      //this.setState({ showRecordingTimer: false });
      this.start();
    }
    else
      this.setState({ nowRecordingStartIn: this.state.nowRecordingStartIn -1 });
  }

   timerRecord = (setNow=false) => {
    if (setNow === false){
      if (this.state.recordCount === this.state.recordingDuration) {
        clearInterval(this.state.recordIntervalId);
        // stop the recording & its timer & show recording voice & original sample
        this.stop();
        
      }
      this.setState({ recordCount: this.state.recordCount + 1 });
    }
    else{
      var recordIntervalId = setInterval(this.timerRecord, 1000);
      this.setState({recordIntervalId: recordIntervalId});
    }
  }

  changeRange = (e) => {
    this.setState({volume: e.target.value});
  }

	componentWillUnmount = () => {
    console.log("UN MOUNT")
		clearInterval(this.state.intervalId);
	}

  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();
    }
  	if (this.props.data) {
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
  		question = this.props.data.question;
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}
    if (this.state.isBlocked) {
      return (<NoMicrophone />);
    }
    return (
      <>
      <Dictaphone setTrans={this.setTrans} status={this.state.reset || this.state.isRecording} />
      <Col xs={12}>
      	<h4 className="infoText"></h4>
      </Col>
      <Col xs={12}>
      	<div className="col-lg-12 p-0 text-center">
        { !this.props.resultMode && <div> <div className="currentStatus">
            <p>Current Status:{this.state.soundStatus === true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
            {this.state.secondCount === 0 && this.state.blobURL === '' && !this.state.isRecording && ' Playing' }

            <br></br>
            Volume
             <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
             <br></br>
            <Progress color="info" value={(this.state.soundPer)} />
            </p>
            {!this.props.testMode && this.state.secondCount!==0 && this.state.blobURL === '' && !this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.skip} size="sm">Skip</Button></div> }

          </div>
  		  <div className="audio-box">
          <h4>Recorded Answer</h4>
          <p><strong>Current status:</strong></p>
          <p> { this.state.blobURL === '' && this.state.isRecording?"Recording started": "" } </p>
          <p> { this.state.nowRecordingStartIn !== 0 && 'Recording starts in '+this.state.nowRecordingStartIn+ ' seconds'  }</p>

          <hr></hr>
          {/*this.state.isRecording && "Recording Time : "+this.state.recordCount*/}
          {!this.props.testMode && this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.stop} size="sm">Stop</Button></div>}
          <hr></hr>
          <Progress color="info" value={(this.state.recordCount*3)} />
          <div className="audo-line"><span className="progress-bar-fill-Read intro"></span></div>
        </div> </div>}
        <Col xs={12}>
          {this.props.data && this.state.isRecording === false && this.state.soundEnded === false && <Sound
            url={this.props.segmentAudio}
            playStatus={Sound.status.PLAYING}
            onPlaying={this.handleSongPlaying}
            volume={this.state.volume}
            onLoading={this.loadingSound}
            onFinishedPlaying={this.handleSongFinishedPlaying}
            onLoad={this.onLoadSound}
          />}
        </Col>
  		  <Col xs={12}>
          <audio src={this.state.blobURL} controls="controls" />
  		  	{ this.state.blobURL && !this.props.testMode && <audio src={this.state.blobURL} controls="controls" />}
  		  </Col>
		  </div>
      </Col>
      <Col xs={12}><h5>{question}</h5></Col>
      {!this.props.resultMode && this.state.beepStatus && <Sound
          url={s3base + "/ptedata/ptemedia/beep.mp3"}
          playStatus={Sound.status.PLAYING}
          onFinishedPlaying={this.handleBeepFinishedPlaying}
        />}
      </>
    );
  }
}
export default RepeatSentece;
