import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import FameWallNaati from '../components/Frontend/FameWallNaati';
//import { Link} from 'react-router-dom';
import VideoTestimonial from '../components/Frontend/VideoNaatiTestimonial';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Naati extends React.Component {
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
 render(){   
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Best NAATI CCL Coaching Classes In Parramatta | Top NAATI CCL Training Institute In Sydney</title>
     	<meta name="twitter:title" content="Best NAATI CCL Coaching Classes In Parramatta | Top NAATI CCL Training Institute In Sydney" />
    	<meta property="og:title" content="Best PTE CCL Coaching Classes In Parramatta | Top NAATI CCL Training Institute In Sydney" />

        <meta name="description" content="Are you searching for the best NAATI coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top NAATI training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading NAATI Coaching centre, contact at +61 426230061." />      
        
        <meta name="twitter:description" content="Are you searching for the best NAATI coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top NAATI training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading NAATI Coaching centre, contact at +61 426230061." />
        <meta property="og:description" content="Are you searching for the best NAATI coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top NAATI training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading NAATI Coaching centre, contact at +61 426230061." />
        
     </Helmet>
    <Header />
    	<div class="innerPage">
        <section class="bannerOuter">
			<div class="container">
				<div class="row">
					<div class="col-sm-12 text-center">
						<h1>Accomplish your dream of Permanent Residency with the LA-Language Academy - Best NAATI CCL exam training centre in Parramatta</h1>
						<span>Enrol in the Best NAATI CCL Preparation Classes and crack the exam with flying colors</span><br />
						<br />
						<br />
						<a href="https://www.languageacademy.com.au/auth/register" class="themeBtn">Contact Us</a>
						<br/>
						<a href="javascript:;" class="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
					</div>
				</div>
			</div>
		</section>
		</div>
		<section class="naatInfo">
			<div class="container">
				<div class="row">
					<div class="col-sm-12 text-center">
						<p>Since its introduction in July 2018, NAATI CCL (Credentialed Community Language) test is rapidly gaining prominence among the aspirants who want to achieve their dream of securing permanent residency in Australia. This exam assesses the candidates' performance based on their ability to comprehend & translate the English dialogues to their chosen LOTE (Language other than English) and vice versa. By getting success in the NAATI CCL exam, they can claim five bonus points on their point-based visa application.</p>

						<p>Do you also want to grab these five CCL points for Australia PR, but are confused about how to optimize your NAATI exam preparation? With a proper strategy, by following the right NAATI test preparation course, and practicing regularly, you can take your exam performance to the peak level. If you need an expert who can guide you throughout during the preparation journey, you can Enrol in one of the top NAATI CCL preparation classes. After having all this by your side, you are all ready to smash the exam.</p>

						<p>Are you searching for the top NAATI CCL training centre in Parramatta that can help you accomplish your dreams? If yes, you are in the right place.</p>
						<div class="coursesInfo text-center">
							<div class="row cardsOuter">
								<div class="col-sm-4">
									<img src="images/intensive-course-book.png" alt="intensive-course-book" />
									<h3>8-week Intensive Course</h3>
									<p>Upto 4 classes per week</p>
								</div>
								<div class="col-sm-4">
									<img src="images/mouse.png" alt="mouse" />
									<h3>Online Intensive Course</h3>
									<p>10 Online Sessions + 1 hr consultation</p>
								</div>
							</div>
							<p>To get free demo classes at our PTE / NAATI coaching centre, you can call us on <a style={{color: 'rgb(81, 203, 206)'}} href="tel:+61426230061">+61 426230061</a> or drop us a mail at <a style={{color: 'rgb(81, 203, 206)'}} href="mailto:info@languageacademy.com.au">info@languageacademy.com.au</a> We would love to help!</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="offeredLang">
			<div class="container">
				<div class="row">
					<div class="col-sm-12 text-center">
						<h2 class="title">Languages We Offer</h2>
						<p>Begin your preparation with the best NAATI course and tackle the exam questions like a pro</p>
						<div class="row cardsOuter">
							<div class="col-sm-4">
								<img src="images/hindi-eng.png" alt="hindi-eng" />
								<h3>Naati Hindi</h3>
								<p>Improve your English-Hindi interpretation skills with the best Hindi NAATI CCL course</p>
							</div>
							<div class="col-sm-4">
								<img src="images/pun-eng.png" alt="pun-eng" />
								<h3>Naati Punjabi</h3>
								<p>Join our NAATI CCL Punjabi training course and be ready to ace the exam</p>
							</div>
							<div class="col-sm-4">
								<img src="images/hindi-eng.png" alt="hindi-eng" />
								<h3>Naati Nepali</h3>
								<p>Get the expert tips to boost your Nepali NAATI CCL exam Preparation</p>
							</div>
							<div class="col-sm-4">
								<img src="images/eng.png" alt="eng" />
								<h3>Naati Urdu</h3>
								<p>Master your preparation for the NAATI CCL Urdu exam with the latest updated practice material</p>
							</div>
							<div class="col-sm-4">
								<img src="images/Bangla.png" alt="Bangla-eng" />
								<h3>Naati Bangla</h3>
								<p>Get the expert tips to boost your Bangla NAATI CCL exam Preparation</p>
							</div>
							<div class="col-sm-4">
								<img src="images/Tamil.png" alt="Tamil-eng" />
								<h3>Naati Tamil</h3>
								<p>Get the expert tips to boost your Tamil NAATI CCL exam Preparation</p>
							</div>
							<div class="col-sm-4">
								<img src="images/Telugu.png" alt="Telugu-eng" />
								<h3>Naati Telugu</h3>
								<p>Get the expert tips to boost your Telugu NAATI CCL exam Preparation</p>
							</div>
							<div class="col-sm-4">
								<img src="images/Malayalam.jpg" alt="Malayalam-eng" />
								<h3>Naati Malayalam</h3>
								<p>Get the expert tips to boost your Malayalam NAATI CCL exam Preparation</p>
							</div>
							<div class="col-sm-4">
								<img src="images/Gujarati.jpg" alt="Gujarati-eng" />
								<h3>Naati Gujarati</h3>
								<p>Get the expert tips to boost your Gujarati NAATI CCL exam Preparation</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="whatWeOffer">
			<div class="container-fluid">
				<div class="row">
					<div class="col-sm-5 p-0 text-center">
						<img src="images/what-we-offer.jpg" alt="what-we-offer" />
					</div>
					<div class="col-sm-7 offerInfo my-auto">
						<h2 class="title"><span>What We Offer?</span></h2>
						<ul>
							<li>Intensive Vocabulary</li>
							<li>Personal 1-1 Feedback</li>
							<li>Online Audio Dialogues</li>
							<li>Regular Mock Exams under Exam Conditions</li>
						</ul>
						<a  href="tel:+61426230061" class="themeBtn">Call : +61 426230061</a>
					</div>
				</div>
			</div>
		</section>
		<br />
		<VideoTestimonial  title="What Our Students Say About Our NAATI CCL Online Classes"/>
		<FameWallNaati />
    <Footer />
    </React.Fragment>);
    }
}

export default Naati;