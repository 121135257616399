import React from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";
class FameWallPte extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false
        }
    }


    componentDidMount() {

    }
 render(){
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1
    };
	return (
		<section className="fameWall">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2 className="title">Score Cards of our PTE students </h2>
                        <p>A candidate’s score on the scorecard of the PTE exam speaks the success rate of the LA-Language Academy Jalandhar PTE centre. Not once, but several times.</p>

                        <Slider {...settings} classNameName="fameSlider regular slider">
                            <div>
                              <img src="/images/pte/pte1.jpg" alt="fame-img-01" />
                            </div>
                            <div>
                              <img src="/images/pte/pte2.jpg" alt="fame-img-02" />
                            </div>
                            <div>
                              <img src="/images/pte/pte3.jpg" alt="fame-img-03" />
                            </div>
                            <div>
                              <img src="/images/pte/pte4.jpg" alt="fame-img-04" />
                            </div>
                            <div>
                              <img src="/images/pte/pte5.jpg" alt="fame-img-05" />
                            </div>
                            <div>
                              <img src="/images/pte/pte6.jpg" alt="fame-img-01" />
                            </div>
                            <div>
                              <img src="/images/pte/pte7.jpg" alt="fame-img-02" />
                            </div>
                            <div>
                              <img src="/images/pte/pte8.jpg" alt="fame-img-03" />
                            </div>
                            <div>
                              <img src="/images/pte/pte9.jpg" alt="fame-img-04" />
                            </div>
                            <div>
                              <img src="/images/pte/pte10.jpg" alt="fame-img-05" />
                            </div>
                            <div>
                              <img src="/images/pte/pte11.jpg" alt="fame-img-05" />
                            </div>
                            <div>
                              <img src="/images/pte/pte12.jpg" alt="fame-img-05" />
                            </div>
                        </Slider>
                        <Link to="/scorecard"><button className="themeBtn">Visit our Wall of Fame</button></Link>
                    </div>
                </div>
            </div>
        </section>
	);
}
}

export default FameWallPte;
