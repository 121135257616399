import React from 'react';
import { Link} from 'react-router-dom';
import {store} from 'store/index';
import API from "api.js";

class Packages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false,
            plans:[]
        }
    }

    async componentDidMount() {
        let stateData = store.getState();
        await this.getPlans();
    }

    // to get plans for both logged & non-logged user
    getPlans = async() => {
        try{
            let apiUrl = !store.getState().token?"/webportal_plans":"/plans";
            const response = await API({
                method: 'GET',
                url: apiUrl
            });
            if (response.status === 200){
                this.setState({
                    plans:response.data.data.plans
                })
            }
            //console.log("PLANS GET",response)
        }catch(error){
            console.log("ERRPR")
        }

    }

 render(){
	return (
		<section className="featurePricing" id="packages">
            <div className="container" >
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2 className="title">Packages</h2>
                        <p></p>
                        <div className="featurePlans">
                            <div className="featurePlan">
                                <h3>Features</h3>
                                <ul>
                                    <li>Price</li>
                                    <li>Duration</li>
                                    <li>Real Exam Practice Material</li>
                                    <li>Full Vocabulary Access</li>
                                    <li>Full Length Mock Tests</li>
                                    <li>Last Minute Practice</li>
                                    <li>1 Mock test with Scorecard and Feedback on Improvement</li>
                                </ul>
                            </div>
                            {
                                this.state.plans.map((prop, key) => {
                                    let packageNameClass=(key > 0 && key===1)?"green":(key === 2)?"red":"";
                                    let validityMonths = (prop.validity/30);
                                    let validityMonthLabel = (validityMonths>0)?validityMonths + " months":"Free";
                                    return (
                                        (prop.plan_type.name !== "free")?(
                                            <div key={key} className={"featurePackage " + packageNameClass}>
                                                <h3>{prop.name}</h3>
                                                <ul>
                                                    <li>
                                                        <span className="packagePrice">${prop.price}</span>
                                                        {validityMonthLabel}
                                                        {<br></br>}
                                                        {
                                                            /*(validityMonths===0)?<><br></br><br></br></>:""*/

                                                        }
                                                    </li>
                                                    <li><img src="/images/icon/check-silver.png"  alt="check-silver"/></li>
                                                    
                                                    <li><img src="/images/icon/check-silver.png"  alt="check-silver1"/></li>
                                                    <li><img src="/images/icon/check-silver.png"  alt="check-silver2"/></li>
                                                    <li><img src="/images/icon/check-silver.png"  alt="check-silver3"/></li>
                                                    <li>{(prop.name === "Beginner")?<img src="/images/icon/white-crose.png" className="crossImg"  alt="whiteCrose"/>:<img src="/images/icon/check-silver.png" alt="check-silver5" />}</li>
                                                    <li>
                                                        {
                                                            (prop.plan_type.name !== "free")?(
                                                                <>
                                                                 {store.getState().token && <Link to={'/sub/payment/form/'+prop.id}> <button className="themeBtn">Enrol now</button></Link>}
                                                                 {!store.getState().token && <Link to="/auth/register"><button className="themeBtn">Enrol now</button></Link>}
                                                                </>
                                                            ):<><button className="themeBtn">Free</button></>
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        ):null
                                    )
                                })
                            }
                            {/*<div className="featurePackage">
                                <h3>Gold</h3>
                                <ul>
                                    <li>
                                        <span className="packagePrice">$89</span>
                                        1 Month <br></br>Renewed Every 1 Month(s)
                                    </li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li>1</li>
                                    <li>1</li>
                                    <li><i className="fa fa-times" aria-hidden="true"></i></li>
                                    <li>1 Month</li>
                                    <li>
                                        {store.getState().token && <Link to='/sub/payment/form/8'> <button className="themeBtn">Enrol</button></Link>}
                                        {!store.getState().token && <Link to="/auth/login"><button className="themeBtn">Enrol</button></Link>}
                                    </li>
                                </ul>
                            </div>
                            <div className="featurePackage green">
                                <h3>Plus</h3>
                                <ul>
                                    <li>
                                        <span className="packagePrice">$299</span>
                                        2 Month <br></br>Renewed Every 2 Month(s)
                                    </li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li>2</li>
                                    <li>2</li>
                                    <li>1</li>
                                    <li>2 Month</li>
                                    <li>
                                        {store.getState().token && <Link to='/sub/payment/form/2'> <button className="themeBtn">Enrol</button></Link>}
                                        {!store.getState().token && <Link to="/auth/login"><button className="themeBtn">Enrol</button></Link>}
                                    </li>
                                </ul>
                            </div>
                            <div className="featurePackage red">
                                <h3>Pro</h3>
                                <ul>
                                    <li>
                                        <span className="packagePrice">$349</span>
                                        3 Month <br></br>Renewed Every 3 Month(s)
                                    </li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li>3</li>
                                    <li>3</li>
                                    <li>2</li>
                                    <li>3 Month</li>
                                    <li>
                                     {store.getState().token && <Link to='/sub/payment/form/3'> <button className="themeBtn">Enrol</button></Link>}
                                        {!store.getState().token && <Link to="/auth/login"><button className="themeBtn">Enrol</button></Link>}
                                    </li>
                                </ul>
                            </div>*/}

                            
                        </div>
                    </div>
                </div>
            </div>
        </section>

	);
}
}

export default Packages;