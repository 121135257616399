import React from 'react';
import Packages from '../components/Frontend/Packages';

class PlanPackages extends React.Component {
 render(){   
    return (
        <>
        <div className="loggedPackages">
            <Packages />
        </div>
        <section className="refundPolicy loggedPolicy">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="refundNote text-center">
                            <h4>Refund Policy</h4>
                            <p>We don't offer any refund in any given circumstances. Nevertheless, a refund is possible is in case you are charged extra due to a technical problem. The refund request should be initiated within 24 hours by sending an email with all the details on <a hrf="mailto:info@languageacademy.com.au">info@languageacademy.com.au</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
    }
}

export default PlanPackages;