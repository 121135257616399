import React from "react";
import {
  Button,
  Alert,
  Input,
} from "reactstrap";
import "components/custom.css";
import API from "api.js";
import { Redirect, NavLink } from "react-router-dom";

class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      login:false,
      error:null,
      error_bag:null,
      otpflag:false
    };
  }
  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  postLogIn = async(e) => {
    
    this.setState({error_bag:null});
    e.preventDefault();

    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/forgot_password',
        data: {
          email:this.state.email
        }
      })
    }catch(error) {
        
    };
    
    if (res.status === 200) {
      this.setState({otpflag:true,error_bag:res.data.message});
    }else if(res.response.status === 422){
      this.setState({error:res.response });
      let str = '';
      
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+''; })
      this.setState({error_bag:str});

    }
    else if (res.response.status === 402) {
      this.setState({error_bag:"Wrong credentials."});
    }
    
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };
  render() {
    if (this.state.login) {
        return (<Redirect to="/admin/dashboard" />);
    }
    return (
      <div className=" innerPage login-page">
        <section className="bannerOuter">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h1>Forgot Password</h1>
                <span> </span>
              </div>
            </div>
          </div>
        </section>
        <section className="contentOuter">
          <div className="container">
            <div className="row">
              <div className="col-sm-7 loginForm">
                {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                  <div className="fieldOuter">
                    <Input 
                        placeholder="Email"
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={(e) => { this.changeInput(e)} }
                      />
                      
                  </div>
                  
                  <div className="rememberForgot">
                    <div className="rememberMe">
                      <input type="checkbox" name="" style={{display:"none"}}/>
                      <span></span>
                    </div>
                    <div className="forgotPwd">
                      <NavLink className="mt-5" to='/auth/login'><span>Back</span></NavLink>
                    </div>
                  </div>
                  <div className="fieldOuter text-right">
                    <Button
                      className="themeBtn"
                      onClick={(e) => {this.postLogIn(e)}}
                    >
                      Submit
                    </Button>
                    {this.state.otpflag && <NavLink to="/auth/resetPassword">Got OTP?</NavLink>}
                  </div>
              </div>
              <div className="col-sm-5 registerLink text-center">
                <h3>New User?</h3>
                <p>Register now to obtain your<br /> Free Mock Test Material</p>
                <NavLink to="/auth/register" className="themeBtn blueBtn">Register</NavLink>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Forgot;
