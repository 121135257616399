import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Modal, ModalBody, ModalFooter
} from "reactstrap";
import "components/custom.css";
import currentStatus from "components/current-status.jpg";
import completeStatus from "components/current-status-complete.jpg";
import playingStatus from "components/current-status-playing.jpg";
import completeRecord from "components/current-status-record-complete.jpg";
import currentRecording from "components/current-status-recording.jpg";
import img from "components/a.png";
import resourceCheckAudio from "components/AUD_2_1539694341.mp3";
import {s3base} from "api.js";
import IntroTest from "components/Question/Speaking/IntroTest.jsx";
import {store} from 'store/index';
import MicRecorder from 'mic-recorder-to-mp3';
import Sound from 'react-sound';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'

//import WaveSurferPlayer from "components/Wavesurfer/WavesurferPlayer.jsx";
//import WavesurferPlayerRecord from "components/Wavesurfer/WavesurferPlayerRecord.jsx";
//import Waves from "components/Wavesurfer/Waves.jsx";
import WaveSurferComponent from "components/Wavesurfer/WaveSurferComponent.jsx";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
class ResourceCheck extends React.Component {
  waveref = null;
  constructor(props) {
    super(props);
    this.state = {
      questionData:[],
      soundStatus:false,
      isBlocked:false,
      volume:100,
      soundPer:0,
      recordPer:0,
      isRecording:false,
      recordingSoundStatus:false,
      btnText:'Playback',
      mic:false,
      headphone:false,
      keyboard:false,
      testDetail:false,
      currDetail:false,
      sequenceArr:[],
      index:0,
      nextFlag:true,
      startFlag:false,
      intro:false,
      speak_inst:false,
      test_intro:false,
      modal: false,
      pos:0,
      playing:false,
      wave:null,
      recordState: null,
      playbackButton:false,
      clickedStartButton:false,
      clickedPlaybackButton:false,
      recordedBlob:null,
      allowRecording:false,
      attemptStarted:false
    };
    this.isAttemptedRef = React.createRef();
    this.isAttemptedRef.current = 0;
    this.test = 0;
  }

  componentDidMount() {

    navigator.getUserMedia({ audio: true },
      () => {
        //console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
        //console.log('Permission Denied');
        this.setState({ isBlocked: true })
      },
    );
    let arr;
    //console.log("PRO", this.props.cat)
    if(this.props.cat === 1){
      arr = ['headphone','mic'];
      this.setState({sequenceArr:arr});
      
    }
    else if(this.props.cat === 2){
      arr = ['keyboard'];
      this.setState({sequenceArr:arr});
    }
    else if(this.props.cat === 3){
      arr = ['keyboard'];
      this.setState({sequenceArr:arr});
    }else if(this.props.cat === 4){
      arr = ['headphone','keyboard'];
      this.setState({sequenceArr:arr});
    }
    else if(this.props.cat === 5 && this.props.pending === 1){
      arr = ['headphone','mic','keyboard'];
      this.setState({sequenceArr:arr});
    }else if(this.props.cat === 5 && this.props.pending === 0){
      arr = ['headphone','mic','keyboard','test_intro' ,'speak_inst','testDetail','currDetail','intro'];
      this.setState({sequenceArr:arr});
    }
    let flag = false;
    if (arr[this.state.index] === 'keyboard' ) {
       flag = true;
    }
    this.setState({[arr[this.state.index]]:true,nextFlag:flag});

  }

  handlePosChange = (e) => {
    //console.log("POS CHANGE", e)
    this.setState({
      pos:e.originalArgs[0]
    })
  }
  
  onSound = () =>{
    this.setState({soundStatus:true});
    // generate waveform
  }

  offSound = () =>{
    this.setState({soundStatus:false,nextFlag:true});
  }

  handleSongFinishedPlaying = () =>{
    this.setState({soundStatus:false,nextFlag:true});
  }

  recordingFinishedPlaying = () =>{
    this.setState({recordingSoundStatus:false});
  }

  changeRange = (e) => {
    this.setState({volume: e.target.value});
  }

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

  handleRecordPlaying = (obj) => {
    this.setState({recordPer:obj.position*100/obj.duration});
  }

  nextButton = () => {
    if (this.state.index < (this.state.sequenceArr.length-1) ) {
      let arr = this.state.sequenceArr;
      let oldIndex = this.state.index;
      let newIndex = this.state.index+1;
      let flag = false;
      let mod = false;
      if ([arr[newIndex]] === 'keyboard' || [arr[newIndex]] === 'testDetail' || [arr[newIndex]] === 'currDetail' || [arr[newIndex]] === 'test_intro'|| [arr[newIndex]] === 'speak_inst' || [arr[newIndex]] === 'intro') {
        flag=true;
      }
      this.setState({[arr[newIndex]]:true,[arr[oldIndex]]:false,index:newIndex,nextFlag:flag,modal:mod});
    }else{
      if (this.state.sequenceArr[this.state.index] === 'intro') {
        this.setState({modal:true});
      }else{
        this.setState({startFlag:true});
      }

    }
  }

  playRecording = () => {
    if (this.state.recordingSoundStatus) {
      this.setState({recordingSoundStatus:false,btnText:'Playback',nextFlag:true});
    }else{
      this.setState({recordingSoundStatus:true,btnText:'Stop Playback'});
    }

  }

  nextStep = () =>{
    if (this.state.index < (this.state.sequenceArr.length-1) ) {
      this.setState({nextFlag:true});
    }else{
      this.setState({startFlag:true});
    }
  }

  toggleFlags = () =>{
    this.setState({startFlag:true, nextFlag:false})
  }

  handlePlaybackButton = () =>{
    this.setState({clickedPlaybackButton:true});
  }

  handleAllowRecording = () =>{
    this.setState({allowRecording:true});
  }


  startRecording = () => {
    if (this.state.isBlocked) {
      alert("Please attach your microphone")
    } else {
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:''});
        }).catch((e) => console.error(e));
    }
  }

  toggleYes = () => {
    this.setState({modal:false,startFlag:true},function(){
      this.props.showTest();
    });

  }

  toggle = () => {
    this.setState({modal:false});
  }

  onStop = (audioData) =>{

    //console.log('RECORDED OUTER audioData', audioData)
    this.setState({recordedBlob:audioData.url})

  } 
  onStartRecording = () =>{
    //console.log("On START OUTER RECORDING", this.state.clickedStartButton, this.state.playbackButton);
    this.setState({recordState:RecordState.START, clickedStartButton:true, playbackButton:true})
  }

  onStopRecording = () =>{
    //console.log("On STOP OUTER RECORDING", this.state.clickedStartButton, this.state.playbackButton);
    this.setState({recordState:RecordState.STOP, clickedStartButton:false, playbackButton:true})
  }

  onPlayRecording = () =>{
    console.log("On PLAY OUTER RECORDING", this.state.recordedBlob);
  }
  stopRecording = () => {
    if (!this.state.isBlocked) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob)
          this.setState({ blobURL, isRecording: false });
        }).catch((e) => console.log(e));
    }
  }

  handlePlay = () => {
    this.setState({ playing: !this.state.playing });
    this.waveform.playPause();
  };

  handlerAttemptStarted = (clickStartButton=false) =>{
    //console.log("HAS 1:", clickStartButton)
    this.setState({attemptStarted:clickStartButton});
    //this.forceUpdate()
  }
  render() {
    if (this.state.sequenceArr[this.state.index] !== 'intro'  && this.state.index === (this.state.sequenceArr.length-1) && !this.state.startFlag) {
      this.setState({startFlag:true});
    }
    return (
    <>
    {
      this.state.modal && 
      <Modal isOpen={this.state.modal} size="sm" >
        <ModalBody>
          <div>Do you want to go to next Question?</div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="modalCloseBtn" onClick={this.toggleYes}>Yes</Button>
          <Button color="secondary" className="modalCloseBtn" onClick={this.toggle}>No</Button>
        </ModalFooter>
      </Modal>
    }
    <div className="content questionsArea deviceTestPage">

      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              {this.state.headphone && <div className="col-xs-12 successContent">
                <div className="row">
                  <div className="col-sm-6">
                    <h4 className="mt-0"><span>Headset & Microphone check</span></h4>
                    <p>This segment is intented to <strong>test your audio</strong> prior to taking the full CCL test.</p>
                    <p>Click on <strong>“Play”</strong> below the audio, to hear the segment and test your microphone.</p>
                    <p>The waveform will start to play and after it ends, you will hear a beep to indicate you that you can now start speaking and your voice will be shown as soundwaves.</p>
                    <p>After the soundwave disappears you need to click on “Playback” button and listen back to the audio to ensure your voice has been recorded, only then you would be able to proceed.</p>
                    
                    <h4 className="mt-5"><span>Repeat:</span></h4>
                    <p>You are able to repeat any segment before you begin speaking (<strong>one repeat per dialogue without penalty</strong>) to do this:</p>
                    <ul>
                      <li>Click on the <strong>Start</strong> button again</li>
                    </ul>

                    <h5 className="mt-5">Click <strong>Next</strong> to move on</h5>
                    {/*<h6>This is an opportunity to check that your <strong>headset is working correctly</strong></h6>
                    <ol>
                      <li>Put your <strong>headset on</strong> and adjust it so that if it's comfortably over your ears</li>
                      <li>When you are ready. <strong>cilck on the play button</strong>. You hear a short recording</li>
                      <li>If you <strong>do not hear</strong> anything in your headphones while the status reads Playing. <strong>Raise your hard</strong> to get the alteration of the Test Administator</li>
                    </ol>*/}
                  </div>
                  
                  <div className="col-sm-6">
                    <div className="currentStatus" id="waveformContainer">
                      <div className="waveformVolume">
                        <WaveSurferComponent 
                          audio={resourceCheckAudio}
                          handlerAttemptStarted={this.handlerAttemptStarted.bind(this)}
                          showAttemptText={true}
                          hidePlayPause={false}
                          forInstructions={true}
                          showRecordBlinker={true}
                          showPlayingSourceBlinker={true}
                          forSegment={false}
                          autoPlayAllowed={true}
                        />

                        {/*<WavesurferPlayerRecord
                          height={80}
                          interact={false}
                          autoCenter={true}
                          cursorWidth={1}
                          waveColor="#94c23c"
                          progressColor="#e3e3e3"
                          url={resourceCheckAudio}
                          toggle={this.toggleFlags.bind(this)}
                          handlerPlayback={this.handlePlaybackButton.bind(this)}
                          handleAllowRecording={this.handleAllowRecording.bind(this)}
                          allowRecording={this.state.allowRecording}
                          playPauseIcons={true}
                          //playbackButton={true}
                          //autoPlay={true} // remove for WavesurferPlayerRecord
                          //showAttemptButton={false}
                          recordForSeconds={20000} // 20 seconds
                          forInstructions={true}
                          showRecordBlinker={true}
                            ={true}
                          showAttemptText={true}
                          //handlerAttemptStarted={this.handlerAttemptStarted.bind(this)}

                        />*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

          {this.state.speak_inst && <div className="col-xs-12 successContent">
              <div className="row w-100">
              <div className="col-lg-12">
                  <h4 className="mt-0">Speaking Instructions</h4>
                  <h6>Some questions require you to first listen to an audio clip and then record your answer by speaking clearly into the microphone.</h6>
                  <div className="row speakingStatus mb-5 mt-4">
                    <div className="col-sm-4">
                      <p><b>Listening: </b>The Current Status shows how long you have until the audio clip starts.</p>
                      <img src={currentStatus} alt="currentStatus" />
                    </div>
                    <div className="col-sm-6 pl-5">
                      <p>A blue bar shows the progress of the audio clip. When it reaches the right hand side the audio, clip will stop playing.</p>
                      <div className="row doubleImges">
                        <div className="col-sm-6"><img src={completeStatus} alt="completeStatus" /></div>
                        <div className="col-sm-6"><img src={playingStatus} alt="playStatus" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="row speakingStatus">
                    <div className="col-sm-4">
                      <p><b>Speaking: </b>The Current Status shows how long you have until the microphone opens. Start speaking when the status changes to recording.</p>
                      <img src={currentStatus} alt="currentStatus" />
                    </div>
                    <div className="col-sm-6 pl-5">
                      <p>You must finish before the progress bar reaches the right hand side. If you remain silent for longer than 3 seconds, the recording will stop. You will not be able to re-record.</p>
                      <div className="row doubleImges">
                        <div className="col-sm-6"><img src={completeRecord}  alt="completeRecord" /></div>
                        <div className="col-sm-6"><img src={currentRecording} alt="currentRecording" /></div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
          </div>
          }
          {this.state.test_intro && <div className="col-xs-12 successContent">
            <div className="row w-100">
            <div className="col-lg-12">
                  <h4 className="mt-0">Test Introduction</h4>
                  <h6>This test measures the Reading, Writing, Listening and Speaking skills in English that you will need in an academic setting.</h6>
                  <ol>
                    <li>The test is divided into 3 parts. Each part may contain a number of sections. The sections are individually timed. The timer will be shown in the top right corner of your screen. The number of items in the section will also be displayed.
                      <div className="timeBlock">
                        <div className="timeBlockInr text-right">
                          <p><i className="fa fa-clock-o" aria-hidden="true"></i> Time Remaining : 00:32:56</p>
                          <p>Item 22 of 39</p>
                        </div>
                      </div>
                    </li>
                    <li>At the beginning of each part you will receive instructions. These will provide details on what to expect in that part of the test.</li>
                    <li>By clicking on the Next (N) button at the bottom of each screen you confirm your answer and move to the next question. If you click on Next (N) you will not be able to return to the previous question. You will not be able to revisit any question at the end of the test.</li>
                    {this.props.data.break === 0 ?<li>You will be offered a break of up to 10 minutes before Listening part. The break is optional.</li> : '' }
                    <li>This test makes use of different varieties of English, for example, British, American, Australian. You can answer in the standard English variety of your choice.</li>
                    <li>During the test, do not click on any browser buttons (front, back, or reload). If you want to leave in between any sections, just use Save & Exit button. And if you want to leave after finshing a section, just use Save and Exit button.</li>
                  </ol>
                </div>
                </div>
                </div>}
                {this.state.mic && <div className="col-xs-12 successContent">
                <div className="row w-100">
                  <div className="col-sm-6">
                    <h4 className="mt-0">Microphone Check</h4>
                    <h6>This is an opportunity to check that your <strong>microphone is working correctly</strong></h6>
                    <ol>
                      <li><strong>Make sure your headset is on</strong> and the microphone is in the downward position near your mouth.</li>
                      <li>When you are ready, <strong>click on the Record button and say "Testing, testing</strong>, one, two, three" into the microphone</li>
                      <li>After you have spoken, <strong>cilck on the Stop button.</strong> Your recording is now complete</li>
                      <li>Now <strong>click on the Playback button.</strong> You should clearly hear yourself speaking</li>
                      <li>If you can <strong>not hear</strong> your voice clearly. <strong>please raise your hand</strong> to get the atteration of the Test Administrator</li>
                    </ol>
                  </div>
                  <div className="col-sm-6">                    
                    <div className="currentStatus">
                      <p>Status: Click record to begin</p>
                      <div className="waveRecordedContainer mt-2">
                        <AudioReactRecorder state={this.state.recordState} onStop={this.onStop} backgroundColor={"#f2f3f3"} foregroundColor={"red"} canvasWidth={"635"} canvasHeight={"100"}  /> 
                      </div>
                      {/*<Progress color="info" value={this.state.recordPer} />
                      <div className='stop-btn'>
                        <Button color="success" disabled={this.state.isRecording} className="p-5px" onClick={this.startRecording} size="xs">Record</Button>
                        <Button color="success" onClick={this.playRecording} className="p-5px" size="xs">{this.state.btnText}</Button>
                        <Button color="success" disabled={!this.state.isRecording} onClick={this.stopRecording} className="p-5px" size="xs">Stop</Button>
                      </div>*/}
                      {this.state.isRecording && <div>Recording is started, please click on stop button for stop recording</div>}
                    </div>
                    <div className="note">
                      <ul>
                        <li>During the test, you will not have Record, Playback, and Stop buttons. The voice recording will start automatically.</li>
                      </ul>
                    </div>
                  </div>
                  </div>
              </div>}
            {this.state.keyboard && <div className="col-xs-12 successContent">
                <div className="col-lg-12">
                 <h4 className="mt-0">Keyboard Check</h4>
                  <h6>This is an opportunity to check that you have the correct keyboard.</h6>
                  <div className="col-xs-12">
                    <ol>
                      <li>Look at the top row of letters on the keyboard.</li>
                      <li>The letters should appear in this order Q W E R T Y.</li>
                      <li>If you do not have a QWERTY keyboard, raise your hand to get the attention of the Test Administrator</li>
                    </ol>
                    <div className="keyboardImg">
                      <img src={img} alt="keyboard" />
                    </div>
                  </div>
                  </div>
              </div>}
            {this.state.testDetail && <div className="col-xs-12 successContent">

                <div className="col-lg-12">
                 <h4 className="mt-0">Welcome {store.getState().user.first_name} {store.getState().user.last_name}</h4>
                  <div className="col-xs-12">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th>Part</th>
                          <th>Content</th>
                          <th>Time Allowed</th>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Speaking & writing</td>
                          <td>{this.props.data.time[0]} minutes</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Reading</td>
                           <td>{this.props.data.time[1]} minutes</td>
                        </tr>
                        {this.props.data.break === 0 ? <tr>
                          <td colSpan={2}>Optional scheduled break</td>
                           <td>10 minutes</td>
                        </tr> : ''}
                        <tr>
                          <td>3</td>
                          <td>Listening</td>
                            <td>{this.props.data.time[2]} minutes</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  </div>
              </div>}
              {this.state.intro && <IntroTest />}
              {this.state.currDetail && <div className="col-xs-12 successContent">

                <div className="col-lg-12">
                 <h4 className="mt-0">Welcome {store.getState().user.first_name} {store.getState().user.last_name}</h4>
                  <div className="col-xs-12">
                    <table className="table table-bordered">
                      <tbody>
                          <tr>
                            <th>Section</th>
                            <th>No of Question</th>
                            <th>Time Allowed</th>
                          </tr>
                          <tr>
                            <td>Speaking & writing</td>
                            <td>{this.props.data.full[0].length}</td>
                            <td>{this.props.data.time[0]} minutes</td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                  </div>
              </div>}
              {this.state.soundStatus && <Sound
                url={s3base + "/ptedata/ptemedia/AUD (2)_1539694341.mp3"}
                playStatus={Sound.status.PLAYING}
                onFinishedPlaying={this.handleSongFinishedPlaying}
                onPlaying={this.handleSongPlaying}
                volume={this.state.volume}
              />}
              {this.state.blobURL && this.state.recordingSoundStatus && <Sound
                url={this.state.blobURL}
                playStatus={Sound.status.PLAYING}
                onPlaying={this.handleRecordPlaying}

                onFinishedPlaying={this.playRecording}
              />}
            </CardBody>
          </Card>
        </Col>
        <Col xs={12}><div className="bottomBtns">
          <Row>
          <Col xs={9}>

          </Col>

          <Col xs={3}>
            {
              this.state.headphone?(
                <Button color="primary" onClick={this.props.showTest} disabled={this.state.attemptStarted === true?true:false} size="xs">Next</Button>
              ):
              (
                this.state.startFlag?(
                  <Button color="primary" onClick={this.props.showTest} size="xs">Next</Button>
                ):(
                  <Button color="primary" onClick={this.nextButton} size="xs">NEXT</Button>
                )
              )
            }
          </Col>
          </Row>
          </div>
        </Col>
      </Row>
    </div>
    </>
    );
  }
}
export default ResourceCheck;