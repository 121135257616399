import React,{Component} from "react";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Table,
	Row,
	Col,
} from "reactstrap";
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import { thead, tbody } from "variables/general";
import {store} from 'store/index';

class LastMinutePracticeLists extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			page: 1,
			count: 10,
			prediction: 1,
			all_ok: true,
			paidUser:0,
      		languageId:1,
      		offset:0,
		};
	}

	componentDidMount() {
		let stateData = store.getState();

		this.chromeFunc();
    	// check & state paid user or not
	    this.setState({paidUser:stateData.user.user_paid_plan_id?1:0});
	    // check & state language id
    	this.setState({languageId:stateData.user.language_id});
    	// get dialogues
    	this.getDialogues(0, stateData.user.language_id)
	}

	chromeFunc = () => {
		let isChrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
		if (navigator.getUserMedia === undefined) {
			this.setState({all_ok:false});
		} else {
			navigator.getUserMedia({ audio: true }, () => {
            	//console.log('Permission Granted');
            	this.setState({all_ok:isChrome});
			}, () => {
				this.setState({all_ok:false});
				//console.log('Permission Denied');
    		});
		}
	}

	getDialogues = async(offset=0, languageId) => {
	    try{
			
			let routeName="exam_memories";
			const postData = {
				languageId: languageId,
				pageOffset: offset?offset*this.state.pageSize:this.state.offset,
				pageSize: 15,
				type: routeName,
			};
			const response = await API({
		        method: 'POST',
		        url: '/exam_memories',
		        data: postData
		      });
			if (response.data.success && response.data.data.rows) {
				this.setState({
					data: response.data.data.rows,
					total: response.data.data.count
				});
			} else if (response.data.success && response.data.data.paid_plan_required) {
				console.log("PURCHASE PLAN ERROR in fetchDialogues : ", response.data);
			} else {
				console.log("ERROR in fetchDialogues : ", response.data);
			}
	    }catch(error) {
	        this.setState({error:{error} });
	    };
  	}

	render() {
		let { page, count } = this.state;
    	let i = page * count-count;
		return (
      		<>
      			{!this.state.all_ok && <ChromeCheck />}
        		{this.state.all_ok &&
					<div className="content">
            			<Row>
                    		<Col xs={12}>
                      			<Card>
                        			<CardHeader>
                          				<CardTitle tag="h4"><span>Last Minute Practice</span></CardTitle>
                        			</CardHeader>
                        			<CardBody>
										<div className="table-responsive">
                          					<Table>
                            					<thead className="text-primary">
                              						<tr>
				                                      	<th className="">S. No</th>
                                      					<th className="">Title</th>
                                      					<th className="">Practice Detail</th>
													</tr>
                            					</thead>
                            					<tbody>
                              						{this.state.data.map((prop, key) => {
                                						return (
                                  							<tr key={key}>
                                          						<td>{++i}</td>
																<td className="">
																	{prop.title.split(")")[1]}
																	{/*<p>({prop.session})</p>*/}
																</td>
                                          						<td className="">
                                            						<NavLink
											                            to={`/admin/lmp/practice/${prop.id}/${this.state.languageId}`}
											                            className="nav-link"
											                            activeClassName="active"
																	>
                            											<i className="" />
                            											<p>Practice Now</p>
                          											</NavLink>
                                          						</td>
															</tr>
														);
													})}
                            					</tbody>
                          					</Table>
                          				</div>
                        			</CardBody>
                      			</Card>
                    		</Col>
						</Row>
					</div>
				}
      		</>
		);
  	}
}

export default LastMinutePracticeLists;
