import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import {store} from 'store/index';

class PaidUserCheck extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      paidUser: 0
    };
  }

  componentDidMount() {
    let stateData = store.getState();
    this.setState({paidUser:((stateData.user.user_paid_plan_id === null) || (stateData.user.user_paid_plan_id === undefined)?0:1)});
  }
 
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4"><span>Buy Plan</span></CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                
                  {this.state.paidUser === 0 && <React.Fragment> 
                    <em>*Only available for our premium users. Subscribe to one of our proven packages today.</em>
                    <p><a href="/admin/packages">Click here</a></p>
                    </React.Fragment>
                  }
                   
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PaidUserCheck;
