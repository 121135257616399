import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps
import { connect } from 'react-redux';
import API from "api.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// reactstrap components
import { setUser} from 'action/index.js';
import {store} from 'store/index';
import {
  Row,
  Col,
} from "reactstrap";

const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};


class Dashboard extends React.Component {

  constructor(props) {
  super(props);
    this.state = {
      data: false,
      activeTab:1,
      targetModal:false,
      examModal:false,
      exanDate:null,
      speaking:null,
      reading:null,
      writing:null,
      diff_days: null,
      first_name: null,
      last_name: null,
      country_citizenship:null,
      country_residence:null,
      country_state:null,
      dob: null,
      email: null,
      phone: null,
      address: null,
      lang: null,
      center: null,
      score: null,
      listening:null,
      exam_date:null,
      overall_score:null,
      modal1:false,
      announceModal:false,
      isSubscribed:false,
      isPopupQuery:0,
      isInstitute: false,
      institute: null,
      isMainSite:store.getState().is_main,
      guestFlag:true,
      showDiscountPopup:false,
      anySub:0,
      active_subscription:null,
      dashboardData:false,
      btcModal:false,
      btcModalState:false,
      btc_error_bag:'',
      btc_success_bag:'',
      popup_video: null,
      yt_video_popup: false,
      videos:[],
      totalVideos:0,
      pageOffset:0,
    }
  }

  async componentDidMount() {
    await this.getDashboardVideos();
  }

  getDashboardVideos = async() => {
    if (this.state.videos.length < this.state.totalVideos || this.state.totalVideos === 0) {
      try {
        let postData = {
          pageOffset: this.state.pageOffset,
          pageSize: 5,
          type: 1
        };
        const response = await API.post('videos', postData);
        if (response.data.success) {
          this.setState({totalVideos: response.data.data.count})
          this.fetchAllVimeoDetails(response.data.data.rows);
        }
      } catch (error) {
        console.log("ERROR in fetchVideos : ", error);
      }
    }
  }

  fetchAllVimeoDetails = async (videos) => {
    for (const [index, singleVideo] of videos.entries()) {
      videos[index].thumbnailUrl = "http://i.ytimg.com/vi/"+singleVideo.link+"/maxresdefault.jpg";//result.data.video.thumbs['640'];
      videos[index].videoUrl = singleVideo.link;//result.data.request.files.hls.cdns[result.data.request.files.hls.default_cdn].url;
    }
    this.setState({videos: videos});
  }

    render() {
    return (
      <>
        <div className="content">
          <Row className="videosCntnt">           
              {
                this.state.videos.map((prop, key) => {
                  return(
                    <Col md="6" sm="6" key={key} className="videosBlock">
                      <iframe src={'https://www.youtube.com/embed/'+prop.videoUrl+'?rel=0&autoplay=0&showinfo=0&controls=0&disablekb=1&modestbranding=1&playsinline=1'} frameBorder="0" allowFullScreen title="video_{key}"/>
                        {/*<img
                          src={prop.thumbnailUrl}
                          style={{ height: 200, margin:10 }}
                        />
                        */}
                      <h5 className="mb-0">{prop.title}</h5>
                      <p className="m-0">{prop.description}</p>    
                    </Col>
                  )
                })
              }
          </Row>
        </div>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
