import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
//import FameWall from '../components/Frontend/FameWall';
import Packages from '../components/Frontend/Packages';
//import MockPackages from '../components/Frontend/MockPackages';
//import VideoTuts from '../components/Frontend/VideoTuts';
//import VideoTestimonial from '../components/Frontend/VideoPTETestimonial';
import { Helmet } from "react-helmet";
//import ReactGA from 'react-ga';
//import ReactPixel from 'react-facebook-pixel';

class VideoCourse extends React.Component {
    componentDidMount() {
        /*ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();*/
        if (window.location.href.indexOf('#packages') !== -1) {
            window.location.hash='';
            window.location.hash='packages';
        }
    }
 render(){   
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Language Academy</title>
     </Helmet>
    <Header />
        <div className="innerPage">
        <section className="bannerOuter">
            <div className="container">
                {/*<div className="row">
                    <div className="col-sm-12 text-center">
                        <h1>Excel In CCL With Course</h1>
                        <span>Complete Preparation Package To Achieve The Score You Desire</span><br />
                        <a href="javascript:void(0);" className="downArrow"><img src="/images/banner-arrow.png" alt="banner-arrow" /></a>
                    </div>
                </div>*/}
            </div>
        </section>
        </div>
        {/*<section className="whyOnline">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 whyVideo">
                        <div className="videoOuter">
                            <iframe style={{height:'100%'}} src="https://www.youtube.com/embed/KnOFM034W5U" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                        </div>
                    </div>
                    <div className="col-sm-6 courseFeatures">
                        <h2>Why Online Video Course?</h2>
                        <ul className="checklist">
                            <li>24 hours access.</li>
                            <li>All the proven tips, templates and strategies.</li>
                            <li>Real exam practice questions.</li>
                            <li>Complete access tonline practice portal.</li>
                            <li>3 full length scored mock text with score-report and feedback.</li>
                            <li>One to one consultation session with the tutor. (30 minutes each)</li>
                            <li>Regular prediction files.</li>
                            <li>Access to exam prediction files with highly repeated questions.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>*/}
        <Packages />
        {/*<MockPackages />*/}
        {/*<FameWall />*/}
        {/*<VideoTestimonial /> */}
        {/*<VideoTuts />*/}
        <section className="refundPolicy">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="refundNote text-center">
                            <h4>Refund Policy</h4>
                            <p>We don't offer any refund in any given circumstances. Nevertheless, a refund is possible is in case you are charged extra due to a technical problem. The refund request should be initiated within 24 hours by sending an email with all the details on <a hrf="mailto:info@languageacademy.com.au">info@languageacademy.com.au</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <Footer />
    </React.Fragment>);
    }
}

export default VideoCourse;