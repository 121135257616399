import React,{Component} from "react";

// reactstrap components
import {
  Col,
} from "reactstrap";

class NoMicrophone extends Component {
  render() {
    return (
      <>
        <Col xs={12} className="text-center">
          <h3>Please Attach your Microphone</h3>
        </Col>
      </>
    );
  }
}

export default NoMicrophone;
