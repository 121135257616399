import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Alert,
  Col,Button,
  CardFooter
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import API from "api.js";
import { Redirect } from "react-router-dom";
import { setUser} from 'action/index.js';
// core components
import {store} from 'store/index';
import { connect } from 'react-redux';
import cookie from 'react-cookies';

const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};
class Mock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      email:store.getState().user.email,
      otp:'',
      old_password:'',
      new_password:'',
      confirm_password:'',
      error_bag:null,
      logout:false,
    };
    this.validator = new SimpleReactValidator();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

  componentDidMount() {

  }

  updatePassword = async(e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
    this.setState({error_bag:null});
    e.preventDefault();

    let res = null;
    try{
      res = await API({
        method: 'PUT',
        url: '/profile',
        data: {
          'old_password': this.state.old_password,
          'password': this.state.new_password
        }
      })
    }catch(error) {
      console.error(error);  
      this.setState({error:{error} });
    };
    
    if (res.status === 200) {
      cookie.remove('access-token', { path: '/' });
      //store.dispatch(setAlert({'flag':true,'type':1,'message':"Password changed successfully!"}));
      this.setState({logout:true});
    }else if (res.response.status === 422) {
      this.setState({error:res.response });
      this.setState({error_bag:"Current password not matched!"});
    }
  }

  toggle = tab => {
    if(this.state.activeTab !== tab){
      this.setState({activeTab:tab});
    }
  }

  render() {
    if (this.state.logout) {
      return (<Redirect to="/auth/login" />);
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                  <CardTitle tag="h4"><span>Change Password</span></CardTitle>
                </CardHeader>
                <CardBody>
                   <Form>
                    <Row>
                      <Col className="" md="7">
                        <FormGroup>
                          <label>Old Password</label>
                          <Input
                            defaultValue={this.state.old_password}
                            name="old_password"
                            placeholder="Old Password"
                            type="text"
                            onChange={(e) => { this.changeInput(e)} }
                          />
                          {this.validator.message('old password', this.state.old_password, 'required')}
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup>
                          <label>New Password</label>
                          <Input
                            defaultValue={this.state.new_password}
                            name="new_password"
                            placeholder="New Password"
                            type="text"
                            onChange={(e) => { this.changeInput(e)} }
                          />
                          {this.validator.message('new password', this.state.new_password, 'required')}
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup>
                          <label>Confirm Password</label>
                          <Input
                            defaultValue={this.state.confirm_password}
                            name="confirm_password"
                            placeholder="Confirm Password"
                            type="text"
                            onChange={(e) => { this.changeInput(e)} }
                          />
                          {this.validator.message('confirm password', this.state.confirm_password, `required|in:${this.state.new_password}`, {messages: {in: 'Passwords need to match!'}})}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                   
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-round"
                    color="info"
                    onClick={this.updatePassword}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mock);
