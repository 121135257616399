import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
//import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
/*import Footer from "components/Footer/Footer.jsx";*/

import routes from "routes.js";

//var ps;

class Pages extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      //ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      //ps.destroy();
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <Header />
            <Switch>{this.getRoutes(routes)}</Switch>
        <Footer />
      </>
    );
  }
}

export default Pages;
