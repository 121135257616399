import React,{Component} from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal
} from "reactstrap";
import API, { 
  baseHost,
  baseAssetsPath,
  VOCAB_WORD_AUDIO_PATH,
  VOCAB_TRANSLATION_AUDIO_PATH
} from "api.js";
// core components
import {store} from 'store/index';
import { setAlert, setUser } from 'action/index.js';
import PaidUserCheck from "views/PaidUserCheck.jsx";

const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};

class VocabularyTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total:0,
      page: 0,
      search:'',
      count:30,
      orderby: 'desc',
      vocabCategories: [],
      pageSize:30,
      selectedCategory:'all',
      searchText: '',
      paidUser:0,
      languageId:1,
      offset:0,
      vocabularies:{ rows: [], count: 0 },
      savedVocabulary:{ rows: [], count: 0 },
      categoryName:'all',
      requestedAlphabet:'#',
      categoryId: this.props.match.params.catId !== ":catId"?parseInt(this.props.match.params.catId):0, 
      isCategoryId: this.props.match.params.catId !== ":catId"?true:false,
      whiteListedCategories:["all", "Saved", "Online"],
      purchasePlanModal:false,
      playing:false,
      audioObject:null,
      vocabId:0,
      srNo:1,
      paginatedTotalData:0,
      prevRecordCount:0,
      selectedTestIds:[],
      showTranslation:false,
      testPopUpData:{},
      savedVocabIds:[],
      testComplete:false,
      selectedTestCategory:"",
      correctCount:0,
      inCorrectCount:0,
      correctClicked:false,
      inCorrectClicked:false,
      showCountOption:false,
      testVocabCount:0,
      showTestModal:false,
      showTestCloseModal:false,
      testLabel:"Create Test"
    };

    this.translationShowTimeoutRef = React.createRef();
    this.translationShowTimeoutRef.current = null
  }

  componentDidMount() {
    let stateData = store.getState();
    // check & state paid user or not
    this.setState({paidUser:(stateData.user.user_paid_plan_id !== null)?1:0});
    // check & state language id
    this.setState({languageId:stateData.user.language_id})
    // fetch all categories
    this.getVocabularyCategory();
  }

  componentWillUnmount(){
    this.setState({
      categoryName:"all",
      isCategoryId:false,
      srNo:1,
      page:0,
      offset:0,
    });
    this.clearTranslation();
  }

  // to get all available vocabulary categories
  getVocabularyCategory = async() => {
    try{
      const response = await API({
        method: 'GET',
        url: '/vocabulary_categories'
      });
      if (response.data.success && response.data.data.vocabulary_categories) {
        this.setState({ vocabCategories: response.data.data.vocabulary_categories }, ()=>{
          if(this.state.isCategoryId === true){
            if (this.state.categoryId === 0){
              this.setState({selectedCategory:"all", categoryName: "all"})
              
            }
            else{
              var selectedCategoryObject = this.state.vocabCategories.find((category, index) => category.id === this.state.categoryId );
              //console.log("ESL",selectedCategoryObject)
              if (selectedCategoryObject){
                this.setState({ selectedCategory: {"id":selectedCategoryObject.id,"name":selectedCategoryObject.name}, categoryName: selectedCategoryObject.name },() => {
                    // show pop if user is not paid
                    if (this.state.paidUser === 0){
                      if (this.state.whiteListedCategories.includes(selectedCategoryObject.name) === false)
                        this.setState({purchasePlanModal:true});
                    }
                });
                //console.log("CS", this.state.categoryName)
              }
            }
          }
        });
      } else {
        console.log("Error fetchVocabCategories ==> ");
      }
    }catch(error) {
      this.setState({error:{error} });
    };
  }

  handlePlay = (index,vocabUrl) =>{
    let audio = new Audio(vocabUrl);
    this.setState({audioObject:audio, vocabId:index}, ()=>{      
        this.state.audioObject.play();
        this.setState({playing:true}, ()=>{
          audio.addEventListener("loadeddata", () => {
            let duration = audio.duration;
            setTimeout(()=>{
              this.setState({playing:false});
            }, duration*1000);
          });
        });
    });
  }

  handlePause = (index,vocabUrl) =>{
    this.state.audioObject.pause();
    this.setState({playing:false});    
  }
  
  // to get all vocabularies
  getTestVocabularies = async(selectedCategory, pageSize=20) => {
    try{
      const postData = {
        languageId: this.state.languageId,
        pageSize: pageSize <= 300?pageSize:5000,
        pageOffset:0,
        selectedCategory: selectedCategory,
        requestedAlphabet: '#',
        fetch_alphabets: false,
      };
      const response = await API({
        method: 'POST',
        url: '/vocab',
        data: postData
      });
      if (response.status === 200){
        let data = {'rows':[], 'count':0};
        if (this.state.paidUser === 0)
          data = response.data.data.vocab;
        else
          data = response.data.data;
        this.setState({
          testData: data.rows,
          testTotal: data.count,
          testComplete:false,
          correctCount:0,
          inCorrectCount:0,
          correctClicked:false,
          inCorrectClicked:false,
          showTestModal:false,
        });
        return {
          data: data.rows,
          total: data.count
        }
      }
    }catch(error) {
      this.setState({error:{error} });
    };
  }

  // create and get random index number
  getRandom = async(min=0, max) => {
    const randomIndex = min + Math.floor(Math.random() * ((max-1) - min + 1));
    return randomIndex;
  }

  // get random vocabulary on behalf of random index
  getRandomVocabData = async(randomIndex) => {
    if (this.state.selectedTestIds.length + 1 === this.state.testVocabCount){
      // show test completion message & score
      this.setState({testComplete:true, testPopUpData:{}});
      return true;
    }
    // process & show next word
    if(isNaN(randomIndex) === false){
      let selectedIndexData = this.state.testData[randomIndex];
      if (selectedIndexData){
        let selectedIndexId = selectedIndexData['id'];

        if (this.state.selectedTestIds.includes(selectedIndexId) === false){
          this.setState({selectedTestIds: [...this.state.selectedTestIds, selectedIndexId]})
          this.setState({testPopUpData: selectedIndexData});
          return selectedIndexData;      
        }else{
          // random index occurs which is already shown to user, fetch new random index
          let randomIndex = await this.getRandom(0, this.state.testTotal);
          selectedIndexData = await this.getRandomVocabData(randomIndex);          
          this.setState({testPopUpData: selectedIndexData});
          return selectedIndexData;      
        }
      }
    }    
  }

  // to start the vocabulary test
  startVocabularyTest = async(vocabCount=10) => {
    // set count option & reset other states
    this.setState({
      showCountOption:false
    })
    this.resetStates();
    // get random index number
    let randomIndex = await this.getRandom(0,this.state.testTotal);
    // get random vocabulary
    await this.getRandomVocabData(randomIndex);
    setTimeout(() => {
      // play vocab word audio
      this.playVocabAudio(true);
    },2000);
  }

  // to show the next vocab word
  showNextWord = async() =>{
    this.setState({
      showTranslation: false,
      correctClicked:false,
      inCorrectClicked:false
    });
    this.clearTranslation();

    // get random index number
    let randomIndex = await this.getRandom(0, this.state.testTotal);
    // get random vocabulary
    await this.getRandomVocabData(randomIndex);
    
    setTimeout(() => {
      // play vocab word audio
      this.playVocabAudio(true);
    },2000);
  }

  // to add particular word as saved/wishlist/important vocabs
  addRemoveSavedVocabulary = async(actionType) => {
    const postData = {
      vocab_id: this.state.testPopUpData["id"],
      user_id: store.getState().user.user_id,
      type:actionType
    };
    let response = await API({
      method: 'POST',
      url: '/saved/vocabs/add_edit',
      data: postData
    });
    if (response.status === 200){
      if (actionType === "add"){
        this.setState({savedVocabIds:[...this.state.savedVocabIds, this.state.testPopUpData["id"]]});
      }
      else{
        let filteredArray = this.state.savedVocabIds.filter(item => item !== this.state.testPopUpData["id"])
        this.setState({savedVocabIds: filteredArray});
      }
      store.dispatch(setAlert({'flag':true,'type':1,'message': response.data.message}));
    }
    else{
      store.dispatch(setAlert({'flag':true,'type':2,'message': response.data.message}));
    }
  }

  // clear translation timeout if set
  clearTranslation = () =>{
    if (this.translationShowTimeoutRef.current){
      clearTimeout(this.translationShowTimeoutRef.current)
      this.translationShowTimeoutRef.current = null;
    }
  }

  // play audio of a word & translation after 5 seconds
  playVocabAudio = (word=false, translation=false, currentWordObj=this.state.testPopUpData) => {

    if(Object.keys(currentWordObj).length > 0){
      let vocabAudioName = currentWordObj['id'] + ".mp3";
      let vocabAudioPath = VOCAB_TRANSLATION_AUDIO_PATH + vocabAudioName;
      // get audio for vocabulary word 
      if (word) {
        vocabAudioName = currentWordObj['word'].trim().replace(/[^a-zA-Z0-9]/g, "").trim().toLowerCase() + ".mp3"; 
        vocabAudioPath = VOCAB_WORD_AUDIO_PATH + vocabAudioName; 
      }
      var audioUrl = baseHost + baseAssetsPath + vocabAudioPath;
      let audio = new Audio(audioUrl);
      audio.play();

      if ((word && this.state.showTranslation === false)){
        this.translationShowTimeoutRef.current = setTimeout(()=>{
          this.setState({showTranslation:true});
          this.playVocabAudio(false, true);
        }, 5000)      
      }

    }
  }

  // correct button handler
  correctCountHandler = () => {
    this.setState({
      correctCount: this.state.correctCount + 1,
      correctClicked:true
    });
    //this.showNextWord();
  }

  // incorrect button handler
  inCorrectCountHandler = () => {
    this.setState({
      inCorrectCount: this.state.inCorrectCount + 1,
      inCorrectClicked:true
    });
    //this.showNextWord();
  }

  // to select category & show vocab count dropdown
  selectShowTestCount = async(selectedCategory) => {
    this.setState({
      selectedTestCategory:selectedCategory,
      testLabel:"Select number of words"
    })
    if ((this.state.testVocabCount > 0 && this.state.selectedTestIds.length < this.state.testVocabCount) && (this.state.testComplete === false)){
      // when test is in progress
      this.setState({
        showTestCloseModal:true
      })
    }else{
      // when test is not in progress
      this.switchToAnotherCatTest(selectedCategory)
    }
  }

  // common function to start the test & reset other states
  switchToAnotherCatTest = async(selectedCategory=this.state.selectedTestCategory) =>{
    this.setState({
      selectedTestCategory:selectedCategory,
      showCountOption:true,
      showTestModal:false,
      testVocabCount:0,
      selectedTestIds:[],
      //testLabel:"Create Test"
    });
    this.resetStates();
  }

  // handler to manage the test count
  changeTestCount = async(e) =>{
    let { name, value } = e.target;
    this.setState({
      testVocabCount:parseInt(value)
    })
    // get all test vocabularies of selected category
    await this.getTestVocabularies(this.state.selectedTestCategory, parseInt(value));
    // start vocabulary test, show first word of selected category
    if (parseInt(value) > 0){
      this.setState({
        showTestModal:parseInt(value) > 0?true:false,
      })
      await this.startVocabularyTest(parseInt(value))
    }
  }
  
  // reset states
  resetStates = () =>{
    this.setState({
      //showTestModal:false,
      testComplete:false,
      showTranslation:false,
      correctCount:0,
      inCorrectCount:0,
      correctClicked:false,
      inCorrectClicked:false,
      //testVocabCount:0,
      showTestCloseModal:false
    });
    this.clearTranslation();
  }

  render() {
    let { count, srNo} = this.state;
    let title = "Create Vocabulary Test";
    let i = srNo * count-count;
    return (
      <>
        {this.state.paidUser === 0?(<PaidUserCheck/>):null}
        {this.state.showTestCloseModal && <Modal isOpen={this.state.showTestCloseModal} id="exitPopup" size="md">
        <div className="modal-body">
          <button onClick={(e)=>this.setState({showTestCloseModal:false})} type="button" className="close" data-dismiss="modal">&times;</button>
          <div className="mobileIcon">
            <Card>
              <CardTitle><h3>Exit Test</h3></CardTitle>
              <CardBody>
                <h4>Only {this.state.selectedTestIds.length} vocabulary practiced out of {this.state.testVocabCount}. Are you sure you want to exit ?</h4>
                <button className="btn" onClick={()=>this.switchToAnotherCatTest()}>Yes</button>
                <button className="btn cancelBtn" onClick={(e)=>this.setState({showTestCloseModal:false})} >Cancel</button>
              </CardBody>
            </Card>         
          </div>
          <div className="contentInr">    
          </div>
        </div>
        </Modal>}
        {this.state.paidUser &&
          <div className="content vocabTestOuter">
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4"><span>{title}</span></CardTitle>
                </CardHeader>
              <CardBody>
              <Row className="vocacbTestCntnt">
                <Col lg={3} md={3} sm={3} className="testCatList p-0">
                  <ul>
                    {/*<li>All</li>*/}
                    {
                      this.state.vocabCategories.map((category, index) => (
                        (category.name !== "Saved" && category.name !== "Online")?(
                          <li 
                            className={this.state.selectedTestCategory['id'] === category.id?"active":""}
                            key={index}
                            onClick={(e)=>
                              this.selectShowTestCount({
                                id:category.id,
                                name:category.name
                              })
                            }
                          >
                          {category.name}</li>
                        ):null
                      ))
                    }
                  </ul>
                </Col>
                <Col lg={9} md={9} sm={9} className="testResult">
                  {this.state.showTestModal === false?(
                    <h4 className="mt-0 testResultTitle">{this.state.testLabel}</h4>
                  ):null}
                  {
                    this.state.showTestModal === false && this.state.showCountOption === false?(
                      <div className="testInstructions">
                        <p>1. Select category from left <b><i className="fa fa-angle-double-left"></i></b> menu of which you need to create test.</p>
                        <p>2. Select no of vocabularies you need to practice.</p>
                        <p>3. Test started, please practice vocab pronunciation & translation.</p>
                      </div>
                    ):null
                  }
                  {
                    this.state.showCountOption?(
                      <>
                      {/*<span onClick={(e)=>this.setState({showCountOption:false,showTestModal:true})}>{this.state.testTotal}</span>*/}
                      <select 
                        onChange={this.changeTestCount}
                        name="selectedCategoryCount"
                        className="form-control"
                        //defaultValue={this.state.categoryName}
                        value={this.state.testVocabCount}
                        onFocus={(e)=>{e.target.size = 8}}
                        >
                        <option value="">-------Select-------</option>
                        {
                          ["10","20","30","40","50","60","70","80","90","100+","200+","300+"].map((count, index) => (
                            <option key={index} value={count} >{count}</option>
                          ))
                        }
                      </select>
                      </>
                    ):null
                  }
                  {
                  this.state.showTestModal?(
                    <Card className="testContainer">
                      {this.state.testComplete === false?(
                        <>

                        <CardTitle className="text-right mb-0">
                          <Button 
                            color="primary"
                            size="sm"
                            onClick={
                              (e)=>this.state.savedVocabIds.includes(this.state.testPopUpData["id"])?
                              this.addRemoveSavedVocabulary("delete_app"):
                              this.addRemoveSavedVocabulary("add")
                            }
                            >
                            <i 
                              className={
                                this.state.savedVocabIds.includes(this.state.testPopUpData["id"])?
                                  "fa fa-heart":
                                  "fa fa-heart-o"
                              }
                              aria-hidden="true"></i>
                          </Button>
                          <Button 
                            color="info"
                            className="bottom-btn"
                            size="sm"
                            onClick={(e)=>this.setState({testComplete:true})}
                            >
                            Done
                          </Button>
                        </CardTitle>
                        <CardBody>
                          {
                            (this.state.testPopUpData !== undefined)?(
                              <>
                                <h5 className="mt-0">{this.state.testPopUpData['word']}</h5>
                                {
                                  this.state.showTranslation === false?(
                                  <>
                                  <div className="speakerImg">
                                    <img src="/images/speaker.png" alt="speaker-icon" />
                                  </div>
                                  <h4>Speak your answer</h4>
                                  </>
                                  ):(
                                  <>
                                  <div className="translationCntnt" style={{display:this.state.showTranslation?"block":"none"}}>
                                    <h5><span>Transalation:</span> {this.state.testPopUpData['translation']}</h5>
                                    {/*<div className="buttonContainer">
                                      
                                    </div>*/}
                                  </div>
                                  <div className="testBottomBtns">
                                    <Button 
                                        color="primary"
                                        className="bottom-btn"
                                        onClick={(e)=>this.correctCountHandler()}
                                        >
                                        Correct &nbsp;&nbsp;<i className="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                    </Button>
                                    <Button 
                                      color="danger"
                                      className="bottom-btn"
                                      onClick={(e)=>this.inCorrectCountHandler()}
                                      >
                                      In-correct &nbsp;&nbsp;<i className="fa fa-thumbs-o-down" aria-hidden="true"></i>
                                    </Button>
                                  </div>
                                  <div className="mt-3">
                                    <Button 
                                      color="primary"
                                      className="bottom-btn"
                                      onClick={(e)=>this.showNextWord()}
                                      disabled={
                                        (this.state.inCorrectClicked || this.state.correctClicked)?false:true
                                      }
                                      >
                                      <i className="fa fa-angle-double-right"></i>
                                    </Button>

                                  </div>
                                </>
                              )}
                              </>
                            ):null
                          }
                        </CardBody>
                        </>
                      ):(
                      <>
                        <CardBody className="testResultCntnt">
                          <p>Congrats, test has been completed!</p>
                          <img src="/images/congrats.png"  alt="congrats"/>
                          <span>Your test stats:-</span>
                          <span>Correct:-  <strong>{this.state.correctCount}</strong></span>
                          <span>In-correct:- <strong>{this.state.inCorrectCount}</strong></span>
                        </CardBody>
                      </>
                      )
                    }
                    </Card>

                  ):null
                  }
                </Col>
              </Row>
              </CardBody>
              </Card>
            </Col>
          </div>
        }
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VocabularyTest);
