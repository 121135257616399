import React,{Component} from "react";

// reactstrap components
import {
  Col
} from "reactstrap";
import reactStringReplace from 'react-string-replace';
//import jsxToString from 'jsx-to-string';
import "components/custom.css";
import { arrayMove} from 'react-sortable-hoc';
import ReactHtmlParser from 'react-html-parser';


class WrittingBlank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question:"",
      secondCount:1200,
      questionId:"",
      reset:false,
      resultObj:{},
      board:{
            lanes: [
              {
                id: 1,
                title: 'ReArrange Lines',
                cards: [
                  {
                    id: 1,
                    title: 'Add card',
                  },
                  {
                    id: 2,
                    title: 'AAdd card',
    
                  },
                ]
              }
            ]
          },
    items:[],
    correctFlag:true,
    answerData:{'answer':[],'correct':[]}
  }
  }  
  componentDidMount() {
  	var intervalId = setInterval(this.timer, 1000);
    this.setState({intervalId: intervalId});

  }

  handleChange = (event) => {
    this.setState({selectedValue: event.target.value});
  };

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  		  clearInterval(state.intervalId);
  		  clearInterval(state.recordId);
        document.querySelectorAll('.span11').forEach((t) => { t.value = "" } );
  		  return {correctFlag:true,secondCount:1200,questionId: props.data.id,reset:true,items:props.data.option,answerData:{'answer':[],'correct':[]}};
  		}else{
  			if (!state.questionId) {
  				return {questionId: props.data.id,correctFlag:true};
  			}
  		}
  		}    
  }



	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };

  changeSelect = (e) =>{
    this.props.showResult();
    let tempData = this.state.answerData.answer.slice();
    tempData[e.target.id] = e.target.value;
    
    this.props.submit({'answer':tempData,'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; }) },true);
    this.setState({resultObj:{'answer':tempData,'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; }) },answerData:{'answer':tempData,'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; }) } });
  }

  showAnswer = () => {

    
      if (!this.props.resultMode) {
        let elem = document.querySelectorAll('.checkedSign');
        for (var i = 0; i <= elem.length-1; i++) {
          let id = elem[i].dataset.id;
          
          if (elem[i].dataset.text.trim() === document.getElementById(id).value.trim()) {
            elem[i].innerHTML= '<i class="fa fa-check" style="font-size:32px;color:green"></i>';
          }else{
            elem[i].innerHTML = '<i class="fa fa-times" style="font-size:32px;color:red"></i>';

          }    
        }  
      }
      
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  updateCorrect = () =>{
    this.props.submit({'answer':[],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; }) });
    this.setState({correctFlag:false,resultObj:{'answer':[],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; }) }})
  } 
  
  render() {
    if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.answer) { 
      this.showAnswer();
    }

    if (this.props.stopRecording) {
      this.stopTest();      
    }
    let item =[];
  	let question ="";
    let correct =[];
  	if (this.props.data) {
  		question = this.props.data.question;
      correct = this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !==0) return i; });
      if (this.state.items.length === 0) {
        this.setState({items:this.props.data.option});
      }

      item = this.props.data.option;

      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  	}
    
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		this.setState({intervalId: intervalId,reset:false});
  	}
    return (
      <>
      <Col xs={12}>
      	<h4 class="infoText"></h4>
      </Col>
      

        <Col xs={12}><h5>{this.props.data && !this.props.resultMode && reactStringReplace(question.replace(/<[^>]*>?/gm, 'x_x').replace(/x_x(.*?)x_x/g,''), /(__add_blank__)/g, (m, j, p) => (<>
                        <select className="span11" id={j} onChange={(e) => {this.changeSelect(e)}}><option></option>{ item[(j-1)/2].options.split(',').map(function(item){ return <option>{item}</option> })}</select><span className="checkedSign" style={{display: (this.props.answer)?"inline-block":"none"}} data-id={j} data-text={correct[(j-1)/2]} ></span><span className="correct" style={{display: (this.props.answer)?"inline-block":"none"}} >{correct[(j-1)/2]}</span></>
        ))}</h5></Col>

        
      <Col xs={12}>
        <h3 className="question">{this.props.data && this.props.data.question_mcq}</h3>
      </Col>
        <Col xs={12}>{this.props.data && this.props.resultMode && <h4>Correct answer</h4>}<h5>{this.props.data && this.props.resultMode && ReactHtmlParser(question.replace(/<span[^>]*>?/g, '<b class="correct">').replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5></Col>
        <Col xs={12}>{this.props.data && this.props.resultMode && <h4>Your response</h4>}<h5>{this.props.data && this.props.resultMode && ReactHtmlParser(question.replace(/<span[^>]*>?/g, '<b class="selected-option">').replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5></Col>
        
      </>
    );
  }
}

export default WrittingBlank;
