import React from "react";

import "components/custom.css";
import { Redirect } from "react-router-dom";

class MockTimerComponent extends React.Component {
  waveref = null;
  constructor(props) {
    super(props);
    this.state = {
    	mockIntervalId:null,
    	mockTimeSecond:this.props.mockTimeSecond?this.props.mockTimeSecond:20*60
    };
  }
  
  componentDidMount() {
  	this.mockTimerRecord(true, true);    
  }

  mockTimerRecord = (setNow=false, resourceTested=false) => {
    // pass recording time to parent component
    if(this.props.stopTimer){
      clearInterval(this.state.mockIntervalId);
      if (this.props.handlerUpdateMockTime){
        this.props.handlerUpdateMockTime(this.state.mockTimeSecond);
      }      
    }
    if (setNow === false){
      if (this.state.mockTimeSecond <= 1) {
        clearInterval(this.state.mockIntervalId);
        // stop the recording & its timer & show recording voice & original sample
        //this.stopRecording();
        this.forceUpdate();
      }
      var currentMockTime = this.state.mockTimeSecond - 1;
      this.setState({ mockTimeSecond: currentMockTime });
    }
    else{
      if ((this.props.resourceTested) || (resourceTested)){
        var mockIntervalId = setInterval(this.mockTimerRecord, 1000);
        this.setState({mockIntervalId: mockIntervalId});
      }
    }
  }

  render() {
    if (this.state.mockTimeSecond === 0){
      alert("Time up please restart the Test");
      this.props.handlerUpdateMockTime(this.state.mockTimeSecond);
      return <Redirect to={'/admin/mock/listing'} />;
    }
    return (
    <>
    {
    	(this.props.resourceTested)?(
    		<p>
    			<i className="fa fa-clock-o"></i>
    			<span>Time Remaining: {Math.floor(this.state.mockTimeSecond / 60) +':'+ parseInt(this.state.mockTimeSecond % 60)}</span>
    		</p>

    	):null
    }
    {this.state.mockTimeSecond === 0 && <div>Time up please restart the Test</div> }
    </>
    );
  } 
}
export default MockTimerComponent;