import React from "react";

// reactstrap components
import {
  Button,
  Input,
  Alert,Modal,ModalBody, ModalFooter,Table
} from "reactstrap";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { Redirect } from "react-router-dom";
import {store} from 'store/index';
import { setAlert, setToken, setUser, setLogo, setSub } from 'action/index.js';
import SimpleReactValidator from 'simple-react-validator';
import "components/custom.css";
/*import DatePicker from "react-datepicker";*/
import { Helmet } from "react-helmet";
import "react-datepicker/dist/react-datepicker.css";
import API from "api.js";
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
   return {
      token: state.token
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok)),
      setUser: (user) => dispatch(setUser(user)),
      setSub: (sub) => dispatch(setSub(sub)),
      setLogo: (sub) => dispatch(setLogo(sub))
   };
};
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      cpassword: '',
      mobile: '',
      userName: '',
      firstName: '',
      lastName: '',
      countryState:'',
      examDate: '',
      center:'',
      score:'',
      otp:'',
      country_residence:'',
      isOtp:false,
      isInstitude:true,
      login:false,
      error:null,
      modal:false,
      error_bag:null,
      dobs:'',
      languageArr:[],
      languageId:'',
      responseOtp:''
    };
    this.validator = new SimpleReactValidator();
  }
  async componentDidMount() {
    ReactGA.initialize('UA-157984080-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init('197333187452630');
    ReactPixel.pageView();
    document.body.classList.toggle("register-page");
    // get languages
    this.getLanguages()
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }

  getLanguages = async() => {
    try{
      const response = await API({
        method: 'GET',
        url: '/languages'
      });
      //console.log("RES",response) 
      if (response.status === 200)
        this.setState({ languageArr: response.data.data.languages });
    }catch(error) {
        this.setState({error:{error} });
    };
  }

  sendOTP = async(e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
    }

    this.setState({error_bag:null});
    e.preventDefault();
    let postData = {
      first_name : this.state.firstName,
      last_name  : this.state.lastName,
      email      : this.state.email,
      city       : this.state.country_residence,
      state      : this.state.countryState,
      mobile     : this.state.mobile,
      language_id: this.state.languageId,
      exam_date  : this.state.examDate,
      password   : this.state.password
    };
    //console.log("OIS", postData)
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/sendOtp',
        data: postData
      })
      
      if (res.data.success) {
        this.setState({'isOtp':true, responseOtp:res.data.otp});
        store.dispatch(setAlert({'flag':true,'type':1,'message':"Please check your email for verification code."}));
      }else {
        this.setState({error:res.data.message });
        store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
        this.setState({error_bag:res.data.message});

        if (this.state.error.data !== undefined){
          let str = '';
          Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
          //console.log("ELSE ", str, Object.values(this.state.error.data.errors))
          this.setState({error_bag:str});
        }
        this.forceUpdate();
        return;
      }
    }catch(error) {
        this.setState({error:{error} });
    };


  }

  postSignUp = async(e) => {
    if (this.state.otp === ""){
      store.dispatch(setAlert({'flag':true,'type':1,'message':"Please enter verification code."}));
      return;
    }
    if (parseInt(this.state.responseOtp) !== parseInt(this.state.otp)){
      store.dispatch(setAlert({'flag':true,'type':1,'message':"Verification code is invalid. Please check your mail again for verification code."}));
      return;
    }

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
    }
    this.setState({error_bag:null});
    e.preventDefault();

    let postData = {
      first_name : this.state.firstName,
      last_name  : this.state.lastName,
      email      : this.state.email,
      city       : this.state.country_residence,
      state      : this.state.countryState,
      mobile     : this.state.mobile,
      language_id: this.state.languageId,
      exam_date  : this.state.examDate,
      password   : this.state.password,
      otp        : this.state.otp
    };
    
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/signup',
        data: postData
      })
    }catch(error) {
      this.setState({error:{error} });
    };
    //console.log("POST OTP",res);
    if (res.data.success) {
      //this.setState({'login':true});
      store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60);
      this.props.setToken(res.data.token);
      this.props.setUser(res.data.data.userData);
      
      //console.log("UD", res.data.data.userData, res.data.data.userData.user_paid_plan_end_date)
      this.props.setLogo(res.data.data.userData.profile_image);
      let sub  = (res.data.data.userData.user_paid_plan_end_date === null)?false:true;
      this.props.setSub(sub);
      if (res.status === 200) {
        window.location.href  = "/admin/dashboard";
        //this.setState({login:true,loginLock:false});     
      }
    }else if (res.data.success === false) {
      store.dispatch(setAlert({'flag':true,'type':2,'message':res.data.message}));
    }else{
      this.setState({error:res.data.message });
      store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
      if (this.state.error.data !== undefined){
      
        let str = '';
        Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
        this.setState({error_bag:str});
      }
    }
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

  toggleModal = (e) => {
    this.setState({modal: !this.state.modal });
  };

  changeCheckInput = (e) => {
    let { name } = e.target;
    this.setState({ [name] : !this.state.isInstitude });
  };


  changeDate = (date) =>{
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    //console.log([year, month, day].join('-'));
    this.setState({examDate:[year, month, day].join('-'),dobs:date});
  }

  selectCountryResidence (val="") {
    this.setState({ country_residence: val, countryState : "" });
  }

  selectRegion (val) {
    this.setState({ countryState: val });
  }

  render() {
    if (this.state.login) {
        return (<Redirect to="/register-success" />);
    }
    return (
      <React.Fragment>

      <Helmet>
        <title>Register Language Academy</title>
        <meta name="twitter:title" content="Register Language Academy" />
        <meta property="og:title" content="Register Language Academy" />
        <meta name="description" content="Register your free account now at Language Academy to obtain your Free Mock Test Material." />

        <meta name="twitter:description" content="Register your free account now at Language Academy to obtain your Free Mock Test Material." />
        <meta property="og:description" content="Register your free account now at Language Academy to obtain your Free Mock Test Material." />
      </Helmet>
      <div className="innerPage">
      <Modal isOpen={this.state.modal} size="lg"  >
        <ModalBody>
          <Table>
          <tr>
            <td>
              Person Aiming for 5 bands, if he get 36 in overall in full mock test, or 30+ in reading  test or 50+ in listening mock then select this option in dropdown.
            </td>
          </tr>
          <tr>
            <td>
              Person Aiming for 5.5 bands, if he get 42 in overall in full mock test, or 30+ in reading  test or 55+ in listening mock then select this option in dropdown.
            </td>
          </tr>
          <tr>
            <td>
            Person Aiming for 6 bands, if he get 46 in overall in full mock test, or 35+ in reading  test or 50+ in listening mock then select this option in dropdown.
            </td>
          </tr>
          <tr>
            <td>
            Person Aiming for 6.5 bands, if he get 50 in overall in full mock test, or 40+ in reading  test or 55+ in listening mock then select this option in dropdown.
            </td>
          </tr>
          <tr>
            <td>
            Person Aiming for 7 bands, if he get 55 in overall in full mock test, or 45+ in reading  test or 65+ in listening mock then select this option in dropdown.
            </td>
          </tr>
          <tr>
            <td>
            Person Aiming for 7.5 bands, if he get 62 in overall in full mock test, or 50+ in reading  test or 70+ in listening mock then select this option in dropdown.
            </td>
          </tr>
          <tr>
            <td>
              Person Aiming for 8 bands, if he get 68+ in overall in full mock test, or 55+ in reading  test or 75+ in listening mock then select this option in dropdown.
            </td>
          </tr>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="modalCloseBtn" onClick={this.toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
      <section className="bannerOuter">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h1>Create Account</h1>
        <span>Register now to obtain your free Mock Test Material</span>
      </div>
    </div>
  </div>
</section>
{!this.state.isOtp && <section className="contentOuter">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 registerForm">

        {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}

          <div className="fieldOuter col-md-6">
          <label>First Name</label>
            <Input
                placeholder="First Name"
                type="text"
                name="firstName"
                value={this.state.firstName}
                onChange={(e) => { this.changeInput(e)} }
              />
              {this.validator.message('First name', this.state.firstName, 'required')}
          </div>
          <div className="fieldOuter col-md-6">
          <label>Last Name</label>
              <Input
                placeholder="Last Name"
                type="text"
                name="lastName"
                value={this.state.lastName}
                onChange={(e) => { this.changeInput(e)} }
              />
              {this.validator.message('Last name', this.state.lastName, 'required')}
          </div>
          <div className="fieldOuter col-md-6">
          <label>Email</label>
            <Input
              placeholder="Email"
              type="email"
              name="email"
              value={this.state.email}
              onChange={(e) => { this.changeInput(e)} }
            />
            {this.validator.message('Email', this.state.email, 'required|email')}
          </div>
          <div className="fieldOuter col-md-6">
          <label>Mobile</label>
            <Input
              placeholder="Mobile"
              type="number"
              name="mobile"
              value={this.state.mobile}
              onChange={(e) => { this.changeInput(e)} }
            />
            {this.validator.message('Mobile', this.state.mobile, 'required')}
          </div>
          {
          /*<div className="fieldOuter col-md-6">
          <label>Country of residence</label>
          <CountryDropdown
            value={this.state.country_residence}
            onChange={(val) => this.selectCountryResidence(val)} name="country_residence"/>
            {this.validator.message('Country of residence', this.state.country_residence, 'required')}
          </div>
          <div className="fieldOuter col-md-6">
          <label>State (of Country of residence)</label>
          <RegionDropdown
            country={this.state.country_residence}
            value={this.state.countryState}
            onChange={(val) => this.selectRegion(val)} name="countryState"/>
          {this.validator.message('State', this.state.countryState, 'required')}
          </div>*/
          }
          <div className="fieldOuter col-md-6">
          <label>Exam Date</label>
              <Input
              placeholder="Exam Date (YYYY-MM-DD)"
              type="date"
              name="examDate"
              value={this.state.examDate}
              onChange={(e) => { this.changeInput(e)} }
            />

            {this.validator.message('examDate', this.state.examDate, 'required')}
          </div>
          <div className="fieldOuter col-md-6">
          <label>Language</label>
            <select className="language-dropdown" onChange={(e) => { this.changeInput(e)} } name="languageId" value={this.state.languageId}>
              <option value="">-</option>
              {this.state.languageArr.length > 0 && this.state.languageArr.map((v,key)=>{
                return (<option value={v.id} key={key}>{v.label}</option>);
              })}
            </select>
            {this.validator.message('Language', this.state.languageId, 'required')}
          </div>
          <div className="fieldOuter col-md-6">
          <label>Password</label>
            <Input
              placeholder="Password"
              type="password"
              name="password"
              value={this.state.password}
              onChange={(e) => { this.changeInput(e)} }
            />
            {this.validator.message('Password', this.state.password, 'required')}
          </div>
          <div className="fieldOuter col-md-6">
          <label>Confirm Password</label>
            <Input
              placeholder="Confirm Password"
              type="password"
              name="cpassword"
              value={this.state.cpassword}
              onChange={(e) => { this.changeInput(e)} }
            />
            {this.validator.message('Confirm password', this.state.cpassword, 'required')}
            </div>
            <br />

          <div className="fieldOuter text-center mb-0">
            <button className="themeBtn blueBtn" onClick={this.sendOTP}>Send Verification Code</button>
          </div>
      </div>
    </div>
  </div>
</section>}
{this.state.isOtp && <section className="contentOuter">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 registerForm">
        <div className="alert alert-success">Please check your email for Verification Code.</div>
        {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
          <div className="fieldOuter">
            <Input
                placeholder="Enter Verification Code"
                type="text"
                name="otp"
                value={this.state.otp}
                onChange={(e) => { this.changeInput(e)} }
              />
              {this.validator.message('otp', this.state.otp, 'required')}
          </div>
          <div className="fieldOuter text-center mb-0">
            <button className="themeBtn blueBtn" onClick={this.postSignUp}>SIGN UP</button>
          </div>
      </div>
    </div>
  </div>
</section>}
</div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
