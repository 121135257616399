import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
} from "reactstrap";
import API, { AUDIO_PATH, ASSETS_SERVER_PATH, EXAM_AUDIO_PATH } from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import { Redirect } from "react-router-dom";
import { setUser} from 'action/index.js';
// core components
import { connect } from 'react-redux';
import getBlobDuration from 'get-blob-duration';
import WaveSurferModuleComponent from "components/Wavesurfer/WaveSurferModuleComponent.jsx";

const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};

class DialogueAttempt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false,
      dialogueId:this.props.match.params.id,
      languageId:this.props.match.params.language_id,
      currentIndex:0,
      isMock:false,
      exitModal:false,
      segmentModal:false,
      redirect:false,
      segmentIndex:-1,
      segmentName:"START",
      segmentTranscript:"",
      segmentAudio: "",
      blobURL: '',
      isBlocked: false,
      isRecording:false,
      volume:100,
      soundStatus:true,
      nextFlag:false,
      showRecordingTimer:false,
      isSegment:false,
      audioEnded:false,
      secondCount:5,
      recordCount:0,
      intervalId:0,
      recordIntervalId:1,
      reset:false,
      recordingCompleted:false,
      all_ok1:false,
      totalSegments:0,
      segmentAudioBlob:null,
      segmentResponseAudioBlob:null,
      stopPlayingAudio:false, 
      nextSegmentClicked:false,
      destroyWavsurfer:false,
      attemptStarted:false,
      showDescription:false
    };
  }
  async componentDidMount() {
    // close the sidebar
    document.body.classList.add("sidebar-mini");
    
    let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
    let all_ok1 = (isChrome)?true:false;

    navigator.getUserMedia({ audio: true },
    () => {
      //console.log('Permission Granted');
      this.setState({all_ok1:all_ok1});
      this.setState({isBlocked:false});
    },
    () => {
      this.setState({all_ok1:false});
      this.setState({isBlocked:true});
      //console.log('Permission Denied');
      },
    );
    // get dialogue detail
    await this.getDialogueDetail(this.state.dialogueId, this.state.languageId)
  }
  
  closeExitModal = () =>{
    this.setState({exitModal:!this.state.exitModal});
  }
  navigateBack = () =>{
    this.setState({redirect:!this.state.redirect});
  }
  closeSegmentModal = () =>{
    this.setState({segmentModal:!this.state.segmentModal});
  }
  navigateSegment = async(index) => {

    let dialogueSegmentAudio = "";
    this.setState({destroyWavsurfer:true})
    if (index < 0){
      this.setState({
        showDescription:false,
        recordingCompleted:false,
        nextSegmentClicked:false
      });
    }
    if (index === -1){
      this.setState({segmentName: "START"})
      this.setState({segmentAudio: ASSETS_SERVER_PATH + AUDIO_PATH + this.state.data.start_audio})
      this.setState({segmentTranscript: this.state.data.start_transcript})
      this.setState({isSegment: false})

      dialogueSegmentAudio =  this.state.data.start_audio;

    }
    else if (index === -2){
      this.setState({segmentName: "END"})
      this.setState({segmentAudio: ASSETS_SERVER_PATH + AUDIO_PATH + this.state.data.end_audio})      
      this.setState({segmentTranscript: this.state.data.end_transcript})
      this.setState({isSegment: false})
      dialogueSegmentAudio =  this.state.data.end_audio;
    }
    else{
      this.setState({isSegment: true})
      this.setState({segmentName: index+1 + "/" + this.state.data.dialogue_segments.length})
      this.setState({segmentAudio: ASSETS_SERVER_PATH + AUDIO_PATH + this.state.data.dialogue_segments[index].audio_path})      
      dialogueSegmentAudio =  this.state.data.dialogue_segments[index].audio_path;
      this.setState({segmentTranscript: this.state.data.dialogue_segments[index].transcript})
      //this.setState({totalSegments: this.state.data.dialogue_segments.length})
      
      this.setState({segmentSampleAudio: ASSETS_SERVER_PATH + AUDIO_PATH + this.state.data.dialogue_segments[index].sample_response})      
      this.setState({segmentSampleTranscript: this.state.data.dialogue_segments[index].sample_transcript})
      // get blob for segment sample audio 
      await this.getSegmentAudioBlob(this.state.data.dialogue_segments[index].sample_response, true)
      
    }
    this.setState({segmentIndex: index})
    this.setState({segmentModal:false})
    //console.log(this.state.segmentIndex, this.state.totalSegments)
    this.resetSegment();
    
    if (dialogueSegmentAudio)
      await this.getSegmentAudioBlob(dialogueSegmentAudio, false, false, true)
  }

  getDialogueDetail = async(dialogueId,languageId,pageFrom="dialogue") => {
    const url = (pageFrom.toLowerCase() === 'exammemories') ? 'exam_memories/' : 'dialogue/';
    try{
      const response = await API({
        method: 'GET',
        url: url + dialogueId,
      });

      if (response.data.success && response.data.data.dialogue_details) {
        const dialogueDetails = response.data.data.dialogue_details;
        this.setState({data:dialogueDetails});
        this.setState({totalSegments:dialogueDetails.dialogue_segments.length});
        this.setState({segmentAudio: ASSETS_SERVER_PATH + AUDIO_PATH + dialogueDetails.start_audio})
        this.setState({segmentTranscript: dialogueDetails.start_transcript})
        // get blob for segment sample audio 
        await this.getSegmentAudioBlob(dialogueDetails.start_audio)
      } else {
        console.log("ERROR in fetchDialgoue : ", response.data);
      }
    }catch(error){
      console.log("ERROR IN CATCH", error)
    };
  }

  segmentAudioEnded = () =>{
    this.setState({audioEnded:true});
  }

  handleSegmentNavigation = (index, action="next") => {
    this.setState({stopPlayingAudio:true, nextSegmentClicked:true});
    if (this.child)
      this.child.updateAutoPlayViaParent(false);
    let segmentIndex = 1;
    if (action === "next"){
      segmentIndex += index;
      if (segmentIndex === this.state.totalSegments)
        segmentIndex = -2;
    }else{
      if (index === 0)
        segmentIndex = -1;
      else if(index === -2)
        segmentIndex = this.state.totalSegments - 1;
      else
        segmentIndex = index - segmentIndex;
    }
    this.resetSegment()
    if (segmentIndex < this.state.totalSegments){
      this.navigateSegment(segmentIndex);
    }
  }

  resetSegment = (isReset=false) => {
    this.setState({
      recordingCompleted:false,
      secondCount:5, 
      recordCount:0, 
      audioEnded:false,
      showRecordingTimer:false,
      isRecording: false
    });
    if (isReset)
      this.setState({reset:true});
  }

  getRecordingAudioBlob = async(recordedAudioBlobUrl) =>{
    if (recordedAudioBlobUrl){
      if (this.child)
        this.child.updateRecordedWaveformAudio(recordedAudioBlobUrl, "#recorded_waveform");
      //this.forceUpdate();
      //this.setState({packageRecordedAudio:recordedAudioBlobUrl})
      let duration = await getBlobDuration(recordedAudioBlobUrl);
      let file = new File([recordedAudioBlobUrl], this.state.dialogueId+"_"+this.state.segmentId+".mp3", { type: recordedAudioBlobUrl.type });
      // update required state variables
      this.setState({
        blobURL: recordedAudioBlobUrl,
        isRecording: false,
        recordCount:0,
        file:file,
        segmentDuration:duration,
        recordingCount: this.state.recordingCount + 1,
        recordingCompleted:true
      });
    }
  }

  getSegmentAudioBlob = async(segmentAudioPath, forSegmentResponse=false, forUserResponse=false, resetChild=false) => {
    const url = 'convert_mock_audio';
    var bodyFormData = new FormData();
    bodyFormData.set('path', segmentAudioPath);
    await API({
        method: 'POST',
        url: url,
        data: {
          'is_response':false,
          'is_dialogue':true,
          'file_path':segmentAudioPath
        },
      }).then(response => {
        if (response.data){
          if (response.data.success) {
            if (resetChild){
              if (this.child){
                this.child.resetComponent(response.data.data.base64, "#waveform", false, this.state.segmentIndex);
              }
              this.forceUpdate();
            }
            // for segment response 
            if (forSegmentResponse)
              this.setState({segmentResponseAudioBlob:response.data.data.base64})
            
            // for user recorded response
            if (forUserResponse)
              this.setState({segmentUserResponseAudioBlob:response.data.data.base64})

            // for segment
            if (!forUserResponse && !forSegmentResponse)
              this.setState({segmentAudioBlob:response.data.data.base64})
          
          } else {
            //showMessage({ message: response.data.message, type: "danger" });
            console.log("ERROR in convert_mock_audio : ", response.data);
          }          
        }
    }).catch((error)=>{
      console.log("ERROR IN CATCH", error)
    });
  }

  handlerAttemptStarted = (isStarted=false) =>{
    this.setState({attemptStarted:isStarted});
  }

  render() {

    let i = 0;
    if (this.state.redirect) {
      return <Redirect to={'/admin/dialogues/'} />;
    }
    if (this.state.reset) {
      this.setState({reset:false, secondCount:5, recordCount:0});
      window.location.reload(true)
    }
    return (
      <>
      {!this.state.all_ok1 && <ChromeCheck />}
      
      {this.state.exitModal && <Modal isOpen={this.state.exitModal} id="exitPopup" size="md">
        <div className="modal-body">
          <button onClick={(e)=>this.closeExitModal()} type="button" className="close" data-dismiss="modal">&times;</button>
          <div className="mobileIcon">
         
                <Card>
                  <CardTitle><h3>EXIT</h3></CardTitle>
                  <CardBody>
                  <h4>Are you sure you want to go back ?</h4>
                  <button className="btn" onClick={()=>this.navigateBack()}>Yes</button>
                  
                  <button className="btn cancelBtn" onClick={()=>this.closeExitModal()}>Cancel</button>
                  </CardBody>
                </Card>
         
          </div>
          <div className="contentInr">    
          </div>
        </div>            
        </Modal>}
      {this.state.segmentModal && <Modal isOpen={this.state.segmentModal} id="segmentPopup" size="lg">
        <div className="modal-body">
          <button onClick={(e)=>this.closeSegmentModal()} type="button" className="close" data-dismiss="modal">&times;</button>
          <div className="mobileIcon">
         
                <Card>
                  <CardTitle><h3>Select Segment</h3></CardTitle>
                  <CardBody>
                    <Row>
                      <Col>
                      <Button className="btn-icon btn-round btn btn-default" onClick={()=>this.navigateSegment(-1)}>Start</Button>
                      {
                        this.state.data.dialogue_segments && this.state.data.dialogue_segments.map((prop, key) => {
                          return(
                              <Button key={key} className="btn-icon btn-round btn btn-default" onClick={()=>this.navigateSegment(key)}>{key+1}</Button>
                          )
                        })
                      }
                      <Button className="btn-icon btn-round btn btn-default" onClick={()=>this.navigateSegment(-2)}>End</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
         
          </div>
          <div className="contentInr">    
          </div>
        </div>            
        </Modal>}
        {this.state.all_ok1 && <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader className="devideTitle">
                  <CardTitle tag="h4"><span>{this.state.data.title}</span></CardTitle>
                  <div className="rightExitBtn">
                    {
                    (this.state.secondCount === 0 && this.state.isSegment)?(
                      <button className="btn" onClick={()=>this.resetSegment(true)}>Reset</button>
                    ):null
                  }                  
                  <Button onClick={()=>{ this.setState({exitModal:!this.state.exitModal}) } } className="btn btn-danger">Finish</Button>
                  </div>
                </CardHeader>                    

                <CardBody>
                  <span className="segmentCount"> {this.state.segmentName} </span>
                  <Button onClick={()=>{ this.setState({segmentModal:!this.state.segmentModal}) } }>Change Segment</Button>
                  <Card className="descriptionCard px-4 pb-4" >
                      {
                        (this.state.segmentIndex !== -2) && (this.state.segmentAudioBlob)?(
                        <div className="currentStatus" id="waveformContainer">
                          <div className="waveformVolume">
                            <WaveSurferModuleComponent
                              key={1}
                              audio={this.state.segmentAudioBlob}
                              containerEle = "#waveform"
                              handlerAttemptStarted={this.handlerAttemptStarted.bind(this)}
                              showAttemptText={false}
                              hidePlayPause={false}
                              forInstructions={!this.state.nextSegmentClicked}
                              showRecordBlinker={this.state.nextSegmentClicked}
                              showPlayingSourceBlinker={this.state.nextSegmentClicked}
                              recordForSeconds={this.state.nextSegmentClicked?30000:0} // 30 sec
                              segmentIndex={this.state.segmentIndex}
                              //forSegment={true}
                              handlerRecordedAudio={this.getRecordingAudioBlob.bind(this)}
                              autoPlayAllowed={false}
                              handlerAudioFinish={this.segmentAudioEnded.bind(this)}
                              ref={instance => { this.child = instance; }}
                              allowRecording={this.state.segmentIndex>=0?true:false}
                              onlyAudioPlayer={true}
                              onlyRecordedPlayer={false}
                              onlySamplePlayer={false}
                            />
                          </div>
                        </div>
                        ):null
                      }
                      {
                        this.state.recordingCompleted || ((this.state.segmentIndex === -1) || (this.state.segmentIndex === -2))?
                        (
                          <div className="descTransTab">
                            <h3 className="mt-4 mb-0" onClick={()=>this.setState({showDescription:!this.state.showDescription})}>
                              Description Transcript
                              {
                                !this.state.nextSegmentClicked?(
                                  <>
                                  &nbsp;
                                  <i className={!this.state.showDescription?"fa fa-arrow-down":"fa fa-arrow-up"} aria-hidden="true">
                                  </i>
                                  </>

                                ):null
                              }
                              </h3>
                              <div className="descTransCntnt" style={{display:this.state.showDescription?"block":!this.state.nextSegmentClicked?"none":"block"}}>
                                <h4>{this.state.showDescription || this.state.nextSegmentClicked ?this.state.segmentTranscript:null}</h4>
                              </div>
                          </div>
                        ):null
                      }
                      {
                        (this.state.recordingCompleted && this.state.isSegment)?
                        (
                          <>
                            <div className="responseBlock">
                              <h3 className="mt-4 pageTitle">Your response</h3>
                              {

                                this.state.blobURL?(
                                  <div className="currentStatus" id="waveformContainer">
                                    <div className="waveformVolume">
                                      {/*<div
                                        className="waveContainer"
                                        id="recorded_waveform"
                                      />*/}
                                      <WaveSurferModuleComponent 
                                        key={this.state.blobURL}
                                        audio={this.state.blobURL}
                                        showAttemptText={false}
                                        hidePlayPause={false}
                                        forInstructions={false}
                                        showRecordBlinker={false}
                                        showPlayingSourceBlinker={false}
                                        autoPlayAllowed={false}
                                        allowRecording={false}
                                        containerEle="#recorded_waveform"
                                        onlyAudioPlayer={false}
                                        onlyRecordedPlayer={true}
                                      />
                                    </div>
                                  </div>
                                ):null
                              }
                            </div>
                            <div className="responseBlock">
                              <h3 className="mt-4 pageTitle">Sample response</h3>
                              <div className="currentStatus" id="waveformContainer">
                                <div className="waveformVolume">
                                  
                                  <WaveSurferModuleComponent 
                                    audio={this.state.segmentResponseAudioBlob}
                                    showAttemptText={false}
                                    hidePlayPause={false}
                                    forInstructions={false}
                                    showRecordBlinker={false}
                                    showPlayingSourceBlinker={false}
                                    autoPlayAllowed={false}
                                    allowRecording={false}
                                    containerEle = "#sample_waveform"
                                    onlyAudioPlayer={false}
                                    onlySamplePlayer={true}
                                  />
                                </div>
                              </div>
                              <div className="translatedText">
                                <h4>{this.state.segmentSampleTranscript}</h4>
                              </div>
                            </div>
                          </>
                        ):null
                      }
                  </Card>
                  <div className="nextPrevOuter">
                    { // previous
                      (this.state.segmentIndex !== -1)?
                        (
                          <Button
                            className="btn-icon btn-round"
                            color="default"
                            onClick={() => this.handleSegmentNavigation(this.state.segmentIndex, "back")}
                            disabled={this.state.attemptStarted === true?true:false}
                          >
                            <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-mini" />
                            <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                            <span>Previous</span>
                          </Button>
                        ):null
                    }
                    { // next
                      (
                        (
                         (this.state.segmentIndex !== -2) 
                          //(this.state.segmentIndex+1 < this.state.totalSegments)
                        )
                       )?
                        (
                          <Button
                            className="btn-icon btn-round"
                            color="default"
                            onClick={() => this.handleSegmentNavigation(this.state.segmentIndex, "next")}
                            disabled={this.state.attemptStarted === true?true:false}
                          >
                            <span>Next</span>
                            <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                            <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-regular" />
                          </Button>
                        ):null
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogueAttempt);
